import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import RefreshCircleIcon from 'mdi-react/RefreshCircleIcon'

import { LandingPageTitle } from '../common/Title'
import Button from '../common/Button'
import LandingContainer from './LandingContainer'

const TextContainer = styled.div`
  max-width: 300px;
`

const Content = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin: 0 0 20px;
`

const StyledButton = styled(Button)`
  width: 120px;
`

const PendingInvitationLanding = ({ invitation, refresh }) => (
  <LandingContainer>
    <TextContainer>
      <LandingPageTitle>
        You have been invited to join a practice.
      </LandingPageTitle>
    </TextContainer>
    <Content>
      Your invitation awaits approval by{' '}
      {invitation.inviter.firstName + ' ' + invitation.inviter.lastName}.
    </Content>

    <StyledButton onClick={() => refresh()} padding="small">
      <RefreshCircleIcon />
      Refresh
    </StyledButton>
  </LandingContainer>
)

PendingInvitationLanding.propTypes = {
  invitation: PropTypes.shape({
    inviter: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string
    })
  }),
  refresh: PropTypes.func
}

export default PendingInvitationLanding
