import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Chart from './Chart'
import { SectionTitle } from '../common/Title'
import { filterDataBySpecialistCategory } from '../../util/filterData'
import PivotTable from './PivotTable'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Bar = styled.div`
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

function ReferralTrends({ receivedReferrals, filters = null }) {
  const filteredReferrals = filters
    ? filterDataBySpecialistCategory(receivedReferrals, 'referrals', filters)
    : receivedReferrals

  if (filteredReferrals.length === 0) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  return (
    <ReportVisualsContainer>
      <SectionTitle>
        Referral Trends (For Referrals Accepted at Triage)
      </SectionTitle>
      <Bar />
      <Chart
        type="stackingColumn"
        legend={true}
        title="By Directed/Next Available"
        dataSourceSettings={{
          valueSortSettings: { headerDelimiter: '##' },
          dataSource: filteredReferrals,
          rows: [{ name: 'year' }, { name: 'month' }],
          columns: [{ name: 'isDirected' }],
          values: [{ name: 'isDirected' }],
          expandAll: true
        }}
        customSettings={{ height: '220', width: '100%' }}
        expandable={true}
        downloadable={true}
      />
      <PivotTable
        title=""
        dataSourceSettings={{
          rows: [{ name: 'year' }, { name: 'month' }, { name: 'to' }],
          columns: [{ name: 'isDirected' }],
          values: [{ name: 'isDirected' }],
          dataSource: filteredReferrals,
          allowSorting: true,
          drilledMember: [{ name: 'year' }]
        }}
        downloadable={true}
        expandable={true}
      />
      <Chart
        type="stackingColumn"
        legend={true}
        title="By Urgency"
        dataSourceSettings={{
          valueSortSettings: { headerDelimiter: '##' },
          dataSource: filteredReferrals,
          rows: [{ name: 'year' }, { name: 'month' }],
          columns: [{ name: 'urgency' }],
          values: [{ name: 'urgency' }],
          expandAll: true
        }}
        customSettings={{ height: '220', width: '100%' }}
        expandable={true}
        downloadable={true}
      />
      <PivotTable
        title=""
        dataSourceSettings={{
          rows: [{ name: 'year' }, { name: 'month' }, { name: 'to' }],
          columns: [{ name: 'urgency' }],
          values: [{ name: 'urgency' }],
          dataSource: filteredReferrals,
          allowSorting: true
        }}
        downloadable={true}
        expandable={true}
      />
      <Chart
        type="line"
        title="By Doctor"
        dataSourceSettings={{
          valueSortSettings: { headerDelimiter: '##' },
          dataSource: filteredReferrals,
          rows: [{ name: 'year' }, { name: 'month' }],
          columns: [{ name: 'to' }],
          values: [{ name: 'to' }],
          expandAll: true
        }}
        expandable={true}
        downloadable={true}
      />
    </ReportVisualsContainer>
  )
}

ReferralTrends.propTypes = {
  receivedReferrals: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default ReferralTrends
