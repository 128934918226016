import React, { useState } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import useAccount from '../../hooks/useAccount'
import { InputAdornment } from '@material-ui/core/'
import { useApolloClient, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { deriveAccountKeys, unwrapEccKey, importPublicKey } from '../../crypto'

import EmailIcon from 'mdi-react/EmailIcon'
import KeyIcon from 'mdi-react/KeyIcon'
import VisibilityIcon from 'mdi-react/VisibilityIcon'
import VisibilityOffIcon from 'mdi-react/VisibilityOffIcon'
import LoginIcon from 'mdi-react/LoginIcon'

import { ErrorMessage } from '../common/UserAlert'
import { PageTitle, SectionTitle } from '../common/Title'
import IconButton from '@material-ui/core/IconButton'
import Button, { LinkButton } from '../common/Button'
import Select from '../common/Select'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 100px 30px 0;
`

const Welcome = styled(PageTitle)`
  margin: 0 0 15px;
`

const Title = styled(SectionTitle)`
  color: #3a3a3a;
  margin: 0 0 30px;
`

const Form = styled.form`
  width: 400px;
`

const FieldContainer = styled.div`
  margin-bottom: 15px;
`

const InputIcon = styled(InputAdornment)`
  min-height: 15px;
  margin: 13px 0 12px 15px;
  .mdi-icon {
    color: #3a3a3a;
    max-width: 20px;
  }
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root .MuiInputBase-input {
    padding: 13px 15px 11px;
  }
`

const ButtonWrapper = styled.div`
  padding-top: 10px;
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
`

const ForgotButtonContainer = styled.div`
  width: 400px;
  display: flex;
  justify-content: flex-end;
`

const ForgotPasswordButton = styled(LinkButton)`
  padding: 5px 15px;
  text-transform: none;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
`

const LinksContainer = styled.div`
  color: #3a3a3a;
  font-family: Source Sans Pro;
  font-size: 12px;
  line-height: 15px;
  padding-left: 9px;
  display: flex;
  align-items: center;
`

const NewAccountButton = styled(LinkButton)`
  padding: 9px;
`

const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      salt
    }
  }
`

const LOGIN = gql`
  mutation login($email: String!, $accountToken: String!) {
    login(email: $email, accountToken: $accountToken) {
      authToken
      user {
        publicKey
        privateKey {
          encryptedData
          iv
        }
      }
    }
  }
`

function LoginForm() {
  const [showPassword, setShowPassword] = useState(false)
  const [login] = useMutation(LOGIN)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { register, errors, handleSubmit } = useForm()
  const { setAccount, isLoggedIn } = useAccount()
  const client = useApolloClient()
  const history = useHistory()
  const location = useLocation()

  if (isLoggedIn) history.push('/notifications')

  const dest =
    location.search.length > 0 ? location.search.substring(6) : location.search

  const handleTogglePasswordVisibility = () => {
    setShowPassword(showPassword => !showPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const required = 'This field is required'

  async function onSubmit({ email, password }) {
    setLoading(true)
    setError(false)
    try {
      const result = await client.query({
        query: GET_USER_BY_EMAIL,
        variables: { email }
      })

      const salt = result.data.getUserByEmail?.salt
      if (!salt) throw new Error('Invalid email')

      const { encryptionKey, accountToken } = await deriveAccountKeys(
        password,
        salt
      )

      const {
        data: {
          login: { authToken, user }
        }
      } = await login({ variables: { email, accountToken } })

      const { publicKey, privateKey } = user

      const importedPublicKey = await importPublicKey(publicKey)
      const unwrappedKey = await unwrapEccKey(
        privateKey.encryptedData,
        encryptionKey,
        privateKey.iv
      )

      setAccount(authToken, importedPublicKey, unwrappedKey)
      dest ? history.push(dest) : history.push('/notifications')
    } catch (e) {
      console.error('Request error occurred:', e)
      if (e.message.includes('Invalid email')) {
        setError('Invalid email or password. Try again.')
      } else {
        setError('Hm, an error occurred. Try again.')
      }
      setLoading(false)
    }
  }

  return (
    <Container>
      <Welcome>Welcome Back</Welcome>
      <Title id="form-title">Log in to your account</Title>
      <Form
        aria-labelledby="form-title"
        onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <FieldContainer>
          <StyledSelect
            id="email"
            type="email"
            name="email"
            label="Email"
            error={!!errors.email}
            helperText={errors?.email?.message}
            inputRef={register({
              pattern: { value: emailRegex, message: 'Invalid email' },
              required
            })}
            InputProps={{
              startAdornment: (
                <InputIcon position="start">
                  <EmailIcon size={22} />
                </InputIcon>
              )
            }}
            InputLabelProps={{ required: true }}
            placeholder="Enter email"
            fullWidth
          />
        </FieldContainer>
        <StyledSelect
          id="password"
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          error={!!errors.password}
          helperText={errors?.password?.message}
          inputRef={register({
            required
          })}
          InputProps={{
            startAdornment: (
              <InputIcon position="start">
                <KeyIcon size={22} />
              </InputIcon>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  onMouseDown={handleMouseDownPassword}>
                  {showPassword ? (
                    <VisibilityOffIcon color="#C4C4C4" />
                  ) : (
                    <VisibilityIcon color="#C4C4C4" />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          InputLabelProps={{ required: true }}
          placeholder="Enter password"
          fullWidth
        />
        <ForgotButtonContainer>
          <ForgotPasswordButton color="blue">
            <Link to="/forgot">Forgot your password?</Link>
          </ForgotPasswordButton>
        </ForgotButtonContainer>
        {error && (
          <FieldContainer>
            <ErrorMessage>{error}</ErrorMessage>
          </FieldContainer>
        )}

        <ButtonWrapper>
          <Button type="submit" loading={loading} padding="small">
            <LoginIcon />
            Log in
          </Button>
        </ButtonWrapper>
      </Form>

      <LinksContainer>
        Don&apos;t have an account?
        <NewAccountButton color="blue">
          <Link to="/register">Create an account</Link>
        </NewAccountButton>
      </LinksContainer>
    </Container>
  )
}

export default LoginForm
