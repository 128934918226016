import React, { useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem
} from '@material-ui/core'
import AccountOffIcon from 'mdi-react/AccountOffIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import CheckIcon from 'mdi-react/CheckIcon'
import styled from 'styled-components'
import Select from '../common/Select'
import { SecondaryButton } from '../common/Button'
import { ErrorMessage } from '../common/UserAlert'

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 30px;
  }
`

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 0 30px 30px;
  }
`

const CancelButton = styled(SecondaryButton).attrs({
  color: 'secondary',
  padding: 'small'
})`
  background-color: #f9fbfe;
  margin: 0 15px 0 30px;
`

const DescriptionWrapper = styled.div`
  margin-top: 30px;
`

const DescriptionTextField = styled(Select)`
  textarea {
    min-height: 100px;
  }
`

const ErrorContainer = styled.div`
  margin-bottom: -15px;
`

const reasons = [
  'Send to another specialist',
  'Send to another specialty/program/clinic',
  'Send to another region',
  'Patient in the care of or already seen by a different doctor',
  'Patient not due for treatment yet',
  'Cancelled by GP office',
  'Cancelled by patient',
  'Unable to contact patient/patient did not show up/patient deceased',
  'Duplicate referral',
  'Other'
]

const DECLINE_REFERRAL = gql`
  mutation declineReferral(
    $referral: ID!
    $reason: String!
    $description: String
  ) {
    declineReferral(
      referral: $referral
      reason: $reason
      description: $description
    ) {
      id
      state
    }
  }
`

const schema = yup.object().shape({
  reason: yup.string().required('Reason is required'),
  description: yup.string().when('reason', {
    is: 'Other',
    then: yup
      .string()
      .required('Description is required when choosing "Other"'),
    otherwise: yup.string()
  })
})

function DeclineReferralButton({ referralId, onSuccess = () => {} }) {
  const [declineReferral, { loading, error }] = useMutation(DECLINE_REFERRAL)
  const [isOpen, setIsOpen] = useState(false)

  const { handleSubmit, control, errors, reset, register, watch } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: 'all',
    defaultValues: { reason: '', description: '' }
  })

  const reason = watch('reason')

  async function onSubmit({ reason, description }) {
    try {
      await declineReferral({
        variables: { referral: referralId, reason, description }
      })
      setIsOpen(false)
      onSuccess()
    } catch (e) {
      console.error('Error declining referral:', e)
    }
  }

  function handleClose() {
    if (!loading) {
      setIsOpen(false)
      reset()
    }
  }
  function handleOpen() {
    setIsOpen(true)
  }

  return (
    <div>
      <SecondaryButton disabled={loading} onClick={handleOpen}>
        <AccountOffIcon />
        Decline Referral
      </SecondaryButton>
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledDialogContent>
            <Controller
              name="reason"
              control={control}
              render={({ onChange, value, name }) => (
                <Select
                  select
                  label="Please provide a reason for declining this referral"
                  id="reason"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  name={name}
                  error={!!errors.reason}
                  helperText={errors?.reason?.message}
                  InputLabelProps={{ required: true }}
                  FormHelperTextProps={{ role: 'alert' }}>
                  {reasons.map(reason => (
                    <MenuItem key={reason} value={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <DescriptionWrapper>
              <DescriptionTextField
                margin="dense"
                id="description"
                name="description"
                label="Please describe"
                type="text"
                inputRef={register}
                fullWidth
                multiline
                maxRows={3}
                error={!!errors.description}
                helperText={errors?.description?.message}
                InputLabelProps={{ required: reason === 'Other' }}
                FormHelperTextProps={{ role: 'alert' }}
              />
            </DescriptionWrapper>

            {error && (
              <ErrorContainer>
                <ErrorMessage />
              </ErrorContainer>
            )}
          </StyledDialogContent>
          <StyledDialogActions>
            <CancelButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
              Cancel
            </CancelButton>
            <SecondaryButton
              type="submit"
              color="secondary"
              padding="small"
              loading={loading}>
              <CheckIcon />
              Confirm
            </SecondaryButton>
          </StyledDialogActions>
        </form>
      </Dialog>
    </div>
  )
}

DeclineReferralButton.propTypes = {
  referralId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func
}

export default DeclineReferralButton
