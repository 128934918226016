import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import CogIcon from 'mdi-react/CogIcon'
import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import PowerIcon from 'mdi-react/PowerIcon'
import BellIcon from 'mdi-react/BellIcon'
import FileIcon from 'mdi-react/FileIcon'
import ArrowCollapseLeftIcon from 'mdi-react/ArrowCollapseLeftIcon'
import ArrowCollapseRightIcon from 'mdi-react/ArrowCollapseRightIcon'
import ArchiveIcon from 'mdi-react/ArchiveIcon'
import MenuRightIcon from 'mdi-react/MenuRightIcon'
import MenuDownIcon from 'mdi-react/MenuDownIcon'

import { ReactComponent as SentIcon } from '../../images/icons/icon_sent.svg'
import { ReactComponent as ReceivedIcon } from '../../images/icons/icon_received.svg'
import { ReactComponent as FaxCreateIcon } from '../../images/icons/icon_create_fax.svg'
import { ReactComponent as FaxSentIcon } from '../../images/icons/icon_sent_fax.svg'
import { ReactComponent as FaxReceivedIcon } from '../../images/icons/icon_received_fax.svg'
import { ReactComponent as FaxIcon } from '../../images/icons/icon_fax.svg'

import LogoImage from '../../images/logo.svg'
import LogoIcon from '../../images/clinnect-icon.png'
import AlertBadge from '../common/AlertBadge'
import styled from 'styled-components'

const drawerWidth = '260px'

const StyledDrawer = styled(Drawer)`
  width: ${props => (props.open ? drawerWidth : '90px')};

  .MuiDrawer-paper {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    padding: 0px 15px;
    width: ${props => (props.open ? drawerWidth : '90px')};
    transition: width 0.25s ease-in;
    border-right: 1px solid rgba(58, 58, 58, 0.05);
  }
`

const LogoWrapper = styled.div`
  height: 202px;
  padding: 30px 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 92px;
  overflow: hidden;
`

const StyledLogo = styled.img`
  position: absolute;
  left: 0;
  top: 0;

  &.logo {
    opacity: ${props => (props.open ? '1' : '0')};
    transition: ${props =>
      props.open ? 'opacity 0.1s ease-in 0.25s' : 'opacity 0.1s ease-out'};
  }

  &.icon {
    opacity: ${props => (props.open ? '0' : '1')};
    transition: ${props =>
      props.open ? 'opacity 0.1s ease-out' : 'opacity 0.1s ease-in 0.25s'};
  }
`

const ListHeader = styled.h3`
  text-align: ${props => (props.open ? 'left' : 'center')};
  margin: 30px 15px 0 15px;
  padding-bottom: 5px;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #1193ad;
  border-bottom: 1px solid rgba(17, 147, 173, 0.3);
`

const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3a3a3a;
`

const StyledListItem = styled(ListItem)`
  width: ${props => (props.open ? '100%' : '36px')};
  height: 34px;
  border-radius: 6px;
  margin: 4px 0;
  display: flex;
  justify-content: ${props => (props.open ? 'flex-start' : 'center')};
  text-transform: uppercase;

  &.MuiListItem-gutters {
    padding-left: ${props => (props.open ? '28px' : '0')};
    padding-right: ${props => (props.open ? '16px' : '0')};
  }

  &:hover {
    color: #3a3a3a;
    border-radius: 6px;

    path {
      fill: #3a3a3a;
    }
  }
  svg {
    height: 14px;
    width: 14px;
  }
  .MuiTypography-body1 {
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    line-height: 18px;
  }
`

const StyledListItemButton = styled(StyledListItem)`
  &.active {
    color: #fff;
    background: #1193ad;
    boxshadow: 0px 5px 15px rgba(58, 58, 58, 0.15);

    path {
      fill: #fff;
    }
  }
`

const FaxItemButton = styled(StyledListItemButton)`
  svg {
    height: 16px;
    width: 16px;
  }
`

const DropdownFaxItemButton = styled(StyledListItemButton)`
  width: ${props => (props.open ? '100%' : '28px')};
  margin-left: ${props => !props.open && '8px'};

  &.MuiListItem-gutters {
    padding-left: ${props => (props.open ? '16px' : '0')};
  }

  svg {
    height: 12px;
    width: 12px;
    margin-left: 2px;
  }
`

const DropdownHeaderButton = styled(StyledListItem)`
  &.MuiListItem-gutters {
    padding-left: ${props => (props.open ? '16px' : '0')};
  }

  svg {
    height: 16px;
    width: 16px;
  }
`

const MenuToggle = styled(StyledListItemButton)`
  width: 36px;
  justify-content: center;
  align-self: ${props => (props.open ? 'flex-end' : 'center')};

  &.MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.05);
  margin: 4px 15px;
`

// The second part of this styling is for accessibility and testing purpose
const StyledListItemText = styled(ListItemText)`
  padding-left: 12px;
  overflow: hidden;
  height: 18px;

  ${props =>
    !props.open &&
    `
      border: 0;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    `}
`

const StyledSubListItemText = styled(StyledListItemText)`
  text-transform: capitalize;
`

const SettingsContainer = styled.div`
  padding: 20px 0 40px 0;
`

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`

const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${props =>
    props.open &&
    `
      padding-left: 40px;
    `}

  ${props =>
    props.show &&
    `
        & > a {
          clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
          clip; rect(1px, 1px, 1px, 1px);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          width: 1px;
          position: absolute;
          }
      `}
`

const AlertDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #e84c3d;
  position: absolute;
  top: 14px;
  right: -8px;
`

const StyledMenuRightIcon = styled(MenuRightIcon)`
  margin-left: -4px;
`

const StyledMenuDownIcon = styled(MenuDownIcon)`
  margin-left: -4px;
`

const GET_ALERTS = gql`
  query getAlerts {
    getAlerts {
      sentReferrals
      receivedReferrals
      sentFaxes
    }
  }
`

function Sidebar({ showFaxing, showReports }) {
  const { data } = useQuery(GET_ALERTS)

  const [open, setOpen] = useState(true)
  const [showSentDropdown, setShowSentDropdown] = useState(false)
  const [showReceivedDropdown, setShowReceivedDropdown] = useState(false)
  const [sentReferrals, setSentReferrals] = useState(0)
  const [receivedReferrals, setReceivedReferrals] = useState(0)
  const [sentFaxes, setSentFaxes] = useState(0)

  const location = useLocation()

  const handleToggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (data) {
      setSentReferrals(data.getAlerts.sentReferrals)
      setReceivedReferrals(data.getAlerts.receivedReferrals)
      setSentFaxes(data.getAlerts.sentFaxes)
    }
  }, [data])

  useEffect(() => {
    if (location.pathname.match(/\/fax\/sent\//)) {
      setShowReceivedDropdown(false)
      setShowSentDropdown(true)
    } else if (location.pathname.match(/\/fax\/received\//)) {
      setShowSentDropdown(false)
      setShowReceivedDropdown(true)
    } else {
      setShowSentDropdown(false)
      setShowReceivedDropdown(false)
    }
  }, [location, setShowReceivedDropdown, setShowSentDropdown])

  return (
    <StyledDrawer
      variant="permanent"
      anchor="left"
      ModalProps={{
        keepMounted: true
      }}
      open={open}>
      <div>
        <LogoWrapper>
          <ImageWrapper open={open}>
            <StyledLogo
              src={LogoImage}
              className="logo"
              open={open}
              alt="Efficient | Secure"
              width="100%"
            />
            <StyledLogo
              src={LogoIcon}
              className="icon"
              open={open}
              alt="Efficient | Secure"
              width="100%"
            />
          </ImageWrapper>
        </LogoWrapper>
        <StyledList>
          <MenuToggle button open={open} onClick={() => handleToggle()}>
            {open ? <ArrowCollapseLeftIcon /> : <ArrowCollapseRightIcon />}
          </MenuToggle>
        </StyledList>
        <ListHeader open={open}>{open ? 'Referrals' : 'R'}</ListHeader>
        <StyledList>
          <StyledListItemButton
            button
            component={NavLink}
            open={open}
            to="/notifications">
            <BellIcon />
            <StyledListItemText open={open} primary="Notifications" />
          </StyledListItemButton>
          <StyledListItemButton
            button
            component={NavLink}
            open={open}
            to="/refer">
            <AccountPlusIcon />
            <StyledListItemText open={open} primary="Create" />
          </StyledListItemButton>
          <StyledListItemButton
            button
            component={NavLink}
            open={open}
            to="/sent">
            <SentIcon />
            <StyledListItemText open={open} primary="Sent" />
            {sentReferrals > 0 && (
              <>{open ? <AlertBadge number={sentReferrals} /> : <AlertDot />}</>
            )}
          </StyledListItemButton>
          <StyledListItemButton
            button
            component={NavLink}
            open={open}
            to="/received">
            <ReceivedIcon />
            <StyledListItemText open={open} primary="Received" />
            {receivedReferrals > 0 && (
              <>
                {open ? (
                  <AlertBadge number={receivedReferrals} />
                ) : (
                  <AlertDot />
                )}
              </>
            )}
          </StyledListItemButton>
        </StyledList>
        {showFaxing && (
          <>
            <ListHeader open={open}>{open ? 'Faxes' : 'F'}</ListHeader>
            <StyledList>
              <FaxItemButton
                button
                component={NavLink}
                open={open}
                to="/fax/create">
                <FaxCreateIcon />
                <StyledListItemText open={open} primary="Create" />
              </FaxItemButton>
              <Dropdown>
                <DropdownHeaderButton
                  button
                  component={NavLink}
                  open={open}
                  to="/fax/sent/active">
                  {!showSentDropdown ? (
                    <StyledMenuRightIcon />
                  ) : (
                    <StyledMenuDownIcon />
                  )}
                  <FaxSentIcon />
                  <StyledListItemText open={open} primary="Sent" />
                  {sentFaxes > 0 && (
                    <>
                      {open ? <AlertBadge number={sentFaxes} /> : <AlertDot />}
                    </>
                  )}
                </DropdownHeaderButton>
                <DropdownList show={!showSentDropdown} open={open}>
                  <DropdownFaxItemButton
                    button
                    component={NavLink}
                    open={open}
                    to="/fax/sent/active">
                    <FaxIcon />
                    <StyledSubListItemText open={open} primary="Active" />
                    {sentFaxes > 0 && (
                      <>
                        {open ? (
                          <AlertBadge number={sentFaxes} />
                        ) : (
                          <AlertDot />
                        )}
                      </>
                    )}
                  </DropdownFaxItemButton>
                  <DropdownFaxItemButton
                    button
                    component={NavLink}
                    open={open}
                    to="/fax/sent/archived">
                    <ArchiveIcon />
                    <StyledSubListItemText open={open} primary="Archived" />
                  </DropdownFaxItemButton>
                </DropdownList>
              </Dropdown>
              <Dropdown>
                <DropdownHeaderButton
                  button
                  component={NavLink}
                  open={open}
                  to="/fax/received/active">
                  {!showReceivedDropdown ? (
                    <StyledMenuRightIcon />
                  ) : (
                    <StyledMenuDownIcon />
                  )}
                  <FaxReceivedIcon />
                  <StyledListItemText open={open} primary="Received" />
                </DropdownHeaderButton>
                <DropdownList show={!showReceivedDropdown} open={open}>
                  <DropdownFaxItemButton
                    button
                    component={NavLink}
                    open={open}
                    to="/fax/received/active">
                    <FaxIcon />
                    <StyledSubListItemText open={open} primary="Active" />
                  </DropdownFaxItemButton>
                  <DropdownFaxItemButton
                    button
                    component={NavLink}
                    open={open}
                    to="/fax/received/archived">
                    <ArchiveIcon />
                    <StyledSubListItemText open={open} primary="Archived" />
                  </DropdownFaxItemButton>
                </DropdownList>
              </Dropdown>
            </StyledList>
          </>
        )}
      </div>
      <SettingsContainer>
        <Divider />
        <StyledList>
          {showReports && (
            <StyledListItemButton
              button
              component={NavLink}
              open={open}
              to="/reports">
              <FileIcon />
              <StyledListItemText open={open} primary="Reports" />
            </StyledListItemButton>
          )}
          <StyledListItemButton
            button
            component={NavLink}
            open={open}
            to="/settings">
            <CogIcon />
            <StyledListItemText open={open} primary="Settings" />
          </StyledListItemButton>
          <StyledListItemButton
            button
            component={NavLink}
            open={open}
            to="/logout">
            <PowerIcon />
            <StyledListItemText open={open} primary="Log out" />
          </StyledListItemButton>
        </StyledList>
      </SettingsContainer>
    </StyledDrawer>
  )
}

Sidebar.propTypes = {
  showFaxing: PropTypes.bool.isRequired,
  showReports: PropTypes.bool.isRequired
}

export default Sidebar
