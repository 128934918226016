import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AutorenewIcon from 'mdi-react/AutorenewIcon'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import { MenuItem } from '@material-ui/core'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  getCategoryName,
  getSubcategoryName,
  getCategoryId,
  getSubcategoryId,
  doesCategoryHaveSubcategories,
  getSpecialtyName
} from '../../util/categories'
import { ErrorMessage, SuccessMessage, InfoMessage } from '../common/UserAlert'
import { SecondaryButton } from '../common/Button'
import specialties from '../../specialties.json'
import regions from '../../regions.json'
import Select from '../common/Select'
import { SectionTitle } from '../common/Title'
import { useForm, Controller } from 'react-hook-form'
import { FieldLabel, FieldValue } from '../common/StyledField'

const padding = {
  confirmation: '27px 50px 0',
  referral: '27px 35px 0',
  freeReferral: '27px 5px 0 25px'
}

const SectionLabel = styled(SectionTitle)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const AllFields = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: ${props =>
    props.changingCategory ? 'rgba(6, 153, 135, 0.05)' : 'transparent'};
  padding-bottom: ${props => (props.changingCategory ? '30px' : '0')};
  padding-right: ${props => (props.page === 'freeReferral' ? '20px' : 'unset')};
`

const Field = styled.div`
  padding: ${props => padding[props.page]};
  width: ${props => (props.page === 'referral' ? '50%' : '33.3%')};
`

const StyledFieldValue = styled(FieldValue)`
  min-width: 0;
`

const CancelButton = styled(SecondaryButton).attrs({
  color: 'secondary',
  padding: 'small'
})`
  background-color: #f9fbfe;
  margin: 0 15px 0 40px;
`

const ChangeCategoryMessage = styled(FieldValue)`
  line-height: 20px;
  background-color: rgba(6, 153, 135, 0.05);
  padding: 27px 35px 0;
  margin: 0;
`

const CHANGE_CATEGORY = gql`
  mutation updateCategory($referral: ID!, $category: ID!) {
    updateCategory(referral: $referral, category: $category) {
      id
      category
    }
  }
`

function SpecialistDataView({
  referral,
  recipient,
  isRecipient = false,
  page = 'referral'
}) {
  const [mutate, { loading, error, data }] = useMutation(CHANGE_CATEGORY)
  const [isChangingCategory, setChangingCategory] = useState(false)
  const [success, setSuccess] = useState(false)

  const categorySchema = yup.object().shape({
    category: yup.string().required('Category is required'),
    subcategory: yup.string().when(['category'], {
      is: doesCategoryHaveSubcategories,
      then: yup.string().required('Subcategory is required')
    })
  })

  const { handleSubmit, setValue, errors, control, watch, reset } = useForm({
    resolver: yupResolver(categorySchema),
    criteriaMode: 'all',
    defaultValues: data?.updateCategory
      ? {
          category: getCategoryId(data.updateCategory.category),
          subcategory: getSubcategoryId(data.updateCategory.category) ?? ''
        }
      : {
          category: getCategoryId(referral.category),
          subcategory: getSubcategoryId(referral.category) ?? ''
        }
  })

  const category = watch('category')

  const region = regions.find(r => r.id === referral.region)

  const filteredSpecialties = specialties.filter(s => s.region === region.id)

  const filteredCategories = filteredSpecialties
    .filter(s => s.id === recipient.specialty)
    .map(s => s.categories)
    .flat()

  const filteredSubcategories = filteredCategories
    .filter(c => category && c.id === category)
    .map(c => c.subcategories)
    .flat()

  async function onSave(values) {
    try {
      const {
        data: { updateCategory }
      } = await mutate({
        variables: {
          referral: referral.id,
          category:
            values.subcategory === '' ? values.category : values.subcategory
        }
      })
      reset({
        category: getCategoryId(updateCategory.category),
        subcategory: getSubcategoryId(updateCategory.category) ?? ''
      })
      setChangingCategory(false)
      setSuccess(true)
    } catch (e) {
      console.error('Error changing category:', e)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <SectionLabel>
        Specialist Information
        {isRecipient &&
          referral.state !== 'redirected' &&
          (isChangingCategory ? (
            <div>
              <CancelButton
                disabled={loading}
                onClick={() => setChangingCategory(false)}>
                <CloseIcon />
                Cancel
              </CancelButton>
              <SecondaryButton
                color="secondary"
                padding="medium"
                type="submit"
                loading={loading}>
                <ContentSaveIcon />
                Save
              </SecondaryButton>
            </div>
          ) : (
            <SecondaryButton
              color="secondary"
              onClick={() => {
                setSuccess(false)
                setChangingCategory(true)
              }}>
              <AutorenewIcon transform="rotate(15)" />
              Change Category
            </SecondaryButton>
          ))}
      </SectionLabel>
      <Divider />
      {referral.type === 'free' && (
        <InfoMessage>
          It is still important to update the category if it is incorrect, as
          this will update the balancing algorithm
        </InfoMessage>
      )}
      {error && <ErrorMessage />}
      {isChangingCategory && (
        <ChangeCategoryMessage>
          The accuracy of the category is important. Please ensure you have
          reviewed the referral before changing.
        </ChangeCategoryMessage>
      )}
      {success && <SuccessMessage>Category has been changed</SuccessMessage>}
      <AllFields changingCategory={isChangingCategory} page={page}>
        <Field page={page}>
          <FieldLabel>Region</FieldLabel>
          <StyledFieldValue>{region.name}</StyledFieldValue>
        </Field>
        <Field page={page}>
          <FieldLabel>Specialty</FieldLabel>
          <StyledFieldValue>
            {getSpecialtyName(recipient.specialty)}
          </StyledFieldValue>
        </Field>
        {isChangingCategory ? (
          <>
            <Field page={page}>
              <Controller
                name="category"
                control={control}
                render={({ onChange, value, name }) => (
                  <Select
                    select
                    label="Category"
                    id="category"
                    name={name}
                    onChange={e => {
                      setValue('subcategory', '')
                      onChange(e.target.value)
                    }}
                    disabled={filteredCategories.length === 0}
                    value={value}
                    error={!!errors.category}
                    helperText={errors?.category?.message}
                    FormHelperTextProps={{ role: 'alert' }}>
                    {filteredCategories.map(c => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Field>
            <Field page={page}>
              <Controller
                name="subcategory"
                control={control}
                render={({ onChange, value, name }) => (
                  <Select
                    select
                    label="Subcategory"
                    id="subcategory"
                    name={name}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    disabled={filteredSubcategories.length === 0}
                    value={value}
                    error={!!errors.subcategory}
                    helperText={errors?.subcategory?.message}
                    FormHelperTextProps={{ role: 'alert' }}>
                    {filteredSubcategories.map(c => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Field>
          </>
        ) : (
          <>
            <Field page={page}>
              <FieldLabel>Category</FieldLabel>
              {data?.updateCategory ? (
                <StyledFieldValue>
                  {getCategoryName(data.updateCategory.category)}
                </StyledFieldValue>
              ) : (
                <StyledFieldValue>
                  {getCategoryName(referral.category)}
                </StyledFieldValue>
              )}
            </Field>
            <Field page={page}>
              <FieldLabel>Subcategory</FieldLabel>
              {data?.updateCategory ? (
                <StyledFieldValue>
                  {getSubcategoryName(data.updateCategory.category)}
                </StyledFieldValue>
              ) : (
                <StyledFieldValue>
                  {getSubcategoryName(referral.category)}
                </StyledFieldValue>
              )}
            </Field>
          </>
        )}
        <Field page={page}>
          <FieldLabel>Specialist</FieldLabel>
          <StyledFieldValue>Dr. {recipient.lastName}</StyledFieldValue>
        </Field>
        <Field></Field>
      </AllFields>
    </form>
  )
}

SpecialistDataView.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string,
    state: PropTypes.string,
    region: PropTypes.string,
    specialty: PropTypes.string,
    category: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  recipient: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    specialty: PropTypes.string.isRequired
  }),
  isRecipient: PropTypes.bool,
  page: PropTypes.oneOf(['confirmation', 'referral', 'freeReferral'])
}

export default SpecialistDataView
