import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import ArrowRightThickIcon from 'mdi-react/ArrowRightThickIcon'

import { unwrapEccKey, importAesKey } from '../../crypto'
import { convertMnemonicToBuffer } from '../../mnemonic'
import wordlist from '../../wordlist'
import { ErrorMessage } from '../common/UserAlert'
import { SectionTitle } from '../common/Title'
import ProgressDots from '../login/ProgressDots'
import Button from '../common/Button'

const MNEMONIC_LENGTH = 24

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 100px 30px 0;
  min-height: 720px;
`

const Title = styled(SectionTitle)`
  color: #3a3a3a;
  margin-bottom: 30px;
`

const ButtonWrapper = styled.div`
  padding-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`

const Form = styled.form`
  max-width: 600px;
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
`

const DescriptionText = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3a3a3a;
`

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
  grid-column-gap: 27px;
  grid-row-gap: 27px;

  margin-bottom: 20px;
`

const Input = styled.input`
  border: 1px solid rgba(58, 58, 58, 0.7);
  border-radius: 3px;
  background: #f9fbfe;
  padding: 8px 10px;
  height: 35px;
  width: 100%;
  font-size: 14px;
  font-family: Courier, monospace;

  :focus {
    outline: none;
    border: 1px solid #1193ad;
    border-radius: 3px;
  }
`

function MnemonicForm({ onSuccess, backupKey }) {
  const [decryptionError, setDecryptionError] = useState(false)
  const [isDecrypting, setIsDecrypting] = useState(false)

  const { register, handleSubmit, setValue, errors } = useForm({
    reValidateMode: 'onSubmit'
  })

  const uniqueErrors = [
    ...new Set(errors.mnemonic?.map(e => e.message).filter(v => v))
  ]

  async function onSubmit(values) {
    setDecryptionError(null)
    setIsDecrypting(true)

    try {
      const { mnemonic } = values
      const keyBuffer = await convertMnemonicToBuffer(mnemonic)
      const recoveryKey = await importAesKey(keyBuffer, true)

      const privateKey = await unwrapEccKey(
        backupKey.encryptedData,
        recoveryKey,
        backupKey.iv,
        true
      )

      setIsDecrypting(false)
      onSuccess({ privateKey, recoveryKey })
    } catch (e) {
      setIsDecrypting(false)
      setDecryptionError(e)
      console.error('Error decrypting secret key:', e)
    }
  }

  function handleChange(e, i) {
    // User pastes in words
    if (e.target.value.includes(' ') && e.target.value.length > 1) {
      e.preventDefault()
      // Separate out individual words
      const values = e.target.value
        .split(' ')
        .map(v => v.trim())
        .filter(v => v !== '')
      // Set the values of subsequent inputs
      values.forEach((value, offset) => {
        // Ignore words that would go past the last box
        if (i + offset < MNEMONIC_LENGTH) {
          setValue(`mnemonic[${i + offset}]`, value)
        }
      })

      // Focus last touched input
      const form = e.target.form
      const indexToFocus = Math.min(i + values.length - 1, MNEMONIC_LENGTH - 1)

      form.elements[indexToFocus].focus()
    }
  }

  function handleKeyDown(e, i) {
    const form = e.target.form

    // If user presses spacebar, focus next input
    if (e.key === ' ') {
      const nextElement = form.elements[i + 1]
      nextElement.focus()
      // Filter on `input` elements, since the submit button doesn't have a .select() method
      if (nextElement.nodeName === 'input') {
        nextElement.select()
      }
      e.preventDefault()
    }
    // Move to previous input if value is empty and user pressed back
    else if (e.key === 'Backspace' && e.target.value === '') {
      // Only move focus if not the first input
      if (i !== 0) {
        form.elements[i - 1].focus()
      }
      e.preventDefault()
    }
  }

  return (
    <Container>
      <Title>Emergency Access Kit</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {uniqueErrors.length > 0 && (
          <ErrorMessage>{uniqueErrors[0]}</ErrorMessage>
        )}
        {uniqueErrors.length === 0 && decryptionError && (
          <ErrorMessage>
            We’re sorry, those words are incorrect. Please check your Emergency
            Access Kit and try again.
          </ErrorMessage>
        )}
        <DescriptionText>
          Please enter the 24 words from the Emergency Access Kit you printed.
          Please note that the order of the words should match the initial copy.
        </DescriptionText>
        <InputsContainer>
          {Array.from(Array(MNEMONIC_LENGTH)).map((_, i) => (
            <Input
              key={`mnemonic[${i}]`}
              name={`mnemonic[${i}]`}
              id={`mnemonic[${i}]`}
              aria-label={`Word #${i + 1}`}
              onChange={e => handleChange(e, i)}
              onKeyDown={e => handleKeyDown(e, i)}
              ref={register({
                required: 'All words are required',
                validate: {
                  validWord: value =>
                    wordlist.includes(value.toLowerCase()) ||
                    `We're sorry '${value}' is not a valid word. Please check your Emergency Access Kit and try again.`
                }
              })}
            />
          ))}
        </InputsContainer>
        <ButtonWrapper>
          <Button type="submit" padding="small" loading={isDecrypting}>
            <ArrowRightThickIcon />
            Continue
          </Button>
        </ButtonWrapper>
      </Form>
      <ProgressDots currentStep={3} steps={4} />
    </Container>
  )
}

MnemonicForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  backupKey: PropTypes.shape({
    encryptedData: PropTypes.string.isRequired,
    iv: PropTypes.string.isRequired
  }).isRequired
}

export default MnemonicForm
