import React from 'react'
import ReactDOM from 'react-dom'

import DateFnsUtils from '@date-io/date-fns'
import { StylesProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import './global.css'
import App from './components/app/App'
import ErrorBoundary from './components/app/ErrorBoundary'
import * as serviceWorker from './serviceWorker'
import AccountProvider from './providers/AccountProvider'
import history from './history'

import reporter from './reporter.js'
import client from './graphql/client'

ReactDOM.render(
  <ApolloProvider client={client}>
    <StylesProvider injectFirst>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={history}>
          <AccountProvider>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </AccountProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

reporter.register()

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister()
