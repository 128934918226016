import React from 'react'
import PropTypes from 'prop-types'
import { LinearProgress } from '@material-ui/core'
import styled from 'styled-components'

const ProgressTextContainer = styled.div`
  display: flex;
  margin: 6px 9px;
`

const ProgressText = styled.p`
  font-size: 12px;
  font-wight: 300;
  line-height: 14px;
  color: #828282;
  margin-right: 5px;
`

const StyledLinearProgress = styled(LinearProgress)`
  border-top: 1px solid #c6c6c6;
  background-color: #fffdfd;
  height: 10px;
  .MuiLinearProgress-barColorPrimary {
    background-color: #145374;
  }
`

const DownloadProgress = ({ downloads }) => {
  function getProgress() {
    const progress = Object.keys(downloads).reduce(
      (acc, key) => acc + downloads[key].progress,
      0
    )
    const total = Object.keys(downloads).reduce(
      (acc, key) => acc + downloads[key].total,
      0
    )
    return (progress / total) * 100
  }

  return (
    <div>
      {Object.keys(downloads).length !== 0 && (
        <div>
          <ProgressTextContainer>
            <ProgressText>Downloading...</ProgressText>
            {Object.keys(downloads).map((key, i) => (
              <ProgressText key={key}>
                {downloads[key].name}
                {i !== Object.keys(downloads).length - 1 && ','}
              </ProgressText>
            ))}
          </ProgressTextContainer>
          <StyledLinearProgress variant="determinate" value={getProgress()} />
        </div>
      )}
    </div>
  )
}

DownloadProgress.propTypes = {
  downloads: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
}

export default DownloadProgress
