import { useContext } from 'react'
import AccountContext from '../contexts/AccountContext'

function useAccount() {
  const value = useContext(AccountContext)
  if (value === null)
    throw new Error('<AccountProvider> is not in the parent tree')
  return value
}

export default useAccount
