import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import styled from 'styled-components'
import {
  Dialog,
  DialogContent,
  Fade,
  CircularProgress
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {
  generateAesKey,
  exportAesKey,
  convertBase64ToBuffer,
  deriveAccountKeys,
  unwrapEccKey,
  wrapSecretKey
} from '../../crypto'
import ProgressDots from '../login/ProgressDots'
import OutlinedTextField from '../common/OutlinedTextField'
import { convertBufferToMnemonic } from '../../mnemonic'
import Button, { SecondaryButton } from '../common/Button'
import { SectionTitle } from '../common/Title'
import { InfoMessage } from '../common/UserAlert'

const StyledDialog = styled(Dialog).attrs(() => ({
  maxWidth: 'sm'
}))``

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 30px;
  width: 500px;
  align-items: center;
`

const StyledTextField = styled(OutlinedTextField)`
  margin: 30px 0px;

  .MuiFormLabel-root {
    color: rgba(58, 58, 58, 0.7);
  }
`

const PasswordMessage = styled.h5`
  font-size: 18px;
  font-weight: 400;
  margin: 0px 0px 20px;
  color: rgba(58, 58, 58, 0.7);
  width: 100%;
`

const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: #a95676;
`

const AlertContainer = styled.div`
  margin: 8px 0;
`

const FullWidthBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const ActionButtons = styled(FullWidthBar)`
  justify-content: flex-end;

  button:last-child {
    margin-left: 15px;
  }
`

const UPDATE_KEYS = gql`
  mutation updateKeys($accountToken: String!, $keyChange: KeyChangeInput!) {
    updateKeys(accountToken: $accountToken, keyChange: $keyChange) {
      id
      recoveryKey {
        encryptedData
        iv
      }
    }
  }
`
function NewMnemonicDialog({ user, isOpen, onClose, onMnemonic }) {
  const [modalState, setModalState] = useState('password')
  const [accountKeys, setAccountKeys] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [updateKeys] = useMutation(UPDATE_KEYS)
  const [password, setPassword] = useState('')

  async function handlePassword(e) {
    e.preventDefault()
    e.stopPropagation()

    setLoading(true)
    setError()

    try {
      const accountKeys = await deriveAccountKeys(password, user.salt)
      await unwrapEccKey(
        user.privateKey.encryptedData,
        accountKeys.encryptionKey,
        user.privateKey.iv,
        true
      )

      setLoading(false)

      setAccountKeys(accountKeys)
      setModalState('confirm')
    } catch (e) {
      setError('Incorrect password')
      setLoading(false)
    }
  }

  async function handleConfirm() {
    setError(null)
    setLoading(true)
    try {
      const recoveryKey = await generateAesKey('wrap')
      const exportedKey = await exportAesKey(recoveryKey)
      const keyBuffer = convertBase64ToBuffer(exportedKey)
      const mnemonic = await convertBufferToMnemonic(keyBuffer)

      const extractablePrivateKey = await unwrapEccKey(
        user.privateKey.encryptedData,
        accountKeys.encryptionKey,
        user.privateKey.iv,
        true
      )

      const { wrappedKey, iv: newIv } = await wrapSecretKey(
        extractablePrivateKey,
        accountKeys.encryptionKey
      )

      const { wrappedKey: backupKey, iv: backupIv } = await wrapSecretKey(
        extractablePrivateKey,
        recoveryKey
      )

      const { wrappedKey: wrappedRecoveryKey, iv: recoveryIv } =
        await wrapSecretKey(recoveryKey, accountKeys.encryptionKey)

      const keyChange = {
        backupKey: {
          encryptedData: backupKey,
          iv: backupIv
        },
        recoveryKey: {
          encryptedData: wrappedRecoveryKey,
          iv: recoveryIv
        },
        accountToken: accountKeys.accountToken,
        privateKey: {
          encryptedData: wrappedKey,
          iv: newIv
        },
        salt: user.salt
      }

      await updateKeys({
        variables: { accountToken: accountKeys.accountToken, keyChange }
      })
      setLoading(false)
      handleClose()
      onMnemonic(mnemonic)
    } catch (e) {
      console.error('An error occurred while generating a recovery key', e)
      setError(e)
      setLoading(false)
    }
  }

  function handleClose() {
    setModalState('password')
    setPassword('')
    setError(null)
    onClose()
  }

  return (
    <StyledDialog open={isOpen} onClose={handleClose} keepMounted={false}>
      {modalState === 'password' && (
        <StyledDialogContent as="form" onSubmit={handlePassword}>
          <PasswordMessage>
            To <strong>generate new words</strong> please confirm your password
          </PasswordMessage>
          <StyledTextField
            autoFocus
            id="currentPassword"
            label="Current password"
            type="password"
            fullWidth
            onChange={e => setPassword(e.target.value)}
            value={password}
            error={!!error}
            helperText={error}
          />
          <ActionButtons>
            <SecondaryButton
              onClick={handleClose}
              disabled={loading}
              color="secondary">
              Cancel
            </SecondaryButton>
            <Button type="submit" variant="text" disabled={loading}>
              {loading && (
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? '800ms' : '0ms'
                  }}
                  unmountOnExit>
                  <ButtonProgress size={24} />
                </Fade>
              )}
              Confirm
            </Button>
          </ActionButtons>
          <ProgressDots steps={2} currentStep={1} size={8} />
        </StyledDialogContent>
      )}
      {modalState === 'confirm' && (
        <StyledDialogContent>
          <FullWidthBar>
            <SectionTitle>Are you ready to generate new words?</SectionTitle>
          </FullWidthBar>
          {error && (
            <AlertContainer>
              <Alert severity="error">Hm, an error occurred. Try again.</Alert>
            </AlertContainer>
          )}
          <InfoMessage>
            This will make any printed Emergency Access Kits invalid and those
            words will not work to recover your account in the future. Be close
            to a printer so you can print your new Emergency Access Kit.
          </InfoMessage>
          <ActionButtons>
            <Button onClick={handleConfirm} variant="text" disabled={loading}>
              {loading && (
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? '800ms' : '0ms'
                  }}
                  unmountOnExit>
                  <ButtonProgress size={24} />
                </Fade>
              )}
              Yes, I am ready
            </Button>
            <SecondaryButton onClick={handleClose} color="secondary">
              I have changed my mind
            </SecondaryButton>
          </ActionButtons>
          <ProgressDots steps={2} currentStep={2} size={8} />
        </StyledDialogContent>
      )}
    </StyledDialog>
  )
}

NewMnemonicDialog.propTypes = {
  user: PropTypes.shape({
    privateKey: PropTypes.shape({
      encryptedData: PropTypes.string.isRequired,
      iv: PropTypes.string.isRequired
    }).isRequired,
    salt: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMnemonic: PropTypes.func.isRequired
}

export default NewMnemonicDialog
