import React, { useEffect, useState, useRef } from 'react'
import gql from 'graphql-tag'
import * as yup from 'yup'
import styled from 'styled-components'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { useLocation } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, DialogContent } from '@material-ui/core'

import DownloadIcon from 'mdi-react/DownloadIcon'
import InformationIcon from 'mdi-react/InformationIcon'

import useAccount from '../../hooks/useAccount'
import { getPracticeDisplayName, capitalize } from '../../util/strings'
import { getSubcategoryName, getCategoryName } from '../../util/categories'
import { getDecryptedReferral } from '../../util/decryptReferrals'
import { SectionTitle } from './Title'
import { InfoMessage, ErrorMessage } from './UserAlert'
import Button, { SecondaryButton } from './Button'
import ReferralFilters from '../forms/ReferralFilters'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 20px 30px 30px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 14px;
`

const SectionLabel = styled(SectionTitle)`
  margin: 0;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const StyledInfo = styled(InfoMessage)`
  margin-bottom: 0px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button:last-child {
    margin-left: 10px;
  }
`

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

const InfoAlert = styled.p`
  font-style: italic;
  font-size: 12px;
  line-height: 15px;
  color: #4ca1d8;
  margin: 0 0 0 5px;
`

const GET_FILTER_DATA = gql`
  query getFilterData {
    getPractices {
      id
      type
      specialty
      firstName
      lastName
      centralIntakeName
    }
  }
`

const GET_REFERRALS_FOR_CSV = gql`
  query getReferralsForCsv(
    $direction: ReferralPageType!
    $startDate: String!
    $endDate: String!
    $categories: [ID]!
    $practices: [ID]!
  ) {
    getReferralsForCsv(
      direction: $direction
      startDate: $startDate
      endDate: $endDate
      categories: $categories
      practices: $practices
    ) {
      id
      urgency
      gender
      category
      referralDate
      createdAt
      isDirected
      encryptedData {
        iv
        encryptedData
      }
      state
      referringProvider {
        id
        name
        faxNumber
      }
      sender {
        publicKey
        encryptionKey {
          encryptedData
          iv
        }
        practice {
          id
          firstName
          lastName
          centralIntakeName
          type
          memberships {
            privateKey {
              encryptedData
              iv
            }
            publicKey
            user {
              id
            }
          }
        }
      }
      recipient {
        publicKey
        encryptionKey {
          encryptedData
          iv
        }
        practice {
          id
          firstName
          lastName
          memberships {
            privateKey {
              encryptedData
              iv
            }
            publicKey
            user {
              id
            }
          }
        }
      }
    }
  }
`

const csvLabelMap = {
  id: 'Referral ID',
  referralDate: 'Referral Date',
  createdAt: 'Referral Created At Date',
  phn: 'Patient PHN',
  birthDate: 'Patient DOB',
  patient: 'Patient Name',
  gender: 'Patient Gender',
  categoryId: 'Referral Category ID',
  category: 'Referral Category',
  urgency: 'Referral Urgency',
  state: 'Referral State',
  isDirected: 'Directed',
  referringProvider: 'Referring Provider',
  fromPracticeName: 'Sending Practice Name',
  toPracticeName: 'Recipient Practice Name'
}

const filterSchema = yup.object().shape({
  direction: yup.string().required('Direction is required'),
  startDate: yup
    .date()
    .max(yup.ref('endDate'), '')
    .required('Start date is required')
    .nullable()
    .typeError(''),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), '')
    .max(new Date(new Date().setHours(23, 59, 59, 999)), '')
    .required('End date is required')
    .nullable()
    .typeError('')
})

function ReferralDownloadButton() {
  const filterDataResult = useQuery(GET_FILTER_DATA)

  const { getAccount } = useAccount()
  const [isVisible, setIsVisible] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [practices, setPractices] = useState([])
  const [triggerReset, setTriggerReset] = useState(false)

  const isMounted = useRef(true)

  const location = useLocation()
  const defaultDirection = location.pathname.match(/\/received/)
    ? 'Received'
    : 'Sent'

  const methods = useForm({
    resolver: yupResolver(filterSchema),
    criteriaMode: 'all',
    defaultValues: {
      direction: defaultDirection,
      startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
      endDate: new Date(),
      selectedPractices: [],
      selectedCategories: []
    },
    shouldUnregister: false
  })

  const { handleSubmit } = methods

  const [getReferralsForCsv, referralsForCsvResult] = useLazyQuery(
    GET_REFERRALS_FOR_CSV,
    {
      fetchPolicy: 'network-only'
    }
  )

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (filterDataResult?.data) {
      setPractices(filterDataResult.data.getPractices)
    }
  }, [filterDataResult?.data])

  const handleOpen = () => setIsVisible(true)

  function handleClose(event, reason) {
    if (reason && reason === 'backdropClick') return
    setIsVisible(false)
  }

  function onCancel() {
    if (!referralsForCsvResult.loading) {
      setError(null)
      setTriggerReset(true)
      setIsVisible(false)
    }
  }

  const onResetFilters = () => setTriggerReset(true)

  function onSubmit(values) {
    setLoading(true)
    getReferralsForCsv({
      variables: {
        direction: values.direction.toLowerCase(),
        startDate: values.startDate.toISOString(),
        endDate: values.endDate.toISOString(),
        categories: values.selectedCategories.map(c => c.categoryId),
        practices: values.selectedPractices.map(p => p.id)
      }
    })
  }

  useEffect(() => {
    async function saveReferrals() {
      setError(null)
      const decryptedReferrals = await decryptReferrals()

      if (decryptedReferrals.length > 0) {
        const labels = Object.keys(decryptedReferrals[0])
        const formattedLabels = labels.map(l => csvLabelMap[l])
        const data = decryptedReferrals.map(r => Object.values(r))

        const rows = [formattedLabels].concat(data)
        const csvData = rows.map(e => e.join(',')).join('\n')
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

        const link = document.createElement('a')
        link.download = 'ClinnectReferrals.csv'

        const newObjectUrl = URL.createObjectURL(blob)
        link.href = newObjectUrl

        link.click()

        setIsVisible(false)
        setLoading(false)
      } else {
        setLoading(false)
        setError('Error: No data to download.')
      }
    }

    async function decryptReferrals() {
      const referrals = await Promise.all(
        referralsForCsvResult.data.getReferralsForCsv.map(decryptReferral)
      )
      return referrals
    }

    async function decryptReferral(referral) {
      const account = await getAccount()

      const senderMembership = referral.sender.practice.memberships.find(
        m => m.user.id === account.id
      )

      const encryptedData = await getDecryptedReferral(
        referral,
        account.id,
        account,
        senderMembership ? true : false
      )

      if (!encryptedData) return null

      const category =
        getSubcategoryName(referral.category) !== 'N/A'
          ? `${getCategoryName(referral.category)} - ${getSubcategoryName(
              referral.category
            )}`
          : getCategoryName(referral.category)

      return {
        id: referral.id,
        referralDate: new Date(parseInt(referral.referralDate)),
        createdAt: new Date(parseInt(referral.createdAt)),
        phn: encryptedData.phn,
        birthDate: new Date(encryptedData.birthDate),
        patient: capitalize(
          `${encryptedData.firstName} ${encryptedData.lastName}`
        ),
        gender: referral.gender,
        categoryId: referral.category,
        category,
        urgency: referral.urgency,
        state: referral.state,
        isDirected: referral.isDirected,
        referringProvider: referral.referringProvider?.name || '',
        fromPracticeName: getPracticeDisplayName(
          referral.sender.practice,
          'long',
          'long'
        ),
        toPracticeName: `Dr. ${referral.recipient.practice.firstName} ${referral.recipient.practice.lastName}`
      }
    }

    if (referralsForCsvResult?.data?.getReferralsForCsv) saveReferrals()
  }, [referralsForCsvResult?.data, getAccount])

  return (
    <>
      <SecondaryButton color="secondary" padding="small" onClick={handleOpen}>
        <DownloadIcon />
        Download CSV
      </SecondaryButton>
      <Dialog fullWidth open={isVisible} onClose={handleClose}>
        <StyledDialogContent>
          <FormProvider {...methods}>
            <form
              aria-label="Download referral CSV"
              onSubmit={handleSubmit(onSubmit)}>
              <Header>
                <SectionLabel>Download Referral CSV</SectionLabel>
                <SecondaryButton
                  onClick={onResetFilters}
                  padding="small"
                  color="secondary">
                  Reset Filters
                </SecondaryButton>
              </Header>
              <Divider />
              <StyledInfo>
                Note: Due to the encrypted nature of the referrals, downloading
                the referrals takes significant resources. We suggest setting
                your download criteria so there are roughly 1500 referrals or
                less included in the CSV. If the download fails, try narrowing
                your download criteria further. For example, narrow your date
                range and do multiple downloads.
              </StyledInfo>
              <ReferralFilters
                practices={practices}
                defaultDirection={defaultDirection}
                triggerReset={triggerReset}
                setTriggerReset={setTriggerReset}
              />
              <ButtonContainer>
                <SecondaryButton
                  color="secondary"
                  padding="small"
                  onClick={onCancel}
                  disabled={
                    referralsForCsvResult?.data
                      ? loading
                      : referralsForCsvResult?.loading
                  }>
                  Cancel
                </SecondaryButton>
                <Button
                  padding="small"
                  onClick={handleSubmit(onSubmit)}
                  loading={
                    referralsForCsvResult?.data
                      ? loading
                      : referralsForCsvResult?.loading
                  }>
                  Download
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                {loading && !error && !referralsForCsvResult?.error && (
                  <AlertContainer>
                    <InformationIcon size={15} style={{ color: '#4ca1d8' }} />
                    <InfoAlert>Note: The download may take a while.</InfoAlert>
                  </AlertContainer>
                )}
                {error && <ErrorMessage role="alert">{error}</ErrorMessage>}
                {referralsForCsvResult?.error && (
                  <ErrorMessage role="alert">
                    Hm, an error occurred. Please try again.
                  </ErrorMessage>
                )}
              </ButtonContainer>
            </form>
          </FormProvider>
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

export default ReferralDownloadButton
