import React from 'react'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

import { matchPostalCode } from '../../util/strings'
import provinces from '../../provinces.json'
import { SectionTitle } from '../common/Title'
import Select from '../common/Select'

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const AllFields = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: 20px;
`

const Field = styled.div`
  flex-grow: 1;
  flex-basis: 33.3%;
  padding: 27px 5px 0 25px;
`

const ClinicInformation = ({ isSpecialist }) => {
  const { control, errors } = useFormContext()

  return (
    <div>
      <SectionTitle>Clinic Information</SectionTitle>
      <Divider />
      <AllFields>
        <Field>
          <Controller
            name="clinicName"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="clinicName"
                name={name}
                label="Clinic Name"
                value={value}
                onChange={onChange}
                placeholder="Enter clinic name"
                error={!!errors.clinicName}
                helperText={errors?.clinicName?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Field>
        <Field>
          <Controller
            name="phone"
            control={control}
            render={({ onChange, value, name }) => (
              <NumberFormat
                id="phone"
                name={name}
                label="Phone No."
                value={value}
                onChange={onChange}
                mask="_"
                format="(###)-###-####, Ext. ######"
                placeholder="(000)-000-0000, Ext. 123"
                customInput={Select}
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true, shrink: true }}
              />
            )}
          />
        </Field>
        <Field>
          <Controller
            name="address"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="address"
                name={name}
                label="Address"
                value={value}
                onChange={onChange}
                placeholder="Enter address"
                error={!!errors.address}
                helperText={errors?.address?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true, shrink: true }}
              />
            )}
          />
        </Field>
        <Field>
          <Controller
            name="city"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="city"
                name={name}
                label="City"
                value={value}
                onChange={onChange}
                placeholder="Enter city name"
                error={!!errors.city}
                helperText={errors?.city?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true, shrink: true }}
              />
            )}
          />
        </Field>
        <Field>
          <Controller
            name="province"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                select
                id="province"
                name={name}
                label="Province"
                onChange={onChange}
                value={value}
                error={!!errors.province}
                helperText={errors?.province?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true }}>
                {Object.keys(provinces).map(p => (
                  <MenuItem key={p} value={p}>
                    {provinces[p]}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Field>
        <Field>
          <Controller
            name="postalCode"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="postalCode"
                name={name}
                label="Postal Code"
                value={value}
                onChange={e => {
                  const postalCode = matchPostalCode(e.target.value)
                  onChange(postalCode)
                }}
                placeholder="X1X 1X1"
                error={!!errors.postalCode}
                helperText={errors?.postalCode?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true, shrink: true }}
              />
            )}
          />
        </Field>
        {isSpecialist && (
          <>
            <Field>
              <Controller
                name="emrNumber"
                control={control}
                render={({ onChange, value, name }) => (
                  <NumberFormat
                    id="emrNumber"
                    name={name}
                    label="EMR Fax No."
                    value={value}
                    onChange={onChange}
                    mask="_"
                    format="(###)-###-####"
                    placeholder="(000)-000-0000"
                    customInput={Select}
                    error={!!errors.emrNumber}
                    helperText={errors?.emrNumber?.message}
                    InputLabelProps={{ shrink: true }}
                    FormHelperTextProps={{ role: 'alert' }}
                  />
                )}
              />
            </Field>
            <Field />
            <Field />
          </>
        )}
      </AllFields>
    </div>
  )
}

ClinicInformation.propTypes = {
  isSpecialist: PropTypes.bool.isRequired
}

export default ClinicInformation
