import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { SectionTitle } from '../common/Title'
import styled from 'styled-components'
import SpecialistDataView from './SpecialistDataView'
import provinces from '../../provinces.json'
import { FieldLabel, FieldValue } from '../common/StyledField'

const padding = {
  confirmation: '27px 50px 0',
  referral: '27px 35px 0',
  freeReferral: '27px 5px 0 25px'
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const AllFields = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: ${props => (props.page === 'freeReferral' ? '20px' : 'unset')};
`

const Field = styled.div`
  padding: ${props => padding[props.page]};
  width: ${props => (props.page === 'referral' ? '50%' : '33.3%')};
`

const StyledFieldValue = styled(FieldValue)`
  min-width: 0;
`

function ReferralDataView({
  referral,
  recipient,
  isRecipient = false,
  page = 'referral'
}) {
  return (
    <div>
      <Content>
        {referral.referringProvider && (
          <>
            <SectionTitle>Referrer Information</SectionTitle>
            <Divider />
            <AllFields page={page}>
              <Field page={page}>
                <FieldLabel>Contact</FieldLabel>
                <StyledFieldValue>
                  {referral.referringProvider.name}
                </StyledFieldValue>
              </Field>
              <Field page={page}>
                <FieldLabel>Fax no.</FieldLabel>
                <StyledFieldValue>
                  {referral.referringProvider.faxNumber}
                </StyledFieldValue>
              </Field>
              <Field page={page}>
                <FieldLabel>Location</FieldLabel>
                <StyledFieldValue>
                  {referral.referringProvider.location}
                </StyledFieldValue>
              </Field>
              <Field />
            </AllFields>
          </>
        )}
        <SectionTitle>Patient Information</SectionTitle>
        <Divider />
        <AllFields page={page}>
          <Field page={page}>
            <FieldLabel>PHN</FieldLabel>
            <StyledFieldValue>{referral.phn}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Date of Birth</FieldLabel>
            <StyledFieldValue>
              {format(referral.birthDate, 'dd/MMM/yyyy')}
            </StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>First Name</FieldLabel>
            <StyledFieldValue>{referral.firstName}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Last Name</FieldLabel>
            <StyledFieldValue>{referral.lastName}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Phone Number</FieldLabel>
            <StyledFieldValue>{referral.phone}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Date of Referral</FieldLabel>
            <StyledFieldValue>
              {format(referral.referralDate, 'dd/MM/yyyy')}
            </StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Gender Identity</FieldLabel>
            <StyledFieldValue>{referral.gender}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Address</FieldLabel>
            <StyledFieldValue>{referral.address}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>City</FieldLabel>
            <StyledFieldValue>{referral.city}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Province</FieldLabel>
            <StyledFieldValue>{provinces[referral.province]}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Postal Code</FieldLabel>
            <StyledFieldValue>{referral.postalCode}</StyledFieldValue>
          </Field>
          <Field page={page}>
            <FieldLabel>Email</FieldLabel>
            <StyledFieldValue>{referral.email}</StyledFieldValue>
          </Field>
        </AllFields>
        <SpecialistDataView
          referral={referral}
          recipient={recipient}
          isRecipient={isRecipient}
          page={page}
        />
      </Content>
    </div>
  )
}

ReferralDataView.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phn: PropTypes.number,
    phone: PropTypes.string,
    gender: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
    email: PropTypes.string,
    region: PropTypes.string,
    specialty: PropTypes.string,
    category: PropTypes.string,
    referralDate: PropTypes.instanceOf(Date),
    birthDate: PropTypes.instanceOf(Date),
    referringProvider: PropTypes.object
  }).isRequired,
  recipient: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    specialty: PropTypes.string.isRequired
  }).isRequired,
  isRecipient: PropTypes.bool,
  page: PropTypes.oneOf(['confirmation', 'referral', 'freeReferral'])
}

export default ReferralDataView
