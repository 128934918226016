import React, { useState, useEffect, useCallback, useRef } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { Dialog, DialogContent } from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CloseIcon from 'mdi-react/CloseIcon'

import { ErrorMessage } from '../common/UserAlert'
import Button, { SecondaryButton } from '../common/Button'

const DeleteButton = styled(SecondaryButton).attrs({
  color: 'secondary',
  padding: 'small'
})`
  background-color: #f9fbfe;
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 500px;
  }
`

const ErrorContainer = styled.div`
  margin: 0 30px -5px;
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  :first-child {
    padding-top: 0px;
  }
`

const Message = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #393e46;
  padding: 30px 30px 0;
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 500px;
  button:last-child {
    margin-left: 15px;
  }
  padding: 20px 30px 30px 0;
`

const GET_FAX_CONTACTS = gql`
  query getFaxContacts {
    getFaxContacts {
      id
      practice
      name
      location
      faxNumber
    }
  }
`

const DELETE_FAX_CONTACT = gql`
  mutation deleteFaxContact($faxContact: ID!) {
    deleteFaxContact(faxContact: $faxContact)
  }
`

function DeleteContactButton({ contact, handleDelete = () => {} }) {
  const [deleteFaxContact, { loading, error }] = useMutation(
    DELETE_FAX_CONTACT,
    {
      update(cache) {
        if (cache.data.data.ROOT_QUERY?.[`getFaxContacts`]) {
          const { getFaxContacts } = cache.readQuery({
            query: GET_FAX_CONTACTS
          })
          cache.writeQuery({
            query: GET_FAX_CONTACTS,
            data: {
              getFaxContacts: getFaxContacts.filter(c => c.id !== contact.id)
            }
          })
        }
      }
    }
  )

  const [isVisible, setIsVisible] = useState(false)
  const handleOpen = () => setIsVisible(true)
  const handleClose = () => setIsVisible(false)

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const onDelete = useCallback(async () => {
    setIsVisible(true)
    try {
      await deleteFaxContact({
        variables: {
          faxContact: contact.id
        }
      })
      if (isMounted.current) handleClose()
      handleDelete(contact.id)
    } catch (e) {
      console.error('Error deleting fax contact:', e)
    }
  }, [contact.id, deleteFaxContact, handleDelete])

  return (
    <>
      <DeleteButton color="secondary" padding="small" onClick={handleOpen}>
        <CloseIcon />
        Delete
      </DeleteButton>
      <StyledDialog
        fullWidth
        open={isVisible}
        onClose={handleClose}
        keepMounted={false}>
        <StyledDialogContent>
          <Message>
            Are you sure you want to delete {contact.name}
            {contact.location.length > 0 && ` - ${contact.location}`}?
          </Message>
          {error && (
            <ErrorContainer>
              <ErrorMessage />
            </ErrorContainer>
          )}
          <ButtonContainer>
            <SecondaryButton
              onClick={handleClose}
              disabled={loading}
              padding="small"
              color="secondary">
              Cancel
            </SecondaryButton>
            <Button onClick={onDelete} loading={loading} padding="small">
              Confirm
            </Button>
          </ButtonContainer>
        </StyledDialogContent>
      </StyledDialog>
    </>
  )
}

DeleteContactButton.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    practice: PropTypes.string.isRequired,
    location: PropTypes.string
  }),
  handleDelete: PropTypes.func
}

export default DeleteContactButton
