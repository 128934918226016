import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import CloseIcon from 'mdi-react/CloseIcon'
import styled from 'styled-components'
import Button, { SecondaryButton, IconButton } from '../common/Button'
import { ErrorMessage } from '../common/UserAlert'

const DeleteMemberButton = styled(IconButton)`
  padding: 2px;
  min-width: 10px;
  color: #000;
  border: none;
  background-color: inherit;
  :hover {
    svg {
      color: #e84c3d;
    }
  }
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 500px;
  }
`

const ErrorContainer = styled.div`
  margin: 0 30px -15px;
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  :first-child {
    padding-top: 0px;
  }
`

const Message = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #393e46;
  padding: 30px 30px 0;
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button:last-child {
    margin-left: 15px;
  }
  padding: 30px;
`

const DELETE_FROM_PRACTICE = gql`
  mutation deleteFromPractice($membership: ID!) {
    deleteFromPractice(membership: $membership)
  }
`

const GET_PRACTICES = gql`
  query getPractices {
    getPractices {
      id
      firstName
      lastName
      msp
      phone
      region
      address
      city
      province
      postalCode
      type
      memberships {
        id
        user {
          id
          email
        }
      }
      invitations {
        id
        email
      }
    }
  }
`

function DeleteMembershipButton({ membership }) {
  const [mutate, { loading, error }] = useMutation(DELETE_FROM_PRACTICE, {
    update(cache) {
      const { getPractices } = cache.readQuery({
        query: GET_PRACTICES
      })
      cache.writeQuery({
        query: GET_PRACTICES,
        data: {
          getPractices: getPractices.map(practice => ({
            ...practice,
            memberships: practice.memberships.filter(
              m => m.id !== membership.id
            )
          }))
        }
      })
    }
  })

  const [isVisible, setIsVisible] = useState(false)
  const handleOpen = () => setIsVisible(true)
  const handleClose = () => setIsVisible(false)

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const onDelete = useCallback(async () => {
    setIsVisible(true)
    try {
      await mutate({ variables: { membership: membership.id } })
      if (isMounted.current) handleClose()
    } catch (e) {
      console.error('Error removing user from practice:', e.message)
    }
  }, [membership.id, mutate])

  return (
    <div>
      <DeleteMemberButton onClick={handleOpen} aria-label="Remove user">
        <CloseIcon size={16} />
      </DeleteMemberButton>
      <StyledDialog
        fullWidth
        open={isVisible}
        onClose={handleClose}
        keepMounted={false}>
        <StyledDialogContent>
          <Message>
            Are you sure you want to remove {membership.user.email} from this
            practice?
          </Message>
          {error && (
            <ErrorContainer>
              <ErrorMessage />
            </ErrorContainer>
          )}
          <ButtonContainer>
            <SecondaryButton
              onClick={handleClose}
              disabled={loading}
              padding="small"
              color="secondary">
              Cancel
            </SecondaryButton>
            <Button onClick={onDelete} loading={loading} padding="small">
              Remove
            </Button>
          </ButtonContainer>
        </StyledDialogContent>
      </StyledDialog>
    </div>
  )
}

DeleteMembershipButton.propTypes = {
  membership: PropTypes.shape({
    id: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string
    })
  })
}

export default DeleteMembershipButton
