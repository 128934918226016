import React from 'react'
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import LogoImage from '../../images/logo-black.png'
import { getPracticeType, getPracticeDisplayName } from '../../util/strings'
import { format } from 'date-fns'
import {
  getCategoryName,
  getSubcategoryName,
  getSpecialtyName
} from '../../util/categories'
import regions from '../../regions.json'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5in',
    fontSize: '14pt',
    width: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.25in 0',
    width: '100%',
    height: '100pt'
  },
  senderReceiver: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '80%',
    padding: '34pt 0'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '20%'
  },
  practiceInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  to: {
    fontSize: '10pt',
    margin: '0 15pt'
  },
  doctor: {
    fontSize: '11pt',
    fontWeight: '700'
  },
  doctorType: {
    fontSize: '10pt',
    fontStyle: 'italic',
    fontWeight: '400'
  },
  headerLabel: {
    fontSize: '10pt',
    margin: '0 3pt 0 0',
    fontWeight: '500'
  },
  divider: {
    fontSize: '10pt',
    margin: '0 3pt 0 3pt',
    fontWeight: '500'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '50%',
    flexGrow: '1',
    fontWeight: '600',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    marginLeft: '5pt'
  },
  info: {
    fontSize: '10pt',
    fontWeight: '700',
    margin: '0'
  },
  body: {
    fontSize: '10pt'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '11pt',
    margin: '0',
    paddingBottom: '5pt',
    paddingTop: '25pt',
    borderBottomWidth: '0.5pt',
    breakAfter: 'avoid'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '5pt',
    alignItems: 'flex-start',
    breakInside: 'avoid'
  },
  reasonText: {
    width: '75%',
    margin: '0'
  },
  label: {
    width: '100pt',
    minWidth: '100pt',
    marginRight: '10pt',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: '500'
  }
})

Font.registerHyphenationCallback(word => [word])

const ReferralPdf = ({ sender, referral, recipient }) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.header}>
        <View style={styles.senderReceiver}>
          <View style={styles.practiceInfo}>
            <View style={styles.item}>
              <Text style={styles.doctor}>
                {getPracticeDisplayName(sender, 'short', 'med')}
              </Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.doctorType}>
                {getPracticeType(sender.type)}
              </Text>
            </View>
            <View style={styles.item}>
              {sender.type !== 'centralIntake' && (
                <>
                  <Text style={styles.headerLabel}>MSP</Text>
                  <Text style={styles.info}>{sender.msp}</Text>
                  <Text style={styles.divider}>|</Text>
                </>
              )}
              <Text style={styles.headerLabel}>Phone</Text>
              <Text style={styles.info}>
                {' '}
                {sender.phone.substring(sender.phone.length - 13) ===
                ', Ext. ______'
                  ? sender.phone.substr(0, 14)
                  : sender.phone}
              </Text>
            </View>
          </View>
          <View style={styles.to}>
            <Text>to</Text>
          </View>
          <View style={styles.practiceInfo}>
            <View style={styles.item}>
              <Text style={styles.doctor}>
                Dr. {recipient.firstName.charAt(0)}. {recipient.lastName}
              </Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.doctorType}>Specialist</Text>
            </View>
            <View style={styles.item}>
              <Text style={styles.headerLabel}>MSP</Text>
              <Text style={styles.info}>{recipient.msp}</Text>
              <Text style={styles.divider}>|</Text>
              <Text style={styles.headerLabel}>Phone</Text>
              <Text style={styles.info}>
                {' '}
                {recipient.phone.substring(recipient.phone.length - 13) ===
                ', Ext. ______'
                  ? recipient.phone.substr(0, 14)
                  : recipient.phone}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.imageContainer}>
          <Image src={LogoImage} />
        </View>
      </View>
      <View style={styles.body}>
        {referral.referringProvider && (
          <>
            <Text style={styles.sectionTitle}> Referrer Information </Text>
            <View style={styles.row}>
              <View style={styles.item}>
                <Text style={styles.label}>Contact</Text>
                <Text style={styles.item}>
                  {referral.referringProvider.name}
                </Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.label}>Fax No.</Text>
                <Text style={styles.item}>
                  {referral.referringProvider.faxNumber}
                </Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.item}>
                <Text style={styles.label}>Location</Text>
                <Text style={styles.item}>
                  {referral.referringProvider.location}
                </Text>
              </View>
            </View>
          </>
        )}

        <Text style={styles.sectionTitle}> Patient Information </Text>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>PHN</Text>
            <Text style={styles.item}>{referral.phn}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>Date of Birth</Text>
            <Text style={styles.item}>
              {format(referral.birthDate, 'dd/MMM/yyyy')}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>First Name</Text>
            <Text style={styles.item}>{referral.firstName}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>Last Name</Text>
            <Text style={styles.item}>{referral.lastName}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Phone No.</Text>
            <Text style={styles.item}>{referral.phone}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>Date of Referral</Text>
            <Text style={styles.item}>
              {format(referral.referralDate, 'dd/MMM/yyyy')}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Gender Identity</Text>
            <Text style={styles.item}>{referral.gender}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Address</Text>
            <Text style={styles.item}>{referral.address}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>City</Text>
            <Text style={styles.item}>{referral.city}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Province</Text>
            <Text style={styles.item}>{referral.province}</Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>Postal Code</Text>
            <Text style={styles.item}>{referral.postalCode}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Email</Text>
            <Text style={styles.item}>{referral.email}</Text>
          </View>
        </View>

        <Text style={styles.sectionTitle}> Specialist Information </Text>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Specialty</Text>
            <Text style={styles.item}>
              {getSpecialtyName(recipient.specialty)}
            </Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>Category</Text>
            <Text style={styles.item}>
              {getCategoryName(referral.category)}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Subcategory</Text>
            <Text style={styles.item}>
              {getSubcategoryName(referral.category)}
            </Text>
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>Region</Text>
            <Text style={styles.item}>
              {regions.find(r => r.id === referral.region).name}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Specialist</Text>
            <Text style={styles.item}>
              Dr. {recipient.firstName} {recipient.lastName}
            </Text>
          </View>
        </View>

        <Text style={styles.sectionTitle}> Referral Information </Text>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Urgency</Text>
            <Text style={styles.item}>{referral.urgency}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>
              Reason for Referral and/or Additional Comments
            </Text>
            <Text style={styles.reasonText}>{referral.message}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.item}>
            <Text style={styles.label}>Attach File(s)</Text>
            <Text>
              {referral.fileNames &&
                referral.fileNames.map((file, i) => (
                  <Text key={`${file}_${i}`}>{file} </Text>
                ))}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
)

ReferralPdf.propTypes = {
  sender: PropTypes.object.isRequired,
  referral: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phn: PropTypes.number,
    phone: PropTypes.string,
    gender: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
    email: PropTypes.string,
    region: PropTypes.string,
    specialty: PropTypes.string,
    category: PropTypes.string,
    subcategory: PropTypes.string,
    referralDate: PropTypes.instanceOf(Date),
    birthDate: PropTypes.instanceOf(Date),
    urgency: PropTypes.string,
    fileNames: PropTypes.array,
    files: PropTypes.array,
    message: PropTypes.string,
    referringProvider: PropTypes.object
  }).isRequired,
  recipient: PropTypes.object.isRequired
}
export default ReferralPdf
