import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import AccountCheckIcon from 'mdi-react/AccountCheckIcon'
import Button, { SecondaryButton } from '../common/Button'
import styled from 'styled-components'

const ErrorMessage = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #e84c3d;
  margin: 0;
  padding-top: 5px;
`

const ACCEPT_REFERRAL = gql`
  mutation acceptReferral($referral: ID!) {
    acceptReferral(referral: $referral) {
      id
      state
    }
  }
`

function AcceptReferralButton({ referralId, variant = 'default' }) {
  const [accept, { loading, error }] = useMutation(ACCEPT_REFERRAL)

  async function handleClick() {
    try {
      await accept({ variables: { referral: referralId } })
    } catch (e) {
      console.error('Error accepting referral:', e)
    }
  }

  return (
    <div>
      {variant === 'default' ? (
        <Button loading={loading} onClick={handleClick}>
          <AccountCheckIcon />
          Accept Referral
        </Button>
      ) : (
        <SecondaryButton loading={loading} onClick={handleClick}>
          <AccountCheckIcon />
          Accept Referral
        </SecondaryButton>
      )}

      {error && (
        <ErrorMessage role="alert">
          Hm, an error occurred. Try again.
        </ErrorMessage>
      )}
    </div>
  )
}

AcceptReferralButton.propTypes = {
  referralId: PropTypes.string.isRequired,
  variant: PropTypes.string
}

export default AcceptReferralButton
