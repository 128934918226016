import React from 'react'
import PropTypes from 'prop-types'
import { SectionTitle } from '../common/Title'
import { getPracticeType, getPracticeDisplayName } from '../../util/strings'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import styled from 'styled-components'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1193ad;
`

const PhysicianLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;
`

const PhysicianRight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const Separator = styled.span`
  font-weight: bold;
  padding: 0 5px;
`

const Bold = styled.span`
  font-weight: bold;
`

const Name = styled(SectionTitle)`
  margin: 0;
  color: #fff;
`

const Arrow = styled(ArrowRightIcon)`
  color: rgba(255, 255, 255, 0.35);
`

const Info = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #fff;
  margin: 0;
`

const Type = styled(Info)`
  font-style: italic;
  margin-bottom: 5px;
`

const PhysicianFromToInfo = ({ recipient, sender }) => (
  <Content>
    <PhysicianLeft>
      <Name>{getPracticeDisplayName(sender, 'short', 'med')}</Name>
      <Type>{getPracticeType(sender.type)}</Type>
      <Info>
        {sender.type !== 'centralIntake' && (
          <>
            <span>MSP</span> <Bold>{sender.msp}</Bold>
            <Separator>|</Separator>
          </>
        )}
        <span>Phone</span>{' '}
        <Bold>
          {sender.phone.substring(sender.phone.length - 13) === ', Ext. ______'
            ? sender.phone.substr(0, 14)
            : sender.phone}
        </Bold>
      </Info>
      {sender.clinicName && <Info>{sender.clinicName}</Info>}
    </PhysicianLeft>
    <Arrow />
    <PhysicianRight>
      <Name>
        Dr. {recipient.firstName.charAt(0)}. {recipient.lastName}&thinsp;
      </Name>
      <Type>Specialist</Type>
      <Info>
        <span>MSP</span> <Bold>{recipient.msp}</Bold>
        <Separator>|</Separator>
        <span>Phone</span>{' '}
        <Bold>
          {recipient.phone.substring(recipient.phone.length - 13) ===
          ', Ext. ______'
            ? recipient.phone.substr(0, 14)
            : recipient.phone}
        </Bold>
      </Info>
      {recipient.clinicName && <Info>{recipient.clinicName}</Info>}
    </PhysicianRight>
  </Content>
)

PhysicianFromToInfo.propTypes = {
  sender: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    msp: PropTypes.string,
    centralIntakeName: PropTypes.string,
    clinicName: PropTypes.string,
    phone: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  recipient: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    msp: PropTypes.string,
    clinicName: PropTypes.string,
    phone: PropTypes.string,
    specialty: PropTypes.string.isRequired
  }).isRequired
}

export default PhysicianFromToInfo
