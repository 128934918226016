import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'

import ArrowRightThickIcon from 'mdi-react/ArrowRightThickIcon'
import InformationIcon from 'mdi-react/InformationIcon'
import AccountIcon from 'mdi-react/AccountIcon'
import EmailIcon from 'mdi-react/EmailIcon'
import KeyIcon from 'mdi-react/KeyIcon'

import Button, { LinkButton } from '../common/Button'
import { PageTitle, SectionTitle } from '../common/Title'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityIcon from 'mdi-react/VisibilityIcon'
import VisibilityOffIcon from 'mdi-react/VisibilityOffIcon'
import Select from '../common/Select'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`

const Welcome = styled(PageTitle)`
  margin: 0 0 15px;
`

const Title = styled(SectionTitle)`
  color: #3a3a3a;
  margin: 0 0 30px;
`

const FieldContainer = styled.div`
  margin-bottom: 15px;
`

const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

const NameWrapper = styled.div`
  width: 185px;
`

const Form = styled.form`
  width: 400px;
`

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const InfoAlert = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: #3a3a3a;
  margin: 5px 4px;
`

const ButtonWrapper = styled.div`
  padding-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`

const LoginContainer = styled.div`
  color: #3a3a3a;
  font-family: Source Sans Pro;
  font-size: 12px;
  line-height: 15px;
  padding-left: 9px;
  display: flex;
  align-items: center;
`

const InputIcon = styled(InputAdornment)`
  min-height: 15px;
  margin: 13px 0 12px 15px;
  .mdi-icon {
    color: #3a3a3a;
    max-width: 20px;
  }
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root .MuiInputBase-input {
    padding: 13px 15px 11px;
  }
`

function UserDataForm({
  userData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  },
  onSubmit
}) {
  const { email } = useParams()

  const { register, errors, handleSubmit, getValues } = useForm({
    defaultValues: {
      ...userData,
      email: email ? decodeURIComponent(email) : userData.email
    }
  })

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleTogglePasswordVisibility = () => {
    setShowPassword(showPassword => !showPassword)
  }

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(showConfirmPassword => !showConfirmPassword)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const required = 'This field is required'

  return (
    <Container>
      <Welcome>Welcome</Welcome>
      <Title id="form-title">Create your account</Title>
      <Form
        aria-labelledby="form-title"
        onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <NameContainer>
          <NameWrapper>
            <StyledSelect
              id="firstName"
              name="firstName"
              label="First Name"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
              inputRef={register({ required })}
              InputProps={{
                startAdornment: (
                  <InputIcon position="start">
                    <AccountIcon size={22} />
                  </InputIcon>
                )
              }}
              InputLabelProps={{ required: true }}
              placeholder="Your first name"
              fullWidth
            />
          </NameWrapper>
          <NameWrapper>
            <StyledSelect
              id="lastName"
              name="lastName"
              label="Last Name"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
              inputRef={register({ required })}
              InputProps={{
                startAdornment: (
                  <InputIcon position="start">
                    <AccountIcon size={22} />
                  </InputIcon>
                )
              }}
              InputLabelProps={{ required: true }}
              placeholder="Your last name"
              fullWidth
            />
          </NameWrapper>
        </NameContainer>
        <FieldContainer>
          <StyledSelect
            id="email"
            type="email"
            name="email"
            label="Email"
            error={!!errors.email}
            helperText={errors?.email?.message}
            inputRef={register({
              pattern: { value: emailRegex, message: 'Invalid email' },
              required
            })}
            InputProps={{
              startAdornment: (
                <InputIcon position="start">
                  <EmailIcon size={22} />
                </InputIcon>
              )
            }}
            InputLabelProps={{ required: true }}
            placeholder="Enter your email"
            fullWidth
          />
        </FieldContainer>
        <StyledSelect
          id="password"
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          error={!!errors.password}
          helperText={errors?.password?.message}
          inputRef={register({
            minLength: {
              value: 8,
              message: 'Your password must be at least 8 characters long'
            },
            required
          })}
          InputProps={{
            startAdornment: (
              <InputIcon position="start">
                <KeyIcon size={22} />
              </InputIcon>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  onMouseDown={handleMouseDownPassword}>
                  {showPassword ? (
                    <VisibilityOffIcon color="#C4C4C4" />
                  ) : (
                    <VisibilityIcon color="#C4C4C4" />
                  )}
                </IconButton>
              </InputAdornment>
            )
          }}
          InputLabelProps={{ required: true }}
          placeholder="Enter password"
          fullWidth
        />
        <AlertContainer>
          <InformationIcon size={13} style={{ color: '#4ca1d8' }} />
          <InfoAlert>Password must be 8 characters long</InfoAlert>
        </AlertContainer>
        <FieldContainer>
          <StyledSelect
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            error={!!errors.confirmPassword}
            helperText={errors?.confirmPassword?.message}
            inputRef={register({
              required,
              validate: value =>
                value === getValues('password') || 'Your passwords do not match'
            })}
            InputProps={{
              startAdornment: (
                <InputIcon position="start">
                  <KeyIcon size={22} />
                </InputIcon>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleConfirmPasswordVisibility}
                    onMouseDown={handleMouseDownPassword}>
                    {showConfirmPassword ? (
                      <VisibilityOffIcon color="#C4C4C4" />
                    ) : (
                      <VisibilityIcon color="#C4C4C4" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
            InputLabelProps={{ required: true }}
            placeholder="Re-enter password"
            fullWidth
          />
        </FieldContainer>

        <ButtonWrapper>
          <Button type="submit" padding="small">
            <ArrowRightThickIcon />
            Continue
          </Button>
        </ButtonWrapper>
      </Form>

      <LoginContainer>
        Already have an account?
        <LinkButton color="blue">
          <Link to="/login">Log in</Link>
        </LinkButton>
      </LoginContainer>
    </Container>
  )
}

UserDataForm.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired
}

export default UserDataForm
