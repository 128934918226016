import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import AccountPlusIcon from 'mdi-react/AccountPlusIcon'

import LandingContainer from './LandingContainer'
import { LandingPageTitle } from '../common/Title'
import Button from '../common/Button'

const TextContainer = styled.div`
  max-width: 380px;
  margin-bottom: 19px;
`

const LinkButton = styled(Link)`
  text-decoration: none;
`

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`

const NewPracticeLanding = () => (
  <LandingContainer>
    <TextContainer>
      <LandingPageTitle>
        Create a practice & get started on your first referral
      </LandingPageTitle>
    </TextContainer>
    <LinksContainer>
      <LinkButton to="/settings/practices/new">
        <Button padding="small">
          <AccountPlusIcon />
          Create Practice
        </Button>
      </LinkButton>
    </LinksContainer>
  </LandingContainer>
)

export default NewPracticeLanding
