import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import FilterIcon from 'mdi-react/FilterIcon'
import UndoIcon from 'mdi-react/UndoIcon'
import NotificationClearAllIcon from 'mdi-react/NotificationClearAllIcon'
import { useApolloClient, useMutation } from '@apollo/client'

import { compareDesc } from 'date-fns'
import gql from 'graphql-tag'

import Notification from './Notification'
import { TextButton, SecondaryButton } from '../common/Button'
import { DateIndicator, TimeIndicator } from '../common/DateTimeIndicators'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px 26px;
  display: flex;
  align-items: center;

  svg {
    color: #fff;
    margin-right: 26px;
  }
`

const Filters = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.35);
  display: flex;
  align-items: baseline;
  padding-left: 5px;
`

const FilterButton = styled(TextButton)`
  text-transform: none;
  margin: 0 19px;
  padding: 9px 6px 6px;
  border-bottom: 3px solid transparent;
  letter-spacing: 0;

  &.active {
    border-bottom: 3px solid #fff;
    border-radius: 0;
  }
`

const NotificationItem = styled.div`
  margin: 0 0 15px;
`

const NotificationContent = styled.div`
  display: flex;
  gap: 15px;
`

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`

const NotificationContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 3px;
  background: ${props =>
    props.type === 'referral_declined' ? 'rgba(232, 76, 61, 0.05)' : '#fff'};
  border-left: 3px solid transparent;
  opacity: ${props => (props.isdismissed ? '0.5' : '1')};

  :hover {
    background: rgba(17, 147, 173, 0.03);
    border-left: 3px solid #1193ad;
    cursor: pointer;
  }
`

const CountContainer = styled.div`
  border-radius: 50%;
  background: #fff;
  margin: 0 0 0 5px;
  width: 15px;
  height: 15px;
`

const Count = styled.h5`
  font-weight: 600;
  font-size: 8px;
  line-height: 15px;
  color: #f2641a;
  margin: 0;
  padding: 0;
`

const ButtonContainer = styled.div`
  display: flex;
`

const DismissButton = styled(SecondaryButton)`
  width: 100px;
  height: 35px;
`

const DISMISS_NOTIFICATION = gql`
  mutation dismissNotification($notification: ID!) {
    dismissNotification(notification: $notification) {
      id
      isDismissed
    }
  }
`

const UNDO_DISMISSAL = gql`
  mutation undoDismissal($notification: ID!) {
    undoDismissal(notification: $notification) {
      id
      isDismissed
    }
  }
`

const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      id
      isDismissed
    }
  }
`

const NotificationList = ({ notifications }) => {
  const client = useApolloClient()
  const [dismissNotification] = useMutation(DISMISS_NOTIFICATION)
  const [undoDismissal] = useMutation(UNDO_DISMISSAL)

  const [filterBy, setFilterBy] = useState('none')
  const history = useHistory()

  const filteredNotifications = notifications
    .filter(n =>
      filterBy === 'none'
        ? true
        : n.event.type === 'referral_redirected'
        ? filterBy === 'referral_sent'
        : n.event.type === filterBy
    )
    .sort((a, b) => compareDesc(a.event.createdAt, b.event.createdAt))

  const filters = [
    { value: 'none', label: 'All Notifications' },
    { value: 'referral_sent', label: 'New Referrals' },
    { value: 'referral_declined', label: 'Declined Referrals' },
    { value: 'message_sent', label: 'Messages' },
    { value: 'file_added', label: 'Files' },
    { value: 'category_changed', label: 'Category Changed' },
    { value: 'urgency_changed', label: 'Urgency Changed' }
  ]

  async function handleDismiss(id) {
    try {
      await dismissNotification({ variables: { notification: id } })
    } catch (e) {
      console.error('Error dismissing notification:', e.message)
    }
  }

  async function handleUndoDismissal(id) {
    try {
      await undoDismissal({ variables: { notification: id } })
    } catch (e) {
      console.error('Error dismissing notification:', e.message)
    }
  }

  useEffect(() => {
    return () => {
      if (client.cache.data.data.ROOT_QUERY) {
        const data = client.readQuery({ query: GET_NOTIFICATIONS })

        client.writeQuery({
          query: GET_NOTIFICATIONS,
          data: {
            getNotifications: data.getNotifications.filter(n => !n.isDismissed)
          }
        })
      }
    }
  }, [client])

  return (
    <Container>
      <BlueBar>
        <FilterIcon size={26} />
        <Filters>
          {filters.map(({ value, label }) => (
            <FilterButton
              key={value}
              value={value}
              onClick={() => setFilterBy(value)}
              className={filterBy === value ? 'active' : null}>
              {label}
              {filterBy === value && (
                <CountContainer>
                  <Count>{filteredNotifications.length}</Count>
                </CountContainer>
              )}
            </FilterButton>
          ))}
        </Filters>
      </BlueBar>

      {filteredNotifications.length > 0 &&
        filteredNotifications.map((notification, i, arr) => {
          return (
            <NotificationItem key={notification.id}>
              {i === 0 ||
              arr[i - 1].event.createdAt.getDate() !==
                notification.event.createdAt.getDate() ? (
                <DateIndicator dateTime={notification.event.createdAt} />
              ) : null}
              <NotificationContent>
                <TimeContainer>
                  <TimeIndicator dateTime={notification.event.createdAt} />
                </TimeContainer>
                <NotificationContainer
                  type={notification.event.type}
                  isdismissed={notification.isDismissed}
                  onClick={() =>
                    history.push(`/referrals/${notification.referral.id}`)
                  }>
                  <Notification notification={notification} />
                </NotificationContainer>
                <ButtonContainer>
                  {!notification.isDismissed ? (
                    <DismissButton
                      color="secondary"
                      padding="tiny"
                      onClick={() => handleDismiss(notification.id)}>
                      <NotificationClearAllIcon />
                      Dismiss
                    </DismissButton>
                  ) : (
                    <DismissButton
                      color="secondary"
                      padding="tiny"
                      onClick={() => handleUndoDismissal(notification.id)}>
                      <UndoIcon />
                      Undo
                    </DismissButton>
                  )}
                </ButtonContainer>
              </NotificationContent>
            </NotificationItem>
          )
        })}
    </Container>
  )
}

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired
}

export default NotificationList
