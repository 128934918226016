import React from 'react'
import styled from 'styled-components'
import RegistrationForm from '../registration/RegistrationForm'
import Hero from '../common/Hero'
import MetaData from '../app/MetaData'

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
`

const RegistrationPage = () => (
  <>
    <MetaData
      title="Create Your Clinnect Account Here"
      description="Welcome to Clinnect. Create your account here."
    />
    <Container>
      <Hero />
      <RegistrationForm />
    </Container>
  </>
)

export default RegistrationPage
