import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/client'
import { MenuItem, Paper } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { format } from 'date-fns'

import { PageTitle } from '../common/Title'
import Select from '../common/Select'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import FilterButton from '../reports/FilterButton'
import GroupSummary from '../reports/GroupSummary'
import ReferralSummary from '../reports/ReferralSummary'
import ReferralTrends from '../reports/ReferralTrends'
import DoctorAvailAllocReport from '../reports/DoctorAvailAllocReport'
import PatientTriageReport from '../reports/PatientTriageReport'
import ProcedureReport from '../reports/ProcedureReport'
import WaitlistReport from '../reports/WaitlistReport'
import WaitTimeReport from '../reports/WaitTimeReport'
import PatientJourneySummary from '../reports/PatientJourneySummary'
import { getPracticeCategoryFactors } from '../../util/factors'
import {
  getCategoryName,
  getSubcategoryName,
  getFactorCategoryName
} from '../../util/categories'
import regions from '../../regions.json'
import specialties from '../../specialties.json'
import { InfoMessage } from '../common/UserAlert'
import {
  getPracticeDisplayName,
  getAllowReferralsDisplayName,
  capitalize
} from '../../util/strings'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`

const HeaderContainer = styled.div`
  display: flex;
  margin: 53px 0 30px;
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 97px;
  width: 100%;
`

const DropdownContainer = styled.div`
  display: flex;
`

const BlueBarTextContainer = styled.div`
  height: 36px;
  display: flex;
  align-items: center;
`

const SelectorLabel = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  color: #fff;
  padding-right: 18px;
  margin: 0;
  white-space: nowrap;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    margin-right: 8px;
    width: 250px;
  }
`

const GroupStyledSelect = styled(StyledSelect)`
  .MuiInputBase-root {
    height: 38px;
    margin-right: 16px;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: rgba(6, 153, 135, 0.15);
  }
  &.MuiListItem-root:hover {
    background-color: rgba(6, 153, 135, 0.05);
  }
`

const TestText = styled.span`
  color: rgba(58, 58, 58, 0.7);
  font-size: 12px;
  margin: 0 0 0 5px;
  font-style: italic;
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

const ReportContainer = styled(Paper)`
  width: 100%;
  border-radius: 6px;
  padding: 0px 30px 30px;
`

const DateContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: 350px;
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const DateSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
  .MuiFormHelperText-root.Mui-error {
    color: #fff;
    padding: 4px 0 0;
  }
`

const ToText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 0 10px;
`

const LoadingErrorContainer = styled.div`
  min-height: 100px;
  div:first-child {
    margin-top: 30px;
    height: calc(100vh - 370px);
    min-height: 70px;
  }
`

const StyledInfoMessage = styled(InfoMessage)`
  margin-top: 0;
`

const HelperText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  color: #fff;
  margin: 0;
  padding-top: 10px;
`

const GET_REPORTS_PAGE_DATA = gql`
  query getReportsPageData {
    getPractices {
      id
      type
      group
      region
      specialty
      isAdmin
    }
    getSpecialistsForReports {
      id
      firstName
      lastName
      isTest
      type
      group
      region
      specialty
      allowReferrals
      firstName
      lastName
      specialties
      createdAt
      categoryFactors {
        category
        factor
      }
    }
  }
`

const GET_GROUP_REPORTS_DATA = gql`
  query getGroupReportsData(
    $group: ID!
    $startDate: String
    $endDate: String
    $type: WaitlistType!
  ) {
    getGroupReferrals(group: $group, startDate: $startDate, endDate: $endDate) {
      id
      urgency
      category
      referralDate
      state
      isDirected
      sender {
        practice {
          id
          type
          firstName
          lastName
          centralIntakeName
          group
        }
      }
      recipient {
        practice {
          id
          firstName
          lastName
          group
        }
      }
    }
    getHistoricalGroupFactors(
      group: $group
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      type
      data {
        practice {
          id
          firstName
          lastName
          group
        }
        category
        factor
        initialFactor
      }
      createdAt
    }
    getHistoricalAllowReferrals(
      group: $group
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      type
      data {
        practice {
          id
          firstName
          lastName
          group
        }
        allowReferrals
        initialAllowReferrals
      }
      createdAt
    }
    getGroupDeclinedEvents(
      group: $group
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      type
      reason
      description
      practice
      group
      createdAt
      practiceName
    }
    getGroupProcedures(
      group: $group
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      practice {
        id
        firstName
        lastName
        group
      }
      category
      date
      billingCode
    }
    getGroupPatientJourneys(
      group: $group
      startDate: $startDate
      endDate: $endDate
    ) {
      practice {
        id
        firstName
        lastName
        group
      }
      category
      endedAt
      referralDate
    }
    getGroupWaitTimes(group: $group, startDate: $startDate, endDate: $endDate) {
      practice {
        id
        firstName
        lastName
        group
      }
      category
      type
      value
      referralDate
    }
    getGroupWaitlist(
      group: $group
      type: $type
      startDate: $startDate
      endDate: $endDate
    ) {
      practice {
        id
        firstName
        lastName
        group
      }
      category
      type
      referralDate
    }
  }
`

const dateFormat = 'dd/MMM/yyyy'

const displayMapping = {
  waitTimes: 'wait times',
  patientJourneys: 'patient journeys',
  waitlist: 'currently waitlisted patients'
}

const reports = [
  { value: 'groupSummary', label: 'Group Summary' },
  { value: 'referralSummary', label: 'Referral Summary' },
  { value: 'referralTrends', label: 'Referral Trends' },
  { value: 'doctorAvail', label: 'Doctor Availability and Allocation' },
  { value: 'patientTriage', label: 'Patient Triage' },
  { value: 'procedures', label: 'Procedure Report' },
  { value: 'waitlist', label: 'Waitlist Report' },
  { value: 'waitTimes', label: 'Wait Time Report' },
  { value: 'patientJourneys', label: 'Patient Journey Summary' }
]

function ReportsPage() {
  const { loading, error, data } = useQuery(GET_REPORTS_PAGE_DATA)

  const client = useApolloClient()

  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 4))
  )
  const [endDate, setEndDate] = useState(new Date())

  const [getGroupReportsData, groupReportsDataResult] = useLazyQuery(
    GET_GROUP_REPORTS_DATA,
    {
      fetchPolicy: 'cache-only'
    }
  )

  const [report, setReport] = useState('groupSummary')
  const [groups, setGroups] = useState(null)
  const [activeGroup, setActiveGroup] = useState(null)
  const [filters, setFilters] = useState(null)
  const [specialists, setSpecialists] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [referrals, setReferrals] = useState(null)
  const [triageEvents, setTriageEvents] = useState(null)
  const [currentFactors, setCurrentFactors] = useState(null)
  const [historicalFactors, setHistoricalFactors] = useState(null)
  const [historicalAllowReferrals, setHistoricalAllowReferrals] = useState(null)
  const [procedures, setProcedures] = useState(null)
  const [waitlist, setWaitlist] = useState(null)
  const [waitTimes, setWaitTimes] = useState(null)
  const [patientJourneys, setPatientJourneys] = useState(null)

  const handleChangeReport = e => setReport(e.target.value)
  const handleChangeGroup = e => {
    setReferrals(null)
    setTriageEvents(null)
    setCurrentFactors(null)
    setHistoricalFactors(null)
    setHistoricalAllowReferrals(null)
    setProcedures(null)
    setWaitlist(null)
    setWaitTimes(null)
    setPatientJourneys(null)

    setFilters(null)
    setIsLoading(true)

    setActiveGroup(e.target.value)
  }
  const handleChangeStartDate = e => setStartDate(e)
  const handleChangeEndDate = e => setEndDate(e)

  useEffect(() => {
    async function formatGroups() {
      const practices = data.getPractices
      const specialists = data.getSpecialistsForReports

      const isAdmin = practices.filter(p => p.isAdmin === true).length > 0

      const practicesToFilter = isAdmin ? specialists : practices
      const filteredPractices = practicesToFilter.filter(p => p.group !== null)
      const groups = [...new Set(filteredPractices.map(p => p.group))]
      const groupsWithName = []

      for (const group of groups) {
        const practice = specialists.find(s => s.group === group)
        const region = regions.find(r => r.id === practice.region)
        const specialty = specialties.find(s => s.id === practice.specialty)

        groupsWithName.push({
          id: group,
          name: `${specialty.name} - ${region.name}`,
          isTest: practice.isTest
        })
      }

      setGroups(groupsWithName)
      if (groupsWithName.length > 0) setActiveGroup(groupsWithName[0].id)
    }

    if (data?.getPractices && data?.getSpecialistsForReports) formatGroups()
  }, [data?.getPractices, data?.getSpecialistsForReports])

  useEffect(() => {
    async function fetchReportData() {
      const queryEndDate = data.getSpecialistsForReports
        .filter(s => s.group === activeGroup)
        .reduce((prev, curr) => {
          const currentDate = new Date(parseInt(curr.createdAt))
          if (prev > currentDate) return currentDate
          else if (prev < currentDate) return prev
          return prev
        }, new Date())

      let qEndDate = new Date()
      let qStartDate = new Date(
        new Date(qEndDate).setMonth(new Date(qEndDate).getMonth() - 4)
      )

      while (
        queryEndDate <
        new Date(
          new Date(qStartDate).setMonth(new Date(qStartDate).getMonth() + 4)
        )
      ) {
        await client.query({
          query: GET_GROUP_REPORTS_DATA,
          variables: {
            group: activeGroup,
            startDate: qStartDate.toISOString(),
            endDate: qEndDate.toISOString(),
            type: 'both'
          },
          fetchPolicy: 'network-only'
        })

        qEndDate = new Date(
          new Date(qStartDate).setDate(new Date(qStartDate).getDate() - 1)
        )
        qStartDate = new Date(
          new Date(qStartDate).setMonth(new Date(qStartDate).getMonth() - 4)
        )
      }

      setIsLoading(false)
    }

    if (
      activeGroup &&
      data?.getSpecialistsForReports &&
      !client.cache.data.data.ROOT_QUERY?.[
        `getGroupReferrals:{"group":"${activeGroup}"}`
      ]
    ) {
      fetchReportData()
      setSpecialists(
        data.getSpecialistsForReports.filter(s => s.group === activeGroup)
      )
    } else if (
      activeGroup &&
      data?.getSpecialistsForReports &&
      client.cache.data.data.ROOT_QUERY?.[
        `getGroupReferrals:{"group":"${activeGroup}"}`
      ]
    ) {
      setSpecialists(
        data.getSpecialistsForReports.filter(s => s.group === activeGroup)
      )
      setIsLoading(false)
    }
  }, [activeGroup, client, data?.getSpecialistsForReports])

  useEffect(() => {
    if (activeGroup && startDate && endDate) {
      getGroupReportsData({
        variables: { group: activeGroup, startDate, endDate }
      })
    }
  }, [activeGroup, startDate, endDate, getGroupReportsData])

  useEffect(() => {
    async function formatReferrals() {
      const formattedReferrals =
        groupReportsDataResult.data.getGroupReferrals.map(formatReferral)
      setReferrals(formattedReferrals)
    }

    function formatReferral(referral) {
      const subcat = getSubcategoryName(referral.category)
      const subcategory = subcat === 'N/A' ? null : subcat

      function getIsDirectedValue(isDirected) {
        if (isDirected) return 'Directed'
        if (isDirected === false) return 'Next Available'
        return 'Unknown'
      }

      return {
        id: referral.id,
        state: referral.state,
        urgency: referral.urgency,
        isDirected: getIsDirectedValue(referral.isDirected),
        category: getCategoryName(referral.category),
        categoryId: referral.category,
        subcategory,
        dateSent: new Date(parseInt(referral.referralDate)),
        month: new Date(parseInt(referral.referralDate)).getMonth(),
        year: new Date(parseInt(referral.referralDate)).getFullYear(),
        from: getPracticeDisplayName(referral.sender.practice),
        senderPractice: {
          id: referral.sender.practice.id,
          group: referral.sender.practice.group
        },
        to: `${referral.recipient.practice.lastName}, ${referral.recipient.practice.firstName}`,
        recipientPractice: {
          id: referral.recipient.practice.id,
          group: referral.recipient.practice.group
        }
      }
    }

    if (groupReportsDataResult.data?.getGroupReferrals) formatReferrals()
  }, [groupReportsDataResult.data?.getGroupReferrals])

  useEffect(() => {
    async function formatCurrentFactors() {
      const currentSpecialists = data.getSpecialistsForReports.filter(
        s => s.group === activeGroup
      )
      const specialistsWithFactors = currentSpecialists.map(
        getPracticeCategoryFactors
      )

      const categoryFactors = []
      for (const specialist of specialistsWithFactors) {
        const overallFactor = specialist.categoryFactors.find(
          c => c.category === null
        ).factor
        const formattedFactors = specialist.categoryFactors.map(c =>
          formatCurrentFactor(c, specialist, overallFactor)
        )
        categoryFactors.push(...formattedFactors)
      }

      setCurrentFactors(categoryFactors)
    }

    function formatCurrentFactor(currentFactor, specialist, overallFactor) {
      return {
        practice: `${specialist.lastName}, ${specialist.firstName}`,
        practiceId: specialist.id,
        category: getFactorCategoryName(currentFactor.category),
        categoryId: currentFactor.category,
        factor: overallFactor === 0 ? 0 : currentFactor.factor
      }
    }

    if (activeGroup && data?.getSpecialistsForReports) formatCurrentFactors()
  }, [data?.getSpecialistsForReports, activeGroup])

  useEffect(() => {
    async function formatHistoricalFactors() {
      const factors = groupReportsDataResult.data.getHistoricalGroupFactors
      const formatted = factors.map(formatHistoricalFactor)
      setHistoricalFactors(formatted)
    }

    function formatHistoricalFactor(historicalFactor) {
      return {
        practice: `${historicalFactor.data.practice.lastName}, ${historicalFactor.data.practice.firstName}`,
        practiceId: historicalFactor.data.practice.id,
        category: getFactorCategoryName(historicalFactor.data.category),
        categoryId: historicalFactor.data.category,
        initialFactor: historicalFactor.data.initialFactor * 100 + '%',
        factor: historicalFactor.data.factor * 100 + '%',
        createdAt: format(
          new Date(parseInt(historicalFactor.createdAt)),
          dateFormat
        )
      }
    }

    if (groupReportsDataResult.data?.getHistoricalGroupFactors)
      formatHistoricalFactors()
  }, [groupReportsDataResult.data?.getHistoricalGroupFactors])

  useEffect(() => {
    function formatHistoricalAllowReferrals() {
      const historical = groupReportsDataResult.data.getHistoricalAllowReferrals
      const formatted = historical.map(formatHistoricalEvent)
      setHistoricalAllowReferrals(formatted)
    }

    function formatHistoricalEvent(e) {
      return {
        practice: `${e.data.practice.lastName}, ${e.data.practice.firstName}`,
        practiceId: e.data.practice.id,
        initialAllowReferrals: getAllowReferralsDisplayName(
          e.data.initialAllowReferrals
        ),
        allowReferrals: getAllowReferralsDisplayName(e.data.allowReferrals),
        createdAt: format(new Date(parseInt(e.createdAt)), dateFormat)
      }
    }

    if (groupReportsDataResult.data?.getHistoricalAllowReferrals)
      formatHistoricalAllowReferrals()
  }, [groupReportsDataResult.data?.getHistoricalAllowReferrals])

  useEffect(() => {
    if (groupReportsDataResult.data?.getGroupDeclinedEvents) {
      const declinedEvents = groupReportsDataResult.data.getGroupDeclinedEvents
      const formattedTriageEvents = declinedEvents.map(e => {
        return {
          ...e,
          createdAt: new Date(parseInt(e.createdAt))
        }
      })
      setTriageEvents(formattedTriageEvents)
    }
  }, [groupReportsDataResult.data?.getGroupDeclinedEvents])

  useEffect(() => {
    function formatProcedures() {
      const procedures = groupReportsDataResult.data.getGroupProcedures
      const formatted = procedures.map(formatProcedure)
      setProcedures(formatted)
    }

    function formatProcedure(e) {
      const subcat = getSubcategoryName(e.category)
      const subcategory = subcat === 'N/A' ? null : subcat

      return {
        to: `${e.practice.lastName}, ${e.practice.firstName}`,
        practiceId: e.practice.id,
        category: getCategoryName(e.category),
        categoryId: e.category,
        subcategory,
        billingCode: e.billingCode,
        date: new Date(parseInt(e.date)),
        month: new Date(parseInt(e.date)).getMonth(),
        year: new Date(parseInt(e.date)).getFullYear()
      }
    }

    if (groupReportsDataResult.data?.getGroupProcedures) formatProcedures()
  }, [groupReportsDataResult.data?.getGroupProcedures])

  useEffect(() => {
    function formatWaitlist() {
      const waitlist = groupReportsDataResult.data.getGroupWaitlist
      const formatted = waitlist.map(formatWaitlistItem)
      setWaitlist(formatted)
    }

    function formatWaitlistItem(w) {
      const subcat = getSubcategoryName(w.category)
      const subcategory = subcat === 'N/A' ? null : subcat

      return {
        type: w.type,
        to: `${w.practice.lastName}, ${w.practice.firstName}`,
        practiceId: w.practice.id,
        category: getCategoryName(w.category),
        categoryId: w.category,
        subcategory
      }
    }

    if (groupReportsDataResult.data?.getGroupWaitlist) formatWaitlist()
  }, [groupReportsDataResult.data?.getGroupWaitlist])

  useEffect(() => {
    function formatPatientJourneys() {
      const journeys = groupReportsDataResult.data.getGroupPatientJourneys
      const formatted = journeys.map(formatPatientJourney)
      setPatientJourneys(formatted)
    }

    function formatPatientJourney(w) {
      const subcat = getSubcategoryName(w.category)
      const subcategory = subcat === 'N/A' ? null : subcat

      return {
        to: `${w.practice.lastName}, ${w.practice.firstName}`,
        practiceId: w.practice.id,
        category: getCategoryName(w.category),
        categoryId: w.category,
        subcategory,
        endedAt: capitalize(w.endedAt)
      }
    }

    if (groupReportsDataResult.data?.getGroupPatientJourneys)
      formatPatientJourneys()
  }, [groupReportsDataResult.data?.getGroupPatientJourneys])

  useEffect(() => {
    function formatWaitTimes() {
      const waitTimes = groupReportsDataResult.data.getGroupWaitTimes
      const formatted = waitTimes.map(formatWaitTime)
      setWaitTimes(formatted)
    }

    function formatWaitTime(w) {
      const subcat = getSubcategoryName(w.category)
      const subcategory = subcat === 'N/A' ? null : subcat

      return {
        to: `${w.practice.lastName}, ${w.practice.firstName}`,
        practiceId: w.practice.id,
        category: getCategoryName(w.category),
        categoryId: w.category,
        subcategory,
        type: w.type,
        value: w.value
      }
    }

    if (groupReportsDataResult.data?.getGroupWaitTimes) formatWaitTimes()
  }, [groupReportsDataResult.data?.getGroupWaitTimes])

  return (
    <Content>
      <Header>
        <PageTitle>Reports</PageTitle>
        <HeaderContainer>
          {groups?.length > 1 && (
            <GroupStyledSelect
              select
              value={activeGroup}
              onChange={handleChangeGroup}
              aria-labelledby="group-dropdown-label">
              {groups.map(({ id, name, isTest }) => (
                <StyledMenuItem key={id} value={id}>
                  {name}
                  {isTest && <TestText>{'(test)'}</TestText>}
                </StyledMenuItem>
              ))}
            </GroupStyledSelect>
          )}
          {specialists && (
            <FilterButton
              specialists={specialists}
              filters={filters}
              applyFilters={setFilters}
            />
          )}
        </HeaderContainer>
      </Header>
      <BlueBar>
        <DropdownContainer>
          <BlueBarTextContainer>
            <SelectorLabel id="report-dropdown-label">Report</SelectorLabel>
          </BlueBarTextContainer>
          <StyledSelect
            select
            value={report}
            onChange={handleChangeReport}
            aria-labelledby="report-dropdown-label">
            {reports.map(({ value, label }) => (
              <StyledMenuItem key={value} value={value}>
                {label}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </DropdownContainer>
        <RightContainer>
          <DateContainer>
            <KeyboardDatePicker
              value={startDate}
              TextFieldComponent={DateSelect}
              mask="__/___/____"
              refuse={/[^a-zA-Z0-9]+/gi}
              format="dd/MMM/yyyy"
              placeholder="DD/MMM/YYYY"
              disableFuture
              views={['year', 'month', 'date']}
              openTo="date"
              maxDate={endDate}
              maxDateMessage={
                startDate > new Date()
                  ? 'Date cannot be in the future'
                  : 'Date should be before end date'
              }
              onChange={handleChangeStartDate}
              InputLabelProps={{
                shrink: true,
                required: true
              }}
              disabled={isLoading}
            />
            <BlueBarTextContainer>
              <ToText>to</ToText>
            </BlueBarTextContainer>
            <KeyboardDatePicker
              value={endDate}
              TextFieldComponent={DateSelect}
              mask="__/___/____"
              refuse={/[^a-zA-Z0-9]+/gi}
              format="dd/MMM/yyyy"
              placeholder="DD/MMM/YYYY"
              disableFuture
              views={['year', 'month', 'date']}
              openTo="date"
              minDate={startDate}
              minDateMessage="Date should be after start date"
              maxDateMessage="Date cannot be in the future"
              onChange={handleChangeEndDate}
              InputLabelProps={{
                shrink: true,
                required: true
              }}
              disabled={isLoading}
            />
          </DateContainer>
          {isLoading && (
            <HelperText>
              Note: Older data is loading in the background. You will be able to
              adjust the dates once the data is loaded.
            </HelperText>
          )}
        </RightContainer>
      </BlueBar>
      {(error || groupReportsDataResult.error) && (
        <LoadingErrorContainer>
          <LoadingError />
        </LoadingErrorContainer>
      )}
      {!error &&
        (loading ||
          groupReportsDataResult.loading ||
          !referrals ||
          !currentFactors ||
          !historicalFactors ||
          !triageEvents ||
          !procedures ||
          !waitlist ||
          !waitTimes ||
          !patientJourneys) && (
          <LoadingErrorContainer>
            <Loading />
          </LoadingErrorContainer>
        )}
      {!error &&
        !loading &&
        referrals &&
        currentFactors &&
        historicalFactors &&
        triageEvents &&
        historicalAllowReferrals &&
        procedures &&
        waitlist &&
        waitTimes &&
        patientJourneys && (
          <>
            {(report === 'referralTrends' || report === 'referralSummary') && (
              <StyledInfoMessage>
                This report only shows Accepted and Pending Referrals. Declined
                and Redirected Referrals are not included.
              </StyledInfoMessage>
            )}
            {report === 'patientTriage' && (
              <StyledInfoMessage>
                This report is only filtered by Specialist.
              </StyledInfoMessage>
            )}
            {['waitTimes', 'patientJourneys', 'waitlist'].includes(report) && (
              <StyledInfoMessage>
                This report shows {displayMapping[report]} for Accepted and
                Pending Referrals received during the selected time frame.
                Declined and Redirected Referrals are not included.
              </StyledInfoMessage>
            )}
            <ReportContainer elevation={3}>
              {['groupSummary', 'referralTrends', 'referralSummary'].includes(
                report
              ) &&
                (activeGroup ? (
                  <>
                    {report === 'groupSummary' && (
                      <GroupSummary
                        setReport={setReport}
                        receivedReferrals={referrals.filter(
                          r => r.recipientPractice.group === activeGroup
                        )}
                        currentFactors={currentFactors}
                        filters={filters}
                      />
                    )}
                    {report === 'referralTrends' && (
                      <ReferralTrends
                        receivedReferrals={referrals.filter(
                          r =>
                            r.recipientPractice.group === activeGroup &&
                            r.state !== 'declined' &&
                            r.state !== 'redirected'
                        )}
                        filters={filters}
                      />
                    )}
                    {report === 'referralSummary' && (
                      <ReferralSummary
                        receivedReferrals={referrals.filter(
                          r =>
                            r.recipientPractice.group === activeGroup &&
                            r.state !== 'declined' &&
                            r.state !== 'redirected'
                        )}
                        filters={filters}
                      />
                    )}
                  </>
                ) : (
                  <EmptyText>
                    There is no data to show in the selected date range
                  </EmptyText>
                ))}
              {report === 'doctorAvail' && (
                <DoctorAvailAllocReport
                  currentFactors={currentFactors}
                  historicalFactors={historicalFactors}
                  allowReferrals={(filters
                    ? filters.specialists
                    : data.getSpecialistsForReports
                  )
                    .filter(s => s.group === activeGroup)
                    .map(p => {
                      return {
                        practice: `${p.lastName}, ${p.firstName}`,
                        allowReferrals: getAllowReferralsDisplayName(
                          p.allowReferrals
                        )
                      }
                    })}
                  historicalAllowReferrals={historicalAllowReferrals}
                  filters={filters}
                />
              )}
              {report === 'patientTriage' && (
                <PatientTriageReport
                  events={triageEvents}
                  referrals={referrals.filter(
                    r => r.recipientPractice.group === activeGroup
                  )}
                  filters={filters}
                />
              )}
              {report === 'procedures' && (
                <ProcedureReport procedures={procedures} filters={filters} />
              )}
              {report === 'waitlist' && (
                <WaitlistReport
                  consultWaitlist={waitlist.filter(w => w.type === 'consult')}
                  procedureWaitlist={waitlist.filter(
                    w => w.type === 'procedure'
                  )}
                  filters={filters}
                />
              )}
              {report === 'waitTimes' && (
                <WaitTimeReport waitTimes={waitTimes} filters={filters} />
              )}
              {report === 'patientJourneys' && (
                <PatientJourneySummary
                  patientJourneys={patientJourneys}
                  filters={filters}
                />
              )}
            </ReportContainer>
          </>
        )}
    </Content>
  )
}

export default ReportsPage
