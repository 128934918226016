import React from 'react'
import PropTypes from 'prop-types'
import ReferralHistoryEvent from './ReferralHistoryEvent'
import MessageBox from '../messaging/MessageBox'
import { SectionTitle } from '../common/Title'

import styled from 'styled-components'

const HistoryContainer = styled.div`
  height: 100%;
  padding: 30px 15px 27px;

  h1:first-child {
    margin-top: 0;
  }
`

const StyledTitle = styled(SectionTitle)`
  margin: 0 15px 14px;
`

const MessageBoxContainer = styled.div`
  margin: 0 15px;
`

const LogEntries = styled.div`
  max-height: ${props =>
    props.recipient === 'true' ? 'calc(100vh - 439px)' : 'calc(100vh - 314px)'};

  /* Flex starts the scroll bar at the bottom of the
   * referral history log, displaying the most recent events */
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;

  & > div {
    padding-top: 30px;
  }

  /* note: ::-webkit-scroll is non-standard */
  scrollbar-width: thin;
  scrollbar-color: rgba(58, 58, 58, 0.15) #fff;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(58, 58, 58, 0.15);
    border-radius: 6px;
  }
`

const Events = styled.div`
  :first-child {
    padding-top: 30px;
  }
  padding: 0 15px;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
  margin: 0 15px;
`

const ReferralHistory = ({
  events,
  sender,
  recipient,
  referral,
  isSender,
  isRecipient = false
}) => (
  <HistoryContainer>
    <StyledTitle variant="section">Referral History</StyledTitle>
    <Divider />
    <LogEntries recipient={isRecipient.toString()}>
      <Events>
        {events.map((event, i) => (
          <ReferralHistoryEvent
            key={i}
            event={event}
            sender={sender}
            recipient={recipient}
            referral={referral}
            isSender={isSender}
          />
        ))}
      </Events>
    </LogEntries>
    <MessageBoxContainer>
      <MessageBox referral={referral} recipient={recipient} sender={sender} />
    </MessageBoxContainer>
  </HistoryContainer>
)

ReferralHistory.propTypes = {
  events: PropTypes.array.isRequired,
  sender: PropTypes.object.isRequired,
  recipient: PropTypes.object.isRequired,
  referral: PropTypes.object.isRequired,
  isSender: PropTypes.bool,
  isRecipient: PropTypes.bool
}

export default ReferralHistory
