import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import styled from 'styled-components'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import EditIcon from 'mdi-react/PencilIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import Select from '../common/Select'
import { IconButton } from '../common/Button'
import { ErrorMessage } from '../common/UserAlert'

const Form = styled.form`
  display: flex;
  flex-direction: column;
`
const FieldLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgba(58, 58, 58, 0.7);
  line-height: 18px;
  margin: 0 9px 0 0;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root.Mui-disabled {
    opacity: 1;
    border: none;
  }
  .MuiInputBase-input.Mui-disabled {
    opacity: 1;
  }
  .MuiInputBase-root {
    margin-top: 5px;
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 5px;
`

const ButtonContainer = styled.div`
  button:last-child {
    margin-left: 10px;
  }
`
const LabelButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
`

const UPDATE_NAME = gql`
  mutation updateName($firstName: String!, $lastName: String!) {
    updateName(firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
    }
  }
`

function ChangeNameButton({ type = 'firstName', user }) {
  const [mutate, { loading, error }] = useMutation(UPDATE_NAME)
  const [isDisabled, setIsDisabled] = useState(true)
  const { handleSubmit, errors, register, reset } = useForm()

  const handleEdit = () => setIsDisabled(false)
  function handleCancel() {
    type === 'firstName'
      ? reset({
          firstName: user.firstName
        })
      : reset({
          lastName: user.lastName
        })
    setIsDisabled(true)
  }

  async function onSubmit(values) {
    try {
      await mutate({
        variables:
          type === 'firstName'
            ? { firstName: values.firstName, lastName: user.lastName }
            : { firstName: user.firstName, lastName: values.lastName }
      })
      setIsDisabled(true)
    } catch (e) {
      console.error('Error updating name:', e.message)
    }
  }

  const required = 'This field is required'

  return (
    <Form aria-label="Change name" onSubmit={handleSubmit(onSubmit)}>
      <LabelButtonContainer>
        {isDisabled ? (
          <StyledIconButton
            color="secondary"
            onClick={handleEdit}
            aria-label={
              type === 'firstName' ? 'Edit first name' : 'Edit last name'
            }>
            <EditIcon />
          </StyledIconButton>
        ) : (
          <ButtonContainer>
            <StyledIconButton
              color="secondary"
              onClick={handleCancel}
              aria-label="Close">
              <CloseIcon />
            </StyledIconButton>
            <StyledIconButton
              padding="tiny"
              color="secondary"
              type="submit"
              loading={loading}
              aria-label="Save">
              <ContentSaveIcon />
            </StyledIconButton>
          </ButtonContainer>
        )}
        <FieldLabel htmlFor={type}>
          {type === 'firstName' ? 'First Name' : 'Last Name'}
        </FieldLabel>
      </LabelButtonContainer>
      <StyledSelect
        id={type}
        name={type}
        defaultValue={type === 'firstName' ? user.firstName : user.lastName}
        inputRef={register({ required })}
        disabled={isDisabled}
        error={type === 'firstName' ? !!errors.firstName : !!errors.lastName}
        helperText={
          type === 'firstName'
            ? errors?.firstName?.message
            : errors?.lastName?.message
        }
      />
      {error && <ErrorMessage>Hm, an error occurred. Try again.</ErrorMessage>}
    </Form>
  )
}

ChangeNameButton.propTypes = {
  type: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
}

export default ChangeNameButton
