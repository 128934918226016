import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Table from './Table'
import PivotTable from './PivotTable'
import { SectionTitle } from '../common/Title'
import { filterDataBySpecialistCategory } from '../../util/filterData'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Bar = styled.div`
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);

  tr,
  th,
  td {
    padding: 7px;
    font-weight: 400;
    border: 0.5px solid #e0e0e0;
    text-align: center;
  }
  tr:first-child {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    background-color: rgba(17, 147, 173, 0.2);

    th {
      font-weight: 600;
    }
  }
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

function DoctorAvailAllocReport({
  currentFactors,
  historicalFactors,
  allowReferrals,
  historicalAllowReferrals,
  filters = null
}) {
  const filteredCurrentFactors = filters
    ? filterDataBySpecialistCategory(currentFactors, 'factors', filters)
    : currentFactors
  const filteredHistoricalFactors = filters
    ? filterDataBySpecialistCategory(historicalFactors, 'factors', filters)
    : historicalFactors
  const filteredHistoricalAllowReferrals = filters
    ? filterDataBySpecialistCategory(
        historicalAllowReferrals,
        'allowReferrals',
        filters
      )
    : historicalAllowReferrals

  if (
    filteredHistoricalFactors.length === 0 &&
    filteredCurrentFactors.length === 0 &&
    filteredHistoricalAllowReferrals.length === 0
  ) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  return (
    <ReportVisualsContainer>
      <SectionTitle>Doctor Availability and Allocation Report</SectionTitle>
      <Bar />
      <TableContainer>
        <SectionTitle>Current Referral Setting</SectionTitle>
        <StyledTable>
          <tbody>
            <tr>
              {allowReferrals.map(p => (
                <th key={p.practice}>{p.practice}</th>
              ))}
            </tr>
            <tr>
              {allowReferrals.map(p => (
                <td key={p.practice}>{p.allowReferrals}</td>
              ))}
            </tr>
          </tbody>
        </StyledTable>
      </TableContainer>
      <PivotTable
        title="Current Availability"
        dataSourceSettings={{
          showGrandTotals: false,
          dataSource: filteredCurrentFactors,
          formatSettings: [{ name: 'factor', format: '### %' }],
          columns: [{ name: 'practice' }],
          rows: [{ name: 'category' }],
          values: [{ name: 'factor' }],
          enableSorting: false,
          valueAxis: 'row'
        }}
        visibleRows={filteredCurrentFactors.length}
        downloadable={true}
      />
      <Table
        title="Availability History"
        options={{
          allowSorting: true,
          columns: [
            { field: 'createdAt', headerText: 'Date' },
            { field: 'practice', headerText: 'Practice' },
            { field: 'category', headerText: 'Category' },
            { field: 'initialFactor', headerText: 'From' },
            { field: 'factor', headerText: 'To' }
          ],
          dataSource: filteredHistoricalFactors,
          height: filteredHistoricalFactors.length > 20 ? 610 : ''
        }}
        downloadable={true}
      />
      <Table
        title="Referral Setting History"
        options={{
          allowSorting: true,
          columns: [
            { field: 'createdAt', headerText: 'Date' },
            { field: 'practice', headerText: 'Practice' },
            { field: 'initialAllowReferrals', headerText: 'From' },
            { field: 'allowReferrals', headerText: 'To' }
          ],
          dataSource: filteredHistoricalAllowReferrals,
          height: filteredHistoricalAllowReferrals.length > 20 ? 600 : ''
        }}
        downloadable={true}
      />
    </ReportVisualsContainer>
  )
}

DoctorAvailAllocReport.propTypes = {
  currentFactors: PropTypes.array.isRequired,
  historicalFactors: PropTypes.array.isRequired,
  allowReferrals: PropTypes.array.isRequired,
  historicalAllowReferrals: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default DoctorAvailAllocReport
