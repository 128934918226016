import JwtDecode from 'jwt-decode'
import history from '../history'

const REFRESH_40_MINUTES_FROM_EXPIRY = 40 * 60
const TOKEN_CHECK_TIME = 60 * 1000

function isTokenExpiringSoon() {
  if (window.localStorage.authToken) {
    const { exp } = JwtDecode(window.localStorage.authToken)
    const currentTime = new Date().getTime() / 1000
    const refreshTime = exp - REFRESH_40_MINUTES_FROM_EXPIRY

    if (currentTime > refreshTime) return true
  }
  return false
}

function isTokenExpired() {
  if (window.localStorage.authToken) {
    const { exp } = JwtDecode(window.localStorage.authToken)
    const currentTime = new Date().getTime() / 1000

    if (currentTime > exp) return true
  }
  return false
}

function isTokenValid() {
  try {
    if (window.localStorage.authToken) {
      const { exp } = JwtDecode(window.localStorage.authToken)
      const currentTime = new Date().getTime() / 1000
      /*
       * Check if token would be expired before next check, to ensure we don't make
       * any requests with an invalid token
       */
      return !(currentTime + TOKEN_CHECK_TIME / 1000 > exp)
    }
  } catch (e) {
    return false
  }

  return false
}

function checkToken() {
  if (isTokenExpired()) {
    history.push('/logout')
  }
}

setInterval(checkToken, TOKEN_CHECK_TIME)

export { isTokenExpiringSoon, isTokenExpired, isTokenValid }
