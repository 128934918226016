import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Chart from './Chart'
import PivotTable from './PivotTable'
import { SectionTitle } from '../common/Title'
import { InfoMessage } from '../common/UserAlert'
import { filterDataBySpecialistCategory } from '../../util/filterData'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Divider = styled.div`
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
`

const Bold = styled.span`
  font-weight: 600;
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

function PatientJourneySummary({ patientJourneys, filters = null }) {
  const filteredJourneys = filters
    ? filterDataBySpecialistCategory(patientJourneys, 'journeys', filters)
    : patientJourneys

  if (filteredJourneys.length === 0) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  const categoryLength = [...new Set(filteredJourneys.map(r => r.category))]
    .length
  const height = categoryLength < 6 ? 450 : categoryLength > 10 ? 800 : 625

  return (
    <ReportVisualsContainer>
      <SectionTitle>Patient Journey Summary</SectionTitle>
      <Divider />
      <InfoMessage>
        <Bold>This report details the current state of patient journeys</Bold>,
        i.e. if the patient journey is ongoing (the patient is still being seen
        by the doctor) or completed. Completed journeys are split into two
        categories: consult (the journey was completed at a consult and no
        procedure was done) and procedure (the journey was completed after the
        patient received a procedure).
      </InfoMessage>
      <PivotTable
        title="Journeys by Doctor and Category"
        dataSourceSettings={{
          dataSource: filteredJourneys,
          columns: [{ name: 'endedAt' }],
          rows: [{ name: 'to' }, { name: 'category' }, { name: 'subcategory' }],
          values: [{ name: 'category' }]
        }}
        visibleRows={15}
        downloadable={true}
        expandable={true}
      />
      <PivotTable
        title="Journeys by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredJourneys,
          columns: [{ name: 'endedAt' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }, { name: 'to' }],
          values: [{ name: 'to' }]
        }}
        visibleRows={15}
        downloadable={true}
        expandable={true}
      />
      <Chart
        type="stackingBar100"
        title="Overall Distribution of Journeys by Category"
        dataSourceSettings={{
          dataSource: filteredJourneys,
          sortSettings: [{ name: 'category', order: 'Descending' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          columns: [{ name: 'endedAt' }],
          values: [{ name: 'category' }]
        }}
        customSettings={{ height }}
        expandable={true}
        downloadable={true}
      />
    </ReportVisualsContainer>
  )
}

PatientJourneySummary.propTypes = {
  patientJourneys: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default PatientJourneySummary
