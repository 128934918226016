import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useForm } from 'react-hook-form'
import {
  deriveAccountKeys,
  wrapSecretKey,
  unwrapEccKey,
  unwrapAesKey
} from '../../crypto'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import EditIcon from 'mdi-react/PencilIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import Select from '../common/Select'
import { IconButton } from '../common/Button'
import { FieldLabel, FieldValue } from '../common/StyledField'
import { ErrorMessage } from '../common/UserAlert'

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const StyledSelect = styled(Select)`
  &.MuiFormControl-root {
    margin-top: 10px;
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 5px;
`

const ButtonContainer = styled.div`
  button:last-child {
    margin-left: 10px;
  }
`
const LabelButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`
const NewEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: rgba(6, 153, 135, 0.05);
`

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
`

const UPDATE_KEYS = gql`
  mutation updateKeys($accountToken: String!, $keyChange: KeyChangeInput!) {
    updateKeys(accountToken: $accountToken, keyChange: $keyChange) {
      id
      privateKey {
        encryptedData
        iv
      }
      recoveryKey {
        encryptedData
        iv
      }
      salt
    }
  }
`

function ChangePasswordButton({ user }) {
  const { errors, handleSubmit, getValues, setError, register } = useForm()
  const [updateError, setUpdateError] = useState(false)

  const [updateKeys, { loading, error: mutationError }] =
    useMutation(UPDATE_KEYS)
  const [isDisabled, setIsDisabled] = useState(true)

  const handleEdit = () => setIsDisabled(false)
  const handleCancel = () => setIsDisabled(true)

  async function onSubmit(values) {
    setUpdateError(false)
    try {
      const { newPassword, currentPassword } = values

      const currentAccountKeys = await deriveAccountKeys(
        currentPassword,
        user.salt,
        true
      )

      let extractablePrivateKey
      try {
        extractablePrivateKey = await unwrapEccKey(
          user.privateKey.encryptedData,
          currentAccountKeys.encryptionKey,
          user.privateKey.iv,
          true
        )
      } catch (e) {
        setError('currentPassword', {
          type: 'manual',
          message: 'Incorrect password'
        })
        return
      }

      const { accountToken, encryptionKey, salt } = await deriveAccountKeys(
        newPassword
      )

      const { wrappedKey, iv } = await wrapSecretKey(
        extractablePrivateKey,
        encryptionKey
      )

      const recoveryKey = await unwrapAesKey(
        user.recoveryKey.encryptedData,
        currentAccountKeys.encryptionKey,
        user.recoveryKey.iv,
        'wrap',
        true
      )

      const { wrappedKey: backupKey, iv: backupIv } = await wrapSecretKey(
        extractablePrivateKey,
        recoveryKey
      )

      const { wrappedKey: wrappedRecoveryKey, iv: recoveryIv } =
        await wrapSecretKey(recoveryKey, encryptionKey)

      const keyChange = {
        backupKey: {
          encryptedData: backupKey,
          iv: backupIv
        },
        recoveryKey: {
          encryptedData: wrappedRecoveryKey,
          iv: recoveryIv
        },
        accountToken,
        privateKey: {
          encryptedData: wrappedKey,
          iv
        },
        salt
      }

      await updateKeys({
        variables: { accountToken: currentAccountKeys.accountToken, keyChange }
      })

      setIsDisabled(true)
    } catch (e) {
      console.error('Error updating password:', e.message)
      setUpdateError(true)
    }
  }

  const required = 'This field is required'

  return (
    <Form aria-label="Change password" onSubmit={handleSubmit(onSubmit)}>
      {(mutationError || updateError) && (
        <ErrorMessage severity="error">Hm, an error occurred.</ErrorMessage>
      )}
      <LabelButtonContainer>
        {isDisabled ? (
          <StyledIconButton
            color="secondary"
            onClick={handleEdit}
            aria-label="Edit password">
            <EditIcon />
          </StyledIconButton>
        ) : (
          <ButtonContainer>
            <StyledIconButton color="secondary" onClick={handleCancel}>
              <CloseIcon />
            </StyledIconButton>
            <StyledIconButton
              padding="tiny"
              color="secondary"
              type="submit"
              loading={loading}
              aria-label="Save password">
              <ContentSaveIcon />
            </StyledIconButton>
          </ButtonContainer>
        )}
        <FieldLabel>Password</FieldLabel>
      </LabelButtonContainer>
      {isDisabled ? (
        <FieldValue>xxxxxxxxxx</FieldValue>
      ) : (
        <NewEmailContainer>
          <Text>
            It is very important you create a strong password. If you forget
            your password, you will only be able to recover your account with
            your recovery key.
          </Text>
          <StyledSelect
            defaultValue=""
            autoFocus
            margin="dense"
            id="currentPassword"
            name="currentPassword"
            label="Current Password"
            type="password"
            fullWidth
            disabled={loading}
            error={!!errors.currentPassword}
            helperText={errors?.currentPassword?.message}
            inputRef={register({ required })}
          />
          <StyledSelect
            defaultValue=""
            margin="dense"
            id="newPassword"
            name="newPassword"
            label="New Password"
            type="password"
            fullWidth
            disabled={loading}
            error={!!errors.newPassword}
            helperText={errors?.newPassword?.message}
            inputRef={register({
              minLength: {
                value: 8,
                message: 'Your password must be at least 8 characters long'
              },
              required
            })}
          />
          <StyledSelect
            defaultValue=""
            margin="dense"
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            fullWidth
            disabled={loading}
            error={!!errors.confirmPassword}
            helperText={errors?.confirmPassword?.message}
            inputRef={register({
              required,
              validate: {
                passwordsMatch: value =>
                  value === getValues().newPassword || 'Passwords do not match'
              }
            })}
          />
        </NewEmailContainer>
      )}
    </Form>
  )
}

ChangePasswordButton.propTypes = {
  user: PropTypes.shape({
    privateKey: PropTypes.shape({
      encryptedData: PropTypes.string.isRequired,
      iv: PropTypes.string.isRequired
    }).isRequired,
    recoveryKey: PropTypes.shape({
      encryptedData: PropTypes.string.isRequired,
      iv: PropTypes.string.isRequired
    }).isRequired,
    salt: PropTypes.string.isRequired
  }).isRequired
}

export default ChangePasswordButton
