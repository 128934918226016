import React from 'react'
import styled from 'styled-components'
import LoginForm from '../login/LoginForm'
import Hero from '../common/Hero'
import MetaData from '../app/MetaData'

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
`

const LoginPage = () => (
  <>
    <MetaData
      title="Secure Login For Your Clinnect Account"
      description="Login to your Clinnect account here. Simple, central, and secure!"
    />
    <Container>
      <Hero />
      <LoginForm />
    </Container>
  </>
)

export default LoginPage
