import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress
} from '@material-ui/core'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/client'
import Alert from '@material-ui/lab/Alert'
import Button from '../common/Button'
import useAccount from '../../hooks/useAccount'
import PropTypes from 'prop-types'
import {
  deriveAesKey,
  generateEccKeys,
  wrapSecretKey,
  unwrapEccKey,
  exportPublicKey,
  importPublicKey
} from '../../crypto'

const Email = styled.span`
  font-weight: 500;
`

const StyledDialogContent = styled(DialogContent)`
  width: 500px;
`

const StyledDialogActions = styled(DialogActions)`
  padding: 0px 30px 30px 0px;
`

const SuccessMessage = styled.div`
  padding: 30px;
  text-align: left;
`

const Bold = styled.span`
  font-weight: 600;
`

const GET_INVITATION = gql`
  query getInvitation($id: ID!) {
    getInvitation(id: $id) {
      user {
        id
        email
        publicKey
      }
      practice {
        id
        memberships {
          publicKey
          privateKey {
            encryptedData
            iv
          }
          user {
            id
          }
        }
      }
    }
  }
`

const ADD_TO_PRACTICE = gql`
  mutation addToPractice($practice: ID!, $membership: MembershipInput!) {
    addToPractice(practice: $practice, membership: $membership) {
      id
    }
  }
`

function ConfirmInvitationDialog({ isOpen, onClose, id }) {
  const { getAccount } = useAccount()
  const client = useApolloClient()
  const [{ email, status, msg }, setState] = useState({})

  useEffect(() => {
    async function addToPractice() {
      try {
        setState({ status: 'loading' })

        const {
          data: {
            getInvitation: { practice, user: invitee }
          }
        } = await client.query({
          query: GET_INVITATION,
          variables: { id }
        })

        const account = await getAccount()

        const sourceMembership = practice.memberships.find(
          m => m.user.id === account.id
        )

        const sourceAesKey = await deriveAesKey(
          account.privateKey,
          await importPublicKey(sourceMembership.publicKey)
        )

        const privateKey = await unwrapEccKey(
          sourceMembership.privateKey.encryptedData,
          sourceAesKey,
          sourceMembership.privateKey.iv,
          true
        )

        // encrypt the practice private key with the user's public key
        const ephemeralKeys = await generateEccKeys()

        const { publicKey } = invitee

        const targetAesKey = await deriveAesKey(
          ephemeralKeys.privateKey,
          await importPublicKey(publicKey)
        )

        const { wrappedKey, iv } = await wrapSecretKey(privateKey, targetAesKey)

        const exportedEphemeralKey = await exportPublicKey(
          ephemeralKeys.publicKey
        )

        // post the new private key for that user
        const targetMembership = {
          user: invitee.id,
          privateKey: { encryptedData: wrappedKey, iv },
          publicKey: exportedEphemeralKey
        }

        await client.mutate({
          mutation: ADD_TO_PRACTICE,
          variables: { practice: practice.id, membership: targetMembership }
        })

        setState({
          status: 'success',
          email: invitee.email
        })
      } catch (e) {
        console.error('Error adding user to practice:', e.message)
        if (e.message === 'Member already exists')
          setState({
            msg: `You have already added this user to your practice.`,
            status: 'error'
          })
        else setState({ status: 'error' })
      }
    }
    if (id) {
      addToPractice()
    }
  }, [id, client, getAccount])

  return (
    <div>
      <Dialog open={isOpen}>
        <StyledDialogContent>
          {status === 'error' && (
            <Alert severity="error">
              {msg ? msg : 'Hm, an error occurred. Try again.'}
            </Alert>
          )}
          {status === 'loading' && <CircularProgress />}
          {status === 'success' && (
            <SuccessMessage>
              <Email>
                <Bold>{email}</Bold>{' '}
              </Email>
              has been successfully added to your practice
            </SuccessMessage>
          )}
        </StyledDialogContent>
        <StyledDialogActions>
          <Button onClick={() => onClose()}>view practice</Button>
        </StyledDialogActions>
      </Dialog>
    </div>
  )
}

ConfirmInvitationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string
}

export default ConfirmInvitationDialog
