import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'
import gql from 'graphql-tag'
import { Dialog, DialogContent, MenuItem } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@apollo/client'

import CalendarPlusIcon from 'mdi-react/CalendarPlusIcon'

import { sortJourneyDatesByDate } from '../../util/sort'
import { ErrorMessage } from '../common/UserAlert'
import { SectionTitle } from '../common/Title'
import Button, { SecondaryButton, IconButton } from '../common/Button'
import Select from '../common/Select'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 12px 30px 30px;
`

const SectionLabel = styled(SectionTitle)`
  margin-top: 18px;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const Field = styled.div`
  margin-top: 27px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
`

const ErrorContainer = styled.div`
  margin-bottom: -15px;
`

const StyledMenuItem = styled(MenuItem)`
  display: ${props => (props.display === 'true' ? 'inherit' : 'none')};
`

const dateTypes = {
  initialConsult: 'Initial Consult Date',
  decision: 'Decision Date',
  procedure: 'Procedure Date',
  followUpConsult: 'Follow Up Consult Date',
  completed: 'Journey Completed Date'
}

const GET_REFERRAL_JOURNEY_DATES = gql`
  query getReferralJourneyDates($referral: ID!) {
    getReferralJourneyDates(referral: $referral) {
      id
      referral
      type
      date
      billingCode
    }
  }
`

const CREATE_JOURNEY_DATE = gql`
  mutation createJourneyDate($journeyDate: JourneyDateInput!) {
    createJourneyDate(journeyDate: $journeyDate) {
      id
      referral
      type
      date
      billingCode
    }
  }
`

function NewJourneyDateButton({
  referral,
  referralDate = null,
  completedDate = null,
  lastJourneyDate = null
}) {
  const journeyDateSchema = yup.object().shape(
    {
      type: yup.string().required('Date Type is required'),
      date: yup
        .date()
        .typeError('Date is required')
        .required('Date is required')
        .when('type', {
          is: type => lastJourneyDate !== null && type === 'completed',
          then: schema =>
            schema.min(
              new Date(lastJourneyDate.setHours(0, 0, 0, 0)),
              'Date must be after the other journey dates'
            ),
          otherwise: schema =>
            referralDate !== null
              ? schema.min(
                  new Date(referralDate.setHours(0, 0, 0, 0)),
                  'Date must be after the referral date'
                )
              : schema
        })
        .when([], dateSchema =>
          completedDate === null
            ? dateSchema
            : dateSchema.max(
                new Date(completedDate.setHours(23, 59, 59, 999)),
                'Date must be before the end of the patient journey'
              )
        )
    },
    ['type', 'date']
  )

  const [isVisible, setIsVisible] = useState(false)
  const isMounted = useRef(true)

  const [createJourneyDate, journeyDateResult] = useMutation(
    CREATE_JOURNEY_DATE,
    {
      update(cache, { data: { createJourneyDate } }) {
        if (
          cache.data.data.ROOT_QUERY?.[
            `getReferralJourneyDates({"referral":"${referral}"})`
          ]
        ) {
          const { getReferralJourneyDates } = cache.readQuery({
            query: GET_REFERRAL_JOURNEY_DATES,
            variables: { referral }
          })
          const data = [...getReferralJourneyDates, createJourneyDate].sort(
            sortJourneyDatesByDate
          )
          cache.writeQuery({
            query: GET_REFERRAL_JOURNEY_DATES,
            variables: { referral },
            data: { getReferralJourneyDates: data }
          })
        }
      }
    }
  )

  const { handleSubmit, control, errors, watch } = useForm({
    resolver: yupResolver(journeyDateSchema),
    criteriaMode: 'all',
    defaultValues: { date: null, type: '' }
  })

  const type = watch('type')

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleOpen = () => setIsVisible(true)
  function handleCancel() {
    if (!journeyDateResult.loading) {
      setIsVisible(false)
    }
  }

  async function onSubmit(values) {
    const journeyDate = {
      ...values,
      date: values.date.toISOString(),
      referral
    }

    try {
      await createJourneyDate({ variables: { journeyDate } })
      setIsVisible(false)
    } catch (e) {
      console.error('Error creating new journey date:', e.message)
    }
  }

  return (
    <>
      <IconButton
        color="secondary"
        onClick={handleOpen}
        aria-label="Add new date">
        <CalendarPlusIcon />
      </IconButton>
      <Dialog fullWidth open={isVisible} onClose={handleCancel}>
        <StyledDialogContent>
          <form aria-label="New Journey Date">
            <SectionLabel>New Journey Date</SectionLabel>
            <Divider />
            <Field>
              <Controller
                name="type"
                control={control}
                render={({ onChange, value, name }) => (
                  <Select
                    select
                    label="Date Type"
                    id="type"
                    name={name}
                    placeholder="Please select the date type"
                    onChange={onChange}
                    value={value}
                    error={!!errors.type}
                    helperText={errors?.type?.message}
                    FormHelperTextProps={{ role: 'alert' }}
                    InputLabelProps={{ required: true }}>
                    {Object.keys(dateTypes).map(d => (
                      <StyledMenuItem
                        key={d}
                        value={d}
                        display={
                          !completedDate
                            ? 'true'
                            : (d !== 'completed').toString()
                        }>
                        {dateTypes[d]}
                      </StyledMenuItem>
                    ))}
                  </Select>
                )}
              />
            </Field>
            <Field>
              <Controller
                name="date"
                control={control}
                render={({ ref, ...props }) => (
                  <KeyboardDatePicker
                    {...props}
                    TextFieldComponent={Select}
                    id="date"
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true
                    }}
                    label="Date"
                    mask="__/___/____"
                    refuse={/[^a-zA-Z0-9]+/gi}
                    format="dd/MMM/yyyy"
                    placeholder="DD/MMM/YYYY"
                    views={['year', 'month', 'date']}
                    openTo="year"
                    error={!!errors.date}
                    helperText={errors?.date?.message}
                    FormHelperTextProps={{ role: 'alert' }}
                  />
                )}
              />
            </Field>
            {type === 'procedure' && (
              <Field>
                <Controller
                  name="billingCode"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value, name }) => (
                    <Select
                      id="billingCode"
                      name={name}
                      label="Billing Code"
                      value={value}
                      onChange={onChange}
                      error={!!errors.billingCode}
                      helperText={errors?.billingCode?.message}
                      FormHelperTextProps={{ role: 'alert' }}
                    />
                  )}
                />
              </Field>
            )}
            <ButtonContainer>
              <SecondaryButton
                color="secondary"
                padding="small"
                disabled={journeyDateResult.loading}
                onClick={handleCancel}>
                Cancel
              </SecondaryButton>
              <Button
                padding="small"
                loading={journeyDateResult.loading}
                onClick={handleSubmit(onSubmit)}>
                Create
              </Button>
            </ButtonContainer>
            {journeyDateResult.error && (
              <ErrorContainer>
                <ErrorMessage />
              </ErrorContainer>
            )}
          </form>
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

NewJourneyDateButton.propTypes = {
  referral: PropTypes.string.isRequired,
  referralDate: PropTypes.object,
  completedDate: PropTypes.object,
  lastJourneyDate: PropTypes.object
}

export default NewJourneyDateButton
