import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import AlarmIcon from 'mdi-react/AlarmIcon'
import AutorenewIcon from 'mdi-react/AutorenewIcon'
import AccountOffIcon from 'mdi-react/AccountOffIcon'
import MessageProcessingIcon from 'mdi-react/MessageProcessingIcon'
import AttachFileIcon from 'mdi-react/AttachFileIcon'

import { getCategoryName, getSubcategoryName } from '../../util/categories'
import StatusIndicator from '../common/StatusIndicator'
import { SmallTitle } from '../common/Title'
import FileButton from '../refer/FileButton'
import { getPracticeType, getPracticeDisplayName } from '../../util/strings'

function textColor({ urgency }) {
  if (urgency === 'Urgent') return '#611A15'
  if (urgency === 'Semi-urgent') return '#663C00'
  return '#60750d'
}

const Container = styled.div`
  padding: 15px 0;
  display: flex;
  width: 100%;
`

const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 30%;
  min-height: 100px;
  border-right: 1px solid rgba(58, 58, 58, 0.15);
`

const NotificationDetails = styled(NotificationInfo)`
  align-items: flex-start;
  width: 70%;
  border: none;
  padding: 0 60px;
`

const TypeContainer = styled.div`
  display: flex;
  align-items: center;
`

const DetailsContainer = styled.div`
  padding-top: 10px;
`

const NotificationType = styled(SmallTitle)`
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding-left: 10px;
`

const PatientName = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #3a3a3a;

  margin: 15px 0;
`

const MainText = styled.h4`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #3a3a3a;
  padding: 0;
  margin: 0;
`

const Strong = styled.strong`
  font-weight: 600;
`

const LineThrough = styled.span`
  text-decoration: line-through;
`

const UrgencyText = styled.span`
  color: ${urgency => textColor(urgency)};
`

const DeclinedText = styled.span`
  color: #e84c3d;

  font-weight: 600;
`

const LabelText = styled.span`
  font-size: 10px;
  font-style: italic;
  color: rgba(58, 58, 58, 0.7);
`

const SmallText = styled.span`
  font-size: 12px;
`

const Notification = ({ notification }) => {
  const event = notification.event
  const referral = notification.referral
  const sender = referral.sender
  const recipient =
    event.type === 'referral_redirected'
      ? notification.event.data.newReferral.recipient
      : referral.recipient

  let category = ''
  let urgency
  if (event.type === 'referral_redirected') {
    category = notification.event.data.newReferral.category
    urgency = notification.event.data.newReferral.urgency
  }
  if (event.type === 'referral_sent') {
    category = event.data.category
    urgency = event.data.urgency
  }

  return (
    <Container type={event.type}>
      <NotificationInfo>
        {['referral_sent', 'referral_redirected'].includes(event.type) && (
          <TypeContainer>
            <AccountPlusIcon size={14} />
            <NotificationType>New referral</NotificationType>
          </TypeContainer>
        )}
        {event.type === 'urgency_changed' && (
          <TypeContainer>
            <AlarmIcon size={14} />
            <NotificationType>Urgency changed</NotificationType>
          </TypeContainer>
        )}
        {event.type === 'category_changed' && (
          <TypeContainer>
            <AutorenewIcon size={14} />
            <NotificationType>Category changed</NotificationType>
          </TypeContainer>
        )}
        {event.type === 'referral_declined' && (
          <TypeContainer>
            <AccountOffIcon size={14} />
            <NotificationType>Referral declined</NotificationType>
          </TypeContainer>
        )}
        {event.type === 'message_sent' && (
          <TypeContainer>
            <MessageProcessingIcon size={14} />
            <NotificationType>New message</NotificationType>
          </TypeContainer>
        )}
        {event.type === 'file_added' && (
          <TypeContainer>
            <AttachFileIcon size={14} />
            <NotificationType>New file</NotificationType>
          </TypeContainer>
        )}
        <PatientName>
          {referral.firstName} {referral.lastName}
        </PatientName>
        <StatusIndicator state={referral.state} />
      </NotificationInfo>
      <NotificationDetails>
        {['referral_sent', 'referral_redirected'].includes(event.type) && (
          <>
            <MainText>
              <Strong>
                {getPracticeDisplayName(sender.practice, 'short', 'long')}
              </Strong>{' '}
              <SmallText>({getPracticeType(sender.practice.type)})</SmallText>{' '}
              to{' '}
              <Strong>
                Dr. {recipient.practice.firstName} {recipient.practice.lastName}{' '}
              </Strong>
              <SmallText>(Specialist)</SmallText> by {event.createdBy.firstName}{' '}
              {event.createdBy.lastName}
            </MainText>
            <DetailsContainer>
              <MainText>
                {getCategoryName(category)}
                {getSubcategoryName(category) !== 'N/A' && (
                  <> - {getSubcategoryName(category)} </>
                )}
                <LabelText>
                  (Category
                  {getSubcategoryName(category) !== 'N/A' && (
                    <> - Subcategory</>
                  )}
                  )
                </LabelText>
              </MainText>
            </DetailsContainer>
            <DetailsContainer>
              <MainText>
                <UrgencyText urgency={urgency}>{urgency} </UrgencyText>
                <LabelText>(Urgency)</LabelText>
              </MainText>
            </DetailsContainer>
          </>
        )}
        {event.type === 'urgency_changed' && (
          <MainText>
            Urgency changed from{' '}
            <LineThrough>
              <UrgencyText urgency={event.data.initialUrgency}>
                <Strong>{event.data.initialUrgency}</Strong>
              </UrgencyText>
            </LineThrough>{' '}
            to
            <UrgencyText urgency={event.data.urgency}>
              <Strong> {event.data.urgency} </Strong>
            </UrgencyText>
            by{' '}
            <Strong>
              {event.createdBy.firstName} {event.createdBy.lastName}
            </Strong>
          </MainText>
        )}
        {event.type === 'category_changed' && (
          <MainText>
            Category changed from{' '}
            <LineThrough>
              <Strong>{getCategoryName(event.data.initialCategory)}</Strong>
            </LineThrough>
            {getSubcategoryName(event.data.initialCategory) !== 'N/A' && (
              <>
                {' '}
                and{' '}
                <LineThrough>
                  <Strong>
                    {getSubcategoryName(event.data.initialCategory)}
                  </Strong>
                </LineThrough>
              </>
            )}{' '}
            to
            <Strong> {getCategoryName(event.data.category)} </Strong>
            {getSubcategoryName(event.data.category) !== 'N/A' && (
              <>
                and<Strong> {getSubcategoryName(event.data.category)} </Strong>
              </>
            )}
            by{' '}
            <Strong>
              {event.createdBy.firstName} {event.createdBy.lastName}
            </Strong>
          </MainText>
        )}
        {event.type === 'referral_declined' && (
          <>
            <MainText>
              Referral has been
              <DeclinedText> Declined </DeclinedText> by{' '}
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}
              </Strong>
            </MainText>
            <DetailsContainer>
              <MainText>
                {event.data.reason}
                <LabelText> (Reason for Decline)</LabelText>
              </MainText>
            </DetailsContainer>
          </>
        )}
        {event.type === 'message_sent' && (
          <>
            <MainText>&quot;{event.data.decryptedMessage}&quot;</MainText>
            <DetailsContainer>
              <MainText>
                <LabelText>
                  Message by <Strong>{event.createdBy.firstName}</Strong>
                </LabelText>
              </MainText>
            </DetailsContainer>
          </>
        )}
        {event.type === 'file_added' && (
          <>
            <MainText>
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}{' '}
              </Strong>
              attached a file
            </MainText>
            <FileButton
              withFileIcon
              referral={referral}
              sender={sender}
              recipient={recipient}
              file={referral.files.findIndex(
                f =>
                  f.iv === event.data.file.iv && f.key === event.data.file.key
              )}
            />
          </>
        )}
      </NotificationDetails>
    </Container>
  )
}

Notification.propTypes = {
  notification: PropTypes.shape({
    event: PropTypes.shape({
      type: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
      createdBy: PropTypes.object.isRequired
    }).isRequired,
    isDismissed: PropTypes.bool.isRequired,
    referral: PropTypes.object.isRequired
  })
}

export default Notification
