import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ArrowLeftThickIcon from 'mdi-react/ArrowLeftThickIcon'
import ArrowRightThickIcon from 'mdi-react/ArrowRightThickIcon'

import { SecondaryButton, IconButton } from './Button'

const Container = styled.div`
  display: flex;
  button:nth-child(2) {
    margin-right: 10px;
    margin-left: 10px;
  }
`

const Pagination = ({
  page,
  hasNextPage,
  loading = false,
  onBack = () => {},
  onNext = () => {}
}) => (
  <Container>
    <IconButton
      color="secondary"
      onClick={onBack}
      disabled={loading || page === 1}>
      <ArrowLeftThickIcon />
    </IconButton>
    <SecondaryButton color="secondary" padding="small">
      Page {page}
    </SecondaryButton>
    <IconButton
      color="secondary"
      onClick={onNext}
      disabled={loading || !hasNextPage}>
      <ArrowRightThickIcon />
    </IconButton>
  </Container>
)

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func
}

export default Pagination
