function filterData(startDate, endDate, data, key, readField) {
  const currStartDate = new Date(startDate).setHours(0, 0, 0, 0)
  const currEndDate = new Date(endDate).setHours(0, 0, 0, 0)

  const filtered = data.filter(d => {
    const currDate = new Date(parseInt(readField(key, d))).setHours(0, 0, 0, 0)

    if (startDate && !endDate) return currDate >= currStartDate
    if (!startDate && endDate) return currDate <= currEndDate
    return currDate >= currStartDate && currDate <= currEndDate
  })

  return filtered
}

const cacheOpts = {
  possibleTypes: {
    EventData: [
      'Referral',
      'OriginalReferralSentEvent',
      'ReferralAcceptedEvent',
      'ReferralDeclinedEvent',
      'ReferralRedirectedEvent',
      'CategoryChangedEvent',
      'FileAddedEvent',
      'MessageSentEvent',
      'UrgencyChangedEvent'
    ]
  },
  typePolicies: {
    Practice: {
      fields: {
        memberships: {
          merge: false
        }
      }
    },
    Query: {
      fields: {
        getNotifications: {
          merge: false
        },
        getReferrals: {
          keyArgs: false
        },
        getFaxContacts: {
          merge: false
        },
        getFaxes: {
          merge: false
        },
        getAlerts: {
          merge: false
        },
        getReferralJourneyDates: {
          merge: false
        },
        getGroupReferrals: {
          keyArgs: ['group'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
          read(existing = [], { args, readField }) {
            return filterData(
              args.startDate,
              args.endDate,
              existing,
              'referralDate',
              readField
            )
          }
        },
        getHistoricalGroupFactors: {
          keyArgs: ['group'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
          read(existing = [], { args, readField }) {
            return filterData(
              args.startDate,
              args.endDate,
              existing,
              'createdAt',
              readField
            )
          }
        },
        getHistoricalAllowReferrals: {
          keyArgs: ['group'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
          read(existing = [], { args, readField }) {
            return filterData(
              args.startDate,
              args.endDate,
              existing,
              'createdAt',
              readField
            )
          }
        },
        getGroupDeclinedEvents: {
          keyArgs: ['group'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
          read(existing = [], { args, readField }) {
            return filterData(
              args.startDate,
              args.endDate,
              existing,
              'createdAt',
              readField
            )
          }
        },
        getGroupProcedures: {
          keyArgs: ['group'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
          read(existing = [], { args, readField }) {
            return filterData(
              args.startDate,
              args.endDate,
              existing,
              'date',
              readField
            )
          }
        },
        getGroupPatientJourneys: {
          keyArgs: ['group'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
          read(existing = [], { args, readField }) {
            return filterData(
              args.startDate,
              args.endDate,
              existing,
              'referralDate',
              readField
            )
          }
        },
        getGroupWaitTimes: {
          keyArgs: ['group'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
          read(existing = [], { args, readField }) {
            return filterData(
              args.startDate,
              args.endDate,
              existing,
              'referralDate',
              readField
            )
          }
        },
        getGroupWaitlist: {
          keyArgs: ['group'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
          read(existing = [], { args, readField }) {
            return filterData(
              args.startDate,
              args.endDate,
              existing,
              'referralDate',
              readField
            )
          }
        }
      }
    }
  }
}

export default cacheOpts
