import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useForm, FormProvider } from 'react-hook-form'
import { Dialog, DialogContent } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@apollo/client'

import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'

import { sortFaxContactsByName } from '../../util/sort'
import { capitalize } from '../../util/strings'
import { SectionTitle } from '../common/Title'
import { ErrorMessage } from '../common/UserAlert'
import Button, { SecondaryButton } from '../common/Button'
import FaxContact from '../forms/FaxContact'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 12px 30px 30px;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const SectionLabel = styled(SectionTitle)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 18px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button:last-child {
    margin-left: 10px;
  }
`

const faxRegex = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})/

const contactSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  faxNumber: yup
    .string()
    .matches(faxRegex, 'Fax Number must be valid')
    .required('Fax Number is required'),
  location: yup.string()
})

const GET_FAX_CONTACTS = gql`
  query getFaxContacts {
    getFaxContacts {
      id
      practice
      name
      location
      faxNumber
    }
  }
`

const CREATE_FAX_CONTACT = gql`
  mutation createFaxContact($faxContact: FaxContactInput!) {
    createFaxContact(faxContact: $faxContact) {
      id
      practice
      name
      location
      faxNumber
    }
  }
`

function NewContactButton({ practice }) {
  const [isVisible, setIsVisible] = useState(false)
  const isMounted = useRef(true)

  const [createFaxContact, faxContactResult] = useMutation(CREATE_FAX_CONTACT, {
    update(cache, { data: { createFaxContact } }) {
      if (cache.data.data.ROOT_QUERY?.[`getFaxContacts`]) {
        const { getFaxContacts } = cache.readQuery({
          query: GET_FAX_CONTACTS
        })
        const data = [...getFaxContacts, createFaxContact].sort(
          sortFaxContactsByName
        )
        cache.writeQuery({
          query: GET_FAX_CONTACTS,
          data: { getFaxContacts: data }
        })
      }
    }
  })

  const methods = useForm({
    resolver: yupResolver(contactSchema),
    criteriaMode: 'all',
    defaultValues: { name: '', faxNumber: '', location: '' }
  })

  const { handleSubmit, reset } = methods

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleOpen = () => setIsVisible(true)

  function handleClose() {
    if (!faxContactResult.loading) {
      setIsVisible(false)
      reset()
    }
  }

  async function onSubmit(values) {
    try {
      const faxContact = {
        ...values,
        name: capitalize(values.name),
        practice
      }
      await createFaxContact({ variables: { faxContact } })
      if (isMounted.current) handleClose()
    } catch (e) {
      console.error('Error creating fax contact:', e)
    }
  }

  return (
    <>
      <Button padding="small" onClick={handleOpen}>
        <PlusCircleOutlineIcon />
        Create New
      </Button>
      <Dialog fullWidth open={isVisible} onClose={handleClose}>
        <StyledDialogContent>
          <FormProvider {...methods}>
            <form aria-label="Contact form" onSubmit={handleSubmit(onSubmit)}>
              <SectionLabel>Create New Contact</SectionLabel>
              <Divider />
              {faxContactResult.error && (
                <ErrorMessage>Hm, an error occurred. Try again.</ErrorMessage>
              )}
              <FaxContact isEditing={true} newContact={true} />
              <ButtonContainer>
                <SecondaryButton
                  color="secondary"
                  padding="small"
                  onClick={handleClose}
                  disabled={faxContactResult.loading}>
                  Cancel
                </SecondaryButton>
                <Button
                  padding="small"
                  onClick={handleSubmit(onSubmit)}
                  loading={faxContactResult.loading}>
                  Create
                </Button>
              </ButtonContainer>
            </form>
          </FormProvider>
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

NewContactButton.propTypes = {
  practice: PropTypes.string
}

export default NewContactButton
