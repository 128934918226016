import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Dialog,
  DialogContent,
  Fade,
  CircularProgress
} from '@material-ui/core'
import {
  exportAesKey,
  deriveAccountKeys,
  unwrapAesKey,
  convertBase64ToBuffer
} from '../../crypto'
import OutlinedTextField from '../common/OutlinedTextField'
import { convertBufferToMnemonic } from '../../mnemonic'
import Button, { SecondaryButton } from '../common/Button'

const StyledDialog = styled(Dialog).attrs(() => ({
  maxWidth: 'sm'
}))``

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 30px;
  width: 500px;
`

const StyledTextField = styled(OutlinedTextField)`
  margin: 30px 0px;

  .MuiFormLabel-root {
    color: rgba(58, 58, 58, 0.7);
  }
`

const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: #fff;
`

const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button:last-child {
    margin-left: 15px;
  }
`

function ViewMnemonicDialog({ user, isOpen, onClose, onMnemonic }) {
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  async function handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    setLoading(true)
    setError(null)

    try {
      const accountKeys = await deriveAccountKeys(password, user.salt)
      const unwrappedRecoveryKey = await unwrapAesKey(
        user.recoveryKey.encryptedData,
        accountKeys.encryptionKey,
        user.recoveryKey.iv,
        'wrap',
        true
      )

      const rawKey = await exportAesKey(unwrappedRecoveryKey)
      const mnemonic = await convertBufferToMnemonic(
        convertBase64ToBuffer(rawKey)
      )

      setPassword('')
      setLoading(false)
      onMnemonic(mnemonic)
    } catch (e) {
      console.error('Could not decrypt words:', e)
      setError('Incorrect password')
      setLoading(false)
    }
  }

  function handleClose() {
    setPassword('')
    setError(null)
    onClose()
  }

  return (
    <StyledDialog open={isOpen} onClose={handleClose} keepMounted={false}>
      <StyledDialogContent as="form" onSubmit={handleSubmit}>
        Please confirm your password to access this.
        <StyledTextField
          autoFocus
          id="currentPassword"
          label="Current password"
          type="password"
          fullWidth
          onChange={e => setPassword(e.target.value)}
          value={password}
          error={!!error}
          helperText={error}
        />
        <ActionButtons>
          <SecondaryButton
            color="secondary"
            onClick={handleClose}
            disabled={loading}>
            Cancel
          </SecondaryButton>
          <Button type="submit" variant="text" disabled={loading}>
            {loading && (
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? '800ms' : '0ms'
                }}
                unmountOnExit>
                <ButtonProgress size={24} />
              </Fade>
            )}
            Confirm
          </Button>
        </ActionButtons>
      </StyledDialogContent>
    </StyledDialog>
  )
}

ViewMnemonicDialog.propTypes = {
  user: PropTypes.shape({
    recoveryKey: PropTypes.shape({
      encryptedData: PropTypes.string.isRequired,
      iv: PropTypes.string.isRequired
    }).isRequired,
    salt: PropTypes.string.isRequired
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMnemonic: PropTypes.func.isRequired
}

export default ViewMnemonicDialog
