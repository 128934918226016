function sortJourneyDatesByDate(a, b) {
  if (a.type === 'referralDate') return -1
  if (b.type === 'referralDate') return 1
  if (a.type === 'completed') return 1
  if (b.type === 'completed') return -1
  if (a.date > b.date) return 1
  if (a.date < b.date) return -1
  return 0
}

function sortFaxContactsByName(a, b) {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

export { sortJourneyDatesByDate, sortFaxContactsByName }
