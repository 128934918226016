import React from 'react'
import styled from 'styled-components'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import { Link } from 'react-router-dom'

import CrashLandingTemplate from '../common/CrashLandingTemplate'
import { SecondaryButton } from '../common/Button'

const ButtonContainer = styled.div`
  margin-top: 15px;
`

export default () => (
  <CrashLandingTemplate
    title="404 Error"
    subtitle="We couldn’t find the page you are looking for">
    <ButtonContainer>
      <SecondaryButton
        color="secondary"
        padding="small"
        component={Link}
        to="/notifications">
        <ChevronLeftIcon />
        Go home
      </SecondaryButton>
    </ButtonContainer>
  </CrashLandingTemplate>
)
