import { TextField } from '@material-ui/core'
import styled from 'styled-components'

const Select = styled(TextField)`
  width: 100%;
  &.MuiFormControl-root {
    margin: 0;
  }
  & .MuiFormLabel-root {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(58, 58, 58, 0.7);
    margin: 0 9px 0 0;
    transform: translate(0) scale(1);
  }
  .MuiInputLabel-asterisk {
    color: rgba(255, 0, 0, 0.7);
  }
  .MuiInputBase-root {
    border: 1px solid rgba(58, 58, 58, 0.35);
    border-radius: 3px;
    margin-top: 25px;
    background-color: #f9fbfe;
    &:before,
    &:after,
    &:hover:not(.Mui-disabled):before {
      border-bottom: 0;
    }
    &.Mui-focused {
      border: 1px solid #1193ad;
      border-radius: 3px;
    }
    &:hover:not(.Mui-disabled) {
      border: 1px solid #1193ad;
      border-radius: 3px;
    }
    & .Mui-disabled {
      color: #e2e2e2;
    }
    & .MuiInputBase-input {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-size: 14px;
      color: #3a3a3a;
      line-height: 18px;
      padding: 8px 15px;
      height: 18px;
      &::placeholder {
        font-weight: 400;
        color: 'rgba(58,58,58,0.3)';
      }
    }
    .MuiSelect-icon {
      margin-right: 10px;
    }
    &.MuiInputBase-multiline {
      display: flex;
      align-items: flex-start;
    }
  }
  .MuiInputBase-root.Mui-disabled {
    opacity: 0.5;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: rgba(58, 58, 58, 0.7);
  }
  .MuiInputBase-multiline {
    min-height: 100px;
  }
  .MuiFormHelperText-root.Mui-error {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 10px;
    color: #e84c3d;
    padding: 5px 16px 0 0;
  }
  .MuiSelect-selectMenu {
    &.MuiInputBase-input {
      &.MuiInput-input {
        padding: 8px 26px 8px 15px;
      }
    }
    div {
      display: flex;
    }
    span {
      padding-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export default Select
