import React from 'react'
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet
} from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import LogoImage from '../../images/logo-black.png'
import { getPracticeDisplayName } from '../../util/strings'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1in',
    fontSize: '14pt'
  },
  title: {
    fontSize: '28pt'
  },
  subtitle: {
    borderBottom: '1pt',
    borderBottomColor: 'black',
    fontSize: '17pt'
  },
  section: {
    paddingVertical: '0.1in'
  },
  content: {
    paddingVertical: '0.2in'
  },
  image: {
    width: '50%',
    alignSelf: 'center'
  },
  imageContainer: {
    marginBottom: '0.5in'
  }
})

const CoverPagePdf = ({
  subject = '',
  message = '',
  practice,
  contact,
  type
}) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <View style={styles.imageContainer}>
        <Image src={LogoImage} style={styles.image} />
      </View>
      <View>
        <Text style={styles.title}>FAX COVER SHEET</Text>
      </View>
      <View style={styles.content}>
        <View style={styles.section}>
          <Text>To: {contact.name}</Text>
          <Text>Fax no: {contact.faxNumber}</Text>
        </View>
        <View style={styles.section}>
          <Text>From: {getPracticeDisplayName(practice, 'long', 'long')}</Text>
          <Text>Fax no: {practice.faxNumber}</Text>
        </View>
        <View style={styles.section}>
          <Text>Date: {new Date().toString()}</Text>
        </View>
      </View>
      {type !== 'return' ? (
        (subject.length > 0 || message.length > 0) && (
          <View style={styles.content}>
            <View style={styles.section}>
              <Text style={styles.subtitle}>Details {}</Text>
            </View>
            {subject.length > 0 && (
              <View style={styles.section}>
                <Text>Subject: {subject}</Text>
              </View>
            )}
            {message.length > 0 && (
              <View style={styles.section}>
                <Text>Message: {message}</Text>
              </View>
            )}
          </View>
        )
      ) : (
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.subtitle}>Return to sender {}</Text>
          </View>
          {subject.length > 0 && (
            <View style={styles.section}>
              <Text>Reason: {subject}</Text>
            </View>
          )}
          {message.length > 0 && (
            <View style={styles.section}>
              <Text>Description: {message}</Text>
            </View>
          )}
        </View>
      )}
    </Page>
  </Document>
)

CoverPagePdf.propTypes = {
  subject: PropTypes.string,
  message: PropTypes.string,
  practice: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}

export default CoverPagePdf
