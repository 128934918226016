import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 4em 5em;

  @media print {
    @page {
      size: portrait;
    }
  }
`

const Title = styled.h1`
  color: #000;
  font-size: 32px;
  font-weight: 500;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 27px;
  grid-row-gap: 27px;
  margin-bottom: 90px;
  width: 100%;
`

const Word = styled.span`
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;

  width: 100%;
  padding: 8px 0;

  font-family: Courier, monospace;
`

const EmergencyAccessKitPrintView = ({ mnemonic, email, date }) => (
  <Container>
    <Title>Clinnect Emergency Access Kit</Title>
    <p>Emergency access for user {email}</p>
    <p>Printed at {format(date, "hh:mm a 'on' MMMM do, y")}</p>
    <Grid>
      {mnemonic.map((word, i) => (
        <Word key={`${word}_${i}`}>{word}</Word>
      ))}
    </Grid>
  </Container>
)

EmergencyAccessKitPrintView.propTypes = {
  mnemonic: PropTypes.arrayOf(PropTypes.string).isRequired,
  email: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired
}

export default EmergencyAccessKitPrintView
