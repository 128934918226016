import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'

import { chartStyling } from '../common/ChartStyling'
import { filterDataBySpecialistCategory } from '../../util/filterData'
import { capitalize } from '../../util/strings'
import { SectionTitle } from '../common/Title'
import Select from '../common/Select'
import Chart from './Chart'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Divider = styled.div`
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
`

const ChartSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ChartContainer = styled.div`
  display: flex;
  gap: 15px;
`

const Field = styled.div`
  padding-top: 15px;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    width: 200px;
  }
`

const ChartWrapper = styled.div`
  width: 100%;
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

const aggregateTypes = [
  { value: 'median', label: 'Median' },
  { value: 'avg', label: 'Average' },
  { value: 'max', label: 'Max' },
  { value: 'min', label: 'Min' }
]

const chartTypes = [
  { value: 'stackingBar', label: 'Stacking Bar' },
  { value: 'stackingBar100', label: '100% Stacking Bar' }
]

function sortCategories(a, b) {
  if (a === 'All') return -1
  if (b === 'All') return 1
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function WaitTimeReport({ waitTimes, filters = null }) {
  const [chartType, setChartType] = useState('stackingBar')
  const [aggregateType, setAggregateType] = useState('median')
  const [category, setCategory] = useState('All')
  const [allWaitTimes, setAllWaitTimes] = useState(waitTimes)
  const [filteredWaitTimes, setFilteredWaitTimes] = useState(waitTimes)

  const categories = [
    'All',
    ...new Set(
      allWaitTimes.map(j =>
        j.subcategory ? j.category + ' - ' + j.subcategory : j.category
      )
    )
  ].sort(sortCategories)

  const doctorLength = [...new Set(allWaitTimes.map(j => j.to))].length
  const height = doctorLength < 6 ? 450 : doctorLength > 10 ? 800 : 625

  const onSelectChartType = event => setChartType(event.target.value)
  const onSelectAggregateType = event => setAggregateType(event.target.value)

  function onSelectCategory(event) {
    setCategory(event.target.value)

    const filtered =
      event.target.value === 'All'
        ? allWaitTimes
        : allWaitTimes.filter(
            j =>
              (j.subcategory
                ? j.category + ' - ' + j.subcategory
                : j.category) === event.target.value
          )

    setFilteredWaitTimes(filtered)
  }

  useEffect(() => {
    if (waitTimes) {
      const filtered = filters
        ? filterDataBySpecialistCategory(waitTimes, 'waitTimes', filters)
        : waitTimes

      setCategory('All')
      setAllWaitTimes(filtered)
      setFilteredWaitTimes(filtered)
    }
  }, [waitTimes, filters])

  if (allWaitTimes.length === 0) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  return (
    <ReportVisualsContainer>
      <SectionTitle>Wait Time Report</SectionTitle>
      <Divider />
      <ChartContainer>
        <ChartSettingsContainer>
          <SectionTitle>Chart Settings</SectionTitle>
          <StyledSelect
            select
            value={chartType}
            onChange={onSelectChartType}
            label="Chart Type"
            aria-label="Chart Type"
            InputLabelProps={{ shrink: true }}>
            {chartTypes.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledSelect>
          <Field>
            <StyledSelect
              select
              value={aggregateType}
              onChange={onSelectAggregateType}
              label="Data Type"
              aria-label="Data Type"
              InputLabelProps={{ shrink: true }}>
              {aggregateTypes.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </StyledSelect>
          </Field>
          <Field>
            <StyledSelect
              select
              value={category}
              onChange={onSelectCategory}
              label="Category"
              aria-label="Category"
              InputLabelProps={{ shrink: true }}>
              {categories.map((value, i) => (
                <MenuItem key={i} value={value}>
                  {value}
                </MenuItem>
              ))}
            </StyledSelect>
          </Field>
        </ChartSettingsContainer>
        <ChartWrapper>
          <Chart
            type={chartType}
            title={`${
              aggregateTypes.find(t => t.value === aggregateType).label
            } Previous Wait Times: ${category}`}
            dataSourceSettings={{
              dataSource: filteredWaitTimes,
              sortSettings: [{ name: 'type', order: 'Ascending' }],
              rows: [{ name: 'to' }],
              columns: [{ name: 'type' }],
              values: [{ name: 'value', type: capitalize(aggregateType) }]
            }}
            customSettings={{ height }}
            customChartSettings={{
              primaryYAxis: {
                title:
                  chartType === 'stackingBar'
                    ? 'Days'
                    : 'Percent of Total Days',
                labelStyle: chartStyling.label,
                titleStyle: chartStyling.title
              }
            }}
            expandable={true}
            downloadable={true}
          />
        </ChartWrapper>
      </ChartContainer>
    </ReportVisualsContainer>
  )
}

WaitTimeReport.propTypes = {
  waitTimes: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default WaitTimeReport
