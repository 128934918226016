import React, { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'
import { set, get, del } from 'idb-keyval'
import AccountContext from '../contexts/AccountContext'
import { isTokenExpired, isTokenValid } from '../util/tokens'

const AccountProvider = ({ children }) => {
  useEffect(() => {
    if (isTokenExpired()) {
      setAccount(null)
    }
  }, [])

  const [isLoggedIn, setIsLoggedIn] = useState(isTokenValid())

  async function getAccount() {
    try {
      const { id } = jwtDecode(window.localStorage.authToken)
      const privateKey = await get('privateKey')
      const publicKey = await get('publicKey')

      setIsLoggedIn(true)

      return { id, publicKey, privateKey }
    } catch {
      setIsLoggedIn(false)

      return {
        id: null,
        publicKey: null,
        privateKey: null
      }
    }
  }

  async function setAccount(authToken, publicKey, privateKey) {
    if (authToken === null) {
      setIsLoggedIn(false)
      window.localStorage.removeItem('authToken')
      await del('privateKey')
      await del('publicKey')
    } else {
      setIsLoggedIn(true)
      window.localStorage.setItem('authToken', authToken)
      await set('privateKey', privateKey)
      await set('publicKey', publicKey)
    }
  }

  return (
    <AccountContext.Provider value={{ getAccount, setAccount, isLoggedIn }}>
      {children}
    </AccountContext.Provider>
  )
}

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AccountProvider
