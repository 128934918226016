import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LogoImage from '../../images/logo.svg'
import GearImage from '../../images/gear.svg'

const Content = styled.div`
  width: 100%;
  height: 100vh;
`

const HeaderImage = styled.img`
  width: 200px;
  margin: 30px;
  position: absolute;
  top: 0;
`

const CrashContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`

const Title = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-variant: small-caps;
  font-size: 48px;
  line-height: 59px;
  color: #e84c3d;
  margin: 0;
`

const SubTitle = styled.h2`
  font-family: Source Sans Pro;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #e84c3d;
`

const CrashLandingTemplate = ({ title, subtitle, children }) => (
  <Content>
    <HeaderImage src={LogoImage} alt="Clinnect: Simple, central, secure" />
    <CrashContainer>
      <img width="193" src={GearImage} alt="Broken gear" />
      <TextContainer>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        {children}
      </TextContainer>
    </CrashContainer>
  </Content>
)

CrashLandingTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default CrashLandingTemplate
