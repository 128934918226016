import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import FilterIcon from 'mdi-react/FilterIcon'
import { PageTitle } from '../common/Title'
import { TextButton } from '../common/Button'
import { ErrorMessage } from '../common/UserAlert'
import LoadingError from '../common/LoadingError'
import Loading from '../common/Loading'
import FaxList from '../faxing/FaxList'
import PdfPreview from '../faxing/PdfPreview'
import ResendFaxButton from '../faxing/ResendFaxButton'
import { getPracticeDisplayName } from '../../util/strings'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Subtitle = styled.span`
  font-size: 16px;
`

const SentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px;
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.size === 'full' ? '100%' : '50%')};
`

const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const StickyContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 15px;
  width: 50%;
  height: calc(100vh - 95px);
`

const FailedContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 15px;
  margin-bottom: 15px;

  > :first-child {
    margin: 0;
  }
`

const ResendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 6px;
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;

  padding: 30px;
  display: flex;
  align-items: center;
  min-height: 90px;

  svg {
    color: #fff;
    margin-right: 26px;
  }
`

const Filters = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.35);
  display: flex;
  align-items: baseline;
  padding-left: 5px;
`

const FilterButton = styled(TextButton)`
  text-transform: none;
  margin: 0 19px;
  padding: 9px 6px 6px;
  border-bottom: 3px solid transparent;
  letter-spacing: 0;

  &.active {
    border-bottom: 3px solid #fff;
    border-radius: 0;
  }
`

const CountContainer = styled.div`
  border-radius: 50%;
  background: #fff;
  margin: 0 0 0 5px;
  width: 15px;
  height: 15px;
`

const Count = styled.h5`
  font-weight: 600;
  font-size: 8px;
  line-height: 15px;
  color: #f2641a;
  margin: 0;
  padding: 0;
`

const BoldText = styled.span`
  font-weight: 600;
`

const GET_FAXES = gql`
  query getFaxes($direction: String!, $archived: String!) {
    getFaxes(direction: $direction, archived: $archived) {
      id
      practice {
        id
        type
        firstName
        lastName
        centralIntakeName
      }
      faxContact {
        ... on FaxContact {
          id
          name
        }
      }
      totalPages
      state
      errorText
      createdAt
      isArchived
      isRead
    }
  }
`

function SentFaxesPage({ status = 'notArchived' }) {
  const { loading, error, data } = useQuery(GET_FAXES, {
    variables: { direction: 'sent', archived: status }
  })

  const [faxes, setFaxes] = useState([])
  const [filterChanged, setFilterChanged] = useState(false)
  const [filteredFaxes, setFilteredFaxes] = useState([])
  const [activeFaxIndex, setActiveFaxIndex] = useState()
  const [activeFaxId, setActiveFaxId] = useState('')
  const [filterBy, setFilterBy] = useState('none')

  const filters = [
    { value: 'none', label: 'All Faxes' },
    { value: 'processing', label: 'Processing' },
    { value: 'success', label: 'Successful' },
    { value: 'failed', label: 'Failed' }
  ]

  useEffect(() => {
    async function formatFaxes() {
      const faxes = await Promise.all(data.getFaxes.map(formatFax))
      setFilterChanged(false)
      setFaxes(faxes)
    }

    function formatFax(fax) {
      return {
        id: fax.id,
        recipientName: fax.faxContact.name,
        senderName: getPracticeDisplayName(fax.practice, 'med', 'med'),
        senderFaxNumber: '',
        state: fax.state,
        isArchived: fax.isArchived ? fax.isArchived : false,
        errorText: fax.errorText,
        createdAt: new Date(parseInt(fax.createdAt)),
        totalPages: fax.totalPages
      }
    }
    if (data) formatFaxes()
  }, [data])

  useEffect(() => {
    if (faxes && filterBy) {
      setFilteredFaxes(
        faxes.filter(f => (filterBy === 'none' ? true : f.state === filterBy))
      )
    }
  }, [faxes, filterBy])

  useEffect(() => {
    if (filteredFaxes && !filterChanged) {
      if (filteredFaxes?.length === 0) {
        setActiveFaxId('')
        setActiveFaxIndex()
      } else if (filteredFaxes?.length > 0) {
        if (activeFaxId === '') {
          setActiveFaxId(filteredFaxes[0].id)
          setActiveFaxIndex(0)
        } else if (activeFaxIndex === filteredFaxes.length) {
          setActiveFaxId(filteredFaxes[activeFaxIndex - 1].id)
          setActiveFaxIndex(activeFaxIndex - 1)
        } else if (
          filteredFaxes.findIndex(fax => fax.id === activeFaxId) === -1
        ) {
          setActiveFaxId(filteredFaxes[activeFaxIndex].id)
        }
      }
    }
  }, [filteredFaxes, filterChanged, activeFaxId, activeFaxIndex])

  if (error) return <LoadingError />
  if (!faxes || loading) return <Loading />

  function handleChangeFilter(filter) {
    setFilterChanged(true)

    const filtered = faxes.filter(f =>
      filter === 'none' ? true : f.state === filter
    )
    setFilteredFaxes(filtered)
    setFilterBy(filter)

    if (filtered.length > 0) {
      const index = filtered.findIndex(fax => fax.id === activeFaxId)
      if (index === -1) {
        setActiveFaxId(filtered[0].id)
        setActiveFaxIndex(0)
      } else {
        setActiveFaxIndex(index)
      }
    } else {
      setActiveFaxId('')
      setActiveFaxIndex()
    }
  }

  function handleSelectFax(fax) {
    const index = filteredFaxes.findIndex(f => f.id === fax.id)
    setActiveFaxId(fax.id)
    setActiveFaxIndex(index)
  }

  const statusTitle = {
    notArchived: 'Active',
    onlyArchived: 'Archived'
  }

  return (
    <>
      <Header>
        <PageTitle>
          Sent Faxes <Subtitle>{`- ${statusTitle[status]}`}</Subtitle>
        </PageTitle>
        <BlueBar>
          <FilterIcon size={26} />
          <Filters>
            {filters.map(({ value, label }) => (
              <FilterButton
                key={value}
                value={value}
                onClick={() => handleChangeFilter(value)}
                className={filterBy === value ? 'active' : null}>
                {label}
                {filterBy === value && (
                  <CountContainer>
                    <Count>{filteredFaxes.length}</Count>
                  </CountContainer>
                )}
              </FilterButton>
            ))}
          </Filters>
        </BlueBar>
      </Header>
      <SentContainer>
        <ListContainer size={filteredFaxes.length === 0 ? 'full' : 'half'}>
          <FaxList
            faxes={filteredFaxes}
            isReceived={false}
            onSelect={handleSelectFax}
            activeFaxId={activeFaxId}
          />
        </ListContainer>
        {activeFaxId !== '' &&
          filteredFaxes.find(f => f.id === activeFaxId) && (
            <StickyContainer>
              {filteredFaxes.find(f => f.id === activeFaxId).state ===
                'failed' && (
                <FailedContainer>
                  <ErrorMessage>
                    <BoldText>Fax Failed: </BoldText>
                    {filteredFaxes.find(f => f.id === activeFaxId).errorText}
                  </ErrorMessage>
                  {status === 'notArchived' && (
                    <ResendContainer>
                      <ResendFaxButton faxId={activeFaxId} />
                    </ResendContainer>
                  )}
                </FailedContainer>
              )}
              <PdfContainer>
                <PdfPreview fax={activeFaxId} />
              </PdfContainer>
            </StickyContainer>
          )}
      </SentContainer>
    </>
  )
}

SentFaxesPage.propTypes = {
  status: PropTypes.oneOf(['onlyArchived', 'notArchived'])
}

export default SentFaxesPage
