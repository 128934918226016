async function deriveAccountKeys(
  password,
  salt = window.crypto.getRandomValues(new Uint8Array(32))
) {
  const enc = new TextEncoder()

  if (typeof salt === 'string') {
    salt = convertBase64ToBuffer(salt)
  }

  /*
   * This function derives a private key encryption key and account token from a
   * given password. If this is for login, you must provide the same salt that
   * was generated at registration time. If this is for registration, we
   * generate a new salt and return it.
   *
   * The first thing we do is import our string password as a CryptoKey
   */
  const keyMaterial = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(password),
    { name: 'PBKDF2' },
    false,
    ['deriveBits']
  )

  /*
   * Next we derive the PBKDF2 master key. PBKDF2 is a key derivation function,
   * it strengthens a possibly weak key such as a password and allows us to use
   * it for sensitive cryptographic operations. In this particular case, we
   * generate raw bits instead of a CryptoKey because we're going to use them as
   * source material for two new keys. We do not EVER use this directly.
   */
  const masterBits = await window.crypto.subtle.deriveBits(
    {
      name: 'PBKDF2',
      salt,
      iterations: 256000,
      hash: 'SHA-256'
    },
    keyMaterial,
    256
  )

  /*
   * We now import the bits as a CryptoKey, just like we did with the password.
   * However, now these bits are a much more robust key than the raw password.
   * Again, this key is only permitted for derivation and not encryption.
   */
  const masterKey = await window.crypto.subtle.importKey(
    'raw',
    masterBits,
    { name: 'HKDF' },
    false,
    ['deriveKey', 'deriveBits']
  )

  /*
   * Here we derive the account token from the master key using HKDF. The `info`
   * parameter of HKDF allows us to take a single input source (the master key)
   * and generate two fully unrelated, irreversable, and reproducible outputs.
   * We take advantage of this to generate 1) an account token and 2) the
   * encryption key itself.
   *
   * We ONLY use the account token as a bearer token to prove to the server that
   * we are who we say we are without disclosing the password itself. It has no
   * cryptographic uses.
   */
  const accountToken = await window.crypto.subtle.deriveBits(
    {
      name: 'HKDF',
      salt,
      info: enc.encode('account-token'),
      hash: 'SHA-256'
    },
    masterKey,
    256
  )

  /*
   * This is the other component that we derive from the master key using HKDF.
   * We use a different `info` parameter, so we get a different output. This is
   * not EVER presented to the server and is set to be non-exportable. We use
   * this encryption key to wrap the user's asymmetric private key, so that we
   * can safely send that private key to the server knowing the server cannot
   * decrypt it without this encryption key.
   */
  const encryptionKey = await window.crypto.subtle.deriveKey(
    {
      name: 'HKDF',
      salt,
      info: enc.encode('encryption-key'),
      hash: 'SHA-256'
    },
    masterKey,
    { name: 'AES-GCM', length: 256 },
    isTest,
    ['wrapKey', 'unwrapKey']
  )

  const accountTokenBase64 = convertBufferToBase64(accountToken)
  const saltBase64 = convertBufferToBase64(salt)

  return {
    accountToken: accountTokenBase64,
    salt: saltBase64,
    encryptionKey
  }
}

async function deriveAesKey(privateKey, publicKey) {
  /*
   * This function derives an AES symmetric key from two unique elliptic curve
   * asymmetric keys, using Elliptic Curve Diffie-Hellmen key exchange. It
   * returns a CryptoKey object which can be used for AES256 encryption /
   * decryption in GCM mode. You can provide your private key and your
   * recipient's public key, and if they use your public key and their private
   * key, you'll both arrive at the same AES key from this function.
   */
  return await window.crypto.subtle.deriveKey(
    {
      name: 'ECDH',
      public: publicKey
    },
    privateKey,
    { name: 'AES-GCM', length: 256 },
    isTest,
    ['wrapKey', 'unwrapKey']
  )
}

async function deriveHmacToken(key, value) {
  /*
   * This function calculates a keyed hash with the HMAC algorithm using the
   * associated key and input value. It returns a hash digest value that can
   * be used on the server side for anonymous server-side search. The input
   * key must have the sign key usage and be an HMAC SHA-512 key.
   */
  const enc = new TextEncoder()
  const buffer = enc.encode(value)
  return convertBufferToBase64(await crypto.subtle.sign('HMAC', key, buffer))
}

async function generateEccKeys() {
  /*
   * This function generates a new elliptic curve asymmetric key pair. It
   * returns an object containing a `privateKey` and `publicKey`, which are
   * CryptoKey objects using the P-256 curve. These asymmetric key pairs are
   * only usable for Diffie-Hellman key exchange, which means we must always be
   * using this to generate another, symmetric encryption key rather than for
   * encryption itself (see the `deriveAesKey` function).
   */
  return await window.crypto.subtle.generateKey(
    {
      name: 'ECDH',
      namedCurve: 'P-256'
    },
    true,
    ['deriveKey']
  )
}

async function generateAesKey(useType) {
  /*
   * This function generates a new, random AES symmetric key. It returns a
   * CryptoKey object which can be used for AES256 encryption / decryption in
   * GCM mode. We use this to generate keys for encrypting large data. We later
   * encrypt the key itself for the individual recipients. This way, we can
   * encrypt for multiple recipients without storing multiple copies of data.
   */
  return await window.crypto.subtle.generateKey(
    { name: 'AES-GCM', length: 256 },
    true,
    getAesUses(useType)
  )
}

async function generateHmacKey() {
  /*
   * This function generates a new HMAC key using SHA-512 as the hash function.
   * It returns a CryptoKey object which can be later used to convert strings
   * into tokens for anonymous server-side search.
   */
  return await window.crypto.subtle.generateKey(
    {
      name: 'HMAC',
      hash: { name: 'SHA-512' }
    },
    true,
    ['sign']
  )
}

async function wrapSecretKey(secretKey, wrappingKey) {
  /*
   * This function takes a secret key, and an AES symmetric encryption key
   * (called the wrapping key in this context). It returns the secret key,
   * encrypted with the wrapping key. Since AES256 in GCM mode requires an
   * initialization vector, it also returns the IV. The IV can be shared
   * publicly and must not be reused, similar to a salt.
   *
   * The secret key can be either a private key or symmetric key -- both work
   * in this function.
   */
  const iv = window.crypto.getRandomValues(new Uint8Array(12))
  const wrappedKey = await window.crypto.subtle.wrapKey(
    'jwk',
    secretKey,
    wrappingKey,
    { name: 'AES-GCM', iv }
  )

  const wrappedKeyBase64 = convertBufferToBase64(wrappedKey)
  const ivBase64 = convertBufferToBase64(iv)

  return {
    wrappedKey: wrappedKeyBase64,
    iv: ivBase64
  }
}

async function unwrapEccKey(wrappedKey, wrappingKey, iv, extractable = isTest) {
  /*
   * This function takes a wrapped elliptic curve private key, and an AES
   * symmetric encryption key (called the wrapping key in this context). It
   * returns a decrypted elliptic curve private key. This function is the
   * opposite of `wrapSecretKey`.
   *
   * For AES keys, use `unwrapAesKey` instead. If extractable is true, the key
   * can be exported. Use extreme caution with extractable keys.
   */
  const ivBuffer = convertBase64ToBuffer(iv)
  const wrappedKeyBuffer = convertBase64ToBuffer(wrappedKey)
  const privateKey = await window.crypto.subtle.unwrapKey(
    'jwk',
    wrappedKeyBuffer,
    wrappingKey,
    { name: 'AES-GCM', iv: ivBuffer },
    { name: 'ECDH', namedCurve: 'P-256' },
    extractable,
    ['deriveKey']
  )
  return privateKey
}

async function unwrapAesKey(
  wrappedKey,
  wrappingKey,
  iv,
  useType,
  extractable = isTest
) {
  /*
   * This function takes a wrapped AES symmetric key, and another AES symmetric
   * key (called the wrapping key in this context). It returns a decrypted AES
   * symmetric key. This function is the opposite of `wrapSecretKey`.
   *
   * For elliptic curve private keys, use `unwrapEccKey` instead. If extractable
   * is true, the key can be exported. Use extreme caution with extractable
   * keys
   */
  const ivBuffer = convertBase64ToBuffer(iv)
  const wrappedKeyBuffer = convertBase64ToBuffer(wrappedKey)
  const secretKey = await window.crypto.subtle.unwrapKey(
    'jwk',
    wrappedKeyBuffer,
    wrappingKey,
    { name: 'AES-GCM', iv: ivBuffer },
    { name: 'AES-GCM', length: 256 },
    extractable,
    getAesUses(useType)
  )
  return secretKey
}

async function unwrapHmacKey(wrappedKey, wrappingKey, iv) {
  /*
   * This function takes a wrapped HMAC key, and an AES symmetric encryption
   * key (called the wrapping key in this context). It returns a decrypted
   * HMAC key. This function is the opposite of `wrapSecretKey`.
   *
   * If extractable is true, the key can be exported. Use extreme caution with
   * extractable keys
   */
  const ivBuffer = convertBase64ToBuffer(iv)
  const wrappedKeyBuffer = convertBase64ToBuffer(wrappedKey)
  const secretKey = await window.crypto.subtle.unwrapKey(
    'jwk',
    wrappedKeyBuffer,
    wrappingKey,
    { name: 'AES-GCM', iv: ivBuffer },
    { name: 'HMAC', hash: { name: 'SHA-512' } },
    isTest,
    ['sign']
  )
  return secretKey
}

async function encryptObject(object, key) {
  /*
   * This function takes a object (which must be serializable) and an AES
   * symmetric key. It will encrypt the object with AES256 in GCM mode using
   * the key provided. Since AES256 in GCM mode requires an initialization
   * vector, it also returns the IV. The IV can be shared publicly and must not
   * be reused, similar to a salt. This function can be undone with
   * `decryptObject`.
   */
  const enc = new TextEncoder()
  const iv = window.crypto.getRandomValues(new Uint8Array(12))
  const buffer = enc.encode(JSON.stringify(object))
  const encryptedBuffer = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    buffer
  )
  return {
    encryptedObject: convertBufferToBase64(encryptedBuffer),
    iv: convertBufferToBase64(iv)
  }
}

async function decryptObject(encryptedObject, key, iv) {
  /*
   * This function takes an encrypted object and an AES symmetric key. It will
   * decrypt the object using the symmetric key and IV, and return the decrypted
   * object. This function is the opposite of `encryptObject`.
   */
  const dec = new TextDecoder()
  const ivBuffer = convertBase64ToBuffer(iv)
  const encryptedBuffer = convertBase64ToBuffer(encryptedObject)
  const buffer = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: ivBuffer },
    key,
    encryptedBuffer
  )
  return JSON.parse(dec.decode(buffer))
}

async function encryptFile(file, key) {
  /*
   * This function takes a file and an AES symmetric key. It will encrypt the
   * file with AES256 in GCM mode using the key provided. Since AES256 in GCM
   * mode requires an initialization vector, it also returns the IV. The IV can
   * be shared publicly and must not be reused, similar to a salt. This function
   * can be undone with `decryptFile`.
   */
  const iv = window.crypto.getRandomValues(new Uint8Array(12))
  const buffer = await convertFileToBuffer(file)

  const encryptedBuffer = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    buffer
  )

  return {
    encryptedFile: convertBufferToFile(encryptedBuffer, file.name),
    iv: convertBufferToBase64(iv)
  }
}

async function decryptFile(encryptedFile, key, iv) {
  /*
   * This function takes an encrypted file and an AES symmetric key. It will
   * decrypt the file using the symmetric key and IV, and return the decrypted
   * file. This function is the opposite of `encryptFile`.
   */
  const ivBuffer = convertBase64ToBuffer(iv)
  const encryptedBuffer = await convertFileToBuffer(encryptedFile)
  const buffer = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: ivBuffer },
    key,
    encryptedBuffer
  )
  return convertBufferToFile(buffer, encryptedFile.name)
}

async function exportPublicKey(publicKey) {
  /*
   * This function takes a public key, represented as a CryptoKey object, and
   * returns it as a base64-encoded raw version. We'll need to do this with any
   * public keys before we send them over the network. We should not EVER try to
   * do this with a private key! Use `wrapKey` for that instead.
   */
  const exportedKey = await window.crypto.subtle.exportKey('raw', publicKey)
  return convertBufferToBase64(exportedKey)
}

async function importPublicKey(exportedKey) {
  /*
   * This function takes an exported public key and returns it as a CryptoKey
   * object. We do this to take serialized public key information from the API
   * and translate that to a format we can use for cryptographic operations.
   */
  const exportedKeyBuffer = convertBase64ToBuffer(exportedKey)
  const publicKey = await window.crypto.subtle.importKey(
    'raw',
    exportedKeyBuffer,
    { name: 'ECDH', namedCurve: 'P-256' },
    true,
    []
  )
  return publicKey
}

async function importAesKey(keyBuffer, extractable = isTest) {
  /*
   * This function takes a buffer and returns it as an AES CryptoKey
   * object. Essentially this function allows us to turn any buffer of
   * sufficient length (256-bit) into a CryptoKey that we can use for
   * wrapping and unwrapping keys.
   */
  const aesKey = await window.crypto.subtle.importKey(
    'raw',
    keyBuffer,
    { name: 'AES-GCM', length: 256 },
    extractable,
    ['wrapKey', 'unwrapKey']
  )
  return aesKey
}

async function exportAesKey(key) {
  /*
   * This function takes a CryptoKey object and returns it as a base64-encoded
   * string of its raw representation. Be aware that the output of this function
   * is an unencrypted representation of a secret key, and should never be
   * uploaded to the server. The exported keys should only ever be stored on the
   * client
   */
  const rawKey = await window.crypto.subtle.exportKey('raw', key)
  return convertBufferToBase64(rawKey)
}

function convertBufferToBase64(arrayBuffer) {
  /*
   * Simple helper function to convert an ArrayBuffer to base 64. The opposite
   * of this is `convertBase64ToBuffer`.
   */
  return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
}

function convertBase64ToBuffer(base64) {
  /*
   * Simple helper function to convert a base 64 to an ArrayBuffer. The opposite
   * of this is `convertBufferToBase64`.
   */
  return Uint8Array.from(atob(base64), c => c.charCodeAt(0))
}

function convertBufferToFile(buffer, name) {
  /*
   * Simple helper function to convert an ArrayBuffer to a File object. The
   * opposite of this is `convertFileToBuffer`.
   */
  return new File([buffer], name)
}

function convertFileToBuffer(file) {
  /*
   * Simple helper function to convert a File object to an ArrayBuffer. The
   * opposite of this is `convertBufferToFile`. Returns a promise.
   */
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => resolve(new Uint8Array(e.target.result))
    reader.onerror = () => reject(reader.error)
    reader.readAsArrayBuffer(file)
  })
}

function getAesUses(useType) {
  /*
   * According to RFC 7517, key uses should not be mixed, as it creates a risk
   * of key misuse. As such, some AES operations will require a specified use
   * type which maps to the correct WebCrypto key uses here.
   */
  if (useType === 'enc') return ['encrypt', 'decrypt']
  if (useType === 'wrap') return ['wrapKey', 'unwrapKey']
  throw new Error("Invalid use type. Specify 'enc' or 'wrap'")
}

function setTestMode(value) {
  /*
   * While testing crypto code, we can call this function to enable marking the
   * keys as extractable by default. This is necessary for comparing keys.
   */
  if (process.env.NODE_ENV !== 'test') return
  isTest = value
}

let isTest = false

export {
  deriveAccountKeys,
  deriveAesKey,
  deriveHmacToken,
  generateEccKeys,
  generateHmacKey,
  generateAesKey,
  wrapSecretKey,
  unwrapEccKey,
  unwrapAesKey,
  unwrapHmacKey,
  encryptObject,
  decryptObject,
  encryptFile,
  decryptFile,
  exportPublicKey,
  importPublicKey,
  importAesKey,
  exportAesKey,
  convertBufferToBase64,
  convertBase64ToBuffer,
  convertBufferToFile,
  convertFileToBuffer,
  setTestMode
}
