import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ArrowRightThickIcon from 'mdi-react/ArrowRightThickIcon'

import { LandingPageTitle } from '../common/Title'
import Button from '../common/Button'
import LandingContainer from './LandingContainer'

const TextContainer = styled.div`
  max-width: 400px;
`

const Content = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin: 0 0 20px;
`

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: 700;
`

const LinkButton = styled(Link)`
  text-decoration: none;
`

const CongratulationsLanding = ({ practice }) => (
  <LandingContainer>
    <LandingPageTitle>Congratulations</LandingPageTitle>
    <TextContainer>
      <Content>
        Your New Practice is currently pending verification. You can now send
        referrals to other specialists. In order to receive referrals your
        account must be verified. You can check the verification status of your
        practice in{' '}
        <StyledLink to={`/settings/practices/${practice}`}>
          your settings.
        </StyledLink>
      </Content>
    </TextContainer>
    <LinkButton to="/refer">
      <Button padding="small">
        <ArrowRightThickIcon />
        Continue
      </Button>
    </LinkButton>
  </LandingContainer>
)

CongratulationsLanding.propTypes = {
  practice: PropTypes.string.isRequired
}

export default CongratulationsLanding
