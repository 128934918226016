import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Chart from './Chart'
import PivotTable from './PivotTable'
import { InfoMessage } from '../common/UserAlert'
import { chartStyling } from '../common/ChartStyling'
import { SectionTitle } from '../common/Title'
import { filterDataBySpecialistCategory } from '../../util/filterData'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Divider = styled.div`
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

const StyledInfoMessage = styled(InfoMessage)`
  margin-bottom: -10px;
`

const Bold = styled.span`
  font-weight: 600;
`

const EmptyChartText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
`

function WaitlistReport({
  consultWaitlist,
  procedureWaitlist,
  filters = null
}) {
  const filteredConsults = filters
    ? filterDataBySpecialistCategory(consultWaitlist, 'waitlist', filters)
    : consultWaitlist

  const filteredProcedures = filters
    ? filterDataBySpecialistCategory(procedureWaitlist, 'waitlist', filters)
    : procedureWaitlist

  if (filteredConsults.length === 0 && filteredProcedures.length === 0) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  const consultLength = [...new Set(filteredConsults.map(r => r.category))]
    .length
  const consultHeight = consultLength < 6 ? 450 : consultLength > 10 ? 800 : 625

  const procedureLength = [...new Set(filteredProcedures.map(r => r.category))]
    .length
  const procedureHeight =
    procedureLength < 6 ? 450 : procedureLength > 10 ? 800 : 625

  return (
    <ReportVisualsContainer>
      <SectionTitle>Waitlist Report</SectionTitle>
      <Divider />
      <StyledInfoMessage>
        A patient is considered to be on the consult waitlist if they
        <Bold> have a referral date and no other journey dates</Bold> (i.e. no
        initial consult, decision, procedure, follow up consult, or completed
        date).
      </StyledInfoMessage>
      <PivotTable
        title="Consult Waitlist Counts by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredConsults,
          columns: [{ name: 'to' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          values: [{ name: 'to' }]
        }}
        visibleRows={15}
        downloadable={true}
        expandable={true}
      />
      <Chart
        type="stackingBar"
        title="Distribution of Consult Waitlist by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredConsults,
          sortSettings: [{ name: 'category', order: 'Descending' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          columns: [{ name: 'to' }],
          values: [{ name: 'category' }]
        }}
        customSettings={{ height: consultHeight }}
        customChartSettings={{
          primaryYAxis: {
            title: 'Number of Patients on the Waitlist',
            labelStyle: chartStyling.label,
            titleStyle: chartStyling.title
          }
        }}
        expandable={true}
        downloadable={true}
      />
      {filteredConsults.length === 0 && (
        <EmptyChartText>No consult waitlist data to display</EmptyChartText>
      )}
      <StyledInfoMessage>
        A patient is considered to be on the procedure waitlist if they{' '}
        <Bold>
          have an initial consult or decision date and do not have a procedure
          or completed date
        </Bold>
        .
      </StyledInfoMessage>
      <PivotTable
        title="Procedure Waitlist Counts by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredProcedures,
          columns: [{ name: 'to' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          values: [{ name: 'to' }]
        }}
        visibleRows={15}
        downloadable={true}
        expandable={true}
      />
      <Chart
        type="stackingBar"
        title="Distribution of Procedure Waitlist by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredProcedures,
          sortSettings: [{ name: 'category', order: 'Descending' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          columns: [{ name: 'to' }],
          values: [{ name: 'category' }]
        }}
        customSettings={{ height: procedureHeight }}
        customChartSettings={{
          primaryYAxis: {
            title: 'Number of Patients on the Waitlist',
            labelStyle: chartStyling.label,
            titleStyle: chartStyling.title
          }
        }}
        expandable={true}
        downloadable={true}
      />
      {filteredProcedures.length === 0 && (
        <EmptyChartText>No procedure waitlist data to display</EmptyChartText>
      )}
    </ReportVisualsContainer>
  )
}

WaitlistReport.propTypes = {
  consultWaitlist: PropTypes.array.isRequired,
  procedureWaitlist: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default WaitlistReport
