import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { format } from 'date-fns'
import { Dialog, DialogContent } from '@material-ui/core'
import { useMutation } from '@apollo/client'

import DeleteIcon from 'mdi-react/DeleteIcon'

import { getJourneyDateDisplayName } from '../../util/strings'
import { ErrorMessage } from '../common/UserAlert'
import Button, { IconButton, SecondaryButton } from '../common/Button'

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 12px 30px 30px;
`

const Message = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #393e46;
  padding: 10px 0 0;
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;
`

const ErrorContainer = styled.div`
  margin: 0 0 -15px;
`

const GET_REFERRAL_JOURNEY_DATES = gql`
  query getReferralJourneyDates($referral: ID!) {
    getReferralJourneyDates(referral: $referral) {
      id
      referral
      type
      date
    }
  }
`

const DELETE_JOURNEY_DATE = gql`
  mutation deleteJourneyDate($journeyDate: ID!) {
    deleteJourneyDate(journeyDate: $journeyDate)
  }
`

function DeleteJourneyDateButton({ journeyDate }) {
  const [mutate, { loading, error }] = useMutation(DELETE_JOURNEY_DATE, {
    update(cache) {
      if (
        cache.data.data.ROOT_QUERY?.[
          `getReferralJourneyDates({"referral":"${journeyDate.referral}"})`
        ]
      ) {
        const { getReferralJourneyDates } = cache.readQuery({
          query: GET_REFERRAL_JOURNEY_DATES,
          variables: { referral: journeyDate.referral }
        })
        cache.writeQuery({
          query: GET_REFERRAL_JOURNEY_DATES,
          variables: { referral: journeyDate.referral },
          data: {
            getReferralJourneyDates: getReferralJourneyDates.filter(
              d => d.id !== journeyDate.id
            )
          }
        })
      }
    }
  })

  const [isVisible, setIsVisible] = useState(false)
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleOpen = () => setIsVisible(true)
  const handleClose = () => setIsVisible(false)

  function handleCancel() {
    if (!loading) setIsVisible(false)
  }

  const onDelete = useCallback(async () => {
    setIsVisible(true)
    try {
      await mutate({ variables: { journeyDate: journeyDate.id } })
      if (isMounted.current) handleClose()
    } catch (e) {
      console.error('Error deleting date:', e.message)
    }
  }, [journeyDate.id, mutate])

  return (
    <>
      <IconButton
        color="secondary"
        padding="short"
        onClick={handleOpen}
        aria-label="Delete date">
        <DeleteIcon />
      </IconButton>
      <Dialog
        fullWidth
        open={isVisible}
        onClose={handleCancel}
        keepMounted={false}>
        <StyledDialogContent>
          <Message>
            Are you sure you want to delete &quot;
            {getJourneyDateDisplayName(journeyDate.type)}:{' '}
            {format(journeyDate.date, 'dd/MMM/yyyy')}&quot;?
          </Message>
          {error && (
            <ErrorContainer>
              <ErrorMessage />
            </ErrorContainer>
          )}
          <ButtonContainer>
            <SecondaryButton
              onClick={handleCancel}
              disabled={loading}
              padding="small"
              color="secondary">
              Cancel
            </SecondaryButton>
            <Button onClick={onDelete} loading={loading} padding="small">
              Delete
            </Button>
          </ButtonContainer>
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

DeleteJourneyDateButton.propTypes = {
  journeyDate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    referral: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired
  }).isRequired
}

export default DeleteJourneyDateButton
