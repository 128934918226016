import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Loading from '../common/Loading'
import styled from 'styled-components'

const Pdf = styled.iframe`
  border: none;
`

const ErrorContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ErrorHeading = styled.h3`
  color: #393e46;
  font-weight: 500;
  margin: 10px;
`

const ErrorSubtext = styled.p`
  color: #393e46;
  margin: 0;
`

const PdfPreview = ({ fax }) => {
  const [documentUrl, setDocumentUrl] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    async function getDocument() {
      setError()
      const token = window.localStorage.authToken

      const requestOptions = {
        method: 'GET',
        headers: { authorization: token ? `Bearer ${token}` : '' },
        redirect: 'follow'
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/documents/${fax}`,
        requestOptions
      )

      if (!response.ok) {
        const body = await response.json()

        setError(body.error)

        console.error(
          'An error occurred while fetching the document: ' + body.error
        )
      } else {
        const reader = response.body.getReader()

        const chunks = []
        let isDone = false

        while (!isDone) {
          const response = await reader.read()

          if (response.value) {
            chunks.push(response.value)
          }

          isDone = response.done
        }

        const blob = new Blob(chunks, { type: 'application/pdf' })
        const newObjectUrl = URL.createObjectURL(blob)
        setDocumentUrl(newObjectUrl)
      }
    }
    if (fax !== '') getDocument()
  }, [fax])

  if (error)
    return (
      <ErrorContainer>
        <ErrorHeading>
          We&#39;re sorry. The fax file could not be loaded.
        </ErrorHeading>
        <ErrorSubtext>
          Please note: The fax file is not immediately available after sending.
        </ErrorSubtext>
      </ErrorContainer>
    )

  if (!documentUrl) return <Loading />

  return (
    <Pdf
      src={`${documentUrl}#view=fitH`}
      title="Fax preview"
      height="100%"
      width="100%"
      type="application/pdf"
    />
  )
}

PdfPreview.propTypes = {
  fax: PropTypes.string.isRequired
}

export default PdfPreview
