import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem } from '@material-ui/core'
import { format } from 'date-fns'
import styled from 'styled-components'
import LogoImage from '../../images/logo.svg'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import provinces from '../../provinces.json'
import regions from '../../regions.json'
import { getPracticeType, getPracticeDisplayName } from '../../util/strings'
import {
  getCategoryName,
  getSubcategoryName,
  getSpecialtyName
} from '../../util/categories'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    @page {
      size: 595px 842px;
    }
    display: block;
  }
`

const Header = styled.div`
  background-color: rgba(58, 58, 58, 0.05);
  padding: 34px 30px 33px 30px;
`

const Item = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-grow: 1;
  color: #3a3a3a;
  font-weight: 600;
  line-height: 15px;
  align-items: baseline;
`

const ReferralLogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

const ReferralLabel = styled.p`
  font-size: 18px;
  font-weight: 400;
`

const ReferralNumber = styled(ReferralLabel)`
  font-weight: 700;
`

const SenderReceiverContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const PracticeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Doctor = styled.p`
  font-size: 14px;
  font-weight: 700;
  margin: 0 5px 0 0;
`

const DoctorType = styled.p`
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  margin: 0;
`

const HeaderLabel = styled.p`
  font-size: 12px;
  margin: 0 3px 0 0;
  font-weight: 500;
`

const Divider = styled(HeaderLabel)`
  margin: 0 5px;
`

const Info = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin: 0px;
`

const StyledArrow = styled(ArrowRightIcon)`
  margin: 0 30px;
`

const Body = styled.div`
  padding: 0 30px 30px;
  font-size: 12px;
`

const SectionTitle = styled.h1`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: rgba(58, 58, 58, 0.7);
  margin: 0;
  padding-bottom: 5px;
  padding-top: 30px;
  border-bottom: 1px solid rgba(58, 58, 58, 0.05);
  break-after: avoid;
`

const Row = styled.div`
  display: flex;
  padding-top: 5px;
  align-items: center;
  break-inside: avoid;
`

const Label = styled.div`
  width: 100px;
  min-width: 100px;
  margin-right: 10px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  color: rgba(58, 58, 58, 0.7);
  font-weight: 500;
`

const ReasonText = styled.p`
  width: 75%;
  margin: 0;
`

const Footer = styled.div`
  font-size: 10px;
  color: #3a3a3a;
  padding-top: 50px;
  break-inside: avoid;
`

const ImportantNote = styled.span`
  text-transform: uppercase;
  color: #e84c3d;
`

const PageBreak = styled.div`
  break-inside: avoid;
`

const StyledList = styled(List)`
  .MuiListItem-root {
    padding: 0;
  }
`

const ReferralPrint = ({ user, sender, referral, recipient, isFree }) => (
  <Container>
    <Header>
      <ReferralLogoContainer>
        <Item>
          {isFree && (
            <>
              <ReferralLabel>Referral # </ReferralLabel>
              <ReferralNumber>{referral.id}</ReferralNumber>
            </>
          )}
        </Item>
        <img
          src={LogoImage}
          alt="Clinnect: Simple, central, secure"
          height="30"
        />
      </ReferralLogoContainer>
      <SenderReceiverContainer>
        <PracticeInfoContainer>
          <Item>
            <Doctor>{getPracticeDisplayName(sender, 'short', 'med')}</Doctor>
            <DoctorType>{getPracticeType(sender.type)}</DoctorType>
          </Item>
          <Item>
            {sender.type !== 'centralIntake' && (
              <>
                <HeaderLabel>MSP</HeaderLabel>
                <Info>{sender.msp}</Info>
                <Divider>|</Divider>
              </>
            )}
            <HeaderLabel>Phone</HeaderLabel>
            <Info>
              {' '}
              {sender.phone.substring(sender.phone.length - 13) ===
              ', Ext. ______'
                ? sender.phone.substr(0, 14)
                : sender.phone}
            </Info>
          </Item>
        </PracticeInfoContainer>
        <StyledArrow />
        <PracticeInfoContainer>
          <Item>
            <Doctor>
              Dr. {recipient.firstName.charAt(0)}. {recipient.lastName}
            </Doctor>
            <DoctorType>Specialist</DoctorType>
          </Item>
          <Item>
            <HeaderLabel>MSP</HeaderLabel>
            <Info>{recipient.msp}</Info>
            <HeaderLabel>&nbsp;| Phone</HeaderLabel>
            <Info>
              {' '}
              {recipient.phone.substring(recipient.phone.length - 13) ===
              ', Ext. ______'
                ? recipient.phone.substr(0, 14)
                : recipient.phone}
            </Info>
          </Item>
        </PracticeInfoContainer>
      </SenderReceiverContainer>
    </Header>
    <Body>
      {isFree && (
        <>
          <SectionTitle> Contact Information </SectionTitle>
          <Row>
            <Item>
              <Label>First Name</Label>
              {user.firstName}
            </Item>
            <Item>
              <Label>Last Name</Label>
              {user.lastName}
            </Item>
          </Row>
          <Row>
            <Item>
              <Label>Email</Label>
              {user.email}
            </Item>
          </Row>
        </>
      )}
      {referral.referringProvider && (
        <>
          <SectionTitle> Referrer Information </SectionTitle>
          <Row>
            <Item>
              <Label>Contact</Label>
              {referral.referringProvider.name}
            </Item>
            <Item>
              <Label>Fax No.</Label>
              {referral.referringProvider.faxNumber}
            </Item>
          </Row>
          <Row>
            <Item>
              <Label>Location</Label>
              {referral.referringProvider.location}
            </Item>
          </Row>
        </>
      )}
      <SectionTitle> Patient Information </SectionTitle>
      <Row>
        <Item>
          <Label>PHN</Label>
          {referral.phn}
        </Item>
        <Item>
          <Label>Date of Birth</Label>
          {format(referral.birthDate, 'dd/MMM/yyyy')}
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>First Name</Label>
          {referral.firstName}
        </Item>
        <Item>
          <Label>Last Name</Label>
          {referral.lastName}
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Phone No.</Label>
          {referral.phone}
        </Item>
        <Item>
          <Label>Date of Referral</Label>
          {format(referral.referralDate, 'dd/MMM/yyyy')}
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Gender Identity</Label>
          {referral.gender}
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Address</Label>
          {referral.address}
        </Item>
        <Item>
          <Label>City</Label>
          {referral.city}
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Province</Label>
          {provinces[referral.province]}
        </Item>
        <Item>
          <Label>Postal Code</Label>
          {referral.postalCode}
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Email</Label>
          {referral.email}
        </Item>
      </Row>
      <SectionTitle> Specialist Information </SectionTitle>
      <Row>
        <Item>
          <Label>Specialty</Label>
          {getSpecialtyName(recipient.specialty)}
        </Item>
        <Item>
          <Label>Category</Label>
          {getCategoryName(referral.category)}
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Subcategory</Label>
          {getSubcategoryName(referral.subcategory)}
        </Item>
        <Item>
          <Label>Region</Label>
          {regions.find(r => r.id === referral.region).name}
        </Item>
      </Row>
      <Row>
        <Item>
          <Label>Specialist</Label>
          Dr. {recipient.firstName} {recipient.lastName}
        </Item>
      </Row>
      <PageBreak>
        <SectionTitle> Referral Information </SectionTitle>
        <Row>
          <Item>
            <Label>Urgency</Label>
            {referral.urgency}
          </Item>
        </Row>
        <Row>
          <Item>
            <Label>Reason for Referral and/or Additional Comments</Label>
            <ReasonText>{referral.message}</ReasonText>
          </Item>
        </Row>
        <Row>
          <Item>
            <Label>Attach File(s)</Label>
            <StyledList disablePadding>
              {referral.fileNames &&
                referral.fileNames.map((file, i) => (
                  <ListItem key={`${file}_${i}`} disableGutters dense>
                    {file}
                  </ListItem>
                ))}
              {isFree &&
                referral.files.map((file, i) => (
                  <ListItem key={`${file.name}_${i}`} disableGutters>
                    {file.name}
                  </ListItem>
                ))}
            </StyledList>
          </Item>
        </Row>
      </PageBreak>
      {isFree && (
        <>
          <PageBreak>
            <SectionTitle> Provider (Dr./NP) Information </SectionTitle>
            <Row>
              <Item>
                <Label>First Name</Label>
                {sender.firstName}
              </Item>
              <Item>
                <Label>Last Name</Label>
                {sender.lastName}
              </Item>
            </Row>
            <Row>
              <Item>
                <Label>MSP</Label>
                {sender.msp}
              </Item>
            </Row>
          </PageBreak>
          <PageBreak>
            <SectionTitle> Clinic Information </SectionTitle>
            <Row>
              <Item>
                <Label>Clinic Name</Label>
                {sender.clinicName}
              </Item>
            </Row>
            <Row>
              <Item>
                <Label>Phone No.</Label>
                {sender.phone}
              </Item>
            </Row>
            <Row>
              <Item>
                <Label>Address</Label>
                {sender.address} {sender.city}, {provinces[referral.province]}{' '}
                {sender.postalCode}
              </Item>
            </Row>
          </PageBreak>
          <Footer>
            <ImportantNote>Important Note:</ImportantNote>
            &ensp;It is your responsibility to keep this referral in a secure
            place for reference, Clinnect does not have access to this referral
            and does not retain this information for you.<br></br>
            What is the next step? You will receive a confirmation email with
            the referral reminder at {user.email}. If you do not receive a
            confirmation email please contact the specialist office to ensure
            your referral was received. For more information about free
            referrals visit&ensp;
            <a href="https://clinnect.ca/free-referrals/">
              clinnect.ca/free-referrals
            </a>
          </Footer>
        </>
      )}
    </Body>
  </Container>
)

ReferralPrint.propTypes = {
  user: PropTypes.object,
  sender: PropTypes.object.isRequired,
  referral: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phn: PropTypes.number,
    phone: PropTypes.string,
    gender: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
    email: PropTypes.string,
    region: PropTypes.string,
    specialty: PropTypes.string,
    category: PropTypes.string,
    subcategory: PropTypes.string,
    referralDate: PropTypes.instanceOf(Date),
    birthDate: PropTypes.instanceOf(Date),
    urgency: PropTypes.string,
    fileNames: PropTypes.array,
    files: PropTypes.array,
    message: PropTypes.string,
    referringProvider: PropTypes.object
  }).isRequired,
  recipient: PropTypes.object.isRequired,
  isFree: PropTypes.bool
}

export default ReferralPrint
