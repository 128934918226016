import React from 'react'
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import LogoImage from '../../images/logo-black@2x.jpg'

Font.register({
  family: 'SourceSansPro',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf'
    },
    {
      src: 'https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf',
      fontWeight: 600
    }
  ]
})

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1in',
    fontSize: '12pt',
    fontWeight: '400',
    fontFamily: 'SourceSansPro'
  },
  title: {
    fontSize: '20pt'
  },
  subtitle: {
    borderBottom: '1pt',
    borderBottomColor: 'black',
    fontSize: '17pt'
  },
  section: {
    paddingVertical: '0.1in'
  },
  footer: {
    fontSize: '10pt',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    marginTop: '20pt'
  },
  image: {
    width: '80pt',
    marginLeft: '10pt'
  },
  bold: {
    fontWeight: '600'
  },
  tab: {
    marginLeft: '80pt',
    fontWeight: '600'
  },
  tab2: {
    marginLeft: '21pt',
    fontWeight: '600'
  },
  patientInfo: {
    display: 'flex',
    flexDirection: 'row'
  }
})

const TriagePdf = ({ info, message }) => (
  <Document>
    <Page size="LETTER" style={styles.page}>
      <View>
        <Text style={styles.title}>
          Notification: Your patient referral has been triaged
        </Text>
      </View>
      <View>
        <View style={styles.section}>
          <Text>
            To: {info.to.name ? info.to.name : ''} {info.to.faxNumber}
          </Text>
        </View>
        <View style={styles.section}>
          <Text>From: {info.from.name}</Text>
          <Text>Fax #: {info.from.faxNumber}</Text>
        </View>
        <View style={styles.section}>
          <Text>Date: {format(new Date(), 'dd/MMM/yyyy')}</Text>
        </View>
      </View>
      <View>
        <View style={styles.section}>
          <View style={styles.patientInfo}>
            <Text style={styles.bold}>Re patient: </Text>
            <Text style={styles.tab2}>
              Name: {info.lastName}, {info.firstName}
            </Text>
          </View>
          <Text style={styles.tab}>DOB: {info.birthDate}</Text>
          <Text style={styles.tab}>PHN: {info.phn}</Text>
        </View>
        <View style={styles.section}>
          <Text>Referral Date: {info.referralDate}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.bold}>{info.triageMessage}</Text>
          <Text>
            Please contact their office directly if you have any future
            questions about this referral.
          </Text>
        </View>
        <View style={styles.section}>
          <Text>Referral Type: {info.referralType}</Text>
        </View>
      </View>
      {message !== '' && (
        <View>
          <View style={styles.section}>
            <Text style={styles.subtitle}></Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.bold}>Please note:</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.bold}>{message}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.subtitle}></Text>
          </View>
        </View>
      )}
      <View style={styles.footer}>
        <Text>Powered by: </Text>
        <Image src={LogoImage} style={styles.image} />
      </View>
    </Page>
  </Document>
)

TriagePdf.propTypes = {
  info: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
}

export default TriagePdf
