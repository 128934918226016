import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import styled from 'styled-components'
import gql from 'graphql-tag'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@apollo/client'

import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import EditIcon from 'mdi-react/PencilIcon'
import CloseIcon from 'mdi-react/CloseIcon'

import { sortFaxContactsByName } from '../../util/sort'
import { capitalize } from '../../util/strings'
import { SecondaryButton } from '../common/Button'
import { SectionTitle } from '../common/Title'
import { ErrorMessage } from '../common/UserAlert'
import DeleteContactButton from './DeleteContactButton'
import FaxContact from '../forms/FaxContact'

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionLabel = styled(SectionTitle)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 18px;
`

const ButtonContainer = styled.div`
  button:last-child {
    margin-left: 10px;
  }
`

const GreyButton = styled(SecondaryButton).attrs({
  color: 'secondary',
  padding: 'small'
})`
  background-color: #f9fbfe;
  margin: 0;
`

const faxRegex = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})/

const contactSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  faxNumber: yup
    .string()
    .matches(faxRegex, 'Fax Number must be valid')
    .required('Fax Number is required'),
  location: yup.string()
})

const GET_FAX_CONTACTS = gql`
  query getFaxContacts {
    getFaxContacts {
      id
      practice
      name
      location
      faxNumber
    }
  }
`

const UPDATE_FAX_CONTACT = gql`
  mutation updateFaxContact($faxContact: ID!, $values: FaxContactInput!) {
    updateFaxContact(faxContact: $faxContact, values: $values) {
      id
      name
      practice
      location
      faxNumber
    }
  }
`

function ExistingContactForm({ contact, handleDelete = () => {} }) {
  const [isEditing, setIsEditing] = useState(false)

  const [updateFaxContact, updateContactResult] = useMutation(
    UPDATE_FAX_CONTACT,
    {
      update(cache) {
        if (cache.data.data.ROOT_QUERY?.[`getFaxContacts`]) {
          const { getFaxContacts } = cache.readQuery({
            query: GET_FAX_CONTACTS
          })
          cache.writeQuery({
            query: GET_FAX_CONTACTS,
            data: {
              getFaxContacts: [...getFaxContacts].sort(sortFaxContactsByName)
            }
          })
        }
      }
    }
  )

  const methods = useForm({
    resolver: yupResolver(contactSchema),
    criteriaMode: 'all',
    defaultValues: { ...contact }
  })

  const { handleSubmit, reset } = methods

  useEffect(() => {
    reset({ ...contact })
  }, [reset, contact])

  const handleEdit = () => setIsEditing(true)

  async function onSubmit(values) {
    try {
      await updateFaxContact({
        variables: {
          faxContact: contact.id,
          values: {
            ...values,
            name: capitalize(values.name),
            practice: contact.practice
          }
        }
      })
      setIsEditing(false)
    } catch (e) {
      console.error('Error updating fax contact:', e)
    }
  }

  function handleCancel() {
    reset({
      ...contact
    })
    setIsEditing(false)
  }

  return (
    <FormProvider {...methods}>
      <form aria-label="Contact form" onSubmit={handleSubmit(onSubmit)}>
        <FormContainer>
          <SectionLabel>
            Contact Information
            {!isEditing ? (
              <ButtonContainer>
                <DeleteContactButton
                  contact={contact}
                  handleDelete={handleDelete}
                />
                <SecondaryButton
                  color="secondary"
                  padding="small"
                  onClick={handleEdit}>
                  <EditIcon />
                  Edit
                </SecondaryButton>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <GreyButton
                  color="secondary"
                  padding="small"
                  disabled={updateContactResult.loading}
                  onClick={handleCancel}>
                  <CloseIcon />
                  Cancel
                </GreyButton>
                <SecondaryButton
                  color="secondary"
                  padding="small"
                  loading={updateContactResult.loading}
                  onClick={handleSubmit(onSubmit)}>
                  <ContentSaveIcon />
                  Save
                </SecondaryButton>
              </ButtonContainer>
            )}
          </SectionLabel>
          <Divider />
          {updateContactResult.error && (
            <ErrorMessage>Hm, an error occurred. Try again.</ErrorMessage>
          )}
          <FaxContact isEditing={isEditing} />
        </FormContainer>
      </form>
    </FormProvider>
  )
}

ExistingContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  handleDelete: PropTypes.func
}

export default ExistingContactForm
