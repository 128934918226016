import React, { useMemo, useState } from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { MenuItem } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import SpecialtySelect from './SpecialtySelect'
import useAccount from '../../hooks/useAccount'
import regions from '../../regions.json'
import specialties from '../../specialties.json'
import {
  generateEccKeys,
  deriveAesKey,
  wrapSecretKey,
  exportPublicKey,
  generateHmacKey
} from '../../crypto'

import { ErrorMessage, InfoMessage } from '../common/UserAlert'
import { PageTitle, SectionTitle } from '../common/Title'
import { getCategoriesFromSpecialty } from '../../util/categories'
import Button, { SecondaryButton } from '../common/Button'
import Select from '../common/Select'
import ClinicInformation from '../forms/ClinicInformation'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const ButtonContainer = styled.div`
  display: flex;
  button:last-child {
    margin-left: 15px;
  }
`

const ButtonErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 51px 0 30px;
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: baseline;
`

const SelectorLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  color: #fff;
  padding-right: 18px;
  margin: 0;
  white-space: nowrap;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    width: 250px;
  }
`

const FormContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(17, 147, 173, 0.05);
  box-shadow: 0px 5px 15px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  padding: 0 30px 30px;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const AllFields = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: 20px;
`

const Field = styled.div`
  flex-grow: 1;
  flex-basis: 33.3%;
  padding: 27px 5px 0 25px;
`

const FieldLarge = styled(Field)`
  flex-basis: 66.6%;
`

const ErrorContainer = styled.div`
  margin-bottom: -15px;
`

const faxRegex = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})/
const phoneRegex = /\(\d{3}\)-\d{3}-\d{4},\x20Ext\.\x20[_\d]{6}/
const postalCodeRegex = /^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]/

const practiceSchema = yup.object().shape({
  type: yup.string().oneOf(['centralIntake', 'specialist', 'referrer']),
  firstName: yup.string().when('type', {
    is: type => type === 'specialist' || type === 'referrer',
    then: schema => schema.required('First Name is required')
  }),
  lastName: yup.string().when('type', {
    is: type => type === 'specialist' || type === 'referrer',
    then: schema => schema.required('Last Name is required')
  }),
  msp: yup.string().when('type', {
    is: type => type === 'specialist' || type === 'referrer',
    then: schema => schema.required('MSP number is required')
  }),
  centralIntakeName: yup.string().when('type', {
    is: 'centralIntake',
    then: schema => schema.required('Central Intake Name is required')
  }),
  clinicName: yup.string(),
  phone: yup.string().required('Phone Number is required').matches(phoneRegex, {
    message: 'Phone Number must be valid'
  }),
  region: yup.string().when('type', {
    is: type => type === 'specialist' || type === 'centralIntake',
    then: schema => schema.required('Region is required')
  }),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  province: yup.string().required('Province is required'),
  postalCode: yup
    .string()
    .required('Postal Code is required')
    .matches(postalCodeRegex, 'Postal Code must be valid'),
  specialty: yup.string().when('type', {
    is: type => type === 'specialist' || type === 'centralIntake',
    then: schema => schema.required('Specialty is required')
  }),
  specialties: yup.array().when('type', {
    is: 'specialist',
    then: yup.array().min(1, 'Please choose at least one category'),
    otherwise: yup.array()
  }),
  emrNumber: yup.string().matches(faxRegex, {
    message: 'Fax Number must be valid',
    excludeEmptyString: true
  })
})

const GET_PRACTICES = gql`
  query getPractices {
    getPractices {
      id
    }
  }
`

const CREATE_PRACTICE = gql`
  mutation createPractice($practice: PracticeInput!) {
    createPractice(practice: $practice) {
      id
      type
      isVerified
    }
  }
`

function PracticeCreationForm({
  practice = {
    type: 'centralIntake',
    firstName: '',
    lastName: '',
    centralIntakeName: '',
    msp: '',
    clinicName: '',
    phone: '',
    region: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    specialty: '',
    specialties: [],
    emrNumber: ''
  }
}) {
  const history = useHistory()
  const { getAccount } = useAccount()
  const [error, setError] = useState(false)

  const [createPractice, { loading }] = useMutation(CREATE_PRACTICE, {
    update(cache, { data: { createPractice } }) {
      if (cache.data.data.ROOT_QUERY?.getPractices) {
        const { getPractices } = cache.readQuery({ query: GET_PRACTICES })
        cache.writeQuery({
          query: GET_PRACTICES,
          data: { getPractices: getPractices.concat([createPractice]) }
        })
      }
    }
  })

  const methods = useForm({
    resolver: yupResolver(practiceSchema),
    criteriaMode: 'all',
    defaultValues: { ...practice }
  })

  const { handleSubmit, errors, control, watch, setValue, clearErrors } =
    methods

  const type = watch('type')
  const region = watch('region')
  const specialty = watch('specialty')

  const filteredSpecialties = useMemo(
    () => specialties.filter(s => region && s.region === region),
    [region]
  )

  const filteredCategories = useMemo(
    () =>
      getCategoriesFromSpecialty(
        filteredSpecialties.find(s => s.id === specialty)
      ),
    [filteredSpecialties, specialty]
  )

  async function onSubmit(data) {
    setError(false)
    const account = await getAccount()
    const practiceKeys = await generateEccKeys()
    const ephemeralKeys = await generateEccKeys()

    const key = await deriveAesKey(ephemeralKeys.privateKey, account.publicKey)
    const { wrappedKey, iv } = await wrapSecretKey(practiceKeys.privateKey, key)
    const exportedEphemeralKey = await exportPublicKey(ephemeralKeys.publicKey)
    const exportedPracticeKey = await exportPublicKey(practiceKeys.publicKey)

    // Generate the practice search key
    const ephemeralKeyPair = await generateEccKeys()
    const hmacKey = await generateHmacKey()

    const wrappingKey = await deriveAesKey(
      ephemeralKeyPair.privateKey,
      practiceKeys.publicKey
    )
    const { wrappedKey: wrappedSearchKey, iv: searchIv } = await wrapSecretKey(
      hmacKey,
      wrappingKey
    )
    const exportedPublicKey = await exportPublicKey(ephemeralKeyPair.publicKey)

    const practice = {
      ...data,
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      msp: data.msp || '',
      region: data.region || '',
      centralIntakeName: data.centralIntakeName || '',
      emrNumber: data.emrNumber || '',
      publicKey: exportedPracticeKey,
      searchKey: {
        hashKey: {
          encryptedData: wrappedSearchKey,
          iv: searchIv
        },
        publicKey: exportedPublicKey
      },
      memberships: [
        {
          user: account.id,
          privateKey: { encryptedData: wrappedKey, iv },
          publicKey: exportedEphemeralKey
        }
      ]
    }

    try {
      const { data } = await createPractice({ variables: { practice } })

      const { createPractice: newPractice } = data
      const isPendingVerification =
        newPractice.type === 'specialist' && !newPractice.isVerified

      history.push(
        `/settings/practices/${newPractice.id}/members?offerSkip=true`,
        {
          unverifiedPractice: isPendingVerification ? newPractice.id : null
        }
      )
    } catch (e) {
      console.error('Request error occurred:', e.message)
      if (e.message.includes('This practice already exists')) {
        setError('This practice already exists.')
      } else {
        setError('Hm, an error occurred. Try again.')
      }
    }
  }

  return (
    <Content>
      <FormProvider {...methods}>
        <form aria-labelledby="form-label" onSubmit={handleSubmit(onSubmit)}>
          <Header>
            <PageTitle id="form-label">Create Practice</PageTitle>
            <ButtonErrorContainer>
              <ButtonContainer>
                <SecondaryButton
                  color="secondary"
                  padding="small"
                  onClick={() => history.push(`/settings`)}>
                  Cancel
                </SecondaryButton>
                <Button type="submit" loading={loading} padding="small">
                  <AccountPlusIcon />
                  Create practice
                </Button>
              </ButtonContainer>
              {error && (
                <ErrorContainer>
                  <ErrorMessage>{error}</ErrorMessage>
                </ErrorContainer>
              )}
            </ButtonErrorContainer>
          </Header>
          <BlueBar>
            <SelectorLabel id="typeLabel">
              Who does this practice represent?
            </SelectorLabel>
            <Controller
              name="type"
              control={control}
              render={({ onChange, value, name }) => (
                <StyledSelect
                  select
                  id="type"
                  aria-labelledby="typeLabel"
                  name={name}
                  onChange={onChange}
                  value={value}>
                  <MenuItem value="centralIntake">Central Intake</MenuItem>
                  <MenuItem value="specialist">Specialist</MenuItem>
                  <MenuItem value="referrer">Referrer</MenuItem>
                </StyledSelect>
              )}
            />
          </BlueBar>
          <InfoMessage>
            {type === 'centralIntake' &&
              'A central intake practice will allow you to receive referrals via integrated fax and send referrals to specialists through Clinnect. If a group of specialists is joining Clinnect as a central intake, one central intake practice should be created. If you are not signing up with a specialist group but want to send referrals to specialists, create a "Referrer" practice instead.'}
            {type === 'specialist' &&
              'A specialist practice will allow you to send and receive referrals. One specialist practice should be created for each specialist in a group. After your practice is created and verified, you will be able to edit your overall and categorical availabilities from your practice settings.'}
            {type === 'referrer' &&
              'A referrer practice will allow you to send referrals. Any NP or physician can create a referrer practice in order to send referrals to specialists who are on Clinnect.'}
          </InfoMessage>
          <FormContainer>
            <SectionTitle>
              {type === 'centralIntake' && 'Central Intake Information'}
              {type === 'specialist' && 'Specialist Information'}
              {type === 'referrer' && 'Referrer Information'}
            </SectionTitle>
            <Divider />
            {type === 'centralIntake' && (
              <AllFields>
                <Field>
                  <Controller
                    name="region"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <Select
                        select
                        id="region"
                        name={name}
                        label="Region"
                        placeholder="Pick the region"
                        onChange={e => {
                          setValue('specialty', '')
                          onChange(e.target.value)
                        }}
                        value={value}
                        error={!!errors.region}
                        helperText={errors?.region?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: true }}>
                        {regions.map(r => (
                          <MenuItem key={r.id} value={r.id}>
                            {r.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Field>
                <Field>
                  <Controller
                    name="specialty"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <Select
                        select
                        label="Specialty"
                        id="specialty"
                        name={name}
                        onChange={e => {
                          onChange(e.target.value)
                          clearErrors('centralIntakeName')
                          setValue(
                            'centralIntakeName',
                            `${regions.find(r => r.id === region).name} ${
                              specialties.find(s => s.id === e.target.value)
                                .name
                            }`
                          )
                        }}
                        disabled={filteredSpecialties.length === 0}
                        value={value}
                        error={!!errors.specialty}
                        helperText={errors?.specialty?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: true }}>
                        {filteredSpecialties.map(s => (
                          <MenuItem key={s.id} value={s.id}>
                            {s.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Field>
                <Field>
                  <Controller
                    name="centralIntakeName"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <Select
                        id="centralIntakeName"
                        name={name}
                        label="Central Intake Name"
                        value={value}
                        onChange={onChange}
                        disabled={true}
                        error={!!errors.centralIntakeName}
                        helperText={errors?.centralIntakeName?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: true, shrink: true }}
                      />
                    )}
                  />
                </Field>
              </AllFields>
            )}
            {(type === 'specialist' || type === 'referrer') && (
              <AllFields>
                <Field>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <Select
                        id="firstName"
                        name={name}
                        label="First Name"
                        value={value}
                        onChange={onChange}
                        placeholder={`Enter ${type} first name`}
                        error={!!errors.firstName}
                        helperText={errors?.firstName?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: true, shrink: true }}
                      />
                    )}
                  />
                </Field>
                <Field>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <Select
                        id="lastName"
                        name={name}
                        label="Last Name"
                        value={value}
                        onChange={onChange}
                        placeholder={`Enter ${type} last name`}
                        error={!!errors.lastName}
                        helperText={errors?.lastName?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: true, shrink: true }}
                      />
                    )}
                  />
                </Field>
                <Field>
                  <Controller
                    name="msp"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <Select
                        id="msp"
                        name={name}
                        label="MSP"
                        value={value}
                        onChange={onChange}
                        placeholder={`Enter ${type} MSP`}
                        error={!!errors.msp}
                        helperText={errors?.msp?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: true, shrink: true }}
                      />
                    )}
                  />
                </Field>
              </AllFields>
            )}

            <ClinicInformation isSpecialist={type === 'specialist'} />

            {type === 'specialist' && (
              <>
                <SectionTitle>Specialty</SectionTitle>
                <Divider />
                <AllFields>
                  <Field>
                    <Controller
                      name="region"
                      control={control}
                      render={({ onChange, value, name }) => (
                        <Select
                          select
                          id="region"
                          name={name}
                          label="Region"
                          placeholder="Pick the region"
                          onChange={e => {
                            setValue('specialty', '')
                            setValue('specialties', [])
                            onChange(e.target.value)
                          }}
                          value={value}
                          error={!!errors.region}
                          helperText={errors?.region?.message}
                          FormHelperTextProps={{ role: 'alert' }}
                          InputLabelProps={{ required: true }}>
                          {regions.map(r => (
                            <MenuItem key={r.id} value={r.id}>
                              {r.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Field>
                  <Field>
                    <Controller
                      name="specialty"
                      control={control}
                      render={({ onChange, value, name }) => (
                        <Select
                          select
                          label="Specialty"
                          id="specialty"
                          name={name}
                          onChange={e => {
                            setValue('specialties', [])
                            onChange(e.target.value)
                          }}
                          disabled={filteredSpecialties.length === 0}
                          value={value}
                          error={!!errors.specialty}
                          helperText={errors?.specialty?.message}
                          FormHelperTextProps={{ role: 'alert' }}
                          InputLabelProps={{ required: true }}>
                          {filteredSpecialties.map(s => (
                            <MenuItem key={s.id} value={s.id}>
                              {s.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Field>
                  <Field />
                  <FieldLarge>
                    {region && specialty && filteredCategories.length > 0 && (
                      <Controller
                        as={SpecialtySelect}
                        categories={filteredCategories}
                        control={control}
                        error={errors.specialties}
                        name="specialties"
                      />
                    )}
                  </FieldLarge>
                </AllFields>
              </>
            )}
          </FormContainer>
        </form>
      </FormProvider>
    </Content>
  )
}

PracticeCreationForm.propTypes = {
  practice: PropTypes.shape({
    type: PropTypes.oneOf(['centralIntake', 'specialist', 'referrer']),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    centralIntakeName: PropTypes.string,
    msp: PropTypes.string,
    phone: PropTypes.string,
    region: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
    specialty: PropTypes.string,
    specialties: PropTypes.array,
    emrNumber: PropTypes.string
  })
}

export default PracticeCreationForm
