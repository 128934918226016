import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Chart from './Chart'
import PivotTable from './PivotTable'
import ProcedureBillingCodeCharts from './ProcedureBillingCodeCharts'
import { SectionTitle } from '../common/Title'
import { filterDataBySpecialistCategory } from '../../util/filterData'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Divider = styled.div`
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

function ProcedureReport({ procedures, filters = null }) {
  const filteredProcedures = filters
    ? filterDataBySpecialistCategory(procedures, 'procedures', filters)
    : procedures

  if (filteredProcedures.length === 0) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  const categoryLength = [...new Set(filteredProcedures.map(r => r.category))]
    .length
  const height = categoryLength < 6 ? 450 : categoryLength > 10 ? 800 : 625

  return (
    <ReportVisualsContainer>
      <SectionTitle>Procedure Report</SectionTitle>
      <Divider />
      <Chart
        type="pie"
        title="Total Procedures by Doctor"
        dataSourceSettings={{
          dataSource: filteredProcedures,
          rows: [{ name: 'to' }],
          columns: [{}],
          values: [{ name: 'to' }]
        }}
        downloadable={true}
      />
      <PivotTable
        title="Procedure Counts by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredProcedures,
          columns: [{ name: 'to' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          values: [{ name: 'to' }]
        }}
        visibleRows={15}
        downloadable={true}
        expandable={true}
      />
      <Chart
        type="stackingBar100"
        title="Distribution of Procedures by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredProcedures,
          sortSettings: [{ name: 'category', order: 'Descending' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          columns: [{ name: 'to' }],
          values: [{ name: 'category' }]
        }}
        customSettings={{ height }}
        expandable={true}
        downloadable={true}
      />
      <Chart
        type="line"
        title="Number of Procedures by Doctor Over Time"
        dataSourceSettings={{
          valueSortSettings: { headerDelimiter: '##' },
          dataSource: filteredProcedures,
          rows: [{ name: 'year' }, { name: 'month' }],
          columns: [{ name: 'to' }],
          values: [{ name: 'to' }],
          expandAll: true
        }}
        expandable={true}
        downloadable={true}
      />
      <ProcedureBillingCodeCharts procedures={filteredProcedures} />
    </ReportVisualsContainer>
  )
}

ProcedureReport.propTypes = {
  procedures: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default ProcedureReport
