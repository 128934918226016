import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Redirect, useParams } from 'react-router-dom'

import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import ContactManagement from '../contacts/ContactManagement'

const GET_FAX_CONTACTS_DATA = gql`
  query getFaxContactsData($practice: ID!) {
    getFaxContacts {
      id
      practice
      name
      location
      faxNumber
    }
    getPractice(id: $practice) {
      id
      firstName
      lastName
      centralIntakeName
      type
      faxNumber
    }
  }
`

function FaxContactsPage() {
  const { practiceId } = useParams()
  const { loading, error, data } = useQuery(GET_FAX_CONTACTS_DATA, {
    variables: { practice: practiceId }
  })

  if (error) return <LoadingError />
  if (loading) return <Loading />

  const practice = data.getPractice

  if (!practice.faxNumber)
    return <Redirect to={`/settings/practices/${practiceId}`} />

  const filteredContacts = data.getFaxContacts.filter(
    c => c.practice === practiceId
  )

  return <ContactManagement contacts={filteredContacts} practice={practice} />
}

export default FaxContactsPage
