import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { InputLabel, FormControl, MenuItem } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDatePicker } from '@material-ui/pickers'

import {
  getAllCategories,
  getAllSubcategories,
  getSubcategoriesString,
  doesCategoryHaveSubcategories,
  getCategoryId,
  getSpecialtyByCategoryId,
  getCategoryName,
  getRegionNameByCategoryId
} from '../../util/categories'
import { InfoMessage } from './UserAlert'
import ReferralTable from './ReferralTable'
import Select from './Select'
import Loading from './Loading'
import ReferralDownloadButton from './ReferralDownloadButton'

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 320px);
`

const FilterByContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
`

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-right: 18px;
`

const StyledFormControl = styled(FormControl).attrs(props => ({
  variant: props.variant || 'outlined',
  margin: props.margin || 'dense'
}))`
  min-width: 200px;
  margin-right: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    width: 250px;
    height: 42px;
  }
  .MuiFormHelperText-root.Mui-error {
    color: #fff;
  }
  .MuiFormHelperText-root {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 10px;
    color: #fff;
    padding: 5px 16px 0 0;
  }
`

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    padding-left: 15px;
  }
  .MuiAutocomplete-endAdornment {
    right: 10px;
  }
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  max-height: 102px;
`

const FilterContainer = styled.div`
  display: flex;
`

const AlertContainer = styled.div`
  margin-bottom: 30px;
`

const StyledLink = styled(Link)`
  font-weight: 600;
  color: #3598db;
  text-decoration: none;
`

const InfoStyling = styled.p`
  color: rgba(58, 58, 58, 0.7);
  font-size: 14px;
  margin: 0px;
  font-style: italic;
`

const CsvButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 2px;
`

const filterOptions = [
  { value: 'patient', label: 'Patient' },
  { value: 'phn', label: 'PHN' },
  { value: 'state', label: 'Status' },
  { value: 'faxToEmrStatus', label: 'EMR Fax Status' },
  { value: 'triageFaxStatus', label: 'Triage Fax Status' },
  { value: 'dateSent', label: 'Date Sent' },
  { value: 'from', label: 'From' },
  { value: 'to', label: 'To' },
  { value: 'category', label: 'Category' },
  { value: 'subcategory', label: 'Subcategory' },
  { value: 'urgency', label: 'Urgency' }
]

const FilterBy = ({ type, value, onFilterByChange, onFilterValueChange }) => {
  if (type === 'category' && value !== '') {
    const categoryId = getCategoryId(value.split(':')[0])
    value = getAllCategories().find(c => c.value === categoryId)
  }
  if (type === 'subcategory' && value !== '') {
    value = getAllSubcategories().find(c => c.value === value)
  }

  return (
    <FilterContainer>
      <FilterByContainer>
        <StyledInputLabel id="selector-label">Filter by</StyledInputLabel>
      </FilterByContainer>
      <StyledFormControl>
        <StyledSelect
          select
          value={type}
          onChange={onFilterByChange}
          aria-labelledby="selector-label">
          <MenuItem value="none">None</MenuItem>
          {filterOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>

      {(() => {
        switch (type) {
          case 'none':
            return null

          case 'urgency':
            return (
              <StyledFormControl>
                <StyledSelect
                  select
                  value={value}
                  onChange={onFilterValueChange}>
                  <MenuItem value="Urgent">Urgent</MenuItem>
                  <MenuItem value="Semi-urgent">Semi-Urgent</MenuItem>
                  <MenuItem value="Not Urgent">Not Urgent</MenuItem>
                </StyledSelect>
              </StyledFormControl>
            )

          case 'state':
            return (
              <StyledFormControl>
                <StyledSelect
                  select
                  value={value}
                  onChange={onFilterValueChange}>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="accepted">Accepted</MenuItem>
                  <MenuItem value="declined">Declined</MenuItem>
                </StyledSelect>
              </StyledFormControl>
            )

          case 'faxToEmrStatus':
          case 'triageFaxStatus':
            return (
              <StyledFormControl>
                <StyledSelect
                  select
                  value={value}
                  onChange={onFilterValueChange}>
                  <MenuItem value="processing">Fax Processing</MenuItem>
                  <MenuItem value="success">Fax Sent</MenuItem>
                  <MenuItem value="failed">Fax Failed</MenuItem>
                </StyledSelect>
              </StyledFormControl>
            )

          case 'dateSent':
            return (
              <StyledFormControl>
                <KeyboardDatePicker
                  TextFieldComponent={StyledSelect}
                  value={value || null}
                  onChange={date => {
                    onFilterValueChange({
                      target: {
                        value: () => {
                          if (date instanceof Date && !isNaN(date))
                            return date.toISOString()
                          else if (!date) return ''
                          else return date.toString()
                        }
                      }
                    })
                  }}
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                />
              </StyledFormControl>
            )

          case 'category':
            return (
              <StyledFormControl>
                <StyledAutocomplete
                  value={value || null}
                  options={getAllCategories()}
                  getOptionLabel={option => option.label}
                  getOptionSelected={(option, value) =>
                    option.value === value.value && option.label === value.label
                  }
                  onChange={(event, newValue) => onFilterValueChange(newValue)}
                  renderOption={(option, { selected }) => (
                    <div key={option.value}>
                      {option.label}
                      <InfoStyling>
                        {getRegionNameByCategoryId(option.value) +
                          ' ' +
                          getSpecialtyByCategoryId(option.value)}
                      </InfoStyling>
                    </div>
                  )}
                  renderInput={params => <StyledSelect {...params} />}
                />
              </StyledFormControl>
            )

          case 'subcategory':
            return (
              <StyledFormControl>
                <StyledAutocomplete
                  value={value || null}
                  options={getAllSubcategories()}
                  getOptionLabel={option => option.label}
                  getOptionSelected={(option, value) =>
                    option.value === value.value && option.label === value.label
                  }
                  onChange={(event, newValue) => onFilterValueChange(newValue)}
                  renderOption={(option, { selected }) => (
                    <div key={option.value}>
                      {option.label}
                      <InfoStyling>
                        {getRegionNameByCategoryId(option.value) +
                          ' ' +
                          getCategoryName(option.value)}
                      </InfoStyling>
                    </div>
                  )}
                  renderInput={params => <StyledSelect {...params} />}
                />
              </StyledFormControl>
            )

          case 'phn':
            return (
              <StyledFormControl>
                <StyledSelect
                  value={value}
                  onChange={onFilterValueChange}
                  helperText="PHNs must be an exact match"
                />
              </StyledFormControl>
            )

          case 'patient':
            return (
              <StyledFormControl>
                <StyledSelect
                  value={value}
                  onChange={onFilterValueChange}
                  helperText="Name must be an exact match to the first name, last name, or the combination of both"
                />
              </StyledFormControl>
            )

          default:
            return (
              <StyledFormControl>
                <StyledSelect value={value} onChange={onFilterValueChange} />
              </StyledFormControl>
            )
        }
      })()}
    </FilterContainer>
  )
}

FilterBy.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onFilterByChange: PropTypes.func.isRequired,
  onFilterValueChange: PropTypes.func.isRequired
}

function Referrals({
  referrals,
  practices = [],
  unverifiedPractice,
  isTokenizing,
  filter = { type: 'none', value: '' },
  onChangeFilter = () => {},
  loading,
  onUpdateReferralFax = () => {}
}) {
  function onFilterByChange(e) {
    onChangeFilter({
      type: e.target.value,
      value: ''
    })
  }

  function onFilterValueChange(e) {
    if (filter.type === 'subcategory') {
      const value = e ? e.value : ''
      onChangeFilter({ type: filter.type, value })
    } else if (filter.type === 'category') {
      const value = e ? e.value : ''
      const hasSubcategories = doesCategoryHaveSubcategories(value)
      const subcategoriesString = hasSubcategories
        ? getSubcategoriesString(value)
        : value
      onChangeFilter({ type: filter.type, value: subcategoriesString })
    } else if (filter.type === 'dateSent') {
      const value = e.target.value()
      onChangeFilter({ type: filter.type, value })
    } else {
      onChangeFilter({ type: filter.type, value: e.target.value })
    }
  }

  return (
    <>
      <BlueBar>
        <FilterBy
          type={filter.type}
          value={filter.value}
          onFilterByChange={onFilterByChange}
          onFilterValueChange={onFilterValueChange}
        />
        <CsvButtonContainer>
          <ReferralDownloadButton />
        </CsvButtonContainer>
      </BlueBar>
      {unverifiedPractice && (
        <AlertContainer>
          <InfoMessage>
            One or more of your Practices are pending approval. You will only be
            able to receive referrals for verified practices. You may check the
            status of your practice under{' '}
            <StyledLink to={`/settings/practices/${unverifiedPractice.id}`}>
              Settings
            </StyledLink>
            .
          </InfoMessage>
        </AlertContainer>
      )}
      {loading ||
      (isTokenizing && (filter.type === 'patient' || filter.type === 'phn')) ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <ReferralTable
          referrals={referrals}
          practices={practices}
          onUpdateReferralFax={onUpdateReferralFax}
        />
      )}
    </>
  )
}

Referrals.propTypes = {
  referrals: ReferralTable.propTypes.referrals,
  practices: PropTypes.array,
  isTokenizing: PropTypes.bool,
  unverifiedPractice: PropTypes.object,
  filter: PropTypes.object,
  onChangeFilter: PropTypes.func,
  loading: PropTypes.bool,
  onUpdateReferralFax: PropTypes.func
}

export default Referrals
