import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import useAccount from '../../hooks/useAccount'
import SendIcon from 'mdi-react/SendIcon'
import MessageOffIcon from 'mdi-react/MessageOffIcon'
import styled from 'styled-components'
import {
  importPublicKey,
  unwrapAesKey,
  deriveAesKey,
  unwrapEccKey,
  encryptObject
} from '../../crypto'
import { IconButton } from '../common/Button'
import { ErrorMessage } from '../common/UserAlert.js'

const SendMessageForm = styled.form`
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  padding: 15px 30px 0px 30px;
  border-top: 1px solid rgba(58, 58, 58, 0.15);
`

const MessageContainer = styled.div`
  border-top: 1px solid rgba(58, 58, 58, 0.15);
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding: 15px 30px 0px 30px;
  align-items: flex-start;

  p {
    font-size: 12px;
    margin: 0px 0px 0px 22px;
  }

  a {
    color: #3598db;
    text-decoration: none;
    font-weight: 600;
  }
`

const StyledMessageOffIcon = styled(MessageOffIcon)`
  color: #e84c3d;
`

const SubmitButton = styled(IconButton)`
  margin-left: 10px;
  padding: 8px 8px 9px 9px;

  svg {
    transform: rotate(-40deg);
  }
`

const MessageInput = styled.input`
  color: #069987;
  flex: 1;
  border: none;
  resize: none;
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid #fff;
  padding: 10px;

  :focus {
    outline: none;
    border: 1px solid #069987;
    border-radius: 3px;
  }

  ::placeholder {
    font-style: italic;
    font-weight: 600;
    color: #069987;
  }
`

const ErrorContainer = styled.div`
  margin: -7px 0 -15px;
`

const GET_REFERRAL_EVENTS = gql`
  query getReferralEvents($referral: ID!) {
    getReferralEvents(referral: $referral) {
      id
      type
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      data {
        ... on Referral {
          sender {
            practice {
              type
              centralIntakeName
              firstName
              lastName
            }
          }
          recipient {
            practice {
              firstName
              lastName
            }
          }
        }
        ... on OriginalReferralSentEvent {
          sender {
            practice {
              type
              centralIntakeName
              firstName
              lastName
            }
          }
          recipient {
            practice {
              firstName
              lastName
            }
          }
        }
        ... on ReferralAcceptedEvent {
          id
        }
        ... on ReferralDeclinedEvent {
          reason
          description
        }
        ... on ReferralRedirectedEvent {
          originalReferral {
            id
            recipient {
              practice {
                firstName
                lastName
              }
            }
          }
          newReferral {
            id
            recipient {
              practice {
                firstName
                lastName
                phone
                msp
              }
            }
          }
        }
        ... on CategoryChangedEvent {
          category
          initialCategory
        }
        ... on UrgencyChangedEvent {
          urgency
          initialUrgency
        }
        ... on MessageSentEvent {
          message {
            iv
            encryptedData
          }
        }
        ... on FileAddedEvent {
          file {
            iv
            key
          }
          encryptedData {
            encryptedData
            iv
          }
        }
      }
    }
  }
`

const SEND_MESSAGE = gql`
  mutation sendMessage($referral: ID!, $message: EncryptedDataInput!) {
    sendMessage(referral: $referral, message: $message) {
      id
      type
      createdAt
      createdBy {
        id
        lastName
        firstName
      }
      data {
        ... on MessageSentEvent {
          message {
            iv
            encryptedData
          }
        }
      }
    }
  }
`

function MessageBox({ referral, recipient, sender }) {
  const [sendMessage, { loading, error }] = useMutation(SEND_MESSAGE, {
    update(cache, { data: { sendMessage } }) {
      if (
        cache.data.data.ROOT_QUERY?.[
          `getReferralEvents({"referral":"${referral.id}"})`
        ]
      ) {
        const { getReferralEvents } = cache.readQuery({
          query: GET_REFERRAL_EVENTS,
          variables: { referral: referral.id }
        })
        cache.writeQuery({
          query: GET_REFERRAL_EVENTS,
          variables: { referral: referral.id },
          data: { getReferralEvents: [...getReferralEvents, sendMessage] }
        })
      }
    }
  })
  const { errors, handleSubmit, register, reset } = useForm()
  const { getAccount } = useAccount()

  async function onSubmit(values) {
    try {
      const account = await getAccount()

      const recipientMembership = recipient.practice.memberships.find(
        m => m.user.id === account.id
      )
      const senderMembership = sender.practice.memberships.find(
        m => m.user.id === account.id
      )

      const participant = recipientMembership ? recipient : sender

      const membership = recipientMembership || senderMembership

      const membershipAesKey = await deriveAesKey(
        account.privateKey,
        await importPublicKey(membership.publicKey)
      )

      const practicePrivateKey = await unwrapEccKey(
        membership.privateKey.encryptedData,
        membershipAesKey,
        membership.privateKey.iv
      )

      const referralAesKey = await deriveAesKey(
        practicePrivateKey,
        await importPublicKey(participant.publicKey)
      )

      const encryptionKey = await unwrapAesKey(
        participant.encryptionKey.encryptedData,
        referralAesKey,
        participant.encryptionKey.iv,
        'enc'
      )

      const { encryptedObject: encryptedMessage, iv: messageIv } =
        await encryptObject({ msg: values.msg }, encryptionKey)

      await sendMessage({
        variables: {
          message: { encryptedData: encryptedMessage, iv: messageIv },
          referral: referral.id
        }
      })
      reset()
    } catch (e) {
      console.error(e)
    }
  }

  if (referral.type === 'free')
    return (
      <MessageContainer>
        <StyledMessageOffIcon />
        <p>
          The messaging feature has been turned off because this referral was
          sent using the free referral service.{' '}
          <a href="https://clinnect.ca/receiving-free-referrals/">Learn More</a>
        </p>
      </MessageContainer>
    )

  return (
    <>
      <SendMessageForm
        onSubmit={handleSubmit(onSubmit)}
        aria-label="Attach a message to the referral"
        autocomplete="off">
        <MessageInput
          defaultValue=""
          name="msg"
          aria-label="Type a message"
          type="text"
          placeholder="Type a message"
          error={!!errors.password}
          helperText={errors?.password?.message}
          ref={register({
            required: true,
            minLength: 1
          })}
        />

        <SubmitButton
          color="secondary"
          aria-label="Send message"
          type="submit"
          loading={loading}>
          <SendIcon />
        </SubmitButton>
      </SendMessageForm>
      {error && (
        <ErrorContainer>
          <ErrorMessage>Hm, something went wrong.</ErrorMessage>
        </ErrorContainer>
      )}
    </>
  )
}

MessageBox.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  sender: PropTypes.shape({
    practice: PropTypes.shape({
      memberships: PropTypes.array
    })
  }).isRequired,
  recipient: PropTypes.shape({
    practice: PropTypes.shape({
      memberships: PropTypes.array
    })
  }).isRequired
}

export default MessageBox
