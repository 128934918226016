import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 190px;
`

const Spacer = styled.p`
  padding: 0px 6px;
  margin: 0;
  color: #f9fbfe;
  font-size: 10px;
`

const Link = styled.a`
  color: #f9fbfe;
  font-size: 10px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export default () => (
  <Container>
    <Link href="https://www.clinnect.ca/about-us">Contact Us</Link>
    <Spacer>|</Spacer>
    <Link href="https://clinnect.ca/terms-of-use/">Terms of Use</Link>
  </Container>
)
