import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { ReactComponent as FeedbackIcon } from '../../images/feedback.svg'
import styled from 'styled-components'

const Button = styled.a`
  position: fixed;
  bottom: 40px;
  right: 40px;
  border: none;
  padding: 0;

  background-color: transparent;
  cursor: pointer;
  outline: none;

  svg {
    margin-bottom: -20px;
    margin-left: -15px;
  }

  :active {
    svg path {
      fill: #114661;
    }
  }
`

const TooltipWrapper = props => (
  <Tooltip
    {...props}
    classes={{ popper: props.className, tooltip: 'tooltip' }}
  />
)
TooltipWrapper.propTypes = { className: PropTypes.string.isRequired }

const CustomTooltip = styled(TooltipWrapper)`
  & .tooltip {
    color: #222831;
    font-size: 14px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }
`

const FeedbackButton = () => (
  <CustomTooltip
    title="Have a question or concern? Let us know!"
    placement="left">
    <Button
      href="mailto:support@clinnect.ca?subject=Clinnect Feedback"
      target="__blank">
      <FeedbackIcon />
    </Button>
  </CustomTooltip>
)

export default FeedbackButton
