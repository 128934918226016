import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'

import { getFactorCategoryName } from '../../util/categories'
import {
  StyledSwitch,
  StyledLabel,
  SelectorLabel
} from '../common/StyledSwitch'
import Select from '../common/Select'

const AllFields = styled.section`
  display: flex;
  flex-direction: column;

  div:first-child {
    padding: 0;
  }
`

const Field = styled.div`
  padding: 5px 0 0;
  display: flex;
  align-items: baseline;
`

const StyledSelect = styled(Select)`
  width: 55px;

  .MuiInputBase-root {
    margin-top: 0;
  }
  & .Mui-disabled {
    border: none;
  }
  .MuiInputBase-root.Mui-disabled {
    opacity: 1;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: rgba(58, 58, 58, 1);
  }
`

const PercentText = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #3a3a3a;
  padding-left: 6px;
  margin: 0;
`

const StyledSelectorLabel = styled(SelectorLabel)`
  padding-left: 30px;
`

function CategoryAvailability({ categoryFactors, isEditing, showPercents }) {
  const { control, errors } = useFormContext()

  return (
    <AllFields>
      {categoryFactors.map((category, i) => {
        const currCat =
          category.category !== null ? category.category : 'overall'
        return (
          <Field key={i}>
            {showPercents ? (
              <>
                <Controller
                  name={currCat}
                  control={control}
                  render={({ onChange, value, name }) => (
                    <StyledSelect
                      id={currCat}
                      name={name}
                      value={value}
                      onChange={e => {
                        const newValue = e.target.value.replace(
                          new RegExp(/[^\d]/, 'ig'),
                          ''
                        )
                        onChange(newValue)
                      }}
                      disabled={!isEditing}
                      error={!!errors.currCat}
                      helperText={errors?.currCat?.message}
                      FormHelperTextProps={{ role: 'alert' }}
                      inputProps={{
                        'aria-labelledby': currCat + '-label'
                      }}
                    />
                  )}
                />
                <PercentText>%</PercentText>
                <StyledSelectorLabel id={currCat + '-label'}>
                  {getFactorCategoryName(category.category)}
                </StyledSelectorLabel>
              </>
            ) : (
              <Controller
                name={currCat}
                control={control}
                render={({ onChange, value, name }) => (
                  <StyledLabel
                    value={value}
                    control={
                      <StyledSwitch
                        name={name}
                        disabled={!isEditing}
                        checked={value > 0}
                        onChange={e =>
                          e.target.checked ? onChange(100) : onChange(0)
                        }
                      />
                    }
                    label={
                      <StyledSelectorLabel id={currCat + '-label'}>
                        {getFactorCategoryName(category.category)}
                      </StyledSelectorLabel>
                    }
                  />
                )}
              />
            )}
          </Field>
        )
      })}
    </AllFields>
  )
}

CategoryAvailability.propTypes = {
  categoryFactors: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      factor: PropTypes.number.isRequired
    })
  ).isRequired,
  isEditing: PropTypes.bool.isRequired,
  showPercents: PropTypes.bool.isRequired
}

export default CategoryAvailability
