import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import gql from 'graphql-tag'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import { PageTitle } from '../common/Title'
import FaxList from '../faxing/FaxList'
import LoadingError from '../common/LoadingError'
import Loading from '../common/Loading'
import Select from '../common/Select'
import PdfPreview from '../faxing/PdfPreview'
import { InputLabel, MenuItem } from '@material-ui/core'
import { IconButton } from '../common/Button'
import ArrowRightThickIcon from 'mdi-react/ArrowRightThickIcon'
import { getPracticeDisplayName } from '../../util/strings'

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`

const Subtitle = styled.span`
  font-size: 16px;
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const StickyContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: -45px;
  width: 50%;
  height: calc(100vh - 40px);

  ${props =>
    props.archived &&
    `
    margin-top: 122px;
    top: 15px;
    height: calc(100vh - 95px);
  `}
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  box-sizing: border-box;
  border-radius: 6px;
  margin: 60px 0 27px 0;

  padding: 30px;
  display: flex;
  align-items: baseline;
`

const ActionLabelContainer = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
`

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-right: 18px;
`

const DropdownContainer = styled.div`
  display: flex;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    margin-right: 8px;
    width: 250px;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: rgba(6, 153, 135, 0.15);
  }
  &.MuiListItem-root:hover {
    background-color: rgba(6, 153, 135, 0.05);
  }
`

const StyledIconButton = styled(IconButton)`
  height: 35px;
`

const GET_FAXES = gql`
  query getFaxes($direction: String!, $archived: String!) {
    getFaxes(direction: $direction, archived: $archived) {
      id
      practice {
        id
        type
        firstName
        lastName
        centralIntakeName
      }
      faxContact {
        ... on FaxNumber {
          faxNumber
        }
      }
      totalPages
      state
      errorText
      createdAt
      isArchived
      isRead
    }
  }
`

function ReceivedFaxesPage({ status = 'notArchived' }) {
  const { loading, error, data } = useQuery(GET_FAXES, {
    variables: { direction: 'received', archived: status }
  })

  const [faxes, setFaxes] = useState(null)
  const [activeFaxId, setActiveFaxId] = useState('')
  const [activeFaxIndex, setActiveFaxIndex] = useState()
  const [action, setAction] = useState('Triage Referral')

  const history = useHistory()

  useEffect(() => {
    async function formatFaxes() {
      const faxes = await Promise.all(data.getFaxes.map(formatFax))
      setFaxes(faxes)
    }

    function formatFax(fax) {
      return {
        id: fax.id,
        recipientName: getPracticeDisplayName(fax.practice, 'med', 'med'),
        senderName: '',
        senderFaxNumber: fax.faxContact.faxNumber,
        isRead: fax.isRead,
        isArchived: fax.isArchived ? fax.isArchived : false,
        state: '',
        createdAt: new Date(parseInt(fax.createdAt)),
        totalPages: fax.totalPages
      }
    }
    if (data) formatFaxes()
  }, [data])

  useEffect(() => {
    if (faxes?.length === 0) {
      setActiveFaxId('')
      setActiveFaxIndex()
    } else if (faxes?.length > 0) {
      if (activeFaxId === '') {
        setActiveFaxId(faxes[0].id)
        setActiveFaxIndex(0)
      } else if (activeFaxIndex === faxes.length) {
        setActiveFaxId(faxes[activeFaxIndex - 1].id)
        setActiveFaxIndex(activeFaxIndex - 1)
      } else if (faxes.findIndex(fax => fax.id === activeFaxId) === -1) {
        setActiveFaxId(faxes[activeFaxIndex].id)
      }
    }
  }, [faxes, activeFaxId, activeFaxIndex])

  if (error) return <LoadingError />
  if (!faxes || loading) return <Loading />

  function handleSelectFax(fax) {
    const index = faxes.findIndex(f => f.id === fax.id)
    setActiveFaxId(fax.id)
    setActiveFaxIndex(index)
  }

  const handleChange = event => setAction(event.target.value)
  const handleClick = () => {
    if (action === 'Triage Referral') {
      history.push({
        pathname: '/refer',
        search: `?faxId=${activeFaxId}`
      })
    } else if (action === 'Forward Fax') {
      history.push({
        pathname: '/fax/create',
        search: `?faxId=${activeFaxId}`
      })
    } else {
      const senderFaxNumber = faxes.find(
        f => f.id === activeFaxId
      ).senderFaxNumber
      history.push({
        pathname: '/fax/create',
        search: `?faxId=${activeFaxId}&faxNumber=${senderFaxNumber}`
      })
    }
  }

  const actions = [
    { value: 'Triage Referral', label: 'Triage Referral' },
    { value: 'Forward Fax', label: 'Forward Fax' },
    { value: 'Return to Sender', label: 'Return to Sender' }
  ]

  const statusTitle = {
    notArchived: 'Active',
    onlyArchived: 'Archived'
  }

  return (
    <Content>
      <ListContainer>
        <PageTitle>
          Received Faxes <Subtitle>{`- ${statusTitle[status]}`}</Subtitle>
        </PageTitle>
        <FaxList
          faxes={faxes}
          isReceived={true}
          onSelect={handleSelectFax}
          activeFaxId={activeFaxId}
        />
      </ListContainer>
      {activeFaxId !== '' && (
        <StickyContainer archived={status === 'notArchived' ? false : true}>
          {status === 'notArchived' && (
            <BlueBar>
              <ActionLabelContainer>
                <StyledInputLabel id="actions-dropdown-label">
                  Action:
                </StyledInputLabel>
              </ActionLabelContainer>
              <DropdownContainer>
                <StyledSelect
                  select
                  value={action}
                  onChange={handleChange}
                  aria-labelledby="actions-dropdown-label">
                  {actions.map(({ value, label }) => (
                    <StyledMenuItem key={value} value={value}>
                      {label}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
                <StyledIconButton
                  color="secondary"
                  aria-label="Complete action"
                  onClick={handleClick}>
                  <ArrowRightThickIcon />
                </StyledIconButton>
              </DropdownContainer>
            </BlueBar>
          )}
          <PdfContainer>
            <PdfPreview fax={activeFaxId} />
          </PdfContainer>
        </StickyContainer>
      )}
    </Content>
  )
}

ReceivedFaxesPage.propTypes = {
  status: PropTypes.oneOf(['onlyArchived', 'notArchived'])
}

export default ReceivedFaxesPage
