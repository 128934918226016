import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import ArchiveArrowDownIcon from 'mdi-react/ArchiveArrowDownIcon'
import styled from 'styled-components'
import Button, { SecondaryButton, IconButton } from '../common/Button'
import { ErrorMessage } from '../common/UserAlert'

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 500px;
  }
`

const ErrorContainer = styled.div`
  margin: 5px 30px 0;
  div:first-child {
    margin-bottom: 8px;
  }
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  :first-child {
    padding-top: 0px;
  }
`

const Message = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #393e46;
  padding: 30px 30px 0;
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 500px;
  button:last-child {
    margin-left: 15px;
  }
  padding: 30px;
`

const ARCHIVE_FAX = gql`
  mutation archiveFax($fax: ID!) {
    archiveFax(fax: $fax)
  }
`

const GET_FAXES = gql`
  query getFaxes($direction: String!, $archived: String!) {
    getFaxes(direction: $direction, archived: $archived) {
      id
      practice {
        id
        firstName
        lastName
      }
      faxContact {
        ... on FaxNumber {
          faxNumber
        }
        ... on FaxContact {
          id
          name
        }
      }
      totalPages
      state
      errorText
      createdAt
      isArchived
      isRead
    }
  }
`

const GET_ALERTS = gql`
  query getAlerts {
    getAlerts {
      sentReferrals
      receivedReferrals
      sentFaxes
    }
  }
`

function ArchiveFaxButton({ faxId, isReceived = true }) {
  const [mutate, { loading, error }] = useMutation(ARCHIVE_FAX, {
    update(cache) {
      const direction = isReceived ? 'received' : 'sent'

      let fax

      if (
        cache.data.data.ROOT_QUERY?.[
          `getFaxes({"archived":"notArchived","direction":"${direction}"})`
        ]
      ) {
        const { getFaxes } = cache.readQuery({
          query: GET_FAXES,
          variables: { direction, archived: 'notArchived' }
        })

        fax = getFaxes.find(f => f.id === faxId)

        cache.writeQuery({
          query: GET_FAXES,
          variables: { direction, archived: 'notArchived' },
          data: {
            getFaxes: getFaxes.filter(f => f.id !== faxId)
          }
        })
      }

      if (
        cache.data.data.ROOT_QUERY?.[
          `getFaxes({"archived":"onlyArchived","direction":"${direction}"})`
        ]
      ) {
        const { getFaxes } = cache.readQuery({
          query: GET_FAXES,
          variables: { direction, archived: 'onlyArchived' }
        })

        const archivedFax = { ...fax, isArchived: true }

        cache.writeQuery({
          query: GET_FAXES,
          variables: { direction, archived: 'onlyArchived' },
          data: {
            getFaxes: [...getFaxes, archivedFax].sort(
              (a, b) =>
                new Date(parseInt(b.createdAt)) -
                new Date(parseInt(a.createdAt))
            )
          }
        })
      }

      if (
        !isReceived &&
        cache.data.data.ROOT_QUERY?.[`getAlerts`] &&
        fax.state === 'failed'
      ) {
        const { getAlerts } = cache.readQuery({
          query: GET_ALERTS
        })

        cache.writeQuery({
          query: GET_ALERTS,
          data: {
            getAlerts: {
              sentFaxes: getAlerts.sentFaxes - 1,
              sentReferrals: getAlerts.sentReferrals,
              receivedReferrals: getAlerts.receivedReferrals
            }
          }
        })
      }
    }
  })

  const [isVisible, setIsVisible] = useState(false)
  const handleOpen = () => setIsVisible(true)
  const handleClose = () => setIsVisible(false)

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const onArchive = useCallback(async () => {
    setIsVisible(true)
    try {
      await mutate({ variables: { fax: faxId } })
      if (isMounted.current) handleClose()
    } catch (e) {
      console.error('Error archiving fax:', e.message)
    }
  }, [faxId, mutate])

  return (
    <div>
      <IconButton
        color="secondary"
        onClick={handleOpen}
        aria-label="Archive fax">
        <ArchiveArrowDownIcon size={16} />
      </IconButton>
      <StyledDialog
        fullWidth
        open={isVisible}
        onClose={handleClose}
        keepMounted={false}>
        <StyledDialogContent>
          <Message>Are you sure you want to archive this fax?</Message>
          {error && (
            <ErrorContainer>
              <ErrorMessage>Hm, an error occurred. Try again.</ErrorMessage>
            </ErrorContainer>
          )}
          <ButtonContainer>
            <SecondaryButton
              onClick={handleClose}
              disabled={loading}
              padding="small"
              color="secondary">
              Cancel
            </SecondaryButton>
            <Button onClick={onArchive} loading={loading} padding="small">
              Archive
            </Button>
          </ButtonContainer>
        </StyledDialogContent>
      </StyledDialog>
    </div>
  )
}

ArchiveFaxButton.propTypes = {
  faxId: PropTypes.string.isRequired,
  isReceived: PropTypes.bool
}

export default ArchiveFaxButton
