import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import useAccount from '../../hooks/useAccount'
import {
  deriveAesKey,
  unwrapEccKey,
  unwrapAesKey,
  decryptObject,
  importPublicKey
} from '../../crypto'
import { sortJourneyDatesByDate } from '../../util/sort'
import { capitalize } from '../../util/strings'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import ReferralView from '../refer/ReferralView'

const GET_REFERRAL_PAGE_DATA = gql`
  query getReferralPageData($id: ID!, $referral: ID!) {
    getReferral(id: $id) {
      id
      createdAt
      region
      urgency
      gender
      category
      referralDate
      encryptedData {
        iv
        encryptedData
      }
      state
      type
      sender {
        publicKey
        encryptionKey {
          iv
          encryptedData
        }
        practice {
          id
          firstName
          lastName
          msp
          centralIntakeName
          clinicName
          phone
          faxNumber
          type
          triageResponseActiveDate
          memberships {
            privateKey {
              encryptedData
              iv
            }
            publicKey
            user {
              id
            }
          }
        }
      }
      recipient {
        publicKey
        encryptionKey {
          iv
          encryptedData
        }
        practice {
          id
          region
          firstName
          lastName
          msp
          clinicName
          phone
          specialty
          emrSyncActiveDate
          memberships {
            privateKey {
              encryptedData
              iv
            }
            publicKey
            user {
              id
            }
          }
        }
      }
      files {
        iv
        key
      }
      initialFax {
        id
        faxContact {
          ... on FaxNumber {
            faxNumber
          }
        }
      }
      faxToEmr {
        id
        state
        errorText
      }
      triageFax {
        id
        state
        errorText
      }
      referringProvider {
        id
        name
        location
        faxNumber
      }
      redirectedFrom
      redirectedTo
    }
    getReferralEvents(referral: $referral) {
      id
      type
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      data {
        ... on Referral {
          sender {
            practice {
              type
              centralIntakeName
              firstName
              lastName
            }
          }
          recipient {
            practice {
              firstName
              lastName
            }
          }
        }
        ... on OriginalReferralSentEvent {
          sender {
            practice {
              type
              centralIntakeName
              firstName
              lastName
            }
          }
          recipient {
            practice {
              firstName
              lastName
            }
          }
        }
        ... on ReferralAcceptedEvent {
          id
        }
        ... on ReferralDeclinedEvent {
          reason
          description
        }
        ... on ReferralRedirectedEvent {
          originalReferral {
            id
            recipient {
              practice {
                firstName
                lastName
              }
            }
          }
          newReferral {
            id
            recipient {
              practice {
                firstName
                lastName
                phone
                msp
              }
            }
          }
        }
        ... on CategoryChangedEvent {
          category
          initialCategory
        }
        ... on UrgencyChangedEvent {
          urgency
          initialUrgency
        }
        ... on MessageSentEvent {
          message {
            iv
            encryptedData
          }
        }
        ... on FileAddedEvent {
          file {
            iv
            key
          }
          encryptedData {
            encryptedData
            iv
          }
        }
      }
    }
  }
`

const GET_REFERRAL_JOURNEY_DATES = gql`
  query getReferralJourneyDates($referral: ID!) {
    getReferralJourneyDates(referral: $referral) {
      id
      referral
      type
      date
      billingCode
    }
  }
`

function ReferralViewPage() {
  const { id } = useParams()
  const { loading, error, data } = useQuery(GET_REFERRAL_PAGE_DATA, {
    variables: { id, referral: id }
  })
  const [getReferralJourneyDates, getReferralJourneyDatesResult] = useLazyQuery(
    GET_REFERRAL_JOURNEY_DATES
  )

  const [referral, setReferral] = useState(null)
  const [events, setEvents] = useState(null)
  const [journeyDates, setJourneyDates] = useState(null)
  const [isRecipient, setIsRecipient] = useState(false)
  const [isSender, setIsSender] = useState(false)
  const { getAccount } = useAccount()

  useEffect(() => {
    async function decryptReferral() {
      const account = await getAccount()
      const referral = data.getReferral

      const recipientMembership = referral.recipient.practice.memberships.find(
        m => m.user.id === account.id
      )
      const senderMembership = referral.sender.practice.memberships.find(
        m => m.user.id === account.id
      )

      if (recipientMembership) setIsRecipient(true)
      if (senderMembership) setIsSender(true)

      const participant = recipientMembership
        ? referral.recipient
        : referral.sender
      const membership = recipientMembership || senderMembership

      const membershipAesKey = await deriveAesKey(
        account.privateKey,
        await importPublicKey(membership.publicKey)
      )

      const practicePrivateKey = await unwrapEccKey(
        membership.privateKey.encryptedData,
        membershipAesKey,
        membership.privateKey.iv,
        true
      )

      const referralAesKey = await deriveAesKey(
        practicePrivateKey,
        await importPublicKey(participant.publicKey)
      )

      const encryptionKey = await unwrapAesKey(
        participant.encryptionKey.encryptedData,
        referralAesKey,
        participant.encryptionKey.iv,
        'enc'
      )

      const decryptedEvents = await Promise.all(
        data.getReferralEvents.map(async event => {
          if (event.type === 'message_sent') {
            const message = await decryptObject(
              event.data.message.encryptedData,
              encryptionKey,
              event.data.message.iv
            )
            return {
              ...event,
              data: { ...event.data, decryptedMessage: message.msg }
            }
          }
          return event
        })
      )

      const encryptedData = await decryptObject(
        referral.encryptedData.encryptedData,
        encryptionKey,
        referral.encryptedData.iv
      )

      setEvents(decryptedEvents)

      setReferral({
        id: referral.id,
        type: referral.type,
        state: referral.state,
        firstName: capitalize(encryptedData.firstName),
        lastName: capitalize(encryptedData.lastName),
        gender: referral.gender,
        fileNames: encryptedData.fileNames,
        message: encryptedData.message,
        phn: encryptedData.phn,
        phone: encryptedData.phone,
        address: encryptedData.address,
        city: encryptedData.city,
        province: encryptedData.province,
        postalCode: encryptedData.postalCode,
        email: encryptedData.email,
        urgency: referral.urgency,
        category: referral.category,
        birthDate: new Date(encryptedData.birthDate),
        referralDate: new Date(parseInt(referral.referralDate)),
        createdAt: new Date(parseInt(referral.createdAt)),
        files: referral.files,
        initialFax: referral.initialFax,
        faxToEmr: referral.faxToEmr,
        triageFax: referral.triageFax,
        referringProvider: referral.referringProvider,
        redirectedFrom: referral.redirectedFrom,
        redirectedTo: referral.redirectedTo
      })
    }
    if (data) decryptReferral()
  }, [data, getAccount])

  useEffect(() => {
    if (id && isRecipient) {
      getReferralJourneyDates({
        variables: { referral: id }
      })
    }
  }, [isRecipient, id, getReferralJourneyDates])

  useEffect(() => {
    function formatJourneyDates() {
      const formattedJourneyDates =
        getReferralJourneyDatesResult.data.getReferralJourneyDates.map(d => {
          return {
            ...d,
            date: new Date(parseInt(d.date))
          }
        })

      const sortedDates = formattedJourneyDates.sort(sortJourneyDatesByDate)
      setJourneyDates(sortedDates)
    }

    if (getReferralJourneyDatesResult?.data) formatJourneyDates()
  }, [getReferralJourneyDatesResult])

  const refreshJourneyDates = () => getReferralJourneyDatesResult.refetch()

  if (error) return <LoadingError />
  if (loading || !referral) return <Loading />
  if (isRecipient && !journeyDates) return <Loading />

  // TODO: pull this from the referral?
  referral.region = data.getReferral.recipient.practice.region

  return (
    <ReferralView
      referral={referral}
      sender={{
        ...data.getReferral.sender,
        practice: {
          ...data.getReferral.sender.practice,
          triageResponseActiveDate: data.getReferral.sender.practice
            .triageResponseActiveDate
            ? new Date(
                parseInt(
                  data.getReferral.sender.practice.triageResponseActiveDate
                )
              )
            : null
        }
      }}
      recipient={{
        ...data.getReferral.recipient,
        practice: {
          ...data.getReferral.recipient.practice,
          emrSyncActiveDate: data.getReferral.recipient.practice
            .emrSyncActiveDate
            ? new Date(
                parseInt(data.getReferral.recipient.practice.emrSyncActiveDate)
              )
            : null
        }
      }}
      isRecipient={isRecipient}
      isSender={isSender}
      events={events}
      journeyDates={journeyDates}
      refreshJourneyDates={refreshJourneyDates}
    />
  )
}

ReferralViewPage.propTypes = {
  referral: PropTypes.string
}

export default ReferralViewPage
