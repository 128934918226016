import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import useAccount from '../../hooks/useAccount'
import { useQuery } from '@apollo/client'
import {
  deriveAesKey,
  unwrapEccKey,
  unwrapAesKey,
  decryptObject,
  importPublicKey
} from '../../crypto'

import { PageTitle } from '../common/Title'
import NotificationList from '../notifications/NotificationList'
import LoadingError from '../common/LoadingError'
import Loading from '../common/Loading'

const Header = styled.div`
  display: flex;
  width: 100%;
`

const GET_NOTIFICATION_PAGE_DATA = gql`
  query getNotificationPageData {
    getCurrentUser {
      id
    }
    getNotifications {
      id
      isDismissed
      referral {
        id
        encryptedData {
          iv
          encryptedData
        }
        state
        sender {
          publicKey
          encryptionKey {
            iv
            encryptedData
          }
          practice {
            id
            firstName
            lastName
            type
            centralIntakeName
            memberships {
              privateKey {
                encryptedData
                iv
              }
              publicKey
              user {
                id
              }
            }
          }
        }
        recipient {
          publicKey
          encryptionKey {
            iv
            encryptedData
          }
          practice {
            id
            firstName
            lastName
            type
            memberships {
              privateKey {
                encryptedData
                iv
              }
              publicKey
              user {
                id
              }
            }
          }
        }
        files {
          iv
          key
        }
      }
      event {
        id
        type
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        data {
          ... on Referral {
            category
            urgency
          }
          ... on ReferralAcceptedEvent {
            id
          }
          ... on ReferralDeclinedEvent {
            reason
          }
          ... on ReferralRedirectedEvent {
            newReferral {
              id
              category
              urgency
              recipient {
                practice {
                  firstName
                  lastName
                }
              }
            }
          }
          ... on CategoryChangedEvent {
            category
            initialCategory
          }
          ... on UrgencyChangedEvent {
            urgency
            initialUrgency
          }
          ... on MessageSentEvent {
            message {
              iv
              encryptedData
            }
          }
          ... on FileAddedEvent {
            file {
              iv
              key
            }
            encryptedData {
              encryptedData
              iv
            }
          }
        }
      }
    }
  }
`

function NotificationsPage() {
  const { loading, error, data } = useQuery(GET_NOTIFICATION_PAGE_DATA)
  const [notifications, setNotifications] = useState(null)
  const { getAccount } = useAccount()

  useEffect(() => {
    async function decryptNotifications() {
      const notifications = await Promise.all(
        data.getNotifications.map(decryptNotification)
      )
      setNotifications(notifications.filter(n => n !== null))
    }

    async function decryptNotification(notification) {
      const currentUser = data.getCurrentUser.id
      const referral = notification.referral

      const recipientMembership = referral.recipient.practice.memberships.find(
        m => m.user.id === currentUser
      )
      const senderMembership = referral.sender.practice.memberships.find(
        m => m.user.id === currentUser
      )

      const participant = recipientMembership
        ? referral.recipient
        : referral.sender

      const membership = recipientMembership || senderMembership

      // Ignore ones we can't decrypt
      if (!membership) return null

      const account = await getAccount()

      const membershipAesKey = await deriveAesKey(
        account.privateKey,
        await importPublicKey(membership.publicKey)
      )

      const practicePrivateKey = await unwrapEccKey(
        membership.privateKey.encryptedData,
        membershipAesKey,
        membership.privateKey.iv,
        true
      )

      const referralAesKey = await deriveAesKey(
        practicePrivateKey,
        await importPublicKey(participant.publicKey)
      )

      const encryptionKey = await unwrapAesKey(
        participant.encryptionKey.encryptedData,
        referralAesKey,
        participant.encryptionKey.iv,
        'enc'
      )

      const encryptedReferralData = await decryptObject(
        referral.encryptedData.encryptedData,
        encryptionKey,
        referral.encryptedData.iv
      )

      if (notification.event.type === 'message_sent') {
        const message = await decryptObject(
          notification.event.data.message.encryptedData,
          encryptionKey,
          notification.event.data.message.iv
        )

        return {
          ...notification,
          referral: {
            ...notification.referral,
            firstName: encryptedReferralData.firstName,
            lastName: encryptedReferralData.lastName,
            fileNames: encryptedReferralData.fileNames
          },
          event: {
            ...notification.event,
            data: {
              ...notification.event.data,
              decryptedMessage: message.msg
            },
            createdAt: new Date(parseInt(notification.event.createdAt))
          }
        }
      }

      return {
        ...notification,
        referral: {
          ...notification.referral,
          firstName: encryptedReferralData.firstName,
          lastName: encryptedReferralData.lastName,
          fileNames: encryptedReferralData.fileNames
        },
        event: {
          ...notification.event,
          createdAt: new Date(parseInt(notification.event.createdAt))
        }
      }
    }

    if (data) decryptNotifications()
  }, [data, getAccount])

  if (error) return <LoadingError />
  if (loading || !notifications) return <Loading />

  return (
    <>
      <Header>
        <PageTitle>Notifications</PageTitle>
      </Header>
      <NotificationList notifications={notifications} />
    </>
  )
}

export default NotificationsPage
