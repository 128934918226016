import React, { useState } from 'react'
import { PivotViewComponent } from '@syncfusion/ej2-react-pivotview'
import { Tooltip as TableTooltip } from '@syncfusion/ej2-popups'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import ArrowExpandAllIcon from 'mdi-react/ArrowExpandAllIcon'
import ArrowCollapseAllIcon from 'mdi-react/ArrowCollapseAllIcon'
import FileExcelIcon from 'mdi-react/FileExcelIcon'
import FileDelimitedIcon from 'mdi-react/FileDelimitedIcon'

import { SectionTitle } from '../common/Title'
import { IconButton } from '../common/Button'
import styled from 'styled-components'

import { Inject, Group, Sort, Resize } from '@syncfusion/ej2-react-grids'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h1:only-child {
    margin-left: auto;
    margin-right: auto;
  }
`

const ButtonContainer = styled.div`
  margin: 25px 0 15px;
  display: flex;
  gap: 10px;
`

const Placeholder = styled.div`
  width: 80px;
`

const PivotTableTitle = styled(SectionTitle)`
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
`

const styling = `
  .e-pivotview .e-headertext {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 13px;
  }

  .e-pivotview .e-grid .e-rowcell {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .e-pivotview .e-columnsheader {
    background-color: rgba(17,147,173,0.2) !important;
  }

  .e-pivotview .e-rowsheader .e-cellvalue {
    font-weight: 600;
  }

  .e-pivotview .e-grid .e-columnsheader {
    padding-left: 21px !important;
  }

  .e-pivotview .e-rowsheader {
    background-color: rgba(17,147,173,0.2) !important;
  }

  .e-pivotview .e-headercelldiv {
    text-align: center;
  }

  .e-pivotview .e-gtot  {
    background-color: rgba(58,58,58,0.1) !important;
    font-weight: bold;
  }

  .e-pivotview .e-valuescontent {
    text-align: center;
  }

  .e-grid .e-rowcell:last-child {
    padding: 0 8px;
  }

  .e-grid .e-sortfilterdiv {
    padding: 2px 3px 3px !important;
  }
`

function PivotTable({
  dataSourceSettings,
  title,
  handleClick,
  visibleRows = 5,
  expandable = false,
  downloadable = false
}) {
  const clickable = handleClick ? true : false
  const [expandAll, setExpandAll] = useState(dataSourceSettings.expandAll)

  let headerTooltip
  let pivotObj

  function beforeRender(args) {
    this.content = args.target.querySelector('.e-cellvalue')
      ? args.target.querySelector('.e-cellvalue').innerText
      : args.target.querySelector('.e-headertext')
      ? args.target.querySelector('.e-headertext').innerText
      : args.target.querySelector('.e-stackedheadercelldiv')
      ? args.target.querySelector('.e-stackedheadercelldiv').innerText
      : ''
  }

  const settings = {
    headerCellInfo: args => {
      if (args.node.innerText === 'Grand Total') {
        args.node.children[0].innerText = 'Total'
      }
      if (title === 'Returned Reasons') {
        if (args.node.children[0].innerText === 'Total Sum of reason') {
          args.node.children[0].innerText = 'Total'
        }
      }
    },
    queryCellInfo: args => {
      if (title === '') {
        if (args.cell.className === 'e-rowcell e-rowsheader') {
          if (Number(args.cell.innerText) < 12) {
            args.cell.children[2].innerText = Intl.DateTimeFormat('en', {
              month: 'long'
            }).format(new Date(`${Number(args.cell.innerText) + 1}`))
          }
        }
      }
      if (args.cell.innerText === 'Grand Total') {
        args.cell.children[0].innerText = 'Total'
      }
      if (args.data[0].actualText === 'Overall') {
        args.cell.children[0].style.fontWeight = '700'
      }
    },
    columnRender: args => {
      if (title === 'Returned Reasons') {
        args.columns[0].width = 525
        args.columns[1].width = 200
      }
    }
  }

  const handleExpandAll = () => setExpandAll(true)
  const handleCollapseAll = () => setExpandAll(false)

  const handleExportToExcel = () => pivotObj.excelExport()
  const handleExportToCsv = () => pivotObj.csvExport()

  const TooltipContent = exportTo => (
    <>
      <p>Export to {exportTo}</p>
      <p>
        Note: Only visible rows will be included in the export file. To include
        all the data in the table, first expand the table.
      </p>
    </>
  )

  return (
    <div>
      <style>{styling}</style>
      <Header>
        {expandable ? (
          <ButtonContainer>
            <Tooltip title="Expand All" arrow>
              <div>
                <IconButton
                  color="secondary"
                  onClick={handleExpandAll}
                  aria-label="Expand All">
                  <ArrowExpandAllIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title="Collapse All" arrow>
              <div>
                <IconButton
                  color="secondary"
                  onClick={handleCollapseAll}
                  aria-label="Collapse All">
                  <ArrowCollapseAllIcon />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonContainer>
        ) : (
          downloadable && <Placeholder />
        )}
        <PivotTableTitle
          onClick={handleClick ? handleClick : () => {}}
          clickable={clickable}>
          {title}
        </PivotTableTitle>
        {downloadable ? (
          <ButtonContainer>
            <Tooltip
              title={expandable ? TooltipContent('Excel') : 'Export to Excel'}
              arrow>
              <div>
                <IconButton
                  color="secondary"
                  onClick={handleExportToExcel}
                  aria-label="Export to Excel">
                  <FileExcelIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip
              title={expandable ? TooltipContent('CSV') : 'Export to CSV'}
              arrow>
              <div>
                <IconButton
                  color="secondary"
                  onClick={handleExportToCsv}
                  aria-label="Export to CSV">
                  <FileDelimitedIcon />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonContainer>
        ) : (
          expandable && <Placeholder />
        )}
      </Header>
      <PivotViewComponent
        ref={d => (pivotObj = d)}
        dataSourceSettings={{
          emptyCellsTextContent: ['0'],
          showHeaderWhenEmpty: false,
          valueSortSettings: { headerDelimiter: '##' },
          ...dataSourceSettings,
          expandAll
        }}
        aggregateCellInfo={function (args) {
          if (args.aggregateType === 'Sum') {
            args.value =
              args.value !== undefined
                ? args.value.toLocaleString()
                : args.value
          }
        }}
        allowExcelExport={true}
        gridSettings={settings}
        showTooltip={false}
        width="100%"
        dataBound={function () {
          if (!headerTooltip) {
            const headerTooltip = new TableTooltip({
              target: 'td.e-rowsheader',
              beforeRender
            })
            headerTooltip.appendTo(this.element)
          }
        }}
        enginePopulated={function (args) {
          let columnCount = 0
          let rowCount = 0
          if (args.pivotValues != null) {
            for (let i = 0; i < args.pivotValues.length; i++) {
              if (args.pivotValues[i] && !args.pivotValues[i][0]) {
                columnCount++
              } else {
                rowCount++
              }
              this.height =
                (rowCount <= visibleRows ? rowCount : visibleRows) *
                  this.gridSettings.rowHeight +
                columnCount * this.gridSettings.rowHeight +
                (this.showToolbar
                  ? this.element.querySelector('.e-pivot-toolbar').offsetHeight
                  : 0) +
                visibleRows // here height is set based on the no. of rows to show in the pivot table.
            }
          }
        }}
        enableValueSorting={true}>
        <Inject services={[Group, Sort, Resize]} />
      </PivotViewComponent>
    </div>
  )
}

PivotTable.propTypes = {
  dataSourceSettings: PropTypes.object.isRequired,
  title: PropTypes.string,
  handleClick: PropTypes.func,
  visibleRows: PropTypes.number,
  expandable: PropTypes.bool,
  downloadable: PropTypes.bool
}

export default PivotTable
