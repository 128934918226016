import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SecondaryButton } from '../common/Button'
import { SectionTitle } from '../common/Title'
import ReferralDuplicateView from './ReferralDuplicateView'
import ReferralTable from '../common/ReferralTable'
import { useHistory } from 'react-router-dom'
import InformationIcon from 'mdi-react/InformationIcon'

const StyledSectionTitle = styled(SectionTitle)`
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(58, 58, 58, 0.1);
`

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const Message = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(58, 58, 58, 0.7);
  margin: 0 0 33px;
`

const Bold = styled.span`
  font-weight: 600;
`

const ReferralListContainer = styled.div`
  max-height: calc(100vh - 325px);
  overflow: scroll;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 15px;
  }
  padding: 15px 0 0px;
`

const ButtonContainer = styled(ButtonsContainer)`
  padding-bottom: 30px;
`

const AlertContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0;
  padding-bottom: 30px;
`

const InfoAlert = styled.p`
  font-style: italic;
  font-size: 12px;
  line-height: 15px;
  color: #4ca1d8;
  margin: 5px;
`

function ReferralDuplicates({
  duplicateReferrals,
  fax,
  phn,
  handleClose = () => {},
  handlePopulate = () => {}
}) {
  const [activeReferral, setActiveReferral] = useState(duplicateReferrals[0])

  const handleSelectReferral = referral => setActiveReferral(referral)

  const history = useHistory()

  const faxId = fax ? fax.id : null

  const handleReturn = () => {
    history.push(
      `/fax/create?faxId=${fax.id}&faxNumber=${
        fax.faxContact.faxNumber
      }&isDuplicate=${true}`
    )
  }

  const handleForward = () => {
    history.push(`/fax/create?faxId=${fax.id}&isDuplicate=${true}`)
  }

  return (
    <>
      <StyledSectionTitle>Possible Duplicate Referrals</StyledSectionTitle>
      <Container>
        <FlexContainer>
          <Message>
            {duplicateReferrals.length > 1
              ? `There are ${duplicateReferrals.length} referrals that match the PHN `
              : `There is ${duplicateReferrals.length} referral that matches the PHN `}
            <Bold>{phn}</Bold>
          </Message>
          <ReferralListContainer>
            <ReferralTable
              referrals={duplicateReferrals}
              type="condensed"
              activeReferralId={activeReferral.id}
              onSelect={handleSelectReferral}
            />
          </ReferralListContainer>
        </FlexContainer>
        <FlexContainer>
          {activeReferral && (
            <ReferralDuplicateView referral={activeReferral} />
          )}
        </FlexContainer>
      </Container>
      {faxId ? (
        <>
          <ButtonsContainer>
            <SecondaryButton
              onClick={handleReturn}
              padding="small"
              color="secondary">
              Return to Sender
            </SecondaryButton>
            <SecondaryButton
              onClick={handleForward}
              padding="small"
              color="secondary">
              Forward to Specialist
            </SecondaryButton>
            <SecondaryButton
              onClick={() => handlePopulate(activeReferral)}
              padding="small"
              color="secondary">
              Populate Patient Info
            </SecondaryButton>
            <SecondaryButton
              onClick={handleClose}
              padding="small"
              color="secondary">
              Continue
            </SecondaryButton>
          </ButtonsContainer>
          <AlertContainer>
            <InformationIcon size={15} style={{ color: '#4ca1d8' }} />
            <InfoAlert>
              This action will be performed on the referral that is in progress
            </InfoAlert>
          </AlertContainer>
        </>
      ) : (
        <ButtonContainer>
          <SecondaryButton
            onClick={() => handlePopulate(activeReferral)}
            padding="small"
            color="secondary">
            Populate Patient Info
          </SecondaryButton>
          <SecondaryButton
            onClick={handleClose}
            padding="small"
            color="secondary">
            Continue
          </SecondaryButton>
        </ButtonContainer>
      )}
    </>
  )
}

ReferralDuplicates.propTypes = {
  duplicateReferrals: PropTypes.array.isRequired,
  fax: PropTypes.object,
  phn: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  handlePopulate: PropTypes.func
}

export default ReferralDuplicates
