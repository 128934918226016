import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { CircularProgress } from '@material-ui/core'

import { SectionTitle } from '../common/Title'
import { ErrorMessage } from '../common/UserAlert'
import {
  StyledSwitch,
  StyledLabel,
  SelectorLabel
} from '../common/StyledSwitch'

const Content = styled.div`
  padding: 0 21px 30px 30px;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
  margin-right: 9px;
`

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`

const LoadingSpinner = styled(CircularProgress)`
  color: #069987;
`

const InfoText = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: rgba(58, 58, 58, 0.7);
  margin: 30px 0 0;
  padding-bottom: 8px;
`

const Strong = styled.strong`
  font-weight: 600;
`

const UPDATE_EMAIL_PREFERENCES = gql`
  mutation updateEmailPreferences($type: String!, $value: Boolean!) {
    updateEmailPreferences(type: $type, value: $value) {
      id
    }
  }
`

const EmailPreferences = ({ preferences }) => {
  const [mutate, { loading, error }] = useMutation(UPDATE_EMAIL_PREFERENCES)

  const [emailPreferences, setEmailPreferences] = useState(preferences)

  async function handleChange(event) {
    const name = event.target.name
    const checked = event.target.checked

    try {
      await mutate({
        variables: {
          type: name,
          value: checked
        }
      })
      if (!error) {
        setEmailPreferences({
          ...emailPreferences,
          [name]: checked
        })
      }
    } catch (e) {
      console.error('Error unsubscribing', e.message)
    }
  }

  return (
    <Content>
      <SectionTitle>Email Preferences</SectionTitle>
      <Divider />
      {error && <ErrorMessage />}
      {loading && (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
      <InfoText>
        Receive emails regarding <Strong>referral updates</Strong> when:
      </InfoText>
      <StyledLabel
        value="start"
        control={
          <StyledSwitch
            checked={emailPreferences.newReferrals}
            onChange={handleChange}
            name="newReferrals"
          />
        }
        label={<SelectorLabel>A referral is received</SelectorLabel>}
        labelPlacement="start"
      />
      <StyledLabel
        value="start"
        control={
          <StyledSwitch
            checked={emailPreferences.acceptedReferrals}
            onChange={handleChange}
            name="acceptedReferrals"
          />
        }
        label={<SelectorLabel>A referral is accepted</SelectorLabel>}
        labelPlacement="start"
      />
      <StyledLabel
        value="start"
        control={
          <StyledSwitch
            checked={emailPreferences.declinedReferrals}
            onChange={handleChange}
            name="declinedReferrals"
          />
        }
        label={<SelectorLabel>A referral is declined</SelectorLabel>}
        labelPlacement="start"
      />
      <StyledLabel
        value="start"
        control={
          <StyledSwitch
            checked={emailPreferences.newMessages}
            onChange={handleChange}
            name="newMessages"
          />
        }
        label={
          <SelectorLabel>A new message is added to a referral</SelectorLabel>
        }
        labelPlacement="start"
      />
      <StyledLabel
        value="start"
        control={
          <StyledSwitch
            checked={emailPreferences.newFiles}
            onChange={handleChange}
            name="newFiles"
          />
        }
        label={
          <SelectorLabel>A new file is attached to a referral</SelectorLabel>
        }
        labelPlacement="start"
      />
      <InfoText>
        Receive <Strong>informational</Strong> emails for:
      </InfoText>
      <StyledLabel
        value="start"
        control={
          <StyledSwitch
            checked={emailPreferences.promotions}
            onChange={handleChange}
            name="promotions"
          />
        }
        label={<SelectorLabel>Promotions and newsletters</SelectorLabel>}
        labelPlacement="start"
      />
    </Content>
  )
}

EmailPreferences.propTypes = {
  preferences: PropTypes.shape({
    declinedReferrals: PropTypes.bool.isRequired,
    acceptedReferrals: PropTypes.bool.isRequired,
    newReferrals: PropTypes.bool.isRequired,
    newMessages: PropTypes.bool.isRequired,
    newFiles: PropTypes.bool.isRequired,
    promotions: PropTypes.bool.isRequired
  }).isRequired
}

export default EmailPreferences
