export default [
  '#18454E',
  '#22616D',
  '#1193AD',
  '#07B09D',
  '#057668',
  '#023B34',
  '#D4520C',
  '#F3712B',
  '#F58B51',
  '#F9C977',
  '#F8BD4F',
  '#EB9C0A',
  '#7F1031',
  '#A3143F',
  '#DE235B',
  '#8F8F8F',
  '#3A3A3A',
  '#5C5C5C'
]
