import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import AlarmIcon from 'mdi-react/AlarmIcon'
import SiteMapIcon from 'mdi-react/SitemapIcon'

import { ReactComponent as DoctorIcon } from '../../images/Vectordoctor.svg'
import { getSubcategoryName, getCategoryName } from '../../util/categories'
import { DateIndicator, TimeIndicator } from './DateTimeIndicators'
import StatusIndicator from './StatusIndicator'
import IconStatusIndicator from './IconStatusIndicator'
import ResendFaxFromReferralButton from '../faxing/ResendFaxFromReferralButton'
import FaxFromReferralButton from '../faxing/FaxFromReferralButton'

function textColor(urgency) {
  if (urgency === 'Urgent') return 'rgba(97, 26, 21, 0.7)'
  if (urgency === 'Semi-urgent') return 'rgba(117, 75, 13, 0.7)'
  return 'rgba(30, 70, 32, 0.7)'
}

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => (props.margin === 'small' ? '10px' : '15px')};
`

const FlexContainer = styled.div`
  display: flex;
  gap: 15px;
`

const ReferralInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border-radius: 3px;

  background: ${props =>
    props.isActive ? 'rgba(17, 147, 173, 0.05)' : 'white'};
  border-left: ${props =>
    props.isActive ? '3px solid #1193ad' : '3px solid transparent'};

  color: rgba(58, 58, 58, 0.7);
  font-size: 14px;

  :hover {
    background: rgba(17, 147, 173, 0.05);
    border-left: 3px solid #1193ad;
  }

  svg {
    width: 15px;
  }
`

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 15px;
`

const CenterFlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`

const CenterFlexBordered = styled(CenterFlexContainer)`
  border-top: 1px solid rgba(17, 147, 173, 0.05);
`

const SpaceBetweenLine = styled(CenterFlexContainer)`
  justify-content: space-between;
  padding: 0 25px;
`

const SpaceBetweenBorderedLine = styled(SpaceBetweenLine)`
  border-top: 1px solid rgba(17, 147, 173, 0.05);
`

const LeftContainer = styled(CenterFlexContainer)`
  width: ${props => (props.hasButtons ? 'calc(35% + 17.5px)' : '35%')};
  padding-left: 30px;
`

const ShortContainer = styled(CenterFlexContainer)`
  width: ${props => (props.hasButtons ? 'calc(17.5% + 8.75px)' : '17.5%')};
  padding-left: 30px;
  border-right: 1px solid rgba(17, 147, 173, 0.05);
`

const CenterContainer = styled(CenterFlexContainer)`
  width: ${props => (props.hasButtons ? 'calc(52% - 17.5px)' : '52%')};
  padding-left: 30px;
`

const RightContainer = styled.div`
  width: 13%;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
`

const PatientName = styled.p`
  font-size: 18px;
  color: rgba(17, 147, 173, 1);
`

const PHN = styled.p`
  font-weight: 600;
  color: rgba(58, 58, 58, 1);
  margin-left: 3px;
`

const Urgency = styled.p`
  color: ${props => textColor(props.urgency)};
`

const ResendContainer = styled.div`
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 35px;
`

const MultiResendContainer = styled(ResendContainer)`
  row-gap: 5px;
  margin-bottom: ${props => (props.position === 'center' ? '0' : '2px')};
  justify-content: ${props =>
    props.position === 'center' ? 'center' : 'flex-end'};
`

const DoctorIconBox = styled(DoctorIcon)`
  margin-right: 15px;
`

const Arrow = styled(ArrowRightIcon)`
  height: 15px;
  margin: 0px 10px 0px 10px;
`

const UrgencyIcon = styled(AlarmIcon)`
  height: 15px;
  margin-right: 15px;
`

const CategoryIcon = styled(SiteMapIcon)`
  margin-right: 15px;
`

export function hasFailedFax(type, referral) {
  if (type === 'emr') {
    return (
      referral.toPractice.emrSyncActiveDate &&
      referral.createdAt > referral.toPractice.emrSyncActiveDate &&
      !referral.faxToEmr
    )
  } else if (type === 'triage') {
    return (
      referral.fromPractice.triageResponseActiveDate &&
      referral.createdAt > referral.fromPractice.triageResponseActiveDate &&
      !referral.triageFax &&
      referral.initialFax
    )
  }
}

function ReferralTable({
  referrals = [],
  practices = [],
  type = 'full',
  activeReferralId,
  onUpdateReferralFax = () => {},
  onSelect = () => {}
}) {
  const history = useHistory()

  const isCondensed = type === 'condensed'

  const containsFaxButtons = !isCondensed
    ? referrals.filter(
        r =>
          r.faxToEmr?.state === 'failed' ||
          hasFailedFax('emr', r) ||
          r.triageFax?.state === 'failed' ||
          hasFailedFax('triage', r)
      ).length > 0
    : false

  const containsFaxingToEmr = !isCondensed
    ? referrals.filter(r => r.faxToEmr?.state || hasFailedFax('emr', r))
        .length > 0
    : false

  const containsTriageFaxing = !isCondensed
    ? referrals.filter(r => r.triageFax?.state || hasFailedFax('triage', r))
        .length > 0
    : false

  if (type === 'full' && containsFaxingToEmr && containsTriageFaxing)
    type = 'expanded'

  function determineButtonPosition(referral) {
    const hasEmrButton =
      referral.faxToEmr?.state === 'failed' || hasFailedFax('emr', referral)

    const hasTriageButton =
      referral.triageFax?.state === 'failed' || hasFailedFax('triage', referral)

    if (hasEmrButton && !hasTriageButton) return 'center'
    return 'bottom'
  }

  function resendButtons(referral, type) {
    const fax = type === 'emr' ? referral.faxToEmr : referral.triageFax
    const faxingOnPractice =
      type === 'emr'
        ? hasFailedFax('emr', referral)
        : hasFailedFax('triage', referral)

    return (
      <>
        {fax?.state === 'failed' && (
          <ResendFaxFromReferralButton
            faxId={fax.id}
            type="icon"
            onResendFax={onUpdateReferralFax}
            isSender={practices
              .map(p => p.id)
              .includes(referral.fromPractice.id)}
            isRecipient={practices
              .map(p => p.id)
              .includes(referral.toPractice.id)}
          />
        )}
        {faxingOnPractice && !fax?.state && (
          <FaxFromReferralButton
            type="icon"
            referral={referral}
            destination={type}
            practices={practices}
            onSendFax={onUpdateReferralFax}
          />
        )}
      </>
    )
  }

  return (
    <div>
      {referrals.length > 0 &&
        referrals.map((referral, i, arr) => {
          const previous = arr[i - 1]

          return (
            <Row
              key={referral.id}
              margin={type === 'condensed' ? 'med' : 'small'}>
              {i === 0 ||
              (previous &&
                previous.createdAt.getDate() !==
                  referral.createdAt.getDate()) ? (
                <DateIndicator
                  dateTime={referral.createdAt}
                  withYear={type === 'condensed' ? true : false}
                />
              ) : null}
              {(type === 'expanded' || type === 'full') && (
                <FlexContainer>
                  <TimeContainer>
                    <TimeIndicator dateTime={referral.createdAt} />
                  </TimeContainer>
                  <ReferralInfo
                    onClick={() => history.push(`/referrals/${referral.id}`)}>
                    <CenterFlexContainer>
                      <LeftContainer hasButtons={containsFaxButtons}>
                        <PatientName>{referral.patient}</PatientName>
                      </LeftContainer>
                      <CenterContainer hasButtons={containsFaxButtons}>
                        <DoctorIconBox />
                        {referral.fromPractice.name}
                        <Arrow />
                        {referral.toPractice.name}
                      </CenterContainer>
                      <RightContainer>
                        <StatusIndicator state={referral.state} />
                      </RightContainer>
                    </CenterFlexContainer>
                    <CenterFlexBordered>
                      {type === 'full' ? (
                        <>
                          <ShortContainer hasButtons={containsFaxButtons}>
                            PHN
                            <PHN>{referral.phn}</PHN>
                          </ShortContainer>
                          <ShortContainer hasButtons={containsFaxButtons}>
                            <UrgencyIcon />
                            <Urgency urgency={referral.urgency}>
                              {referral.urgency}
                            </Urgency>
                          </ShortContainer>
                        </>
                      ) : (
                        <LeftContainer hasButtons={containsFaxButtons}>
                          PHN
                          <PHN>{referral.phn}</PHN>
                        </LeftContainer>
                      )}
                      <CenterContainer hasButtons={containsFaxButtons}>
                        <CategoryIcon />
                        {getCategoryName(referral.category)}
                        {getSubcategoryName(referral.category) !== 'N/A' && (
                          <> - {getSubcategoryName(referral.category)} </>
                        )}
                      </CenterContainer>
                      {(referral.faxToEmr || hasFailedFax('emr', referral)) && (
                        <RightContainer>
                          <IconStatusIndicator
                            state={referral.faxToEmr?.state || 'failed'}
                            label="Referral to EMR"
                          />
                        </RightContainer>
                      )}
                      {type === 'full' &&
                        (referral.triageFax ||
                          hasFailedFax('triage', referral)) && (
                          <RightContainer>
                            <IconStatusIndicator
                              state={referral.triageFax?.state || 'failed'}
                              label="Triage Response"
                            />
                          </RightContainer>
                        )}
                    </CenterFlexBordered>
                    {type === 'expanded' && (
                      <CenterFlexBordered>
                        <LeftContainer hasButtons={containsFaxButtons} />
                        <CenterContainer hasButtons={containsFaxButtons}>
                          <UrgencyIcon />
                          <Urgency urgency={referral.urgency}>
                            {referral.urgency}
                          </Urgency>
                        </CenterContainer>
                        <RightContainer>
                          {(referral.triageFax ||
                            hasFailedFax('triage', referral)) && (
                            <IconStatusIndicator
                              state={referral.triageFax?.state || 'failed'}
                              label="Triage Response"
                            />
                          )}
                        </RightContainer>
                      </CenterFlexBordered>
                    )}
                  </ReferralInfo>
                  {containsFaxButtons && (
                    <>
                      {type === 'expanded' && (
                        <MultiResendContainer
                          position={determineButtonPosition(referral)}>
                          {resendButtons(referral, 'emr')}
                          {resendButtons(referral, 'triage')}
                        </MultiResendContainer>
                      )}
                      {containsFaxingToEmr && type === 'full' && (
                        <ResendContainer>
                          {resendButtons(referral, 'emr')}
                        </ResendContainer>
                      )}
                      {containsTriageFaxing && type === 'full' && (
                        <ResendContainer>
                          {resendButtons(referral, 'triage')}
                        </ResendContainer>
                      )}
                    </>
                  )}
                </FlexContainer>
              )}
              {type === 'condensed' && (
                <ReferralInfo
                  isActive={referral.id === activeReferralId}
                  onClick={() => onSelect(referral)}>
                  <SpaceBetweenLine>
                    <PatientName>{referral.patient}</PatientName>
                    <StatusIndicator state={referral.state} />
                  </SpaceBetweenLine>
                  <SpaceBetweenBorderedLine>
                    <CenterFlexContainer>
                      <CategoryIcon />
                      {getCategoryName(referral.category)}
                      {getSubcategoryName(referral.category) !== 'N/A' && (
                        <> - {getSubcategoryName(referral.category)} </>
                      )}
                    </CenterFlexContainer>
                  </SpaceBetweenBorderedLine>
                  <SpaceBetweenBorderedLine>
                    <CenterFlexContainer>
                      <DoctorIconBox />
                      {referral.fromPractice.name}
                      <Arrow />
                      {referral.toPractice.name}
                    </CenterFlexContainer>
                    <CenterFlexContainer>
                      <UrgencyIcon />
                      <Urgency urgency={referral.urgency}>
                        {referral.urgency}
                      </Urgency>
                    </CenterFlexContainer>
                  </SpaceBetweenBorderedLine>
                </ReferralInfo>
              )}
            </Row>
          )
        })}
    </div>
  )
}

ReferralTable.propTypes = {
  referrals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      patient: PropTypes.string.isRequired,
      phn: PropTypes.number.isRequired,
      urgency: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      createdAt: PropTypes.instanceOf(Date).isRequired,
      fromPractice: PropTypes.object.isRequired,
      toPractice: PropTypes.object.isRequired,
      state: PropTypes.string.isRequired,
      initialFax: PropTypes.object,
      faxToEmr: PropTypes.object,
      triageFax: PropTypes.object
    })
  ),
  practices: PropTypes.array,
  type: PropTypes.oneOf(['full', 'condensed']),
  activeReferralId: PropTypes.string,
  onUpdateReferralFax: PropTypes.func,
  onSelect: PropTypes.func
}

export default ReferralTable
