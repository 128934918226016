import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Paper,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core'
import { FieldLabel } from '../common/StyledField'

const StyledPaper = styled(Paper)`
  padding: 5px 15px;
  margin: 0;
  margin-top: 7px;
  box-shadow: none;
  background: #f9fbfe;
  border-radius: 3px;

  .MuiTypography-body1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3a3a3a;
    letter-spacing: 0;
    padding-left: 7px;
  }
  .MuiIconButton-root {
    padding: 8px;
  }
  .MuiCheckbox-root {
    color: #3a3a3a;
  }
  .MuiCheckbox-root:hover {
    background-color: rgba(17, 147, 173, 0.07);
  }
  .MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #1193ad;
  }
`

const StyledFormHelperText = styled(FormHelperText)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  padding-top: 5px;
`

const SpecialtySelect = React.forwardRef(
  ({ categories = [], value = [], onChange = () => {}, error = {} }, ref) => {
    return (
      <>
        <FieldLabel htmlFor="categories">
          Please select categories relevant to the specialist’s scope:
        </FieldLabel>
        <StyledFormHelperText id="categories-helper-text" error={!!error}>
          {error?.message}
        </StyledFormHelperText>
        <StyledPaper>
          <FormControl component="fieldset">
            <FormGroup id="categories">
              {categories.map(category => (
                <FormControlLabel
                  key={category.id}
                  control={
                    <Checkbox
                      ref={ref}
                      name="specialties"
                      type="checkbox"
                      value={category.id}
                      checked={value.includes(category.id)}
                      onChange={e =>
                        e.target.checked
                          ? onChange(value.concat(category.id))
                          : onChange(value.filter(v => v !== category.id))
                      }
                    />
                  }
                  label={category.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        </StyledPaper>
      </>
    )
  }
)

SpecialtySelect.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  error: PropTypes.object,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired
}

export default SpecialtySelect
