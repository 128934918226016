import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import AloeImage from '../../images/aloevera.svg'

const BackgroundContainer = styled.div`
  position: absolute;
  right: -40px;
  top: -40px;
`

const LandingContent = styled.div`
  background: ${props => (props.type === 'secondary' ? '#fff' : '#069987')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  border-radius: 6px;
  box-shadow: 0px 5px 15px rgba(58, 58, 58, 0.15);

  min-width: 800px;
  height: 260px;

  position: relative;
  overflow: hidden;
`

const LandingContainer = ({ type, children }) => (
  <LandingContent type={type}>
    {children}
    <BackgroundContainer>
      <img width="483" src={AloeImage} alt="Aloevera plant" />
    </BackgroundContainer>
  </LandingContent>
)

LandingContainer.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node.isRequired
}

export default LandingContainer
