import { getAllLeafCategoryIds, getFactorCategoryName } from './categories'

function sortByCategory(a, b) {
  const categoryA = getFactorCategoryName(a.category).toUpperCase()
  const categoryB = getFactorCategoryName(b.category).toUpperCase()

  if (categoryB === 'OVERALL') return 1
  if (categoryA === 'OVERALL') return -1

  if (categoryA < categoryB) return -1
  if (categoryA > categoryB) return 1
  return 0
}

function getPracticeCategoryFactors(practice) {
  if (practice.type === 'specialist') {
    const categoryFactors = [...practice.categoryFactors]

    const existingCategories = practice.categoryFactors.map(f => f.category)
    const leafCategories = getAllLeafCategoryIds(practice.specialty)

    practice.specialties
      .filter(c => !existingCategories.includes(c))
      .map(c => categoryFactors.push({ category: c, factor: 1 }))

    leafCategories
      .filter(
        c =>
          !existingCategories.includes(c) && !practice.specialties.includes(c)
      )
      .map(c => categoryFactors.push({ category: c, factor: 0 }))

    const sortedFactors = categoryFactors.sort(sortByCategory)
    return { ...practice, categoryFactors: sortedFactors }
  }
  return practice
}

export { getPracticeCategoryFactors }
