import React, { useState } from 'react'
import {
  PivotViewComponent,
  FieldList,
  Inject,
  PivotChart
} from '@syncfusion/ej2-react-pivotview'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'

import ArrowExpandAllIcon from 'mdi-react/ArrowExpandAllIcon'
import ArrowCollapseAllIcon from 'mdi-react/ArrowCollapseAllIcon'
import FileImageIcon from 'mdi-react/FileImageIcon'

import palette from '../../util/palette'
import { chartStyling } from '../common/ChartStyling'
import { SectionTitle } from '../common/Title'
import { IconButton } from '../common/Button'

/* eslint-disable no-template-curly-in-string */

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h1:only-child {
    margin-left: auto;
    margin-right: auto;
  }
`
const ChartTitle = styled(SectionTitle)`
  display: flex;
  justify-content: center;
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
`

const ButtonContainer = styled.div`
  margin: 25px 0 15px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 80px;
`

const Placeholder = styled.div`
  width: 85px;
`

const pie = {
  chartSeries: {
    type: 'Pie',
    dataLabel: { visible: true, position: 'Outside' },
    explode: true,
    explodeOffset: '10%'
  },
  legendSettings: { visible: true, textStyle: chartStyling.text },
  textRender: args => {
    args.font = chartStyling.label
    args.text =
      Math.round((args.point.y / args.series.sumOfPoints) * 100, 0) + '%'
  },
  tooltip: { header: '${point.x}', textStyle: chartStyling.label },
  tooltipRender: args => (args.text = 'Value: ' + args.point.y),
  palettes: palette
}

const line = {
  chartSeries: { type: 'Line', width: 2, marker: { visible: true } },
  legendSettings: {
    visible: true,
    position: 'Top',
    textStyle: chartStyling.text
  },
  multiLevelLabelRender: args => {
    if (args.customAttributes.fieldName === 'month') {
      args.text = Intl.DateTimeFormat('en', { month: 'short' }).format(
        new Date(`${Number(args.text) + 1}`)
      )
    }
    args.textStyle = chartStyling.label
  },
  primaryXAxis: {
    title: ' ',
    labelStyle: chartStyling.label
  },
  primaryYAxis: {
    title: ' ',
    labelStyle: chartStyling.label
  },
  tooltip: {
    enable: true,
    header: '${series.name}',
    textStyle: chartStyling.label
  },
  tooltipRender: args => (args.text = 'Value: ' + args.point.y),
  axisLabelRender: args => {
    if (args.value - Math.floor(args.value) !== 0) {
      args.cancel = true
    }
  },
  palettes: palette
}

const stackingColumn = {
  chartSeries: { type: 'StackingColumn' },
  legendSettings: {
    visible: true,
    position: 'Top',
    textStyle: chartStyling.text
  },
  primaryXAxis: {
    title: ' ',
    labelStyle: chartStyling.label
  },
  primaryYAxis: {
    title: ' ',
    labelStyle: chartStyling.label
  },
  multiLevelLabelRender: args => {
    if (args.customAttributes.fieldName === 'month') {
      args.text = Intl.DateTimeFormat('en', { month: 'short' }).format(
        new Date(`${Number(args.text) + 1}`)
      )
    }
    args.textStyle = chartStyling.label
  },
  tooltip: {
    textStyle: chartStyling.label
  },
  tooltipRender: args => {
    args.headerText =
      args.point.x.slice(0, 4) +
      ' - ' +
      Intl.DateTimeFormat('en', { month: 'short' }).format(
        new Date(`${Number(args.point.x.slice(7)) + 1}`)
      )
    args.text = args.series.name + ': ' + args.point.y
  },
  palettes: palette,
  axisLabelRender: args => {
    if (args.value - Math.floor(args.value) !== 0) {
      args.cancel = true
    }
  }
}

const stackingBar = {
  chartSeries: { type: 'StackingBar' },
  legendSettings: { visible: true, textStyle: chartStyling.text },
  primaryXAxis: {
    title: ' '
  },
  primaryYAxis: {
    title: ' ',
    labelStyle: chartStyling.label
  },
  marker: { visible: true },
  tooltip: { header: '${series.name}', textStyle: chartStyling.label },
  tooltipRender: args => {
    args.text = `Value: ${args.point.y} <br> Percentage: ${args.point.percentage}%`
  },
  palettes: palette,
  multiLevelLabelRender: args => {
    args.textStyle.properties = chartStyling.label
  },
  axisLabelRender: args => {
    if (args.value - Math.floor(args.value) !== 0) {
      args.cancel = true
    }
  }
}

const stackingBar100 = {
  chartSeries: { type: 'StackingBar100' },
  legendSettings: { visible: true, textStyle: chartStyling.text },
  primaryXAxis: {
    title: ' '
  },
  primaryYAxis: {
    title: ' ',
    labelStyle: chartStyling.label
  },
  marker: { visible: true },
  tooltip: { header: '${series.name}', textStyle: chartStyling.label },
  tooltipRender: args => {
    args.text = `Value: ${args.point.y} <br> Percentage: ${args.point.percentage}%`
  },
  palettes: palette,
  multiLevelLabelRender: args => {
    args.textStyle.properties = chartStyling.label
  }
}

const chartTypes = { pie, line, stackingColumn, stackingBar, stackingBar100 }

const Chart = ({
  dataSourceSettings,
  type,
  title,
  legend = true,
  customSettings,
  customChartSettings,
  handleClick,
  expandable = false,
  downloadable = false
}) => {
  const [expandAll, setExpandAll] = useState(dataSourceSettings.expandAll)

  let pivotObj

  let chartSettings = legend
    ? chartTypes[type]
    : { ...chartTypes[type], legendSettings: { visible: false } }

  if (title === 'By Urgency')
    chartSettings = {
      ...chartSettings,
      palettes: ['#069988', '#F6AE2D', '#F26419']
    }

  const clickable = handleClick ? true : false

  const handleExpandAll = () => setExpandAll(true)
  const handleCollapseAll = () => setExpandAll(false)

  const handleSaveAsSvg = () => pivotObj.chartExport('SVG', title)

  return (
    <>
      <Header>
        {expandable ? (
          <ButtonContainer>
            <Tooltip title="Expand All" arrow>
              <div>
                <IconButton
                  color="secondary"
                  onClick={handleExpandAll}
                  aria-label="Expand All">
                  <ArrowExpandAllIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title="Collapse All" arrow>
              <div>
                <IconButton
                  color="secondary"
                  onClick={handleCollapseAll}
                  aria-label="Collapse All">
                  <ArrowCollapseAllIcon />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonContainer>
        ) : (
          downloadable && <Placeholder />
        )}
        <ChartTitle
          onClick={handleClick ? handleClick : () => {}}
          clickable={clickable}>
          {title}
        </ChartTitle>
        {downloadable ? (
          <ButtonContainer>
            <Tooltip
              title={
                expandable ? (
                  <>
                    <p>Save as SVG</p>
                    <p>
                      Note: Only visible data will be included in the saved
                      file. To include all the data in the chart, ensure the
                      chart is fully expanded.
                    </p>
                  </>
                ) : (
                  'Save as SVG'
                )
              }
              arrow>
              <div>
                <IconButton
                  color="secondary"
                  onClick={handleSaveAsSvg}
                  aria-label="Save as SVG">
                  <FileImageIcon />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonContainer>
        ) : (
          expandable && <Placeholder />
        )}
      </Header>

      <PivotViewComponent
        ref={d => (pivotObj = d)}
        dataSourceSettings={{
          valueSortSettings: { headerDelimiter: '##' },
          ...dataSourceSettings,
          expandAll
        }}
        displayOption={{ view: 'Chart' }}
        chartSettings={{ ...chartSettings, ...customChartSettings }}
        {...customSettings}>
        <Inject services={[PivotChart, FieldList]} />
      </PivotViewComponent>
    </>
  )
}

Chart.propTypes = {
  dataSourceSettings: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  legend: PropTypes.bool,
  customSettings: PropTypes.object,
  customChartSettings: PropTypes.object,
  handleClick: PropTypes.func,
  expandable: PropTypes.bool,
  downloadable: PropTypes.bool
}

export default Chart
