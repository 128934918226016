import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ChangeNameButton from './ChangeNameButton'
import ChangeEmailButton from './ChangeEmailButton'
import ChangePasswordButton from './ChangePasswordButton'
import EmailPreferences from './EmailPreferences'
import { SectionTitle } from '../common/Title'

const SettingsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(17, 147, 173, 0.05);
  box-shadow: 0px 5px 15px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  color: rgba(58, 58, 58, 0.7);
`

const DetailsContainer = styled(Container)`
  width: 65%;
  padding: 0 30px;
`

const PreferencesContainer = styled(Container)`
  width: 35%;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
  margin-bottom: 10px;
`

const FlexContainer = styled.div`
  display: flex;
`

const Item = styled.div`
  padding: 20px 20px 30px;
  width: 50%;
`

const AccountSettings = ({ user }) => (
  <SettingsContainer>
    <DetailsContainer>
      <SectionTitle>Account Details</SectionTitle>
      <Divider />
      <FlexContainer>
        <Item>
          <ChangeNameButton type="firstName" user={user} />
        </Item>
        <Item>
          <ChangeNameButton type="lastName" user={user} />
        </Item>
      </FlexContainer>
      <FlexContainer>
        <Item>
          <ChangeEmailButton user={user} />
        </Item>
        <Item>
          <ChangePasswordButton user={user} />
        </Item>
      </FlexContainer>
    </DetailsContainer>
    <PreferencesContainer>
      <EmailPreferences preferences={user.emailPreferences} />
    </PreferencesContainer>
  </SettingsContainer>
)

AccountSettings.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    privateKey: PropTypes.shape({
      encryptedData: PropTypes.string.isRequired,
      iv: PropTypes.string.isRequired
    }).isRequired,
    salt: PropTypes.string.isRequired,
    emailPreferences: PropTypes.object.isRequired
  })
}

export default AccountSettings
