import gql from 'graphql-tag'
import client from './graphql/client.js'

const LOG_ERROR = gql`
  mutation logError($error: String!) {
    logError(error: $error)
  }
`

function logError(e) {
  client.mutate({
    mutation: LOG_ERROR,
    variables: { error: e }
  })
  window.location.href = '/error'
}

function register() {
  window.addEventListener('error', e => {
    logError(e.message)
    e.preventDefault()
  })

  window.addEventListener('unhandledrejection', e => {
    logError(e.reason.message)
    e.preventDefault()
  })
}

export default { register }
