import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { Paper, Typography, makeStyles } from '@material-ui/core'
import Button from '../common/Button'
import FolderPlusIcon from 'mdi-react/FolderPlusIcon'

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    margin: 10px 0 0;
  }
`

const useStyles = makeStyles(theme => ({
  dropzone: {
    padding: '6px',
    borderWidth: 1,
    borderColor: 'rgba(58, 58, 58, 0.35)',
    background: '#F9FBFE',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(58, 58, 58, 1)',
    transition: theme.transitions.create(),
    outline: 'none'
  },
  browse: {
    marginTop: theme.spacing()
  },
  dropzoneActive: {
    color: '#F9FBFE',
    borderColor: 'rgba(17,147,173,0.1)',
    backgroundColor: 'rgba(17,147,173,0.1)'
  },
  dropzoneReject: {},
  dropzoneAccept: {},
  typography: {
    fontSize: '14px'
  }
}))

const FileUploader = ({ onAddFiles }) => {
  const classes = useStyles()

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    onDrop: onAddFiles,
    noClick: true
  })

  const currentClasses = [
    classes.dropzone,
    ...(isDragActive ? [classes.dropzoneActive] : []),
    ...(isDragAccept ? [classes.dropzoneAccept] : []),
    ...(isDragReject ? [classes.dropzoneReject] : [])
  ]

  const { ref, ...rootProps } = getRootProps()

  return (
    <StyledPaper
      {...rootProps}
      variant="outlined"
      className={currentClasses.join(' ')}
      ref={ref}>
      <input {...getInputProps()} id="file-upload" aria-label="Upload files" />

      <FolderPlusIcon size={30} style={{ color: 'rgba(58, 58, 58, 0.7)' }} />
      {isDragActive}
      <Typography aria-hidden="true" className={classes.typography}>
        Drop files to upload or
      </Typography>

      <Button
        color="grey"
        onClick={open}
        className={classes.browse}
        padding="medium">
        Browse
      </Button>
    </StyledPaper>
  )
}

FileUploader.propTypes = {
  onAddFiles: PropTypes.func.isRequired
}

export default FileUploader
