import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import { Paper, FormHelperText } from '@material-ui/core'
import { pdf } from '@react-pdf/renderer'

import InformationIcon from 'mdi-react/InformationIcon'
import CheckIcon from 'mdi-react/CheckIcon'

import { SectionTitle, PageTitle } from '../common/Title'
import { FieldLabel, FieldValue } from '../common/StyledField'
import { ErrorMessage, InfoMessage } from '../common/UserAlert'
import Button from '../common/Button'
import Select from '../common/Select'
import FileList from '../refer/FileList'
import FileUploader from '../refer/FileUploader'
import FaxContactsDropdown from './FaxContactsDropdown'
import CoverPagePdf from './CoverPagePdf'
import { getPracticeDisplayName } from '../../util/strings'

const Content = styled.div`
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 51px 0 30px;
`

const ErrorContainer = styled.div`
  margin-bottom: -15px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FaxFormContainer = styled(Paper)`
  width: 100%;
  border-radius: 6px;
  padding: 0px 30px 100px;
`

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
`

const InfoAlert = styled.p`
  font-style: italic;
  font-size: 12px;
  line-height: 15px;
  color: #4ca1d8;
  margin: 5px;
`

const StyledInfoMessage = styled(InfoMessage)`
  margin: 15px 0 -5px 0;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    width: 250px;
  }
`

const ProviderSelect = styled(StyledSelect)`
  .MuiInputBase-root {
    &.Mui-disabled {
      color: #e2e2e2;
      opacity: 1;
    }
  }
`

const Dropdown = styled(Select)`
  .MuiInputBase-root .MuiInputBase-input {
    padding-right: 32px;
  }
`

const SelectorLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  color: #fff;
  padding-right: 18px;
  margin: 0;
  white-space: nowrap;
`

const RecipientInfo = styled.section`
  display: flex;
`

const Field = styled.div`
  flex-grow: 1;
  flex-basis: 33.3%;
  padding: 27px 30px 0;
`

const StyledFieldValue = styled(FieldValue)`
  height: 36px;
`

const RecipientField = styled(Field)`
  padding: 30px 30px 0px;
`

const StyledFormHelperText = styled(FormHelperText)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  padding: 5px 0 0 30px;
`

const emptyContact = {
  id: '',
  practice: '',
  name: '',
  location: '',
  faxNumber: ''
}

const faxSchema = yup.object().shape(
  {
    practice: yup.string().required('Practice required'),
    contact: yup.string().required('Contact required'),
    subject: yup.string().when('files', {
      is: files => files.length === 0,
      then: yup.string().required('Subject required if no files attached')
    }),
    message: yup.string().when('files', {
      is: files => files.length === 0,
      then: yup.string().required('Message required if no files attached')
    }),
    files: yup.array().when(['subject', 'message'], {
      is: (subject, message) => subject === '' || message === '',
      then: yup
        .array()
        .required()
        .min(1, 'File required if subject and message not provided')
    })
  },
  [
    ['files', 'message'],
    ['files', 'subject'],
    ['subject', 'message']
  ]
)

const returnToSenderFaxSchema = yup.object().shape({
  practice: yup.string().required('Practice required'),
  contact: yup.string().required('Contact required'),
  subject: yup.string().required('Subject required'),
  message: yup.string().when('subject', {
    is: 'Other',
    then: yup.string().required('Message is required when choosing "Other"'),
    otherwise: yup.string()
  }),
  files: yup.array().required().min(1, 'File required')
})

function SendFaxForm({
  contacts,
  practices,
  faxId = '',
  senderFaxNumber = '',
  onSuccess = () => {},
  isDuplicate = false
}) {
  let type = 'forward'
  if (!faxId && !senderFaxNumber) {
    type = 'new'
  } else if (faxId && senderFaxNumber) {
    type = 'return'
  }

  const emptyFax = {
    practice: `${practices[0] ? practices[0].id : ''}`,
    contact: '',
    faxNumber: '',
    message: '',
    subject: isDuplicate ? 'Duplicate referral' : '',
    files: []
  }

  const { handleSubmit, control, errors, register, reset, watch, setValue } =
    useForm({
      resolver:
        type === 'return'
          ? yupResolver(returnToSenderFaxSchema)
          : yupResolver(faxSchema),
      defaultValues: emptyFax
    })

  const history = useHistory()
  const [isSelected, setIsSelected] = useState(false)
  const [activeContact, setActiveContact] = useState(emptyContact)

  const [errorMessage, setErrorMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [faxFile, setFaxFile] = useState()
  const [shouldUpdate, setShouldUpdate] = useState(true)
  const [shouldUpdateContact, setShouldUpdateContact] = useState(true)

  const files = watch('files')
  const practice = watch('practice')
  const contact = watch('contact')
  const subject = watch('subject')

  const [filteredContacts, setFilteredContacts] = useState(
    contacts.filter(c => c.practice === practice)
  )
  const returnToContacts = filteredContacts.filter(
    f => f.faxNumber === senderFaxNumber
  )
  const returnToNames = returnToContacts.map(f => f.name)
  const [defaultContact, setDefaultContact] = useState(
    returnToContacts.length === 1 ? returnToContacts[0] : null
  )

  const reasons = [
    'Wrong number',
    'Send to another specialist',
    'Send to another specialty/program/clinic',
    'Send to another region',
    'Patient in the care of or already seen by a different doctor',
    'Duplicate referral',
    'Insufficient Documentation',
    'Other'
  ]

  useEffect(() => {
    register({ name: 'files' })
    register({ name: 'contact' })
  }, [register])

  useEffect(() => {
    if (practice) {
      setFilteredContacts(contacts.filter(c => c.practice === practice))
    }
  }, [practice, contacts, defaultContact])

  useEffect(() => {
    if (practice) setShouldUpdateContact(true)
  }, [practice])

  useEffect(() => {
    async function getDocument() {
      const token = window.localStorage.authToken

      const requestOptions = {
        method: 'GET',
        headers: { authorization: token ? `Bearer ${token}` : '' },
        redirect: 'follow'
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/documents/${faxId}`,
        requestOptions
      )

      if (!response.ok) {
        const body = await response.json()

        console.error(
          'An error occurred while fetching the document: ' + body.error
        )
      } else {
        const reader = response.body.getReader()

        const chunks = []
        let isDone = false

        while (!isDone) {
          const response = await reader.read()

          if (response.value) {
            chunks.push(response.value)
          }

          isDone = response.done
        }

        const file = new File(chunks, `${faxId}.pdf`, {
          type: 'application/pdf'
        })
        setFaxFile(file)
        setShouldUpdate(false)
      }
    }

    if (faxId && faxId !== '') getDocument()
  }, [faxId])

  useEffect(() => {
    if (shouldUpdateContact && returnToContacts.length === 1) {
      setShouldUpdateContact(false)
      setValue('contact', returnToContacts[0].id)
      setDefaultContact(returnToContacts[0])
      setActiveContact(returnToContacts[0])
      setIsSelected(true)
    }
  }, [returnToContacts, shouldUpdateContact, setValue])

  useEffect(() => {
    if (faxFile && shouldUpdate) setValue('files', files.concat(faxFile))
  }, [faxFile, shouldUpdate, setValue, files])

  function onSelect(faxContact) {
    setValue('contact', faxContact.id)
    setActiveContact(faxContact)
    setIsSelected(true)
    setDefaultContact(faxContact)
  }

  function onClear() {
    setActiveContact(emptyContact)
    setValue('contact', '')
    setDefaultContact(null)
    setIsSelected(false)
  }

  const onAddFiles = newFiles => setValue('files', files.concat(newFiles))
  function onDeleteFile(index) {
    const deletedFaxName = files.find((f, i) => i === index).name

    if (deletedFaxName === `${faxId}.pdf`) {
      onClear()
      history.push('/fax/create')
    }

    setValue(
      'files',
      files.filter((f, i) => i !== index)
    )
  }

  async function onSubmit(values) {
    setIsLoading(true)
    setErrorMessage()

    const formData = new FormData()

    const blob = await pdf(
      <CoverPagePdf
        subject={values.subject}
        message={values.message}
        type={type}
        practice={practices.find(p => p.id === values.practice)}
        contact={filteredContacts.find(c => c.id === values.contact)}
      />
    ).toBlob()
    const faxCover = new File([blob], 'faxcover.pdf', {
      type: 'application/pdf'
    })
    formData.append('files', faxCover)

    for (const file of values.files) {
      formData.append('files', file)
    }

    formData.append('practice', values.practice)
    formData.append('contact', values.contact)
    formData.append('subject', values.subject)
    formData.append('message', values.message)
    formData.append('type', type)

    values.subject === 'Duplicate referral'
      ? formData.append('isDuplicate', 'true')
      : formData.append('isDuplicate', isDuplicate.toString())
    if (type !== 'new') formData.append('fax', faxId)

    const token = window.localStorage.authToken

    const requestOptions = {
      method: 'POST',
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      },
      body: formData
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents`,
      requestOptions
    )

    if (!response.ok) {
      const body = await response.json()

      console.error(
        'An error occurred while fetching the document: ' + body.error
      )
      setErrorMessage('Hm, an error occurred. Try again.')
      setIsLoading(false)
    } else {
      reset({
        ...emptyFax,
        practice: values.practice
      })
      setIsLoading(false)
      onSuccess()
      onClear()
    }
  }

  return (
    <Content>
      <form onSubmit={handleSubmit(onSubmit)} aria-label="Fax form">
        <Header>
          <PageTitle>New Fax</PageTitle>
          <ButtonContainer>
            <Button loading={isLoading} type="submit">
              <CheckIcon /> Finish & Send
            </Button>
            {errorMessage && (
              <ErrorContainer>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </ErrorContainer>
            )}
          </ButtonContainer>
        </Header>
        <BlueBar>
          <SelectorLabel id="selector-label">Faxing as</SelectorLabel>
          <Controller
            name="practice"
            control={control}
            render={({ onChange, value, name }) => (
              <ProviderSelect
                select
                id="practice"
                aria-labelledby="selector-label"
                name={name}
                disabled={faxId ? true : false}
                onChange={e => {
                  onClear()
                  onChange(e.target.value)
                }}
                value={value}>
                {practices.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {getPracticeDisplayName(p, 'long', 'short')}
                  </MenuItem>
                ))}
              </ProviderSelect>
            )}
          />
        </BlueBar>
        <FieldsContainer>
          <FaxFormContainer elevation={3}>
            <SectionTitle> Recipient Information</SectionTitle>
            <Divider />
            {senderFaxNumber && returnToContacts.length > 1 && (
              <StyledInfoMessage>
                There were {returnToContacts.length} contacts found with the fax
                number {senderFaxNumber}: {returnToNames.join(', ')}
              </StyledInfoMessage>
            )}
            {senderFaxNumber && returnToContacts.length === 0 && (
              <StyledInfoMessage>
                There were no contacts found with the fax number{' '}
                {senderFaxNumber}. Please create a contact now.
              </StyledInfoMessage>
            )}
            <RecipientInfo>
              <RecipientField>
                <FaxContactsDropdown
                  id="contact"
                  name="contact"
                  value={contact}
                  contacts={filteredContacts}
                  practice={practice}
                  defaultContact={defaultContact}
                  onSelect={onSelect}
                  onClear={onClear}
                />
              </RecipientField>
              {isSelected ? (
                <>
                  <RecipientField>
                    <FieldLabel>Fax no.</FieldLabel>
                    <StyledFieldValue>
                      {activeContact.faxNumber}
                    </StyledFieldValue>
                  </RecipientField>
                  <RecipientField>
                    <FieldLabel>Location</FieldLabel>
                    <StyledFieldValue>
                      {activeContact.location}
                    </StyledFieldValue>
                  </RecipientField>
                </>
              ) : (
                <>
                  <RecipientField />
                  <RecipientField />
                </>
              )}
            </RecipientInfo>
            <StyledFormHelperText
              id="contact-helper-text"
              error={!!errors.contact}>
              {errors?.contact?.message}
            </StyledFormHelperText>
            <SectionTitle> Fax Information</SectionTitle>
            <Divider />
            <>
              {type === 'return' ? (
                <Field>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <Dropdown
                        select
                        label="Subject"
                        id="subject"
                        name={name}
                        placeholder="Select a reason for returning"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        value={value}
                        error={!!errors.subject}
                        helperText={errors?.subject?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: true }}>
                        {reasons.map(r => (
                          <MenuItem key={r} value={r}>
                            {r}
                          </MenuItem>
                        ))}
                      </Dropdown>
                    )}
                  />
                </Field>
              ) : (
                <Field>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <Select
                        id="subject"
                        name={name}
                        label="Subject"
                        value={value}
                        onChange={onChange}
                        error={!!errors.subject}
                        helperText={errors?.subject?.message}
                      />
                    )}
                  />
                </Field>
              )}
              <Field>
                <Controller
                  name="message"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <Select
                      id="message"
                      name={name}
                      label="Message or Additional Comments"
                      value={value}
                      onChange={onChange}
                      placeholder="Include a message for the fax and/or additional comments."
                      multiline
                      error={!!errors.message}
                      InputLabelProps={{
                        required: subject === 'Other' && type === 'return'
                      }}
                      helperText={errors?.message?.message}
                    />
                  )}
                />
                <AlertContainer>
                  <InformationIcon size={15} style={{ color: '#4ca1d8' }} />
                  {type === 'return' ? (
                    <InfoAlert>
                      Write any additional information about why you are
                      returning the fax
                    </InfoAlert>
                  ) : (
                    <InfoAlert>
                      Write a message and/or upload a fax document
                    </InfoAlert>
                  )}
                </AlertContainer>
              </Field>
              <Field>
                <FieldLabel>Attach File(s)</FieldLabel>
                <FileList name="files" files={files} onDelete={onDeleteFile} />
                <FileUploader onAddFiles={onAddFiles} />
              </Field>
              <StyledFormHelperText
                id="files-helper-text"
                error={!!errors.files}>
                {errors?.files?.message}
              </StyledFormHelperText>
            </>
          </FaxFormContainer>
        </FieldsContainer>
      </form>
    </Content>
  )
}

SendFaxForm.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      practice: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      faxNumber: PropTypes.string.isRequired,
      location: PropTypes.string
    })
  ).isRequired,
  practices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    })
  ).isRequired,
  faxId: PropTypes.string,
  senderFaxNumber: PropTypes.string,
  onSuccess: PropTypes.func,
  isDuplicate: PropTypes.bool
}

export default SendFaxForm
