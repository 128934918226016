import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { SectionTitle } from '../common/Title'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`

const Title = styled(SectionTitle)`
  margin: 0;
  text-align: center;
`

const ValueText = styled.h1`
  font-weight: normal;
  font-size: 48px;
  line-height: 55px;
  letter-spacing: 0.02em;
  color: #1193ad;
  margin: 0;
`

const SubValueText = styled.p`
  font-size: 26px;
  margin: 0 0 15px 0;
  color: rgba(58, 58, 58, 0.5);
`

const ValueDisplay = ({ title, value, subValue }) => (
  <Container>
    <Title>{title}</Title>
    <ValueText>{value}</ValueText>
    <SubValueText>{subValue}</SubValueText>
  </Container>
)

ValueDisplay.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.string.isRequired,
  subValue: PropTypes.string
}

export default ValueDisplay
