import React from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { Mutation } from '@apollo/client/react/components'
import CrashPage from '../pages/CrashPage'

const LOG_ERROR = gql`
  mutation logError($error: String!) {
    logError(error: $error)
  }
`

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static propTypes = {
    children: PropTypes.node
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.message }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Mutation mutation={LOG_ERROR}>
          {(logError, { loading, error, data }) => {
            if (!loading && !error && !data) {
              logError({
                variables: { error: this.state.error }
              })
            }

            return <CrashPage />
          }}
        </Mutation>
      )
    }

    if (this.props.children) {
      return this.props.children
    }
    return null
  }
}

export default ErrorBoundary
