import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { Controller, useFormContext } from 'react-hook-form'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  makeStyles,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core'
import InformationIcon from 'mdi-react/InformationIcon'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'

import { matchPostalCode } from '../../util/strings'
import regions from '../../regions.json'
import provinces from '../../provinces.json'
import FileUploader from '../refer/FileUploader'
import FileList from '../refer/FileList'
import ReferralUrgency from '../refer/ReferralUrgency'
import { FieldLabel } from '../common/StyledField'
import { SectionTitle } from '../common/Title'
import Select from '../common/Select'
import { TextButton } from '../common/Button'
import { WarningMessage, SuccessMessage } from '../common/UserAlert'

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const AllFields = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: ${props => (props.padding === 'large' ? '0' : '20px')};
`

const AdditionalFieldsLabel = styled(FieldLabel)`
  font-weight: 600;
  color: #3a3a3a;
  background: rgba(6, 153, 135, 0.05);
  padding: ${props =>
    props.padding === 'large' ? '27px 50px 0' : '27px 5px 0 25px'};
  margin: 27px 0 0;
`

const AdditionalFields = styled(AllFields)`
  background: rgba(6, 153, 135, 0.05);
  padding-bottom: 27px;
`

const Field = styled.div`
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: ${props => (props.column === '2' ? '50%' : '33.3%')};
  padding: ${props =>
    props.padding === 'large' ? '27px 50px 0' : '27px 5px 0 25px'};
`

const FieldLarge = styled.div`
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  flex-grow: 2;
  flex-basis: ${props => (props.column === '2' ? '100%' : '66.6%')};
  padding: ${props =>
    props.padding === 'large' ? '27px 50px 0' : '27px 5px 0 25px'};
`

const OtherLabel = styled.span`
  margin-right: 10px;
`

const CheckboxWrapper = styled.div`
  margin: 8px 0 0;
`

const ChooseSpecialistContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SpecialistSelect = styled.div`
  display: flex;
  align-items: baseline;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    max-width: 250px;
  }
`

const Dropdown = styled(Select)`
  .MuiInputBase-root .MuiInputBase-input {
    padding-right: 32px;
  }
`

const NameSelect = styled(Select)`
  .MuiInputBase-root .MuiInputBase-input {
    text-transform: capitalize;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: rgba(6, 153, 135, 0.15);
  }

  &.MuiListItem-root:hover {
    background-color: rgba(6, 153, 135, 0.05);
  }

  div {
    flex-direction: column;
  }
`

const ClinicNameStyling = styled.span`
  padding-left: 5px;
  color: rgba(58, 58, 58, 0.7);
  font-size: 14px;
  font-style: italic;

  &::before {
    content: '(';
  }

  &::after {
    content: ')';
  }
`

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
`

const InfoAlert = styled.p`
  font-style: italic;
  font-size: 12px;
  line-height: 15px;
  color: #4ca1d8;
  margin: 5px;
`

const StyledFormHelperText = styled(FormHelperText)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  padding-top: 5px;
`

const MessageContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
`

const WarningButton = styled(TextButton)`
  min-width: auto;
  padding: 2px 9px;
  height: 18px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  margin-top: 4px;

  .MuiTypography-body1 {
    font-family: Source Sans Pro, sans-serif;
    color: #3a3a3a;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    white-space: nowrap;
    letter-spacing: 0;
  }
  .Mui-checked {
    color: rgba(17, 147, 173, 1);
  }
  span {
    display: flex;
    align-items: center;
  }
  .MuiTextField-root {
    min-width: 250px;
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-body1': {
      fontFamily: 'Source Sans Pro, sans-serif',
      color: '#3a3a3a',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: '600',
      whiteSpace: 'nowrap',
      letterSpacing: '0'
    },
    '& .MuiIconButton-colorSecondary': {
      color: '#e2e2e2'
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: 'rgba(58,58,58,0.5)'
    },
    '& .MuiFormHelperText-root.Mui-error.Mui-disabled': {
      lineHeight: '0',
      fontSize: '0rem'
    },
    '& .MuiFormControlLabel-root': {
      '&#specificSpecialist': {
        marginRight: '0',
        marginLeft: '10px'
      }
    }
  },
  disabledText: {
    '& .MuiTypography-body1': {
      color: '#e2e2e2'
    }
  }
}))

const ReferralFields = ({
  specialties = [],
  specialists = [],
  faxId = '',
  padding = 'large',
  column = '3',
  hasPossibleDuplicates = null,
  handleOpenDuplicates = () => {}
}) => {
  const [faxFile, setFaxFile] = useState()
  const [shouldUpdate, setShouldUpdate] = useState(true)

  const { control, errors, watch, setValue } = useFormContext()

  const history = useHistory()
  const classes = useStyles()

  // We don't watch all fields to prevent unnecessary re-renders
  const files = watch('files')
  const region = watch('region')
  const specialty = watch('specialty')
  const category = watch('category')
  const subcategory = watch('subcategory')
  const nextSpecialist = watch('nextSpecialist')
  const specificSpecialist = watch('specificSpecialist')
  const urgency = watch('urgency')
  const gender = watch('gender')

  // Filter the regions for ones that have specialists
  const filteredRegions = regions.filter(r =>
    specialists.some(spc => spc.region === r.id)
  )

  /*
   * Filter the specialties for ones that are in the selected
   * region and that have specialists
   */
  const filteredSpecialties = specialties
    .filter(s => region && s.region === region)
    .filter(s => specialists.some(spc => spc.specialty.includes(s.id)))

  /*
   * Filter the categories for ones that are in the selected
   * specialty and that have specialists
   */
  const filteredCategories = filteredSpecialties
    .filter(s => specialty && s.id === specialty)
    .map(({ categories }) => categories)
    .flat()
    .filter(
      c =>
        (c.subcategories.length === 0 &&
          specialists.some(s => s.specialties.includes(c.id))) ||
        (c.subcategories.length > 0 &&
          specialists.some(s =>
            c.subcategories.some(b => s.specialties.includes(b.id))
          ))
    )

  /*
   * Filter the subcategories for ones that are for the selected
   * category and that have specialists
   */
  const filteredSubcategories = filteredCategories
    .filter(c => category && c.id === category)
    .map(({ subcategories }) => subcategories)
    .flat()
    .filter(c => specialists.some(s => s.specialties.includes(c.id)))

  /*
   * Filter the specialists so only specialists with the selected
   * specialty, category, subcategory, and region are shown
   */
  const filteredSpecialists = specialists
    .filter(s => region && s.region === region)
    .filter(specialist =>
      /*
       * filter by category only if that category has no subcategories,
       * otherwise, filter by subcategory
       */
      specialist.specialties.some(
        s => s === (filteredSubcategories.length === 0 ? category : subcategory)
      )
    )

  const onAddFiles = newFiles => {
    setValue('files', files.concat(newFiles))
  }

  const onDeleteFile = index => {
    const deletedFaxName = files.find((f, i) => i === index).name

    if (deletedFaxName === `${faxId}.pdf`) {
      history.push('/refer')
    }

    setValue(
      'files',
      files.filter((f, i) => i !== index)
    )
  }

  const onChangeNextSpecialist = e => {
    setValue('specialist', '') //clear the specialist
    setValue('nextSpecialist', e.target.checked)
    setValue('specificSpecialist', false)
    return e.target.value
  }

  const onChangeSpecificSpecialist = e => {
    setValue('specialist', '') //clear the specialist
    setValue('nextSpecialist', false)
    setValue('specificSpecialist', e.target.checked)
    return e.target.value
  }

  useEffect(() => {
    async function getDocument() {
      const token = window.localStorage.authToken

      const requestOptions = {
        method: 'GET',
        headers: { authorization: token ? `Bearer ${token}` : '' },
        redirect: 'follow'
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/documents/${faxId}`,
        requestOptions
      )

      if (!response.ok) {
        const body = await response.json()

        console.error(
          'An error occurred while fetching the document: ' + body.error
        )
      } else {
        const reader = response.body.getReader()

        const chunks = []
        let isDone = false

        while (!isDone) {
          const response = await reader.read()

          if (response.value) {
            chunks.push(response.value)
          }

          isDone = response.done
        }

        const file = new File(chunks, `${faxId}.pdf`, {
          type: 'application/pdf'
        })
        setFaxFile(file)
        setShouldUpdate(false)
      }
    }

    if (faxId !== '') getDocument()
  }, [faxId])

  useEffect(() => {
    if (faxFile && shouldUpdate && !files.find(f => f.name === faxFile.name))
      setValue('files', files.concat(faxFile))
  }, [faxFile, shouldUpdate, setValue, files])

  return (
    <>
      <SectionTitle>Patient Information</SectionTitle>
      <Divider />
      <AllFields padding={padding}>
        <Field padding={padding} column={column}>
          <Controller
            name="phn"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="phn"
                name={name}
                label="PHN"
                value={value}
                onChange={onChange}
                error={!!errors.phn}
                helperText={errors?.phn?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true }}
              />
            )}
          />
          {hasPossibleDuplicates && (
            <WarningMessage>
              <MessageContent>
                Possible duplicates found
                <WarningButton color="blue" onClick={handleOpenDuplicates}>
                  View
                </WarningButton>
              </MessageContent>
            </WarningMessage>
          )}
          {hasPossibleDuplicates === false && (
            <SuccessMessage>
              <MessageContent>No duplicates found</MessageContent>
            </SuccessMessage>
          )}
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="birthDate"
            control={control}
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                TextFieldComponent={Select}
                id="birthDate"
                inputRef={ref}
                InputLabelProps={{
                  shrink: true,
                  required: true
                }}
                label="Date of Birth"
                mask="__/___/____"
                refuse={/[^a-zA-Z0-9]+/gi}
                format="dd/MMM/yyyy"
                placeholder="DD/MMM/YYYY"
                disableFuture
                views={['year', 'month', 'date']}
                openTo="year"
                error={!!errors.birthDate}
                helperText={errors?.birthDate?.message}
                FormHelperTextProps={{ role: 'alert' }}
              />
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="firstName"
            control={control}
            render={({ onChange, value, name }) => (
              <NameSelect
                id="firstName"
                name={name}
                label="First Name"
                value={value}
                onChange={onChange}
                error={!!errors.firstName}
                helperText={errors?.firstName?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true }}
              />
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="lastName"
            control={control}
            render={({ onChange, value, name }) => (
              <NameSelect
                id="lastName"
                name={name}
                label="Last Name"
                value={value}
                onChange={onChange}
                error={!!errors.lastName}
                helperText={errors?.lastName?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true }}
              />
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="phone"
            control={control}
            render={({ onChange, value, name }) => (
              <NumberFormat
                id="phone"
                name={name}
                label="Phone Number"
                value={value}
                onChange={onChange}
                mask="_"
                format="(###)-###-####, Ext. ######"
                placeholder="(000)-000-0000, Ext. 123"
                customInput={Select}
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true, shrink: true }}
              />
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="referralDate"
            control={control}
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                TextFieldComponent={Select}
                id="referralDate"
                inputRef={ref}
                label="Date of Referral"
                InputLabelProps={{
                  shrink: true,
                  required: true
                }}
                format="dd/MM/yyyy"
                placeholder="DD/MM/YYYY"
                showTodayButton
                disableFuture
                openTo="date"
              />
            )}
          />
        </Field>
        <FieldLarge padding={padding} column={column}>
          <FieldLabel>Current Gender Identity</FieldLabel>
          <Controller
            name="gender"
            control={control}
            render={({ onChange, value, name }) => (
              <RadioGroup
                name={name}
                onChange={e => {
                  onChange(e.target.value)
                  if (gender !== 'other') {
                    setValue('genderDescription', '')
                  }
                }}
                row>
                {['Unknown', 'Female', 'Male'].map(opt => {
                  return (
                    <StyledFormControlLabel
                      key={opt}
                      value={opt}
                      control={<Radio size="small" checked={value === opt} />}
                      label={opt}
                    />
                  )
                })}
                <StyledFormControlLabel
                  value="Other"
                  control={<Radio size="small" checked={value === 'Other'} />}
                  label={
                    <>
                      <OtherLabel>Other</OtherLabel>
                      <Controller
                        name="genderDescription"
                        control={control}
                        render={({ onChange, value, name }) => (
                          <StyledSelect
                            id="genderDescription"
                            name={name}
                            value={value}
                            onChange={onChange}
                            placeholder="Description"
                            disabled={['Unknown', 'Female', 'Male'].includes(
                              gender
                            )}
                            error={!!errors.genderDescription}
                            helperText={errors?.genderDescription?.message}
                            FormHelperTextProps={{ role: 'alert' }}
                          />
                        )}
                      />
                    </>
                  }
                />
              </RadioGroup>
            )}
          />
        </FieldLarge>
      </AllFields>
      <AdditionalFieldsLabel padding={padding}>
        Please fill out these fields if you are not attaching a referral letter
      </AdditionalFieldsLabel>
      <AdditionalFields padding={padding}>
        <Field padding={padding} column={column}>
          <Controller
            name="address"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="address"
                name={name}
                label="Address"
                value={value}
                onChange={onChange}
                error={!!errors.address}
                helperText={errors?.address?.message}
                FormHelperTextProps={{ role: 'alert' }}
              />
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="city"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="city"
                name={name}
                label="City"
                value={value}
                onChange={onChange}
                error={!!errors.city}
                helperText={errors?.city?.message}
                FormHelperTextProps={{ role: 'alert' }}
              />
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="province"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                select
                id="province"
                name={name}
                label="Province"
                onChange={onChange}
                value={value}
                error={!!errors.province}
                helperText={errors?.province?.message}
                FormHelperTextProps={{ role: 'alert' }}>
                {Object.keys(provinces).map(p => (
                  <MenuItem key={p} value={p}>
                    {provinces[p]}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="postalCode"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="postalCode"
                name={name}
                label="Postal Code"
                value={value}
                onChange={e => {
                  const postalCode = matchPostalCode(e.target.value)
                  onChange(postalCode)
                }}
                placeholder="X1X 1X1"
                error={!!errors.postalCode}
                helperText={errors?.postalCode?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="email"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="email"
                type="email"
                name={name}
                label="Email"
                value={value}
                onChange={onChange}
                placeholder="abc@xyz.com"
                error={!!errors.email}
                helperText={errors?.email?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Field>
        <Field padding={padding} column={column}></Field>
      </AdditionalFields>
      <SectionTitle>Specialist Information</SectionTitle>
      <Divider />
      <AllFields padding={padding}>
        <Field padding={padding} column={column}>
          <Controller
            name="region"
            control={control}
            render={({ onChange, value, name }) => (
              <Dropdown
                select
                label="Region"
                id="region"
                name={name}
                placeholder="Select region"
                onChange={e => {
                  setValue('specialist', '')
                  setValue('nextSpecialist', false)
                  setValue('specificSpecialist', false)
                  setValue('specialty', '')
                  setValue('category', '')
                  setValue('subcategory', '')
                  onChange(e.target.value)
                }}
                value={value}
                error={!!errors.region}
                helperText={errors?.region?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true }}>
                {filteredRegions.map(r => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
              </Dropdown>
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="specialty"
            control={control}
            render={({ onChange, value, name }) => (
              <Dropdown
                select
                label="Specialty"
                id="specialty"
                name={name}
                placeholder="Select a specialty"
                onChange={e => {
                  setValue('specialist', '')
                  setValue('nextSpecialist', false)
                  setValue('specificSpecialist', false)
                  setValue('category', '')
                  setValue('subcategory', '')
                  onChange(e.target.value)
                }}
                disabled={filteredSpecialties.length === 0}
                value={value}
                error={!!errors.specialty}
                helperText={errors?.specialty?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true }}>
                {filteredSpecialties.map(s => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </Dropdown>
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="category"
            control={control}
            render={({ onChange, value, name }) => (
              <Dropdown
                select
                label="Category"
                id="category"
                name={name}
                onChange={e => {
                  setValue('specialist', '')
                  setValue('nextSpecialist', false)
                  setValue('specificSpecialist', false)
                  setValue('subcategory', '')
                  onChange(e.target.value)
                }}
                disabled={filteredCategories.length === 0}
                value={value}
                error={!!errors.category}
                helperText={errors?.category?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{ required: true }}>
                {filteredCategories.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Dropdown>
            )}
          />
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="subcategory"
            control={control}
            render={({ onChange, value, name }) => (
              <Dropdown
                select
                label="Subcategory"
                id="subcategory"
                name={name}
                onChange={e => {
                  setValue('specialist', '')
                  setValue('nextSpecialist', false)
                  setValue('specificSpecialist', false)
                  onChange(e.target.value)
                }}
                disabled={filteredSubcategories.length === 0}
                value={value}
                error={!!errors.subcategory}
                helperText={errors?.subcategory?.message}
                FormHelperTextProps={{ role: 'alert' }}
                InputLabelProps={{
                  required: filteredSubcategories.length !== 0
                }}>
                {filteredSubcategories.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Dropdown>
            )}
          />
        </Field>

        <FieldLarge padding={padding} column={column}>
          <FieldLabel>Choose a Specialist</FieldLabel>
          <ChooseSpecialistContainer>
            <SpecialistSelect className={classes.root}>
              <CheckboxWrapper>
                <Controller
                  name="nextSpecialist"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <FormControlLabel
                      label="Choose specialist for me"
                      id="nextSpecialist"
                      name={name}
                      value={value}
                      disabled={
                        filteredSpecialists.length <= 0 ||
                        filteredSpecialists.filter(
                          s => s.allowReferrals === 'all'
                        ).length <= 0
                      }
                      control={
                        <Checkbox
                          checked={nextSpecialist}
                          onChange={onChangeNextSpecialist}
                        />
                      }
                      classes={{
                        root: `${
                          specificSpecialist ||
                          filteredSpecialists.length <= 0 ||
                          filteredSpecialists.filter(
                            s => s.allowReferrals === 'all'
                          ).length <= 0
                            ? classes.disabledText
                            : classes.root
                        }`
                      }}
                    />
                  )}
                />
              </CheckboxWrapper>
              <CheckboxWrapper>
                <Controller
                  name="specificSpecialist"
                  control={control}
                  render={({ onChange, value, name }) => (
                    <FormControlLabel
                      id="specificSpecialist"
                      name={name}
                      value={value}
                      disabled={filteredSpecialists.length <= 0}
                      control={
                        <Checkbox
                          checked={specificSpecialist}
                          onChange={onChangeSpecificSpecialist}
                        />
                      }
                    />
                  )}
                />
              </CheckboxWrapper>
              <Controller
                name="specialist"
                control={control}
                render={({ onChange, value, name }) => (
                  <StyledSelect
                    select
                    id="specialist"
                    name={name}
                    SelectProps={{
                      placeholder: 'Select a specific specialist',
                      displayEmpty: true
                    }}
                    onChange={e => {
                      setValue('specificSpecialist', true)
                      onChange(e.target.value)
                    }}
                    disabled={filteredSpecialists.length <= 0 || nextSpecialist}
                    value={value}
                    error={!!errors.specialist}
                    helperText={errors?.specialist?.message}
                    FormHelperTextProps={{ role: 'alert' }}>
                    <MenuItem value="" disabled>
                      Select a specific specialist
                    </MenuItem>
                    {filteredSpecialists.map(s => (
                      <StyledMenuItem key={s.id} value={s.id}>
                        <div>
                          <span>
                            {s.firstName} {s.lastName}
                            {s.clinicName && (
                              <ClinicNameStyling>
                                {s.clinicName}
                              </ClinicNameStyling>
                            )}
                          </span>
                        </div>
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                )}
              />
            </SpecialistSelect>
            {filteredSpecialists.length > 0 &&
              filteredSpecialists.filter(s => s.allowReferrals === 'all')
                .length <= 0 && (
                <AlertContainer>
                  <InformationIcon size={15} style={{ color: '#4ca1d8' }} />
                  <InfoAlert>
                    For this category the available specialists are only
                    receiving directed referrals
                  </InfoAlert>
                </AlertContainer>
              )}
          </ChooseSpecialistContainer>
        </FieldLarge>
      </AllFields>

      <SectionTitle>Referral Information</SectionTitle>
      <Divider />
      <div>
        <Field padding={padding} column={column}>
          <ReferralUrgency
            urgency={urgency}
            id="urgency"
            name="urgency"
            onSelect={e => setValue('urgency', e)}
          />
          <StyledFormHelperText
            id="urgency-helper-text"
            error={!!errors.urgency}
            disabled={urgency !== ''}>
            {errors?.urgency?.message}
          </StyledFormHelperText>
        </Field>
        <Field padding={padding} column={column}>
          <Controller
            name="message"
            control={control}
            render={({ onChange, value, name }) => (
              <Select
                id="message"
                name={name}
                label="Reason for Referral and/or Additional Comments"
                value={value}
                onChange={onChange}
                placeholder="Include any additional information here you want the specialist to be aware of."
                multiline
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
          <AlertContainer>
            <InformationIcon size={15} style={{ color: '#4ca1d8' }} />
            <InfoAlert>
              Write a reason for referral or upload a referral letter
            </InfoAlert>
          </AlertContainer>
        </Field>
        <Field padding={padding} column={column}>
          <FieldLabel>Attach File(s)</FieldLabel>
          <FileList files={files} onDelete={onDeleteFile} />
          <FileUploader onAddFiles={onAddFiles} />
        </Field>
      </div>
    </>
  )
}

ReferralFields.propTypes = {
  specialties: PropTypes.array,
  specialists: PropTypes.array,
  faxId: PropTypes.string,
  padding: PropTypes.string,
  column: PropTypes.string,
  hasPossibleDuplicates: PropTypes.bool,
  handleOpenDuplicates: PropTypes.func
}

export default ReferralFields
