import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useHistory, NavLink } from 'react-router-dom'
import { InputAdornment } from '@material-ui/core'
import InputIconButton from '@material-ui/core/IconButton'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import gql from 'graphql-tag'

import ArrowRightThickIcon from 'mdi-react/ArrowRightThickIcon'
import VisibilityOffIcon from 'mdi-react/VisibilityOffIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import InformationIcon from 'mdi-react/InformationIcon'
import VisibilityIcon from 'mdi-react/VisibilityIcon'
import KeyIcon from 'mdi-react/KeyIcon'

import { deriveAccountKeys, wrapSecretKey } from '../../crypto'
import useAccount from '../../hooks/useAccount'
import Button, { IconButton } from '../common/Button'
import { SectionTitle } from '../common/Title'
import { ErrorMessage } from '../common/UserAlert'
import ProgressDots from '../login/ProgressDots'
import Select from '../common/Select'

const required = 'This field is required'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 100px 30px 0;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
`

const BackButton = styled(IconButton)`
  padding: 4px;
  .mdi-icon {
    height: 25px;
    width: 25px;
  }
`

const Title = styled(SectionTitle)`
  color: #3a3a3a;
  margin: 0;
`

const Placeholder = styled.div`
  width: 35px;
`

const Form = styled.form`
  width: 400px;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root .MuiInputBase-input {
    padding: 13px 15px 11px;
  }
`

const InputIcon = styled(InputAdornment)`
  min-height: 15px;
  margin: 13px 0 12px 15px;
  .mdi-icon {
    color: #3a3a3a;
    max-width: 20px;
  }
`

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const InfoAlert = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: #3a3a3a;
  margin: 5px 4px;
`

const FieldContainer = styled.div`
  margin-bottom: 15px;
`

const ButtonWrapper = styled.div`
  padding-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`

const MessageContainer = styled.div`
  max-width: 400px;
`

const DescriptionText = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3a3a3a;
`

const RESET_KEYS = gql`
  mutation resetKeys($resetToken: String!, $keyChange: KeyChangeInput!) {
    resetKeys(resetToken: $resetToken, keyChange: $keyChange) {
      authToken
      user {
        publicKey
      }
    }
  }
`

function PasswordResetForm({
  privateKey,
  recoveryKey,
  resetToken,
  handleBack = () => {}
}) {
  const { setAccount } = useAccount()
  const history = useHistory()

  const { errors, handleSubmit, getValues, register } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  })

  const [resetKeys, { loading, error }] = useMutation(RESET_KEYS)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const toggleShowPassword = () => setShowPassword(p => !p)
  const toggleShowConfirm = () => setShowConfirm(p => !p)

  const handleMouseDown = e => e.preventDefault()

  async function onSubmit({ password }) {
    try {
      const { encryptionKey, accountToken, salt } = await deriveAccountKeys(
        password
      )
      const { wrappedKey, iv } = await wrapSecretKey(privateKey, encryptionKey)

      const { wrappedKey: backupKey, iv: backupIv } = await wrapSecretKey(
        privateKey,
        recoveryKey
      )

      const { wrappedKey: wrappedRecoveryKey, iv: recoveryIv } =
        await wrapSecretKey(recoveryKey, encryptionKey)

      const keyChange = {
        backupKey: {
          encryptedData: backupKey,
          iv: backupIv
        },
        recoveryKey: {
          encryptedData: wrappedRecoveryKey,
          iv: recoveryIv
        },
        accountToken,
        privateKey: { encryptedData: wrappedKey, iv },
        salt
      }

      const result = await resetKeys({ variables: { resetToken, keyChange } })
      const { authToken, user } = result.data.resetKeys
      setAccount(authToken, user.publicKey, privateKey)
      history.push('/refer')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Container>
      <TitleContainer>
        <BackButton color="secondary" onClick={() => handleBack()}>
          <ChevronLeftIcon />
        </BackButton>
        <Title>New Password</Title>
        <Placeholder />
      </TitleContainer>
      <MessageContainer>
        <DescriptionText>
          It is very important that you create a strong password that you will
          not forget. Your current Emergency Access Kit is still valid. If you
          wish to generate a new Emergency Access Kit you can do so in your
          settings.
        </DescriptionText>
      </MessageContainer>
      <Form onSubmit={handleSubmit(onSubmit)} aria-label="Change password">
        <StyledSelect
          id="password"
          name="password"
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          error={!!errors.password}
          helperText={errors?.password?.message}
          inputRef={register({
            minLength: {
              value: 8,
              message: 'Your password must be at least 8 characters long'
            },
            required
          })}
          InputProps={{
            startAdornment: (
              <InputIcon position="start">
                <KeyIcon size={22} />
              </InputIcon>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <InputIconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                  onMouseDown={handleMouseDown}>
                  {showPassword ? (
                    <VisibilityOffIcon color="#C4C4C4" />
                  ) : (
                    <VisibilityIcon color="#C4C4C4" />
                  )}
                </InputIconButton>
              </InputAdornment>
            )
          }}
          InputLabelProps={{ required: true }}
          placeholder="Enter password"
          fullWidth
        />
        <AlertContainer>
          <InformationIcon size={13} style={{ color: '#4ca1d8' }} />
          <InfoAlert>Password must be 8 characters long</InfoAlert>
        </AlertContainer>
        <FieldContainer>
          <StyledSelect
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm New Password"
            type={showConfirm ? 'text' : 'password'}
            error={!!errors.confirmPassword}
            helperText={errors?.confirmPassword?.message}
            inputRef={register({
              required,
              validate: value =>
                value === getValues('password') || 'Passwords do not match'
            })}
            InputProps={{
              startAdornment: (
                <InputIcon position="start">
                  <KeyIcon size={22} />
                </InputIcon>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <InputIconButton
                    aria-label="toggle confirm password visibility"
                    onClick={toggleShowConfirm}
                    onMouseDown={handleMouseDown}>
                    {showConfirm ? (
                      <VisibilityOffIcon color="#C4C4C4" />
                    ) : (
                      <VisibilityIcon color="#C4C4C4" />
                    )}
                  </InputIconButton>
                </InputAdornment>
              )
            }}
            InputLabelProps={{ required: true }}
            placeholder="Re-enter password"
            fullWidth
          />
        </FieldContainer>

        {error &&
          (error.message.includes(
            'Cannot find a user with this reset token'
          ) ? (
            <ErrorMessage>
              Hm, we cannot find your account. Please{' '}
              <NavLink to="/forgot">request a new recovery email.</NavLink>
            </ErrorMessage>
          ) : error.message.includes('This reset token has expired') ? (
            <ErrorMessage>
              This recovery key has expired. Please{' '}
              <NavLink to="/forgot">request a new recovery email.</NavLink>
            </ErrorMessage>
          ) : (
            <ErrorMessage />
          ))}
        <ButtonWrapper>
          <Button type="submit" padding="small" loading={loading}>
            <ArrowRightThickIcon />
            Confirm
          </Button>
        </ButtonWrapper>
      </Form>
      <ProgressDots currentStep={4} steps={4} />
    </Container>
  )
}

PasswordResetForm.propTypes = {
  privateKey: PropTypes.object.isRequired,
  recoveryKey: PropTypes.object.isRequired,
  resetToken: PropTypes.string.isRequired,
  handleBack: PropTypes.func
}

export default PasswordResetForm
