import styled from 'styled-components'

const colors = {
  grey: 'rgba(58,58,58,0.7)',
  white: '#fff',
  black: '#3A3A3A'
}

const DefaultTitle = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  color: ${colors['black']};
`

const PageTitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  font-variant: small-caps;
  text-align: left;
  color: ${colors['black']};
  margin: 60px 0 30px;
`

const SectionTitle = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: ${colors['grey']};
  margin: 30px 0 14px;
`

const SmallTitle = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${colors['black']};
`

const LandingPageTitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  color: ${colors['white']};
  padding-bottom: 11px;
  margin: 0px;
`

export default DefaultTitle
export { LandingPageTitle, SectionTitle, PageTitle, SmallTitle }
