function getPracticeType(type) {
  if (type === 'centralIntake') return 'Central Intake'
  if (type === 'specialist') return 'Specialist'
  if (type === 'referrer') return 'Referrer'
  return 'Guest'
}

function getPracticeDisplayName(practice, cLength, sLength) {
  if (practice.type === 'centralIntake') {
    if (cLength === 'short') return practice.centralIntakeName
    else if (cLength === 'med') return `${practice.centralIntakeName} C.I.`
    else return `${practice.centralIntakeName} Central Intake`
  } else {
    if (sLength === 'short') return `Dr. ${practice.lastName}`
    else if (sLength === 'med')
      return `Dr. ${practice.firstName.charAt(0)}. ${practice.lastName}`
    else return `Dr. ${practice.firstName} ${practice.lastName}`
  }
}

function getJourneyDateDisplayName(type) {
  const typeMap = {
    referralDate: 'Referral Date',
    initialConsult: 'Initial Consult Date',
    decision: 'Decision Date',
    procedure: 'Procedure Date',
    followUpConsult: 'Follow Up Consult Date',
    completed: 'Journey Completed Date'
  }

  return typeMap[type]
}

function getGender(gender) {
  const result = ['Unknown', 'Female', 'Male', 'Other'].includes(gender)
    ? gender
    : 'Other'

  return result
}

function getGenderDescription(gender) {
  const result = ['Unknown', 'Female', 'Male', 'Other'].includes(gender)
    ? ''
    : gender

  return result
}

function getAllowReferralsDisplayName(allowReferrals) {
  if (allowReferrals === 'all') return 'Directed and Next Available'
  if (allowReferrals === 'directedOnly') return 'Directed only'
  return 'N/A'
}

function matchPostalCode(input) {
  const preformattedValue = input.toUpperCase().replace(/ /, '')

  const regex =
    /^[A-Z][0-9][A-Z][0-9][A-Z][0-9]|^[A-Z][0-9][A-Z][0-9][A-Z]|^[A-Z][0-9][A-Z][0-9]|^[A-Z][0-9][A-Z]|^[A-Z][0-9]|^[A-Z]/

  const match = preformattedValue.match(regex)

  if (match) {
    const formatted =
      match[0].length > 3
        ? match[0].substring(0, 3) + ' ' + match[0].substring(3)
        : match[0]
    return formatted
  } else {
    return ''
  }
}

function capitalize(str) {
  return str.replace(/\b(\w)/g, s => s.toUpperCase())
}

export {
  getPracticeType,
  getPracticeDisplayName,
  getJourneyDateDisplayName,
  getGender,
  getGenderDescription,
  getAllowReferralsDisplayName,
  matchPostalCode,
  capitalize
}
