import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import { FieldLabel } from '../common/StyledField'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  padding: 10px 0 0;
`

const Star = styled.span`
  color: rgba(255, 0, 0, 0.7);
`

const StyledButton = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: capitalize;
  border-radius: 3px;
  padding: 9px 9px 8px;
  color: ${props => props.txtcolor};
  background-color: ${props => props.bgcolor};
  border-radius: ${props => props.borderradius};
  border: 1px solid rgba(58, 58, 58, 0.7);
  width: 150px;

  &&:hover {
    background-color: 'inherit';
  }
`

function ReferralUrgency({ urgency = '', onSelect }) {
  const greybg = '#f5f5f5'
  const greytxt = '#a3a3a3'

  const handleClick = buttonUrgency => {
    onSelect(`${urgency === buttonUrgency ? '' : buttonUrgency}`)
  }

  return (
    <div>
      <FieldLabel>
        How <strong>urgent</strong> is this referral? <Star>*</Star>
      </FieldLabel>
      <ButtonContainer>
        <StyledButton
          buttonurgency={'Urgent'}
          txtcolor={
            urgency === 'Urgent' || urgency === '' ? '#611A15' : greytxt
          }
          bgcolor={urgency === 'Urgent' || urgency === '' ? '#FDECEA' : greybg}
          borderradius={'3px 0px 0px 3px'}
          onClick={() => handleClick('Urgent')}>
          Urgent
        </StyledButton>
        <StyledButton
          buttonurgency={'Semi-urgent'}
          txtcolor={
            urgency === 'Semi-urgent' || urgency === '' ? '#663C00' : greytxt
          }
          bgcolor={
            urgency === 'Semi-urgent' || urgency === '' ? '#FFF4E5' : greybg
          }
          borderradius={'0'}
          onClick={() => handleClick('Semi-urgent')}>
          Semi-urgent
        </StyledButton>
        <StyledButton
          buttonurgency={'Not Urgent'}
          txtcolor={
            urgency === 'Not Urgent' || urgency === '' ? '#1E4620' : greytxt
          }
          bgcolor={
            urgency === 'Not Urgent' || urgency === '' ? '#EDF7ED' : greybg
          }
          borderradius={'0px 3px 3px 0px'}
          onClick={() => handleClick('Not Urgent')}>
          Not Urgent
        </StyledButton>
      </ButtonContainer>
    </div>
  )
}

ReferralUrgency.propTypes = {
  urgency: PropTypes.string,
  onSelect: PropTypes.func
}

export default ReferralUrgency
