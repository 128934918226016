import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { InputLabel, FormControl, MenuItem } from '@material-ui/core'

import { getPracticeDisplayName } from '../../util/strings'
import { PageTitle } from '../common/Title'
import Select from '../common/Select'
import ExistingContactForm from './ExistingContactForm'
import ContactsList from './ContactsList'
import NewContactButton from './NewContactButton'

const FilterContainer = styled.div`
  display: flex;
`

const FilterByContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 15px;
  width: 40%;
  height: 100%;
`

const ContactFormContainer = styled.div`
  background: #fff;
  box-shadow: 0px 5px 15px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  padding: 0 30px;
`

const StyledInputLabel = styled(InputLabel)`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-right: 18px;
`

const StyledFormControl = styled(FormControl).attrs(props => ({
  variant: props.variant || 'outlined',
  margin: props.margin || 'dense'
}))`
  min-width: 200px;
  margin-right: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    width: 250px;
    height: 42px;
  }
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: baseline;
`

const filterOptions = [
  { value: 'name', label: 'Name' },
  { value: 'faxNumber', label: 'Fax Number' },
  { value: 'location', label: 'Location' }
]

const FilterBy = ({
  filterBy,
  filterValue,
  onFilterByChange,
  onFilterValueChange
}) => (
  <FilterContainer>
    <FilterByContainer>
      <StyledInputLabel id="selector-label">Filter by</StyledInputLabel>
    </FilterByContainer>
    <StyledFormControl>
      <StyledSelect
        select
        value={filterBy}
        aria-labelledby="selector-label"
        onChange={onFilterByChange}>
        <MenuItem value="none">None</MenuItem>
        {filterOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>

    {(() => {
      switch (filterBy) {
        case 'none':
          return null

        case 'faxNumber':
          return (
            <StyledFormControl>
              <NumberFormat
                value={filterValue}
                onChange={onFilterValueChange}
                mask="_"
                format="(###)-###-####"
                placeholder="(000)-000-0000"
                customInput={StyledSelect}
              />
            </StyledFormControl>
          )

        default:
          return (
            <StyledFormControl>
              <StyledSelect
                value={filterValue}
                onChange={onFilterValueChange}
              />
            </StyledFormControl>
          )
      }
    })()}
  </FilterContainer>
)
FilterBy.propTypes = {
  filterBy: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  onFilterByChange: PropTypes.func.isRequired,
  onFilterValueChange: PropTypes.func.isRequired
}

export const filterFunction = (filterBy, filterValue) => {
  if (!filterValue) return () => true

  switch (filterBy) {
    case 'none':
      return () => true

    case 'faxNumber':
      return contacts =>
        contacts[filterBy].toString().includes(filterValue.toString())

    default:
      return contacts =>
        contacts[filterBy].toLowerCase().includes(filterValue.toLowerCase())
  }
}

const newContact = {
  id: '',
  name: '',
  practice: '',
  faxNumber: '',
  location: ''
}

function ContactManagement({ contacts, practice }) {
  const [activeContactId, setActiveContactId] = useState(
    contacts.length > 0 ? contacts[0].id : ''
  )

  const handleSelectContact = contact => setActiveContactId(contact.id)

  function handleDelete(contact) {
    const index = contacts.findIndex(c => c.id === contact)

    if (contacts.length < 2) {
      setActiveContactId('')
    } else if (index === 0) {
      setActiveContactId(contacts[1].id)
    } else {
      setActiveContactId(contacts[index - 1].id)
    }
  }

  const [filterBy, setFilterBy] = useState('none')
  const [filterValue, setFilterValue] = useState('')

  const filteredContacts = useMemo(
    () => contacts.filter(filterFunction(filterBy, filterValue)),
    [contacts, filterBy, filterValue]
  )

  function onFilterValueChange(e) {
    if (filterBy === 'faxNumber') {
      e.target.value.indexOf('_') > -1
        ? setFilterValue(e.target.value.substr(0, e.target.value.indexOf('_')))
        : setFilterValue(e.target.value)
    } else {
      setFilterValue(e.target.value)
    }
  }

  useEffect(() => {
    if (filteredContacts.length > 0) {
      const containsId = filteredContacts.some(e => e.id === activeContactId)
      const activeId = containsId ? activeContactId : filteredContacts[0].id
      setActiveContactId(activeId)
    } else {
      setActiveContactId('')
    }
  }, [filteredContacts, activeContactId])

  return (
    <Container>
      <Header>
        <PageTitle>
          Fax Contacts - {getPracticeDisplayName(practice, 'long', 'long')}
        </PageTitle>
        <NewContactButton practice={practice.id} />
      </Header>
      <div>
        <BlueBar>
          <FilterBy
            filterBy={filterBy}
            filterValue={filterValue}
            onFilterByChange={e => {
              setFilterBy(e.target.value)
              setFilterValue('')
            }}
            onFilterValueChange={onFilterValueChange}
          />
        </BlueBar>
        <Content>
          <LeftColumn>
            <ContactsList
              contacts={filteredContacts}
              activeContactId={activeContactId}
              onSelect={handleSelectContact}
            />
          </LeftColumn>
          {filteredContacts.length > 0 && (
            <RightColumn>
              <ContactFormContainer>
                <ExistingContactForm
                  contact={
                    contacts.find(c => c.id === activeContactId)
                      ? contacts.find(c => c.id === activeContactId)
                      : newContact
                  }
                  handleDelete={handleDelete}
                />
              </ContactFormContainer>
            </RightColumn>
          )}
        </Content>
      </div>
    </Container>
  )
}

ContactManagement.propTypes = {
  contacts: PropTypes.array,
  practice: PropTypes.object.isRequired
}

export default ContactManagement
