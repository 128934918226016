import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Line = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 30px;
  margin-bottom: 10px;
  font-size: 18px;

  background: ${props =>
    props.isactive ? 'rgba(17, 147, 173, 0.05)' : 'white'};
  border-radius: 3px;
  border-left: ${props =>
    props.isactive ? '3px solid #1193ad' : '3px solid transparent'};
  color: rgba(58, 58, 58, 0.7);
  cursor: pointer;

  :hover {
    background: rgba(17, 147, 173, 0.05);
    border-left: 3px solid #1193ad;
  }
`

const ContactName = styled.p`
  color: rgba(17, 147, 173, 1);
`

const ContactsList = ({
  contacts = [],
  activeContactId,
  onSelect = () => {}
}) => (
  <Content>
    {contacts.length > 0 &&
      contacts.map(contact => {
        return (
          <Line
            isactive={contact.id === activeContactId}
            key={contact.id}
            onClick={() => onSelect(contact)}>
            <ContactName>{contact.name}&nbsp;&nbsp;</ContactName>
            {contact.location !== '' && <> -&nbsp;&nbsp;{contact.location}</>}
          </Line>
        )
      })}
  </Content>
)

ContactsList.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      faxNumber: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired
    })
  ),
  activeContactId: PropTypes.string,
  onSelect: PropTypes.func
}

export default ContactsList
