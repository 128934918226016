import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useForm } from 'react-hook-form'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import EditIcon from 'mdi-react/PencilIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import Select from '../common/Select'
import { IconButton } from '../common/Button'
import { FieldLabel, FieldValue } from '../common/StyledField'
import { ErrorMessage } from '../common/UserAlert'

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const StyledSelect = styled(Select)`
  &.MuiFormControl-root {
    margin-top: 10px;
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 5px;
`

const ButtonContainer = styled.div`
  button:last-child {
    margin-left: 10px;
  }
`
const LabelButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`
const NewEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: rgba(6, 153, 135, 0.05);
`

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
`
const UPDATE_EMAIL = gql`
  mutation updateEmail($email: String!) {
    updateEmail(email: $email) {
      email
      id
    }
  }
`

function ChangeEmailButton({ user }) {
  const [mutate, { loading, error }] = useMutation(UPDATE_EMAIL)
  const [isDisabled, setIsDisabled] = useState(true)
  const { errors, handleSubmit, getValues, register, reset } = useForm()

  const handleEdit = () => setIsDisabled(false)
  function handleCancel() {
    reset({
      email: user.email
    })
    setIsDisabled(true)
  }

  async function onSubmit(values) {
    try {
      await mutate({ variables: { email: values.email } })

      setIsDisabled(true)
    } catch (e) {
      console.error('Error updating email:', e.message)
    }
  }

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const required = 'This field is required'

  return (
    <Form aria-label="Change email" onSubmit={handleSubmit(onSubmit)}>
      <LabelButtonContainer>
        {isDisabled ? (
          <StyledIconButton
            color="secondary"
            onClick={handleEdit}
            aria-label="Edit email">
            <EditIcon />
          </StyledIconButton>
        ) : (
          <ButtonContainer>
            <StyledIconButton color="secondary" onClick={handleCancel}>
              <CloseIcon />
            </StyledIconButton>
            <StyledIconButton
              padding="tiny"
              color="secondary"
              type="submit"
              loading={loading}
              aria-label="Save email">
              <ContentSaveIcon />
            </StyledIconButton>
          </ButtonContainer>
        )}
        <FieldLabel>Email</FieldLabel>
      </LabelButtonContainer>
      {isDisabled ? (
        <FieldValue>{user.email}</FieldValue>
      ) : (
        <NewEmailContainer>
          <Text>
            We will send a validation message to your new email address. Please
            click the &quot;Save&quot; button to complete the change.
          </Text>
          <StyledSelect
            id="email"
            name="email"
            label="New Email"
            type="email"
            defaultValue=""
            autoFocus
            margin="dense"
            fullWidth
            disabled={loading}
            error={!!errors.email}
            helperText={errors?.email?.message}
            inputRef={register({
              pattern: { value: emailRegex, message: 'Invalid email' },
              required
            })}
          />

          <StyledSelect
            id="confirmEmail"
            name="confirmEmail"
            label="Confirm Email"
            type="email"
            defaultValue=""
            margin="dense"
            fullWidth
            disabled={loading}
            error={!!errors.confirmEmail}
            helperText={errors?.confirmEmail?.message}
            inputRef={register({
              validate: {
                emailsMatch: value =>
                  value === getValues().email || 'Emails do not match'
              },
              required
            })}
          />
        </NewEmailContainer>
      )}

      {error && <ErrorMessage>Hm, an error occurred. Try again.</ErrorMessage>}
    </Form>
  )
}

ChangeEmailButton.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string
  })
}

export default ChangeEmailButton
