import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormContext, Controller } from 'react-hook-form'
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  ListItemText
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { getPracticeDisplayName } from '../../util/strings'
import { FieldLabel } from '../common/StyledField'
import {
  SelectMultiple,
  SelectMenuItem,
  SelectCheckbox
} from '../common/SelectMultiple'
import Select from '../common/Select'
import {
  getAllLeafCategoryIds,
  getFactorCategoryName,
  getRegionNameByCategoryId,
  getSpecialtyByCategoryId,
  getAllSpecialtyIds
} from '../../util/categories'
import { SecondaryButton } from '../common/Button'

const AllFields = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: 20px;
  padding-bottom: 30px;
`

const Field = styled.div`
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 50%;
  padding: 27px 5px 0 25px;
`

const FieldLarge = styled(Field)`
  flex-basis: 100%;
  padding: 27px 5px 0 25px;
`

const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MultiSelectFormControl = styled(FormControl).attrs(props => ({
  variant: props.variant || 'outlined',
  margin: props.margin || 'dense'
}))`
  margin: 7px 0 0;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  margin-bottom: -5px;

  .MuiTypography-body1 {
    font-family: Source Sans Pro, sans-serif;
    color: #3a3a3a;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    white-space: nowrap;
    letter-spacing: 0;
  }
  .Mui-checked {
    color: rgba(17, 147, 173, 1);
  }
`

const Star = styled.span`
  color: rgba(255, 0, 0, 0.7);
`

const StyledFormHelperText = styled(FormHelperText)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  margin-top: 0px;
`

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiInputBase-root {
    margin-bottom: 8px;
  }
  .MuiFormHelperText-root.Mui-error {
    padding-top: 0px;
  }
  .MuiFormHelperText-root {
    margin-top: 0;
  }
`

const LabelButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const CategoryButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot[class*='MuiInput-root']
    .MuiAutocomplete-input:first-child {
    padding: 10px 0px;
  }
  .MuiInputBase-root {
    padding-left: 12px;
    margin-top: 0;
  }
  .MuiAutocomplete-endAdornment {
    right: 10px;
  }
`

const MultiSelectItem = styled.div`
  display: flex;
  align-items: flex-start;
`

const InfoStyling = styled.p`
  color: rgba(58, 58, 58, 0.7);
  font-size: 14px;
  margin: 0px;
  font-style: italic;
`

function ReferralFilters({
  practices = [],
  defaultDirection = 'Sent',
  triggerReset = false,
  setTriggerReset = () => {}
}) {
  const { control, errors, watch, setValue, getValues, reset } =
    useFormContext()

  const [categories, setCategories] = useState([])

  const direction = watch('direction')
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const selectedPractices = watch('selectedPractices')
  const selectedCategories = watch('selectedCategories')

  const getSpecialties = useCallback((currPractices, currDirection) => {
    const specialties =
      currDirection === 'Received'
        ? new Set(
            currPractices
              .filter(p => p.type === 'specialist')
              .map(p => p.specialty)
              .filter(s => s !== null)
          )
        : new Set(getAllSpecialtyIds())
    return specialties
  }, [])

  const getCategories = useCallback(specialties => {
    const leafCategories = []

    for (const specialty of specialties) {
      const currCategories = getAllLeafCategoryIds(specialty).map(c => {
        return {
          category: getFactorCategoryName(c),
          categoryId: c
        }
      })

      leafCategories.push(...currCategories)
    }

    return leafCategories
  }, [])

  // Need this so the parent component can trigger a reset
  useEffect(() => {
    if (triggerReset) {
      const specialties = getSpecialties(practices, defaultDirection)
      const leafCategories = getCategories(specialties)

      setCategories(leafCategories)
      reset({
        direction: defaultDirection,
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
        endDate: new Date(),
        selectedPractices: practices,
        selectedCategories: leafCategories
      })
      setTriggerReset(false)
    }
  }, [
    triggerReset,
    setTriggerReset,
    practices,
    defaultDirection,
    getSpecialties,
    getCategories,
    reset
  ])

  useEffect(() => {
    if (practices.length > 0 && getValues('selectedPractices').length === 0) {
      setValue('selectedPractices', practices)

      const specialties = getSpecialties(practices, defaultDirection)
      const leafCategories = getCategories(specialties)

      setCategories(leafCategories)
      setValue('selectedCategories', leafCategories)
    }
  }, [
    practices,
    defaultDirection,
    getSpecialties,
    getCategories,
    getValues,
    setValue
  ])

  function onChangeDirection(currDirection) {
    const specialties = getSpecialties(selectedPractices, currDirection)
    const leafCategories = getCategories(specialties)

    setCategories(leafCategories)
    setValue('selectedCategories', leafCategories)
  }

  function onChangeSelectedPractices(e) {
    const filteredPractices = practices.filter(p =>
      e.target.value.includes(p.id)
    )

    const specialties = getSpecialties(filteredPractices, direction)
    const leafCategories = getCategories(specialties)
    const leafCategoryIds = leafCategories.map(c => c.categoryId)
    const currSelectedCategories = selectedCategories.filter(c =>
      leafCategoryIds.includes(c.categoryId)
    )

    setValue('selectedPractices', filteredPractices)
    setValue('selectedCategories', currSelectedCategories)
    setCategories(leafCategories)
  }

  const onSelectAllCategories = () => setValue('selectedCategories', categories)
  const onClearAllCategories = () => setValue('selectedCategories', [])

  return (
    <AllFields>
      <FieldLarge>
        <FieldLabel>
          Direction <Star>*</Star>
        </FieldLabel>
        <Controller
          name="direction"
          control={control}
          render={({ onChange, value, name }) => (
            <RadioGroup
              name={name}
              onChange={e => {
                onChange(e.target.value)
                onChangeDirection(e.target.value)
              }}
              row>
              {['Sent', 'Received', 'Both'].map(opt => {
                return (
                  <StyledFormControlLabel
                    key={opt}
                    value={opt}
                    control={<Radio size="small" checked={value === opt} />}
                    label={opt}
                  />
                )
              })}
            </RadioGroup>
          )}
        />
        <StyledFormHelperText
          id="direction-helper-text"
          error={!!errors.direction}>
          {errors?.direction?.message}
        </StyledFormHelperText>
      </FieldLarge>
      <Field>
        <Controller
          name="startDate"
          control={control}
          render={({ ref, ...props }) => (
            <StyledKeyboardDatePicker
              {...props}
              TextFieldComponent={Select}
              id="startDate"
              inputRef={ref}
              label="Start Date"
              mask="__/___/____"
              refuse={/[^a-zA-Z0-9]+/gi}
              format="dd/MMM/yyyy"
              placeholder="DD/MMM/YYYY"
              disableFuture
              views={['year', 'month', 'date']}
              openTo="date"
              maxDate={endDate}
              maxDateMessage={
                startDate > new Date(new Date().setHours(23, 59, 59, 999))
                  ? 'Date cannot be in the future'
                  : 'Date should be before end date'
              }
              InputLabelProps={{
                shrink: true,
                required: true
              }}
            />
          )}
        />
        <StyledFormHelperText
          id="startDate-helper-text"
          error={!!errors.startDate}>
          {errors?.startDate?.message}
        </StyledFormHelperText>
      </Field>
      <Field>
        <Controller
          name="endDate"
          control={control}
          render={({ ref, ...props }) => (
            <StyledKeyboardDatePicker
              {...props}
              TextFieldComponent={Select}
              id="endDate"
              inputRef={ref}
              label="End Date"
              mask="__/___/____"
              refuse={/[^a-zA-Z0-9]+/gi}
              format="dd/MMM/yyyy"
              placeholder="DD/MMM/YYYY"
              disableFuture
              views={['year', 'month', 'date']}
              openTo="date"
              minDate={startDate}
              maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
              minDateMessage="Date should be after start date"
              maxDateMessage="Date cannot be in the future"
              invalidLabel="Date is invalid"
              InputLabelProps={{
                shrink: true,
                required: true
              }}
            />
          )}
        />
        <StyledFormHelperText id="endDate-helper-text" error={!!errors.endDate}>
          {errors?.endDate?.message}
        </StyledFormHelperText>
      </Field>
      <FieldLarge>
        <MultiSelectContainer>
          <FieldLabel id="practice-selector-label">Practices</FieldLabel>
          <Controller
            name="selectedPractices"
            control={control}
            render={({ ref, ...props }) => (
              <MultiSelectFormControl>
                <SelectMultiple
                  labelId="practice-selector-label"
                  id="selectedPractices"
                  multiple
                  value={selectedPractices.map(p => p.id)}
                  onChange={onChangeSelectedPractices}
                  renderValue={selected => {
                    const selectedPracticeNames = selected.map(id => {
                      const practice = practices.find(s => s.id === id)
                      return getPracticeDisplayName(practice, 'long', 'long')
                    })
                    return selectedPracticeNames.join(', ')
                  }}>
                  {practices.map(p => (
                    <SelectMenuItem key={p.id} value={p.id}>
                      <SelectCheckbox
                        size="small"
                        checked={
                          selectedPractices.map(s => s.id).indexOf(p.id) > -1
                        }
                      />
                      <ListItemText
                        primary={getPracticeDisplayName(p, 'long', 'long')}
                      />
                    </SelectMenuItem>
                  ))}
                </SelectMultiple>
              </MultiSelectFormControl>
            )}
          />
        </MultiSelectContainer>
      </FieldLarge>
      <FieldLarge>
        <MultiSelectContainer>
          <LabelButtonContainer>
            <FieldLabel id="categories-selector-label">Categories</FieldLabel>
            <CategoryButtonContainer>
              <SecondaryButton
                color="secondary"
                padding="short"
                onClick={onClearAllCategories}>
                Clear All
              </SecondaryButton>
              <SecondaryButton
                color="secondary"
                padding="short"
                onClick={onSelectAllCategories}>
                Select All
              </SecondaryButton>
            </CategoryButtonContainer>
          </LabelButtonContainer>
          <Controller
            name="selectedCategories"
            control={control}
            render={({ ref, ...props }) => (
              <MultiSelectFormControl>
                <StyledAutocomplete
                  aria-labelledby="categories-selector-label"
                  id="selectedCategories"
                  multiple
                  limitTags={2}
                  disableCloseOnSelect
                  value={selectedCategories}
                  options={categories}
                  getOptionLabel={option => option.category}
                  getOptionSelected={(option, value) =>
                    option.category === value.category &&
                    option.categoryId === value.categoryId
                  }
                  onChange={(event, newValue) =>
                    setValue('selectedCategories', newValue)
                  }
                  renderOption={(option, { selected }) => (
                    <MultiSelectItem key={option.categoryId}>
                      <SelectCheckbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <div>
                        {option.category}
                        <InfoStyling>
                          {getRegionNameByCategoryId(option.categoryId) +
                            ' ' +
                            getSpecialtyByCategoryId(option.categoryId)}
                        </InfoStyling>
                      </div>
                    </MultiSelectItem>
                  )}
                  renderInput={params => <Select {...params} />}
                />
              </MultiSelectFormControl>
            )}
          />
        </MultiSelectContainer>
      </FieldLarge>
    </AllFields>
  )
}

ReferralFilters.propTypes = {
  practices: PropTypes.array,
  defaultDirection: PropTypes.string,
  triggerReset: PropTypes.bool,
  setTriggerReset: PropTypes.func
}

export default ReferralFilters
