import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Controller, useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import Select from '../common/Select'

const AllFields = styled.div`
  padding: 0 35px 30px;
`

const StyledSelect = styled(Select)`
  &.MuiFormControl-root {
    margin-top: 27px;
  }
  .MuiInputBase-root.Mui-disabled {
    opacity: 1;
    border: none;
    min-height: 36px;
  }
  .MuiInputBase-input.Mui-disabled {
    opacity: 1;
    padding-left: 16px;
  }
`

const NameSelect = styled(StyledSelect)`
  .MuiInputBase-root .MuiInputBase-input {
    text-transform: capitalize;
  }
`

function FaxContact({ isEditing = false, newContact = false }) {
  const { control, errors, register } = useFormContext()
  return (
    <AllFields>
      <NameSelect
        id={newContact ? 'newName' : 'name'}
        name="name"
        label="Name"
        inputRef={register()}
        InputLabelProps={{
          required: !newContact && !isEditing ? false : true
        }}
        disabled={!newContact && !isEditing}
        error={!!errors.name}
        helperText={errors?.name?.message}
      />
      <Controller
        name="faxNumber"
        control={control}
        render={({ onChange, value, name }) => (
          <NumberFormat
            id={newContact ? 'newFaxNumber' : 'faxNumber'}
            name={name}
            label="Fax no."
            value={value}
            onChange={onChange}
            mask="_"
            format="(###)-###-####"
            placeholder="(000)-000-0000"
            customInput={StyledSelect}
            disabled={!newContact && !isEditing}
            error={!!errors.faxNumber}
            helperText={errors?.faxNumber?.message}
            FormHelperTextProps={{ role: 'alert' }}
            InputLabelProps={{
              required: !newContact && !isEditing ? false : true,
              shrink: true
            }}
          />
        )}
      />
      <StyledSelect
        id={newContact ? 'newLocation' : 'location'}
        name="location"
        label="Location"
        inputRef={register()}
        disabled={!newContact && !isEditing}
        error={!!errors.location}
        helperText={errors?.location?.message}
      />
    </AllFields>
  )
}

FaxContact.propTypes = {
  isEditing: PropTypes.bool,
  newContact: PropTypes.bool
}

export default FaxContact
