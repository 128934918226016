import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Redirect, useParams } from 'react-router-dom'

import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import PracticeManagement from '../settings/PracticeManagement'
import ConfirmInvitationDialog from '../settings/ConfirmInvitationDialog'

import { getPracticeCategoryFactors } from '../../util/factors'

const GET_PRACTICE_MANAGEMENT_DATA = gql`
  query getPracticeManagementData {
    getPractices {
      id
      firstName
      lastName
      centralIntakeName
      clinicName
      msp
      specialty
      specialties
      phone
      region
      specialty
      allowReferrals
      address
      city
      province
      postalCode
      type
      isTest
      isVerified
      faxNumber
      emrNumber
      memberships {
        id
        user {
          id
          email
        }
      }
      invitations {
        id
        email
        user {
          id
        }
      }
      categoryFactors {
        category
        factor
      }
    }
    getCurrentUser {
      id
    }
  }
`

function PracticeManagementPage() {
  const { loading, error, data } = useQuery(GET_PRACTICE_MANAGEMENT_DATA)
  const { invitationId, practiceId } = useParams()
  const [isOpen, setIsOpen] = useState(invitationId !== undefined)

  if (error) return <LoadingError />
  if (loading) return <Loading />
  if (data.getPractices.length === 0) return <Redirect to={'/refer'} />

  return (
    <>
      <ConfirmInvitationDialog
        isOpen={isOpen}
        id={invitationId}
        onClose={() => {
          setIsOpen(false)
        }}
      />
      <PracticeManagement
        practice={getPracticeCategoryFactors(
          data.getPractices.find(p => p.id === practiceId)
        )}
        currentUser={data.getCurrentUser}
      />
    </>
  )
}

export default PracticeManagementPage
