import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core'
import CheckBoldIcon from 'mdi-react/CheckBoldIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import Button, { IconButton } from '../common/Button'
import { SectionTitle } from '../common/Title'
import { InfoMessage } from '../common/UserAlert'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
  width: 100%;
`

const BackButton = styled(IconButton)`
  padding: 4px;
  .mdi-icon {
    height: 25px;
    width: 25px;
  }
`

const Placeholder = styled.div`
  width: 35px;
`

const Title = styled(SectionTitle)`
  color: #3a3a3a;
  margin: 0;
`

const StyledCheckbox = styled(Checkbox)`
  color: #3a3a3a;

  &.Mui-checked {
    color: #1193ad !important;
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  line-height: 24px;
  display: flex;
  align-items: flex-start;

  .MuiTypography-body1 {
    padding-left: 5px;
    padding-top: 4px;
  }
`

const StyledLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #3a3a3a;
`

const WarningAstericks = styled.span`
  color: #e84c3d;
`

const StyledLink = styled.a`
  font-weight: 600;
  text-decoration: none;
  color: #3598db;
  :hover {
    color: #3598db;
    background: #fff;
    opacity: 0.6;
    text-decoration: underline solid #3598db;
  }
`

const ConfirmButton = styled(Button)`
  &.MuiButton-root.Mui-disabled {
    color: #fff;
  }
`

function TermsOfUse({
  terms = { isSubscriber: false, isConfirmed: false },
  onBack = () => {},
  onSubmit = () => {},
  open
}) {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      isSubscriber: terms.isSubscriber,
      healthcare: terms.isConfirmed,
      termsOfUse: terms.isConfirmed
    },
    mode: 'all'
  })
  const { isValid } = formState

  const onSubmitTerms = terms => {
    onSubmit({ isSubscriber: terms.isSubscriber, isConfirmed: true })
  }

  return (
    <>
      <Container>
        <BackButton onClick={onBack} color="secondary">
          <ChevronLeftIcon />
        </BackButton>
        <Title id="terms-of-use-title">Terms of Use</Title>
        <Placeholder />
      </Container>
      <InfoMessage>
        Fields marked with <WarningAstericks>*</WarningAstericks> are required
        for registration
      </InfoMessage>
      <form
        aria-labelledby="terms-of-use-title"
        onSubmit={handleSubmit(onSubmitTerms)}
        noValidate>
        <FormGroup>
          <StyledFormControlLabel
            control={
              <Controller
                name="healthcare"
                control={control}
                rules={{
                  required: true,
                  validate: value => value === true
                }}
                render={({ name, onChange, onBlur, value, ref }) => (
                  <StyledCheckbox
                    name={name}
                    id="healthcare"
                    label="Healthcare Provider or Staff"
                    inputRef={ref}
                    checked={value}
                    onBlur={onBlur}
                    onChange={e => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={
              <StyledLabel htmlFor="healthcare">
                I am a Healthcare Provider or Staff.
                <WarningAstericks> *</WarningAstericks>
              </StyledLabel>
            }
          />
          <StyledFormControlLabel
            control={
              <Controller
                name="termsOfUse"
                control={control}
                rules={{
                  required: true,
                  validate: value => value === true
                }}
                render={({ name, onChange, onBlur, value, ref }) => (
                  <StyledCheckbox
                    name={name}
                    id="termsOfUse"
                    label="Agree to Terms of Use"
                    inputRef={ref}
                    checked={value}
                    onBlur={onBlur}
                    onChange={e => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={
              <StyledLabel htmlFor="termsOfUse">
                I have read, understood and agree to the CLINNECT{' '}
                <StyledLink
                  href="https://clinnect.ca/terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Terms of Use
                </StyledLink>
                .<WarningAstericks> *</WarningAstericks>
              </StyledLabel>
            }
          />
          <StyledFormControlLabel
            control={
              <Controller
                name="isSubscriber"
                control={control}
                render={({ name, onChange, onBlur, value, ref }) => (
                  <StyledCheckbox
                    name={name}
                    id="isSubscriber"
                    inputRef={ref}
                    checked={value}
                    onBlur={onBlur}
                    onChange={e => onChange(e.target.checked)}
                    label="Consent to Promotion"
                  />
                )}
              />
            }
            label={
              <StyledLabel htmlFor="isSubscriber">
                I consent to receiving text, email or other form of
                communications about Clinnect news, software updates and the
                latest information about products and services from Clinnect.
              </StyledLabel>
            }
          />
        </FormGroup>
        <ButtonContainer>
          <ConfirmButton type="submit" disabled={!isValid} padding="small">
            <CheckBoldIcon />
            Confirm
          </ConfirmButton>
        </ButtonContainer>
      </form>
    </>
  )
}

TermsOfUse.propTypes = {
  terms: PropTypes.shape({
    isSubscriber: PropTypes.bool,
    isConfirmed: PropTypes.bool
  }),
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool
}

export default TermsOfUse
