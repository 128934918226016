import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoadingText = styled.h3`
  color: #393e46;
  font-weight: 500;
`

const LoadingSpinner = styled(CircularProgress).attrs(props => ({
  size: props.size || 70,
  thickness: props.thickness || 2.3
}))`
  && {
    color: #1193ad;
  }
`

const Loading = () => (
  <Container>
    <LoadingSpinner />
    <LoadingText>Loading...</LoadingText>
  </Container>
)

export default Loading
