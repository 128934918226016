import ReactDOM from 'react-dom'
import { useReactToPrint } from 'react-to-print'

const el = document.createElement('div')

function usePrinter(printerOpts) {
  const handlePrint = useReactToPrint({
    content: () => el,
    ...printerOpts
  })

  function print(component) {
    ReactDOM.render(component, el, handlePrint)
  }

  return print
}

export default usePrinter
