import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import { format } from 'date-fns'

import MapMarkerIcon from 'mdi-react/MapMarkerIcon'
import MapMarkerCheckIcon from 'mdi-react/MapMarkerCheckIcon'
import RoadImage from '../../images/road.svg'

import { getJourneyDateDisplayName } from '../../util/strings'

const typeColorMap = {
  referralDate: '#A5BE00',
  initialConsult: '#B4436C',
  decision: '#404E7C',
  procedure: '#33CCEB',
  followUpConsult: '#BBA0B2',
  completed: '#A5BE00'
}

const LocationsContainer = styled.div`
  margin-bottom: -12px;
  position: relative;
  width: 100%;
  height: 28px;
`

const LocationContainer = styled.div`
  position: absolute;
  left: ${props =>
    `calc((100% - 25px - ${props.extraoffset}px) * ${props.location} + ${props.offset})`};
`

const StyledMapMarker = styled(MapMarkerIcon)`
  color: ${props => (props.type ? typeColorMap[props.type] : '#3a3a3a')};
`

const StyledMapMarkerCheck = styled(MapMarkerCheckIcon)`
  color: ${props => (props.type ? typeColorMap[props.type] : '#3a3a3a')};
`

const TooltipText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin: 3px 5px;
`

const BoldText = styled(TooltipText)`
  font-weight: 600;
`

const StyledImage = styled.img`
  width: 100%;
  height: 19px;
  z-index: -1;
`

function getDaysBetween(a, b) {
  const firstDate = a.date.setHours(0, 0, 0, 0)
  const secondDate = b.date.setHours(0, 0, 0, 0)

  const daysBetween = (secondDate - firstDate) / (1000 * 3600 * 24)
  return daysBetween
}

function Roadmap({ journeyDates }) {
  const totalDaysBetween = getDaysBetween(
    journeyDates[0],
    journeyDates[journeyDates.length - 1]
  )

  const totalDays = totalDaysBetween === 0 ? 1 : totalDaysBetween

  return (
    <>
      <LocationsContainer>
        {journeyDates.map((journeyDate, i) => (
          <LocationContainer
            key={i}
            location={getDaysBetween(journeyDates[0], journeyDate) / totalDays}
            offset={i * 15 + 'px'}
            extraoffset={(journeyDates.length - 1) * 15}>
            <Tooltip
              title={
                <>
                  <BoldText>
                    {getJourneyDateDisplayName(journeyDate.type)}
                  </BoldText>
                  <TooltipText>
                    {format(journeyDate.date, 'dd/MMM/yyyy')}
                  </TooltipText>
                  {journeyDate.type === 'procedure' &&
                    journeyDate.billingCode && (
                      <TooltipText>
                        Billing Code: {journeyDate.billingCode}
                      </TooltipText>
                    )}
                </>
              }
              arrow>
              <div>
                {journeyDate.type !== 'completed' ? (
                  <StyledMapMarker
                    type={journeyDate.type}
                    aria-label={`${getJourneyDateDisplayName(
                      journeyDate.type
                    )} Indicator`}
                  />
                ) : (
                  <StyledMapMarkerCheck
                    type={journeyDate.type}
                    aria-label="Journey Completed Indictor"
                  />
                )}
              </div>
            </Tooltip>
          </LocationContainer>
        ))}
      </LocationsContainer>
      <StyledImage src={RoadImage} alt="Roadmap" />
    </>
  )
}

Roadmap.propTypes = {
  journeyDates: PropTypes.array.isRequired
}

export default Roadmap
