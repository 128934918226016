import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@material-ui/core'

function textColor({ urgency }) {
  if (urgency === 'Urgent') return '#611A15'
  if (urgency === 'Semi-urgent') return '#663C00'
  return '#1E4620'
}

function bgColor({ urgency }) {
  if (urgency === 'Urgent') return '#FDECEA'
  if (urgency === 'Semi-urgent') return '#FFF3E0'
  return '#EDF7ED'
}

const StyledBox = styled(Box)`
  font-family: 'Source Sans Pro', sans-serif;
  background-color: ${urgency => bgColor(urgency)};
  color: ${urgency => textColor(urgency)};
  padding: 9px 50px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
`

const StyledText = styled.span`
  color: ${urgency => textColor(urgency)};
`

function UrgencyIndicator({ urgency, box = false }) {
  if (!box) return <StyledText urgency={urgency}>{urgency}</StyledText>
  return (
    <StyledBox urgency={urgency} component="div" display="inline">
      {urgency}
    </StyledBox>
  )
}

UrgencyIndicator.propTypes = {
  urgency: PropTypes.string,
  box: PropTypes.bool
}

export default UrgencyIndicator
