import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Dialog, DialogContent } from '@material-ui/core'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import styled from 'styled-components'

import useAccount from '../../hooks/useAccount'
import { getDecryptedReferral } from '../../util/decryptReferrals'
import {
  deriveAesKey,
  unwrapHmacKey,
  unwrapEccKey,
  importPublicKey,
  deriveHmacToken
} from '../../crypto'
import specialties from '../../specialties.json'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import NewPracticeLanding from '../landing/NewPracticeLanding'
import ReferralForm from '../refer/ReferralForm'
import PendingInvitationLanding from '../landing/PendingInvitationLanding'
import CongratulationsLanding from '../landing/CongratulationsLanding'
import ReferralConfirmation from '../refer/ReferralConfirmation'
import ReferralDuplicates from '../refer/ReferralDuplicates'
import { getPracticeDisplayName, capitalize } from '../../util/strings'
import {
  getSpecialtyId,
  getCategoryId,
  getSubcategoryName
} from '../../util/categories'
import { getBinaryFile } from '../../util/files'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
`

const LandingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9fbfe;
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 90%;
    padding: 0 30px;
    background: #f9fbfe;
  }
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  :first-child {
    padding-top: 0px;
  }
`

const GET_REFERRAL_PAGE = gql`
  query getReferralPage {
    getInvitations {
      id
      inviter {
        id
        firstName
        lastName
      }
    }
    getPractices {
      id
      firstName
      lastName
      faxNumber
      msp
      centralIntakeName
      clinicName
      phone
      type
      specialty
      specialties
      publicKey
      region
      group
      searchKey {
        hashKey {
          iv
          encryptedData
        }
        publicKey
      }
      memberships {
        privateKey {
          encryptedData
          iv
        }
        publicKey
        user {
          id
        }
      }
      triageResponseActiveDate
    }
    getSpecialists {
      id
      firstName
      lastName
      msp
      clinicName
      phone
      type
      specialty
      specialties
      allowReferrals
      publicKey
      region
      group
      isTest
      emrSyncActiveDate
      memberships {
        user {
          id
        }
      }
    }
    getFaxContacts {
      id
      practice
      name
      location
      faxNumber
    }
  }
`

const GET_NEXT_SPECIALIST = gql`
  query getNextSpecialist($category: ID!, $region: ID!) {
    getNextSpecialist(category: $category, region: $region) {
      id
      firstName
      lastName
      msp
      clinicName
      phone
      publicKey
      specialty
      emrSyncActiveDate
      memberships {
        user {
          id
        }
      }
    }
  }
`

const GET_FAX = gql`
  query getFax($id: ID!) {
    getFax(id: $id) {
      id
      faxContact {
        ... on FaxNumber {
          faxNumber
        }
        ... on FaxContact {
          faxNumber
        }
      }
      practice {
        id
      }
    }
  }
`

const GET_REFERRAL = gql`
  query getReferral($id: ID!) {
    getReferral(id: $id) {
      id
      region
      urgency
      gender
      category
      referralDate
      encryptedData {
        iv
        encryptedData
      }
      state
      redirectedTo
      sender {
        publicKey
        encryptionKey {
          encryptedData
          iv
        }
        practice {
          id
          memberships {
            privateKey {
              encryptedData
              iv
            }
            publicKey
            user {
              id
            }
          }
        }
      }
      recipient {
        practice {
          id
          firstName
          lastName
        }
      }
      files {
        iv
        key
      }
      initialFax {
        id
        faxContact {
          ... on FaxNumber {
            faxNumber
          }
          ... on FaxContact {
            faxNumber
          }
        }
        practice {
          id
        }
      }
      referringProvider {
        id
        name
        location
        faxNumber
      }
    }
  }
`

const GET_DUPLICATE_REFERRALS_DATA = gql`
  query getDuplicateReferralsData(
    $filter: FilterInput
    $before: String
    $after: String
  ) {
    getReferrals(type: both, filter: $filter, before: $before, after: $after) {
      referrals {
        id
        region
        urgency
        gender
        category
        referralDate
        createdAt
        encryptedData {
          iv
          encryptedData
        }
        state
        sender {
          publicKey
          encryptionKey {
            encryptedData
            iv
          }
          practice {
            id
            firstName
            lastName
            msp
            centralIntakeName
            type
            phone
            specialty
            emrSyncActiveDate
            memberships {
              privateKey {
                encryptedData
                iv
              }
              publicKey
              user {
                id
              }
            }
          }
        }
        recipient {
          publicKey
          encryptionKey {
            encryptedData
            iv
          }
          practice {
            id
            firstName
            lastName
            msp
            phone
            specialty
            emrSyncActiveDate
            memberships {
              privateKey {
                encryptedData
                iv
              }
              publicKey
              user {
                id
              }
            }
          }
        }
        files {
          iv
          key
        }
        faxToEmr {
          id
          state
        }
      }
    }
  }
`

const CREATE_DOWNLOAD_URL = gql`
  mutation createDownloadUrl($referral: ID!, $file: Int!) {
    createDownloadUrl(referral: $referral, file: $file)
  }
`

const ReferralPage = () => {
  const location = useLocation()

  const isTest = new URLSearchParams(location.search).get('isTest') === 'true'
  const faxId = new URLSearchParams(location.search).get('faxId')
  const redirectedFrom = new URLSearchParams(location.search).get(
    'redirectedFrom'
  )

  const [referral, setReferral] = useState()
  const [reset, setReset] = useState(() => {})
  const [isResetting, setIsResetting] = useState(false)
  const [isEditing, setIsEditing] = useState(true)
  const [searchKeys, setSearchKeys] = useState(null)
  const [phn, setPhn] = useState(null)
  const [filter, setFilter] = useState(null)
  const [duplicateReferrals, setDuplicateReferrals] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [populatedPatientInfo, setPopulatedPatientInfo] = useState()

  const [createDownloadUrl] = useMutation(CREATE_DOWNLOAD_URL)
  const referralPageResult = useQuery(GET_REFERRAL_PAGE)
  const faxResult = useQuery(GET_FAX, {
    variables: { id: faxId },
    skip: faxId === null
  })
  const referralResult = useQuery(GET_REFERRAL, {
    variables: { id: redirectedFrom },
    skip: redirectedFrom === null
  })
  const [getDuplicateReferralsData, duplicateReferralsDataResult] =
    useLazyQuery(GET_DUPLICATE_REFERRALS_DATA, { fetchPolicy: 'network-only' })
  const [getNextSpecialist, nextSpecialistResult] = useLazyQuery(
    GET_NEXT_SPECIALIST,
    { fetchPolicy: 'network-only' }
  )

  const practices = referralPageResult.data
    ? referralPageResult.data.getPractices
    : null

  const { getAccount } = useAccount()
  const history = useHistory()

  useEffect(() => {
    async function decryptSearchKeys(practices) {
      const account = await getAccount()

      const allSearchKeys = []
      for (const practice of practices) {
        const membership = practice.memberships.find(
          m => m.user.id === account.id
        )

        const membershipAesKey = await deriveAesKey(
          account.privateKey,
          await importPublicKey(membership.publicKey)
        )

        const practicePrivateKey = await unwrapEccKey(
          membership.privateKey.encryptedData,
          membershipAesKey,
          membership.privateKey.iv,
          true
        )

        const ephemeralPublicKey = await importPublicKey(
          practice.searchKey.publicKey
        )
        const wrappingKey = await deriveAesKey(
          practicePrivateKey,
          ephemeralPublicKey
        )
        const searchKey = await unwrapHmacKey(
          practice.searchKey.hashKey.encryptedData,
          wrappingKey,
          practice.searchKey.hashKey.iv
        )

        allSearchKeys.push(searchKey)
      }
      setSearchKeys(allSearchKeys)
    }

    if (practices) decryptSearchKeys(practices)
  }, [practices, getAccount])

  useEffect(() => {
    async function getFilterString() {
      const searchTokens = []
      for (const searchKey of searchKeys) {
        searchTokens.push(await deriveHmacToken(searchKey, phn))
      }

      const filter = {
        type: 'phn',
        value: searchTokens.join(':')
      }

      setFilter(filter)
    }

    if (phn && searchKeys) getFilterString()
    else setDuplicateReferrals(null)
  }, [searchKeys, phn])

  useEffect(() => {
    if (filter) {
      getDuplicateReferralsData({ variables: { type: 'both', filter } })
    }
  }, [filter, getDuplicateReferralsData])

  useEffect(() => {
    async function decryptReferrals() {
      const duplicateReferrals = await Promise.all(
        duplicateReferralsDataResult.data.getReferrals.referrals.map(
          decryptReferral
        )
      )

      setDuplicateReferrals(duplicateReferrals)
    }

    async function decryptReferral(referral) {
      const account = await getAccount()

      const senderMembership = referral.sender.practice.memberships.find(
        m => m.user.id === account.id
      )

      const encryptedData = await getDecryptedReferral(
        referral,
        account.id,
        account,
        senderMembership ? true : false
      )

      if (!encryptedData) return null

      return {
        id: referral.id,
        state: referral.state,
        firstName: encryptedData.firstName,
        lastName: encryptedData.lastName,
        fileNames: encryptedData.fileNames,
        patient: capitalize(
          `${encryptedData.firstName} ${encryptedData.lastName}`
        ),
        phn: encryptedData.phn,
        phone: encryptedData.phone,
        gender: referral.gender,
        address: encryptedData.address,
        city: encryptedData.city,
        province: encryptedData.province,
        postalCode: encryptedData.postalCode,
        email: encryptedData.email,
        region: referral.region,
        urgency: referral.urgency,
        category: referral.category,
        message: encryptedData.message,
        birthDate: new Date(encryptedData.birthDate),
        referralDate: new Date(parseInt(referral.referralDate)),
        createdAt: new Date(parseInt(referral.createdAt)),
        files: referral.files,
        fromPractice: {
          id: referral.sender.practice.id,
          name: getPracticeDisplayName(referral.sender.practice, 'long', 'long')
        },
        toPractice: {
          id: referral.recipient.practice.id,
          name: `Dr. ${referral.recipient.practice.firstName} ${referral.recipient.practice.lastName}`
        },
        sender: referral.sender,
        recipient: referral.recipient
      }
    }

    if (duplicateReferralsDataResult?.data?.getReferrals?.referrals)
      decryptReferrals()
  }, [duplicateReferralsDataResult?.data, getAccount])

  useEffect(() => {
    function setReferralError(message) {
      referralResult.error = { message }
    }

    async function decryptReferral() {
      const referral = referralResult.data.getReferral

      if (referral.state !== 'declined')
        setReferralError(
          'To redirect a referral, the referral must be declined'
        )

      if (referral.redirectedTo)
        setReferralError('The referral has already been redirected')

      const account = await getAccount()

      const senderMembership = referral.sender.practice.memberships.find(
        m => m.user.id === account.id
      )

      if (senderMembership === undefined)
        setReferralError('You do not have permission to redirect this referral')

      if (
        referral.redirectedTo ||
        referral.state !== 'declined' ||
        senderMembership === undefined
      )
        return

      const encryptedData = await getDecryptedReferral(
        referral,
        account.id,
        account,
        senderMembership ? true : false
      )

      if (!encryptedData) return null

      const message = `${encryptedData.message}${
        encryptedData.message.length > 0 ? `\n\n` : ''
      }Note: This referral was declined by Dr. ${
        referral.recipient.practice.firstName
      } ${referral.recipient.practice.lastName} and has been redirected to you.`

      const decryptedReferral = {
        provider: referral.sender.practice.id,
        id: referral.id,
        firstName: encryptedData.firstName,
        lastName: encryptedData.lastName,
        fileNames: encryptedData.fileNames,
        phn: encryptedData.phn,
        phone: encryptedData.phone,
        gender: referral.gender,
        address: encryptedData.address || '',
        city: encryptedData.city || '',
        province: encryptedData.province || '',
        postalCode: encryptedData.postalCode || '',
        email: encryptedData.email || '',
        region: referral.region,
        urgency: referral.urgency,
        specialty: getSpecialtyId(referral.category),
        category: getCategoryId(referral.category),
        subcategory:
          getSubcategoryName(referral.category) === 'N/A'
            ? ''
            : referral.category,
        message,
        birthDate: new Date(encryptedData.birthDate),
        referralDate: new Date(parseInt(referral.referralDate)),
        files: referral.files,
        referringProvider: referral.referringProvider
          ? referral.referringProvider.id
          : '',
        specialist: '',
        nextSpecialist: false,
        specificSpecialist: false,
        redirectedFrom: referral.id,
        sender: referral.sender
      }

      if (senderMembership && referral.files.length > 0) {
        const decryptedFiles = []

        for (let i = 0; i < referral.files.length; i++) {
          const {
            data: { createDownloadUrl: url }
          } = await createDownloadUrl({
            variables: { referral: referral.id, file: i }
          })

          const newFile = await getBinaryFile(
            decryptedReferral,
            referral.files[i],
            account,
            url
          )

          if (newFile !== undefined) {
            decryptedFiles.push(newFile)
          }
        }
        decryptedReferral.files = decryptedFiles
      }

      setReferral(decryptedReferral)
    }

    if (referralResult?.data?.getReferral) decryptReferral()
  }, [referralResult, referralResult?.data, getAccount, createDownloadUrl])

  useEffect(() => {
    if (isResetting) {
      reset()
      setIsResetting(false)
      setIsEditing(true)
    }
  }, [isResetting, referral, reset])

  if (
    referralPageResult.error ||
    nextSpecialistResult.error ||
    faxResult.error ||
    referralResult.error
  ) {
    if (referralResult.error) {
      if (
        referralResult.error.message ===
          'Argument passed in must be a single String of 12 bytes or a string of 24 hex characters' ||
        referralResult.error.message === 'Referral does not exist'
      )
        referralResult.error.message =
          'The referral you are trying to redirect does not exist'

      return (
        <LoadingError
          message={[
            "We're sorry. This referral cannot be redirected.",
            referralResult.error.message + '.'
          ]}
          buttonText="Create a new referral"
          linkTo="/refer"
        />
      )
    }
    return <LoadingError />
  }

  if (
    referralPageResult.loading ||
    isResetting ||
    nextSpecialistResult.loading ||
    faxResult.loading ||
    referralResult.loading ||
    (referralResult.data && !referral)
  )
    return <Loading />

  function onSuccess() {
    if (faxId) {
      history.push('/fax/received')
    } else if (redirectedFrom) {
      history.push('/refer')
      setIsResetting(true)
      setReferral()
    } else {
      setIsResetting(true)
      setReferral()
    }
  }

  async function handleSave(referral) {
    const values = {
      ...referral,
      firstName: capitalize(referral.firstName),
      lastName: capitalize(referral.lastName),
      gender:
        referral.gender === 'Other' && referral.genderDescription !== ''
          ? referral.genderDescription.replace(
              /(^\w{1})|(\s+\w{1})/g,
              firstLetter => firstLetter.toUpperCase()
            )
          : referral.gender,
      initialFax:
        redirectedFrom && referralResult?.data?.getReferral.initialFax
          ? referralResult.data.getReferral.initialFax.id
          : faxId,
      redirectedFrom
    }

    delete values.genderDescription
    setPopulatedPatientInfo()
    setReferral(values)
    if (values.nextSpecialist) {
      await getNextSpecialist({
        variables: {
          category: values.subcategory || values.category,
          region: values.region
        }
      })
    }
    setIsEditing(false)
  }

  const handleOpen = () => setIsVisible(true)
  const handleClose = () => setIsVisible(false)
  function handlePopulate(populatedReferral) {
    setPopulatedPatientInfo({
      birthDate: populatedReferral.birthDate,
      firstName: populatedReferral.firstName,
      lastName: populatedReferral.lastName,
      phone: populatedReferral.phone ? populatedReferral.phone : '',
      gender: populatedReferral.gender ? populatedReferral.gender : 'Unknown',
      address: populatedReferral.address ? populatedReferral.address : '',
      city: populatedReferral.city ? populatedReferral.city : '',
      province: populatedReferral.province ? populatedReferral.province : '',
      postalCode: populatedReferral.postalCode
        ? populatedReferral.postalCode
        : '',
      email: populatedReferral.email ? populatedReferral.email : ''
    })
    setIsVisible(false)
  }

  const isAwaitingPractice =
    referralPageResult.data.getInvitations.length === 0 &&
    referralPageResult.data.getPractices.length === 0
  const isAwaitingInvitation =
    referralPageResult.data.getInvitations.length > 0 &&
    referralPageResult.data.getPractices.length === 0
  const showCongratulations = Boolean(location.state?.unverifiedPractice)
  const isLocked =
    isAwaitingInvitation || isAwaitingPractice || showCongratulations

  let fax = null
  if (faxId) fax = faxResult.data.getFax
  if (redirectedFrom && referralResult?.data?.getReferral.initialFax)
    fax = referralResult?.data?.getReferral.initialFax

  let contacts = []
  if (faxId)
    contacts = referralPageResult.data.getFaxContacts.filter(
      c => c.practice === faxResult.data.getFax.practice.id
    )
  if (redirectedFrom && referralResult?.data?.getReferral.initialFax)
    contacts = referralPageResult.data.getFaxContacts.filter(
      c => c.practice === referralResult.data.getReferral.sender.practice.id
    )

  let tempContact = null
  if (faxResult?.data)
    tempContact = {
      faxNumber: faxResult.data.getFax.faxContact.faxNumber
    }
  if (redirectedFrom && referralResult?.data?.getReferral.initialFax)
    tempContact = {
      faxNumber: referralResult.data.getReferral.initialFax.faxContact.faxNumber
    }

  return (
    <>
      {!isLocked && (
        <>
          {isEditing ? (
            <Content>
              <ReferralForm
                specialties={specialties}
                specialists={referralPageResult.data.getSpecialists.filter(
                  s => isTest || !s.isTest
                )}
                providers={
                  faxId
                    ? [
                        referralPageResult.data.getPractices.find(
                          f => f.id === faxResult.data.getFax.practice.id
                        )
                      ]
                    : referralPageResult.data.getPractices
                }
                contacts={contacts}
                setReset={setReset}
                referral={referral}
                onSave={referral => handleSave(referral)}
                fax={fax}
                setPhn={setPhn}
                hasPossibleDuplicates={
                  !duplicateReferrals
                    ? null
                    : duplicateReferrals.length > 0
                    ? true
                    : false
                }
                handleOpenDuplicates={handleOpen}
                populatedPatientInfo={populatedPatientInfo}
              />
              {isVisible && (
                <StyledDialog
                  fullWidth
                  open={isVisible}
                  onClose={handleClose}
                  keepMounted={false}>
                  <StyledDialogContent>
                    <ReferralDuplicates
                      duplicateReferrals={duplicateReferrals}
                      phn={phn}
                      handleClose={handleClose}
                      handlePopulate={handlePopulate}
                      fax={faxResult?.data ? faxResult.data.getFax : null}
                    />
                  </StyledDialogContent>
                </StyledDialog>
              )}
            </Content>
          ) : (
            <ReferralConfirmation
              referral={referral}
              redirectedFromRecipient={
                redirectedFrom
                  ? referralResult?.data?.getReferral.recipient.practice
                  : null
              }
              onSuccess={onSuccess}
              sender={practices.find(s => s.id === referral.provider)}
              recipient={
                !referral.specialist
                  ? nextSpecialistResult.data.getNextSpecialist
                  : referralPageResult.data.getSpecialists.find(
                      r => r.id === referral.specialist
                    )
              }
              contact={
                referral.referringProvider
                  ? contacts.find(c => c.id === referral.referringProvider)
                  : tempContact
              }
              onEdit={() => setIsEditing(true)}
            />
          )}
        </>
      )}
      {isLocked && (
        <Wrapper>
          {showCongratulations && (
            <Overlay>
              <LandingContainer>
                <CongratulationsLanding
                  practice={location.state.unverifiedPractice}
                />
              </LandingContainer>
            </Overlay>
          )}
          {isAwaitingPractice && (
            <Overlay>
              <LandingContainer>
                <NewPracticeLanding />
              </LandingContainer>
            </Overlay>
          )}
          {isAwaitingInvitation && (
            <Overlay>
              <LandingContainer>
                <PendingInvitationLanding
                  refresh={() => {
                    referralPageResult.refetch()
                  }}
                  invitation={referralPageResult.data.getInvitations[0]}
                />
              </LandingContainer>
            </Overlay>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default ReferralPage
