import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'
import useAccount from './useAccount'
import {
  deriveAesKey,
  unwrapHmacKey,
  unwrapEccKey,
  importPublicKey,
  deriveHmacToken
} from '../crypto'
import { getDecryptedReferral } from '../util/decryptReferrals'

const GET_TOKENIZER_DATA = gql`
  query getTokenizerData {
    getCurrentUser {
      id
    }
    getIndexTasks {
      id
      practice
      referral {
        id
        encryptedData {
          iv
          encryptedData
        }
        recipient {
          publicKey
          encryptionKey {
            encryptedData
            iv
          }
          practice {
            id
            searchKey {
              hashKey {
                iv
                encryptedData
              }
              publicKey
            }
            memberships {
              privateKey {
                encryptedData
                iv
              }
              publicKey
              user {
                id
              }
            }
          }
        }
        sender {
          publicKey
          encryptionKey {
            iv
            encryptedData
          }
          practice {
            id
            searchKey {
              hashKey {
                iv
                encryptedData
              }
              publicKey
            }
            memberships {
              privateKey {
                encryptedData
                iv
              }
              publicKey
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`

const UPDATE_SEARCH_TOKENS = gql`
  mutation updateSearchTokens($id: ID!, $searchTokens: [String]!) {
    updateSearchTokens(id: $id, searchTokens: $searchTokens)
  }
`

const delay = (delay, value) => {
  let timeout
  let _reject
  const promise = new Promise((resolve, reject) => {
    _reject = reject
    timeout = setTimeout(resolve, delay, value)
  })
  return {
    promise,
    cancel() {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
        _reject()
        _reject = null
      }
    }
  }
}

function useTokenizer() {
  const client = useApolloClient()
  const { getAccount } = useAccount()
  const [isTokenizing, setIsTokenizing] = useState(true)

  useEffect(() => {
    async function tokenize() {
      while (isMounted) {
        setIsTokenizing(true)

        const results = await client.query({
          query: GET_TOKENIZER_DATA,
          fetchPolicy: 'network-only'
        })
        const currentUser = results.data.getCurrentUser.id

        for (const indexTask of results.data.getIndexTasks) {
          const tokens = await calculateTokens(currentUser, indexTask)
          await client
            .mutate({
              mutation: UPDATE_SEARCH_TOKENS,
              variables: { id: indexTask.id, searchTokens: tokens }
            })
            .catch(err => console.error(err))
        }

        if (results.data.getIndexTasks.length === 0) {
          setIsTokenizing(false)

          timeout = delay(5 * 60 * 1000)
          await timeout.promise.then(() => {}).catch(() => {})
        }

        if (!isMounted) break
      }
    }

    async function calculateTokens(currentUser, indexTask) {
      const account = await getAccount()

      let isSender = false
      if (indexTask.practice === indexTask.referral.sender.practice.id)
        isSender = true

      const encryptedData = await getDecryptedReferral(
        indexTask.referral,
        currentUser,
        account,
        isSender
      )

      if (!encryptedData) return null

      const practice = isSender
        ? indexTask.referral.sender.practice
        : indexTask.referral.recipient.practice

      const membership = practice.memberships.find(
        m => m.user.id === account.id
      )

      const membershipAesKey = await deriveAesKey(
        account.privateKey,
        await importPublicKey(membership.publicKey)
      )

      const practicePrivateKey = await unwrapEccKey(
        membership.privateKey.encryptedData,
        membershipAesKey,
        membership.privateKey.iv,
        true
      )

      const ephemeralPublicKey = await importPublicKey(
        practice.searchKey.publicKey
      )
      const wrappingKey = await deriveAesKey(
        practicePrivateKey,
        ephemeralPublicKey
      )
      const searchKey = await unwrapHmacKey(
        practice.searchKey.hashKey.encryptedData,
        wrappingKey,
        practice.searchKey.hashKey.iv
      )

      const name = encryptedData.firstName + ' ' + encryptedData.lastName
      const searchTokens = [
        await deriveHmacToken(searchKey, encryptedData.firstName.toLowerCase()),
        await deriveHmacToken(searchKey, encryptedData.lastName.toLowerCase()),
        await deriveHmacToken(searchKey, name.toLowerCase()),
        await deriveHmacToken(searchKey, encryptedData.phn.toString())
      ]

      return searchTokens
    }

    let isMounted = true
    let timeout = null

    tokenize()

    return () => {
      isMounted = false
      timeout.cancel()
    }
  }, [client, getAccount])

  return isTokenizing
}

export default useTokenizer
