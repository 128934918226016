import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LockIcon from 'mdi-react/LockIcon'

const MNEMONIC_LENGTH = 24

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 15px;
  margin-bottom: 30px;
  width: 100%;
`

const Word = styled.span`
  color: #000;
  background: #f9fbfe;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;

  width: 100%;
  height: 35px;
  padding: 8px 0;

  font-family: Courier, monospace;
`

const LockedWord = styled(Word)`
  height: 35px;
  color: #00334e;
  opacity: 0.5;
`

const Mnemonic = ({ mnemonic }) => (
  <Container>
    <Grid>
      {mnemonic
        ? mnemonic.map((word, i) => <Word key={`${word}_${i}`}>{word}</Word>)
        : Array.from(Array(MNEMONIC_LENGTH)).map((_, i) => (
            <LockedWord key={`locked-word_${i}`}>
              <LockIcon size={15} />
            </LockedWord>
          ))}
    </Grid>
  </Container>
)

Mnemonic.propTypes = {
  mnemonic: PropTypes.arrayOf(PropTypes.string)
}

export default Mnemonic
