import React, { useState } from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'
import { List, ListItem, Paper } from '@material-ui/core'
import SendIcon from 'mdi-react/SendIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import styled from 'styled-components'
import { useApolloClient, useMutation } from '@apollo/client'
import { pdf } from '@react-pdf/renderer'
import useAccount from '../../hooks/useAccount'
import {
  deriveAesKey,
  generateEccKeys,
  generateAesKey,
  wrapSecretKey,
  unwrapHmacKey,
  unwrapEccKey,
  encryptObject,
  encryptFile,
  exportPublicKey,
  importPublicKey,
  deriveHmacToken
} from '../../crypto'

import { FieldLabel, FieldValue } from '../common/StyledField'
import { PageTitle, SectionTitle } from '../common/Title'
import Button, { SecondaryButton } from '../common/Button'
import ReferralDataView from './ReferralDataView'
import UrgencyIndicator from '../common/UrgencyIndicator'
import PhysicianFromToInfo from '../refer/PhysicianFromToInfo'
import ReferralPdf from '../faxing/ReferralPdf'
import TriagePdf from '../faxing/TriagePdf'
import { format } from 'date-fns'
import Select from '../common/Select'
import {
  getSubcategoryMessage,
  getCategoryMessage,
  getSpecialtyByCategoryId,
  getCategoryReferralType,
  getSubcategoryReferralType
} from '../../util/categories'
import { getPracticeDisplayName } from '../../util/strings'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const StyledSendIcon = styled(SendIcon)`
  transform: rotate(-45deg);
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Placeholder = styled.div`
  width: 104px;
`

const ConfirmationWrapper = styled(Paper)`
  width: 100%;
  border-radius: 6px;
  padding: 0px 30px 100px;
`

const ReferralConfirm = styled.div`
  display: flex;
  flex-direction: column;
`

const ReferralInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const AllFields = styled.div`
  padding: 0 50px;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const StyledFieldLabel = styled(FieldLabel)`
  padding-top: 27px;
`

const ErrorContainer = styled.div`
  margin: 15px 0 0;
  flex: 0;
`

const BoxWrapper = styled.div`
  margin: 1em 0 9px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 51px 0 30px;
`

const StyledList = styled(List)`
  background-color: #f9fbfe;
  border-radius: 3px;
  padding: 8px 15px;
  margin: 9px 0 0;
  min-height: 34px;
`

const TriageResponse = styled.div`
  font-size: 14px;
  color: #3a3a3a;
  background-color: #f9fbfe;
  border-radius: 3px;
  padding: 8px 15px;
  margin: 9px 0 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const ResponseSection = styled.div`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`

const TabbedText = styled.p`
  margin-left: 62pt;
`

const BoldText = styled.span`
  font-weight: 600;
`

const SEND_REFERRAL = gql`
  mutation sendReferral($referral: ReferralInput!) {
    sendReferral(referral: $referral) {
      id
    }
  }
`

const CREATE_UPLOAD_URL = gql`
  mutation createUploadUrl {
    createUploadUrl
  }
`

const GET_ALERTS = gql`
  query getAlerts {
    getAlerts {
      sentReferrals
      receivedReferrals
      sentFaxes
    }
  }
`

function getReferralType(category) {
  const subcategoryReferralType = getSubcategoryReferralType(category)
  if (subcategoryReferralType !== 'N/A') return subcategoryReferralType

  const categoryReferralType = getCategoryReferralType(category)
  if (categoryReferralType !== 'N/A') return categoryReferralType

  return getSpecialtyByCategoryId(category)
}

function ReferralConfirmation({
  referral,
  redirectedFromRecipient = null,
  sender,
  recipient,
  onEdit,
  onSuccess,
  contact
}) {
  let defaultMessage =
    getCategoryMessage(referral.category) +
    getSubcategoryMessage(referral.subcategory)

  if (redirectedFromRecipient) {
    defaultMessage = `${defaultMessage}${
      defaultMessage.length > 0 ? '\n\n' : ''
    }This referral was previously sent to Dr. ${
      redirectedFromRecipient.firstName
    } ${
      redirectedFromRecipient.lastName
    } who is unable to accept the patient at this time. We have re-triaged your referral to the next available specialist so that the patient can be seen in a timely manner. Please send any additional referral documents to Dr. ${
      recipient.firstName
    } ${recipient.lastName}.`
  }

  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(defaultMessage)
  const [errorMessage, setErrorMessage] = useState(false)
  const [sendReferral] = useMutation(SEND_REFERRAL)
  const [createUploadUrl] = useMutation(CREATE_UPLOAD_URL)
  const { getAccount } = useAccount()
  const client = useApolloClient()

  const reply =
    contact && referral.initialFax && sender.triageResponseActiveDate
      ? {
          to: {
            name: contact.name ? contact.name : undefined,
            faxNumber: contact.faxNumber
          },
          from: {
            name: getPracticeDisplayName(sender, 'long', 'long'),
            faxNumber: sender.faxNumber
          },
          firstName: referral.firstName,
          lastName: referral.lastName,
          birthDate: format(referral.birthDate, 'dd/MMM/yyyy'),
          phn: referral.phn,
          referralDate: format(referral.referralDate, 'dd/MMM/yyyy'),
          triageMessage:
            'Your referral has been triaged to Dr. ' +
            recipient.firstName +
            ' ' +
            recipient.lastName +
            ' (phone no.: ' +
            (recipient.phone.substring(recipient.phone.length - 13) ===
            ', Ext. ______'
              ? recipient.phone.substr(0, 14)
              : recipient.phone.split('_')[0]) +
            ').',
          referralType: getReferralType(
            referral.subcategory || referral.category
          )
        }
      : null

  async function sendFax(type, files, fileNames, referralId, account, message) {
    const formData = new FormData()

    if (type === 'emr') {
      const referralObject = {
        ...referral,
        fileNames,
        referringProvider: contact?.id ? contact : null,
        category: referral.subcategory || referral.category
      }

      const blob = await pdf(
        <ReferralPdf
          referral={referralObject}
          sender={sender}
          recipient={recipient}
        />
      ).toBlob()

      const referralPdf = new File([blob], 'clinnectReferral.pdf', {
        type: 'application/pdf'
      })
      formData.append('files', referralPdf)

      for (const file of files) {
        formData.append('files', file)
      }

      formData.append('referral', referralId)
    } else if (type === 'triage') {
      const blob = await pdf(
        <TriagePdf info={reply} message={message} />
      ).toBlob()

      const triagePdf = new File([blob], 'triageNotification.pdf', {
        type: 'application/pdf'
      })

      formData.append('files', triagePdf)
      formData.append('referral', referralId)
    }

    const token = window.localStorage.authToken

    const requestOptions = {
      method: 'POST',
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      },
      body: formData
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/${type}`,
      requestOptions
    )

    const responseOk = response.ok
    const body = await response.json()

    if (!responseOk || body.state === 'failed') {
      const errorMessage =
        type === 'emr'
          ? "An error occurred while sending the referral to the recipient's EMR:"
          : 'An error occurred while sending the response to the referring provider:'

      if (!responseOk) console.error(errorMessage, response.status, body.error)
      if (body.state === 'failed') console.error(errorMessage, body.errorText)

      if (client.cache.data.data.ROOT_QUERY?.[`getAlerts`]) {
        const { getAlerts } = client.cache.readQuery({
          query: GET_ALERTS
        })

        const receivedReferral = recipient.memberships.find(
          m => m.user.id === account.id
        )

        const updatedData = {
          sentFaxes: getAlerts.sentFaxes,
          sentReferrals: getAlerts.sentReferrals + 1,
          receivedReferrals: receivedReferral
            ? getAlerts.receivedReferrals + 1
            : getAlerts.receivedReferrals
        }

        client.cache.writeQuery({
          query: GET_ALERTS,
          data: {
            getAlerts: updatedData
          }
        })
      }
    }
  }

  async function onConfirm() {
    setIsLoading(true)
    setErrorMessage(false)

    const encryptedData = {
      birthDate: referral.birthDate,
      firstName: referral.firstName,
      lastName: referral.lastName,
      phn: referral.phn,
      phone: referral.phone,
      address: referral.address,
      city: referral.city,
      province: referral.province,
      postalCode: referral.postalCode,
      email: referral.email,
      message: referral.message,
      fileNames: []
    }

    const referralKey = await generateAesKey('enc')

    async function uploadFile(file) {
      const { encryptedFile, iv } = await encryptFile(file, referralKey)

      const {
        data: { createUploadUrl: url }
      } = await createUploadUrl()

      const response = await fetch(url, {
        method: 'PUT',
        body: encryptedFile
      })

      if (!response.ok) {
        console.error(response)
        throw Error(response.status)
      }

      const name = encryptedFile.name
      const key = new URL(url).pathname.split('/')[2]

      return { iv, key, name }
    }

    try {
      const files = []
      const uploads = await Promise.all(referral.files.map(uploadFile))

      for (const upload of uploads) {
        const { iv, key, name } = upload
        encryptedData.fileNames.push(name)
        files.push({ iv, key })
      }

      const { encryptedObject, iv: objectIv } = await encryptObject(
        encryptedData,
        referralKey
      )

      const senderEphemeralKeys = await generateEccKeys()
      const recipientEphemeralKeys = await generateEccKeys()

      const senderPublicKey = await importPublicKey(sender.publicKey)
      const senderAesKey = await deriveAesKey(
        senderEphemeralKeys.privateKey,
        senderPublicKey
      )

      const recipientPublicKey = await importPublicKey(recipient.publicKey)
      const recipientAesKey = await deriveAesKey(
        recipientEphemeralKeys.privateKey,
        recipientPublicKey
      )

      const senderKey = await wrapSecretKey(referralKey, senderAesKey)
      const recipientKey = await wrapSecretKey(referralKey, recipientAesKey)

      // Calculate the searchTokens using the sender's searchKey
      const account = await getAccount()

      const senderMembership = sender.memberships.find(
        m => m.user.id === account.id
      )

      const membershipAesKey = await deriveAesKey(
        account.privateKey,
        await importPublicKey(senderMembership.publicKey)
      )

      const practicePrivateKey = await unwrapEccKey(
        senderMembership.privateKey.encryptedData,
        membershipAesKey,
        senderMembership.privateKey.iv,
        true
      )

      const ephemeralPublicKey = await importPublicKey(
        sender.searchKey.publicKey
      )
      const wrappingKey = await deriveAesKey(
        practicePrivateKey,
        ephemeralPublicKey
      )
      const searchKey = await unwrapHmacKey(
        sender.searchKey.hashKey.encryptedData,
        wrappingKey,
        sender.searchKey.hashKey.iv
      )

      const name = referral.firstName + ' ' + referral.lastName
      const searchTokens = [
        await deriveHmacToken(searchKey, referral.firstName.toLowerCase()),
        await deriveHmacToken(searchKey, referral.lastName.toLowerCase()),
        await deriveHmacToken(searchKey, name.toLowerCase()),
        await deriveHmacToken(searchKey, referral.phn.toString())
      ]

      const objectToSend = {
        encryptedData: {
          iv: objectIv,
          encryptedData: encryptedObject
        },
        isDirected: referral.specificSpecialist,
        category: referral.subcategory || referral.category,
        referralDate: referral.referralDate.toISOString(),
        region: referral.region,
        urgency: referral.urgency,
        gender: referral.gender,
        sender: {
          practice: sender.id,
          encryptionKey: {
            encryptedData: senderKey.wrappedKey,
            iv: senderKey.iv
          },
          publicKey: await exportPublicKey(senderEphemeralKeys.publicKey)
        },
        recipient: {
          practice: recipient.id,
          encryptionKey: {
            encryptedData: recipientKey.wrappedKey,
            iv: recipientKey.iv
          },
          publicKey: await exportPublicKey(recipientEphemeralKeys.publicKey)
        },
        files,
        searchTokens
      }

      if (referral.initialFax) objectToSend.initialFax = referral.initialFax
      if (referral.referringProvider)
        objectToSend.referringProvider = referral.referringProvider
      if (referral.redirectedFrom)
        objectToSend.redirectedFrom = referral.redirectedFrom

      const result = await sendReferral({
        variables: { referral: objectToSend }
      })

      if (recipient.emrSyncActiveDate)
        sendFax(
          'emr',
          referral.files,
          encryptedData.fileNames,
          result.data.sendReferral.id,
          account,
          message
        )
      if (referral.initialFax && sender.triageResponseActiveDate)
        sendFax(
          'triage',
          referral.files,
          encryptedData.fileNames,
          result.data.sendReferral.id,
          account,
          message
        )

      setIsLoading(false)
      onSuccess()
    } catch (e) {
      console.error('Request error occurred:', e.message)
      setErrorMessage('Hm, an error occurred. Try again.')
      setIsLoading(false)
    }
  }

  async function onChangeMessage(e) {
    setMessage(e.target.value)
  }

  return (
    <Content>
      <Header>
        <PageTitle>Referral Confirmation</PageTitle>
        <ButtonContainer>
          <Button loading={isLoading} onClick={onConfirm}>
            <StyledSendIcon /> Confirm & Send
          </Button>
          {errorMessage && (
            <ErrorContainer>
              <Alert severity="error">{errorMessage}</Alert>
            </ErrorContainer>
          )}
        </ButtonContainer>
      </Header>
      <BlueBar>
        <SecondaryButton
          color="secondary"
          padding="medium"
          onClick={() => onEdit(referral)}>
          <PencilIcon /> Edit
        </SecondaryButton>
        <PhysicianFromToInfo sender={sender} recipient={recipient} />
        <Placeholder />
      </BlueBar>
      <ConfirmationWrapper elevation={3}>
        <ReferralConfirm>
          <ReferralDataView
            referral={{
              ...referral,
              category: referral.subcategory || referral.category,
              referringProvider: contact?.id ? contact : null
            }}
            recipient={recipient}
            page="confirmation"
          />
          <ReferralInformation>
            <SectionTitle>Referral Information</SectionTitle>
            <Divider />
            <AllFields>
              <StyledFieldLabel>
                Chosen <strong>urgency</strong>
              </StyledFieldLabel>
              <BoxWrapper>
                <UrgencyIndicator box={true} urgency={referral.urgency} />
              </BoxWrapper>
              <StyledFieldLabel>
                Reason for Referral and/or Additional Comments
              </StyledFieldLabel>
              <FieldValue>{referral.message}</FieldValue>
              <StyledFieldLabel>Attached File(s)</StyledFieldLabel>
              <StyledList disablePadding>
                {referral.files.map((file, i) => {
                  return (
                    <ListItem key={`${file.name}_${i}`} disableGutters dense>
                      <FieldValue
                        style={{
                          padding: '0',
                          margin: '0',
                          minHeight: '18px'
                        }}>
                        {file.name}
                      </FieldValue>
                    </ListItem>
                  )
                })}
              </StyledList>
            </AllFields>
          </ReferralInformation>
          {contact &&
            referral.initialFax &&
            sender.triageResponseActiveDate && (
              <ReferralInformation>
                <SectionTitle>Triage Notification Information</SectionTitle>
                <Divider />
                <AllFields>
                  <StyledFieldLabel>
                    The following message will be sent to the referring provider
                  </StyledFieldLabel>
                  <TriageResponse>
                    To: {reply.to.name ? reply.to.name : ''}{' '}
                    {reply.to.faxNumber}
                    <ResponseSection>
                      <p>From: {reply.from.name}</p>
                      <p>Fax No.: {reply.from.faxNumber}</p>
                    </ResponseSection>
                    <ResponseSection>
                      <BoldText>
                        Re patient: &emsp;Name: {reply.lastName},{' '}
                        {reply.firstName}
                        <TabbedText>DOB: {reply.birthDate}</TabbedText>
                        <TabbedText>PHN: {reply.phn}</TabbedText>
                      </BoldText>
                    </ResponseSection>
                    Referral Date: {reply.referralDate}
                    <ResponseSection>
                      <BoldText>{reply.triageMessage} </BoldText>Please contact
                      their office directly if you have any future questions
                      about this referral.
                    </ResponseSection>
                    <ResponseSection>
                      {reply.referralType.split('\n').map((r, i) => {
                        if (i === 0) return <p key={i}>Referral Type: {r}</p>
                        else return <p key={i}>{r}</p>
                      })}
                    </ResponseSection>
                  </TriageResponse>
                  <Select
                    id="message"
                    name="customMessage"
                    label="Additional Comments"
                    value={message}
                    onChange={onChangeMessage}
                    placeholder="Include any additional information here you want the referrer to be aware of."
                    multiline
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </AllFields>
              </ReferralInformation>
            )}
        </ReferralConfirm>
      </ConfirmationWrapper>
    </Content>
  )
}

ReferralConfirmation.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string,
    encryptedData: PropTypes.object,
    birthDate: PropTypes.instanceOf(Date),
    referralDate: PropTypes.instanceOf(Date),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phn: PropTypes.number,
    phone: PropTypes.string,
    gender: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
    email: PropTypes.string,
    urgency: PropTypes.string,
    files: PropTypes.array,
    category: PropTypes.string,
    subcategory: PropTypes.string,
    region: PropTypes.string,
    message: PropTypes.string,
    specificSpecialist: PropTypes.bool,
    initialFax: PropTypes.string,
    referringProvider: PropTypes.string,
    redirectedFrom: PropTypes.string
  }).isRequired,
  redirectedFromRecipient: PropTypes.object,
  sender: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    msp: PropTypes.string,
    centralIntakeName: PropTypes.string,
    faxNumber: PropTypes.string,
    publicKey: PropTypes.string,
    type: PropTypes.string,
    memberships: PropTypes.array,
    searchKey: PropTypes.object,
    triageResponseActiveDate: PropTypes.string
  }).isRequired,
  recipient: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    publicKey: PropTypes.string,
    lastName: PropTypes.string,
    emrSyncActiveDate: PropTypes.string,
    memberships: PropTypes.array,
    phone: PropTypes.string
  }),
  contact: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    faxNumber: PropTypes.string,
    location: PropTypes.string
  }),
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func
}

export default ReferralConfirmation
