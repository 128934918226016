import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useLocation, useHistory } from 'react-router-dom'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import SendFaxForm from '../faxing/SendFaxForm'

const GET_SEND_FAX_DATA = gql`
  query getSendFaxData {
    getFaxContacts {
      id
      practice
      name
      location
      faxNumber
    }
    getPractices {
      id
      firstName
      lastName
      faxNumber
      type
      centralIntakeName
    }
  }
`

const GET_FAX = gql`
  query getFax($id: ID!) {
    getFax(id: $id) {
      id
      practice {
        id
        firstName
        lastName
        type
        centralIntakeName
      }
    }
  }
`

function FaxPage() {
  const { data, loading, error } = useQuery(GET_SEND_FAX_DATA)

  const location = useLocation()
  const history = useHistory()

  const faxId = new URLSearchParams(location.search).get('faxId')
  const senderFaxNumber = new URLSearchParams(location.search).get('faxNumber')
  const isDuplicate =
    new URLSearchParams(location.search).get('isDuplicate') === 'true'

  const faxResult = useQuery(GET_FAX, {
    variables: { id: faxId },
    skip: faxId === null
  })

  if (error || faxResult.error) return <LoadingError />
  if (loading || faxResult.loading) return <Loading />

  const practices = data.getPractices
  const filteredPractices = practices.filter(f => f.faxNumber !== '')

  function onSuccess() {
    if (faxId) {
      history.push('/fax/received')
    } else {
      history.push('/fax/create')
    }
  }

  return (
    <SendFaxForm
      contacts={data.getFaxContacts}
      practices={faxId ? [faxResult.data.getFax.practice] : filteredPractices}
      faxId={faxId}
      senderFaxNumber={senderFaxNumber}
      onSuccess={onSuccess}
      isDuplicate={isDuplicate}
    />
  )
}

export default FaxPage
