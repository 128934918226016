import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import styled from 'styled-components'

const StyledLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.MuiFormControlLabel-labelPlacementStart {
    margin: 0;
  }
  .MuiSwitch-root {
    height: 34px;
    padding: 10px;
  }
`

const StyledSwitch = styled(Switch)`
  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
  }
  .MuiSwitch-switchBase {
    height: 34px;
  }
  .MuiSwitch-colorSecondary {
    :hover {
      background: rgba(6, 153, 135, 0.1);
    }
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #069987;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #069987;
  }
  .MuiSwitch-colorSecondary.Mui-checked.Mui-disabled {
    color: #bdbdbd;
  }
  .MuiSwitch-colorSecondary.Mui-checked.Mui-disabled + .MuiSwitch-track {
    background-color: #000;
  }
`

const SelectorLabel = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3a3a3a;
  margin: 0;
  white-space: nowrap;
`

export { StyledSwitch, StyledLabel, SelectorLabel }
