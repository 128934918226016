import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useApolloClient, useMutation } from '@apollo/client'
import { pdf } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { Tooltip } from '@material-ui/core'

import RefreshIcon from 'mdi-react/RefreshIcon'

import useAccount from '../../hooks/useAccount'
import ReferralPdf from './ReferralPdf'
import TriagePdf from './TriagePdf'
import {
  getCategoryReferralType,
  getSpecialtyByCategoryId,
  getCategoryMessage,
  getSubcategoryMessage,
  getSubcategoryReferralType
} from '../../util/categories'
import { SecondaryButton, IconButton } from '../common/Button'
import {
  deriveAesKey,
  unwrapEccKey,
  unwrapAesKey,
  importPublicKey,
  decryptFile
} from '../../crypto'
import { getPracticeDisplayName } from '../../util/strings'

const IconButtonContainer = styled.div`
  max-width: 35px;
`

const IconWrapper = styled.div`
  height: 34px;
`

const ErrorText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  color: #e84c3d;
  margin: 0;
  padding-top: 5px;
`

const CREATE_DOWNLOAD_URL = gql`
  mutation createDownloadUrl($referral: ID!, $file: Int!) {
    createDownloadUrl(referral: $referral, file: $file)
  }
`

const GET_ALERTS = gql`
  query getAlerts {
    getAlerts {
      sentReferrals
      receivedReferrals
      sentFaxes
    }
  }
`

const GET_REFERRAL = gql`
  query getReferral {
    getReferral(id: $id) {
      id
      faxToEmr {
        id
        state
        errorText
      }
      triageFax {
        id
        state
        errorText
      }
    }
  }
`

function getReferralType(category) {
  const subcategoryReferralType = getSubcategoryReferralType(category)
  if (subcategoryReferralType !== 'N/A') return subcategoryReferralType

  const categoryReferralType = getCategoryReferralType(category)
  if (categoryReferralType !== 'N/A') return categoryReferralType

  return getSpecialtyByCategoryId(category)
}

function FaxFromReferralButton({
  referral,
  redirectedEvent = null,
  type = 'full',
  destination = 'emr',
  practices = [],
  onSendFax = () => {}
}) {
  const [createDownloadUrl] = useMutation(CREATE_DOWNLOAD_URL)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const { getAccount } = useAccount()
  const client = useApolloClient()
  const location = useLocation()

  async function getBinaryFile(file) {
    const key = file.key
    const index = referral.files.findIndex(f => f.key === key)
    const name = referral.fileNames[index]
    const account = await getAccount()

    const recipientMembership = referral.recipient.practice.memberships?.find(
      m => m.user.id === account.id
    )
    const senderMembership = referral.sender.practice.memberships?.find(
      m => m.user.id === account.id
    )

    const participant = recipientMembership
      ? referral.recipient
      : referral.sender
    const membership = recipientMembership || senderMembership

    const membershipAesKey = await deriveAesKey(
      account.privateKey,
      await importPublicKey(membership.publicKey)
    )

    const practicePrivateKey = await unwrapEccKey(
      membership.privateKey.encryptedData,
      membershipAesKey,
      membership.privateKey.iv,
      true
    )

    const referralAesKey = await deriveAesKey(
      practicePrivateKey,
      await importPublicKey(participant.publicKey)
    )

    const encryptionKey = await unwrapAesKey(
      participant.encryptionKey.encryptedData,
      referralAesKey,
      participant.encryptionKey.iv,
      'enc'
    )

    const {
      data: { createDownloadUrl: url }
    } = await createDownloadUrl({
      variables: { referral: referral.id, file: index }
    })

    const response = await fetch(url)
    const reader = response.body.getReader()

    const chunks = []
    let isDone = false

    while (!isDone) {
      const response = await reader.read()

      if (response.value) {
        chunks.push(response.value)
      }

      isDone = response.done
    }

    const blob = new Blob(chunks)
    const encryptedFile = new File([blob], name)

    const iv = file.iv

    const rawFile = await decryptFile(encryptedFile, encryptionKey, iv)

    return rawFile
  }

  async function getEmrFormData() {
    const files = await Promise.all(referral.files.map(getBinaryFile))

    const formData = new FormData()

    const blob = await pdf(
      <ReferralPdf
        referral={referral}
        sender={referral.sender.practice}
        recipient={referral.recipient.practice}
      />
    ).toBlob()

    const referralPdf = new File([blob], 'clinnectReferral.pdf', {
      type: 'application/pdf'
    })

    formData.append('files', referralPdf)

    for (const file of files) {
      formData.append('files', file)
    }

    formData.append('referral', referral.id)

    return formData
  }

  async function getTriageFormData() {
    const formData = new FormData()

    const triageMessage =
      'Your referral has been triaged to Dr. ' +
      referral.recipient.practice.firstName +
      ' ' +
      referral.recipient.practice.lastName +
      ' (phone no.: ' +
      (referral.recipient.practice.phone.substring(
        referral.recipient.practice.phone.length - 13
      ) === ', Ext. ______'
        ? referral.recipient.practice.phone.substr(0, 14)
        : referral.recipient.practice.phone.split('_')[0]) +
      ').'

    const info = {
      to: {
        name: referral.referringProvider
          ? referral.referringProvider.name
          : undefined,
        faxNumber: referral.referringProvider
          ? referral.referringProvider.faxNumber
          : referral.initialFax.faxContact.faxNumber
      },
      from: {
        name: getPracticeDisplayName(referral.sender.practice),
        faxNumber: referral.sender.practice.faxNumber
      },
      firstName: referral.firstName,
      lastName: referral.lastName,
      birthDate: format(referral.birthDate, 'dd/MMM/yyyy'),
      phn: referral.phn,
      referralDate: format(referral.referralDate, 'dd/MMM/yyyy'),
      triageMessage,
      referralType: getReferralType(referral.category)
    }

    let message =
      getCategoryMessage(referral.category) +
      getSubcategoryMessage(referral.subcategory)

    if (redirectedEvent !== null || referral.redirectedFrom) {
      message = `${message}${
        message.length > 0 ? '\n\n' : ''
      }This referral was previously sent to`

      if (redirectedEvent !== null) {
        message = `${message} Dr. ${redirectedEvent.data.originalReferral.recipient.practice.firstName} ${redirectedEvent.data.originalReferral.recipient.practice.lastName}`
      } else {
        message = `${message} another specialist in the group`
      }
      message = `${message} who is unable to accept the patient at this time. We have re-triaged your referral to the next available specialist so that the patient can be seen in a timely manner. Please send any additional referral documents to Dr. ${referral.recipient.practice.firstName} ${referral.recipient.practice.lastName}.`
    }

    const blob = await pdf(<TriagePdf info={info} message={message} />).toBlob()

    const triagePdf = new File([blob], 'triageNotification.pdf', {
      type: 'application/pdf'
    })

    formData.append('files', triagePdf)
    formData.append('referral', referral.id)

    return formData
  }

  async function onSend() {
    setLoading(true)
    setError()

    const formData =
      destination === 'emr' ? await getEmrFormData() : await getTriageFormData()

    const token = window.localStorage.authToken

    const requestOptions = {
      method: 'POST',
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      },
      body: formData
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/documents/${destination}`,
      requestOptions
    )

    if (!response.ok) {
      const body = await response.json()

      setError(body.error)

      const errorMessage =
        destination === 'emr'
          ? "An error occurred while sending the referral to the recipient's EMR:"
          : 'An error occurred while sending the triage response to the referrer'

      console.error(errorMessage, response.status, body.error)
    } else {
      const body = await response.json()
      const invalidSenderTriage =
        body.state === 'failed' &&
        destination === 'triage' &&
        referral.triageFax?.state === 'cancelled'

      const ignoreAlerts =
        (destination === 'emr' &&
          ['cancelled', 'success'].includes(referral.faxToEmr?.state)) ||
        (destination === 'triage' &&
          ['cancelled', 'success'].includes(referral.triageFax?.state) &&
          !invalidSenderTriage)

      if (!ignoreAlerts && client.cache.data.data.ROOT_QUERY?.[`getAlerts`]) {
        const { getAlerts } = client.cache.readQuery({
          query: GET_ALERTS
        })

        const includedInAlerts =
          destination === 'emr' || destination === 'triage'

        const sentReferral =
          practices.map(p => p.id).includes(referral.sender.practice.id) &&
          includedInAlerts

        const receivedReferral =
          practices.map(p => p.id).includes(referral.recipient.practice.id) &&
          includedInAlerts

        const updateBy = invalidSenderTriage ? 1 : -1

        const updatedData = {
          sentFaxes: getAlerts.sentFaxes,
          sentReferrals: sentReferral
            ? getAlerts.sentReferrals + updateBy
            : getAlerts.sentReferrals,
          receivedReferrals: receivedReferral
            ? getAlerts.receivedReferrals + updateBy
            : getAlerts.receivedReferrals
        }

        client.cache.writeQuery({
          query: GET_ALERTS,
          data: {
            getAlerts: updatedData
          }
        })
      }
      if (
        client.cache.data.data.ROOT_QUERY?.[
          `getReferral({"id":"${referral.id}"})`
        ] &&
        location.pathname.match(/\/referrals\//)
      ) {
        const { getReferral } = client.cache.readQuery({
          query: GET_REFERRAL,
          variables: { id: referral.id }
        })

        const updatedData =
          destination === 'emr'
            ? { ...getReferral, faxToEmr: body }
            : { ...getReferral, triageFax: body }

        client.cache.writeQuery({
          query: GET_REFERRAL,
          variables: { id: referral.id },
          data: {
            getReferral: updatedData
          }
        })
      }
      onSendFax()
    }

    setLoading(false)
  }

  return (
    <>
      {type === 'full' && (
        <>
          <SecondaryButton
            color="secondary"
            padding="small"
            onClick={onSend}
            loading={loading}>
            <RefreshIcon />
            Resend
          </SecondaryButton>
          {error && (
            <ErrorText role="alert">
              Hm, an error occurred. Try again.
            </ErrorText>
          )}
        </>
      )}
      {type === 'icon' && (
        <IconButtonContainer>
          <Tooltip title="Resend fax" arrow>
            <IconWrapper>
              <IconButton
                color="secondary"
                onClick={onSend}
                loading={loading}
                aria-label={
                  destination === 'emr'
                    ? 'Send fax to emr'
                    : 'Send triage response fax'
                }>
                <RefreshIcon />
              </IconButton>
            </IconWrapper>
          </Tooltip>
          {error && <ErrorText role="alert">Error, try again.</ErrorText>}
        </IconButtonContainer>
      )}
    </>
  )
}

FaxFromReferralButton.propTypes = {
  referral: PropTypes.object.isRequired,
  redirectedEvent: PropTypes.object,
  type: PropTypes.oneOf(['icon', 'full']),
  destination: PropTypes.oneOf(['emr', 'triage']),
  practices: PropTypes.array,
  onSendFax: PropTypes.func
}

export default FaxFromReferralButton
