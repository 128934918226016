import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format } from 'date-fns'
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon'
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon'

const DateBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  background-color: #069987;

  width: ${props => (props.width === 'wide' ? '100px' : '70px')};
  margin-bottom: 10px;
`

const DateDisplay = styled.p`
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  color: #ffffff;
  margin: 7px 0;
`

const TimeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  color: rgba(58, 58, 58, 0.7);

  width: 70px;
  height: 25px;
  border-radius: 15px;
  border: 1px solid rgba(58, 58, 58, 0.15);
`

const Time = styled.p`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
`

const CalendarIcon = styled(CalendarBlankIcon)`
  color: #ffffff;
  width: 12px;
  margin-right: 5px;
`

const TimeIcon = styled(ClockOutlineIcon)`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`

const DateIndicator = ({ dateTime, withYear = false }) => (
  <DateBox width={withYear ? 'wide' : 'short'}>
    <CalendarIcon />
    <DateDisplay>
      {withYear ? format(dateTime, 'MMM dd, yyyy') : format(dateTime, 'MMM dd')}
    </DateDisplay>
  </DateBox>
)

const TimeIndicator = ({ dateTime }) => (
  <TimeBox>
    <TimeIcon />
    <Time>{format(dateTime, 'h:mm a')}</Time>
  </TimeBox>
)

DateIndicator.propTypes = {
  dateTime: PropTypes.instanceOf(Date).isRequired,
  withYear: PropTypes.bool
}

TimeIndicator.propTypes = {
  dateTime: PropTypes.instanceOf(Date).isRequired
}

export { DateIndicator, TimeIndicator }
