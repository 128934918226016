import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import styled from 'styled-components'
import AttachFileIcon from 'mdi-react/AttachFileIcon'
import DataUsageIcon from 'mdi-react/DataUsageIcon'
import { useMutation } from '@apollo/client'
import {
  encryptFile,
  deriveAesKey,
  importPublicKey,
  unwrapEccKey,
  encryptObject,
  unwrapAesKey
} from '../../crypto'
import useAccount from '../../hooks/useAccount'
import Alert from '@material-ui/lab/Alert'
import { SecondaryButton } from '../common/Button'

const StyledButton = styled(SecondaryButton)`
  margin: 15px 0px;

  .mdi-icon {
    height: 80%;
    ${props =>
      props.isloading === 'true' ? 'animation: spin 2s linear infinite;' : ''}
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const InvisibleInput = styled.input`
  display: none;
`

const AlertContainer = styled.div`
  margin: 8px 8px 8px 0px;
`

const ADD_FILE = gql`
  mutation addFile(
    $referral: ID!
    $file: FileInput!
    $encryptedData: EncryptedDataInput!
  ) {
    addFile(referral: $referral, file: $file, encryptedData: $encryptedData) {
      id
      files {
        iv
        key
      }
      encryptedData {
        encryptedData
        iv
      }
    }
  }
`

const CREATE_UPLOAD_URL = gql`
  mutation createUploadUrl {
    createUploadUrl
  }
`

function SingleFileUploader({ referral, sender, recipient }) {
  const hiddenFileInput = useRef(null)
  const [loading, setLoading] = useState(false)
  const { getAccount } = useAccount()
  const [addFile, { error: addFileError }] = useMutation(ADD_FILE)
  const [createUploadUrl, { error: createUploadUrlError }] =
    useMutation(CREATE_UPLOAD_URL)

  async function uploadFile(file) {
    const account = await getAccount()

    const recipientMembership = recipient.practice.memberships.find(
      m => m.user.id === account.id
    )
    const senderMembership = sender.practice.memberships.find(
      m => m.user.id === account.id
    )

    const participant = recipientMembership ? recipient : sender
    const membership = recipientMembership || senderMembership

    const membershipAesKey = await deriveAesKey(
      account.privateKey,
      await importPublicKey(membership.publicKey)
    )

    const practicePrivateKey = await unwrapEccKey(
      membership.privateKey.encryptedData,
      membershipAesKey,
      membership.privateKey.iv,
      true
    )

    const referralAesKey = await deriveAesKey(
      practicePrivateKey,
      await importPublicKey(participant.publicKey)
    )

    const encryptionKey = await unwrapAesKey(
      participant.encryptionKey.encryptedData,
      referralAesKey,
      participant.encryptionKey.iv,
      'enc'
    )

    const { encryptedFile, iv } = await encryptFile(file, encryptionKey)

    const {
      data: { createUploadUrl: url }
    } = await createUploadUrl()

    const response = await fetch(url, {
      method: 'PUT',
      body: encryptedFile
    })

    if (!response.ok) {
      console.error(response)
      throw Error(response)
    }

    const name = encryptedFile.name
    const key = new URL(url).pathname.split('/')[2]

    const returnFile = { iv, key }

    const encryptedData = {
      birthDate: referral.birthDate,
      firstName: referral.firstName,
      lastName: referral.lastName,
      phn: referral.phn,
      phone: referral.phone,
      message: referral.message,
      fileNames: [...referral.fileNames, name]
    }

    const { encryptedObject, iv: objectIv } = await encryptObject(
      encryptedData,
      encryptionKey
    )

    return {
      file: returnFile,
      encryptedData: { encryptedData: encryptedObject, iv: objectIv }
    }
  }

  async function onChange(e) {
    setLoading(true)
    try {
      const fileUploaded = e.target.files[0]

      const { file, encryptedData } = await uploadFile(fileUploaded)

      await addFile({
        variables: {
          referral: referral.id,
          file,
          encryptedData
        }
      })
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  return (
    <>
      <InvisibleInput
        id="file-picker"
        ref={hiddenFileInput}
        type="file"
        name="file"
        data-testid="file-picker"
        onChange={onChange}
      />
      <StyledButton
        color="secondary"
        onClick={() => {
          hiddenFileInput.current.click()
        }}
        isloading={loading.toString()}>
        {loading ? (
          <>
            <DataUsageIcon role="progressbar" /> UPLOADING...
          </>
        ) : (
          <>
            <AttachFileIcon />
            ATTACH FILE
          </>
        )}
      </StyledButton>
      {(addFileError || createUploadUrlError) && (
        <AlertContainer>
          <Alert severity="error">Hm, an error occurred. Try again.</Alert>
        </AlertContainer>
      )}
    </>
  )
}

SingleFileUploader.propTypes = {
  referral: PropTypes.object.isRequired,
  sender: PropTypes.object.isRequired,
  recipient: PropTypes.object.isRequired
}

export default SingleFileUploader
