import React from 'react'
import styled from 'styled-components'
import CrashLandingTemplate from '../common/CrashLandingTemplate'

const Text = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: rgba(58, 58, 58, 0.7);
  margin: 0;
`

const EmailText = styled.span`
  font-weight: 600;
  border-bottom: 1px solid #3a3a3a;
`

const CrashPage = () => (
  <CrashLandingTemplate title="Sorry!" subtitle="Application has crashed">
    <Text>
      You can send comments and feedback to{' '}
      <EmailText>support@clinnect.ca</EmailText>
    </Text>
  </CrashLandingTemplate>
)

export default CrashPage
