import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { pdf } from '@react-pdf/renderer'

import useAccount from '../../hooks/useAccount'
import ReferralPdf from '../faxing/ReferralPdf'
import Select from '../common/Select'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import DeclinedReason from './DeclinedReason'
import { MenuItem } from '@material-ui/core'
import {
  deriveAesKey,
  unwrapEccKey,
  unwrapAesKey,
  importPublicKey,
  decryptFile
} from '../../crypto'

const LoadingContainer = styled.div`
  div:first-child {
    max-height: calc(100vh - 325px);
  }
`

const ReferralDuplicateViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`

const PdfContainer = styled.div`
  display: flex;
  max-height: ${props =>
    props.state === 'declined' ? 'calc(100vh - 448px)' : 'calc(100vh - 325px)'};
  overflow: scroll;
`

const DeclinedContainer = styled.div`
  background: #fff;
  border-radius: 3px;
  padding: 18px 15px;
  margin: 0 0 15px;
`

const StyledSelect = styled(Select)`
  width: 250px;

  .MuiInputBase-root {
    margin-top: 0;
    width: 250px;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: rgba(6, 153, 135, 0.15);
  }
  &.MuiListItem-root:hover {
    background-color: rgba(6, 153, 135, 0.05);
  }
`

const DocumentCountStyling = styled.p`
  margin: 0;
  color: rgba(58, 58, 58, 0.7);
  font-size: 14px;
  font-style: italic;
`

const Pdf = styled.embed`
  width: 100%;
`

const CREATE_DOWNLOAD_URL = gql`
  mutation createDownloadUrl($referral: ID!, $file: Int!) {
    createDownloadUrl(referral: $referral, file: $file)
  }
`

function ReferralDuplicateView({ referral }) {
  const [createDownloadUrl] = useMutation(CREATE_DOWNLOAD_URL)
  const [attachments, setAttachments] = useState(null)
  const [activeAttachmentIndex, setActiveAttachmentIndex] = useState(null)
  const [objectUrl, setObjectUrl] = useState(null)
  const { getAccount } = useAccount()

  useEffect(() => {
    async function getBinaryFile(file) {
      try {
        const key = file.key
        const index = referral.files.findIndex(f => f.key === key)
        const name = referral.fileNames[index]
        const account = await getAccount()

        const isSender = referral.sender.publicKey !== undefined

        const participant = isSender ? referral.sender : referral.recipient
        const membership = isSender
          ? referral.sender.practice.memberships.find(
              m => m.user.id === account.id
            )
          : referral.recipient.practice.memberships.find(
              m => m.user.id === account.id
            )

        const membershipAesKey = await deriveAesKey(
          account.privateKey,
          await importPublicKey(membership.publicKey)
        )

        const practicePrivateKey = await unwrapEccKey(
          membership.privateKey.encryptedData,
          membershipAesKey,
          membership.privateKey.iv,
          true
        )

        const referralAesKey = await deriveAesKey(
          practicePrivateKey,
          await importPublicKey(participant.publicKey)
        )

        const encryptionKey = await unwrapAesKey(
          participant.encryptionKey.encryptedData,
          referralAesKey,
          participant.encryptionKey.iv,
          'enc'
        )

        const {
          data: { createDownloadUrl: url }
        } = await createDownloadUrl({
          variables: { referral: referral.id, file: index }
        })

        const response = await fetch(url)
        const reader = response.body.getReader()

        const chunks = []
        let isDone = false

        while (!isDone) {
          const response = await reader.read()

          if (response.value) {
            chunks.push(response.value)
          }

          isDone = response.done
        }

        const blob = new Blob(chunks)
        const encryptedFile = new File([blob], name)

        const iv = file.iv

        const rawFile = await decryptFile(encryptedFile, encryptionKey, iv)

        const extension = rawFile.name.split('.')

        const extensionMap = {
          pdf: 'application/pdf',
          png: 'image/png',
          jpg: 'image/png',
          jpeg: 'image/png'
        }

        const newFile = new File([rawFile], name, {
          type:
            extensionMap[extension[extension.length - 1]] ?? 'application/pdf'
        })

        return newFile
      } catch (e) {
        console.error('Request error occurred:', e.message)
      }
    }

    async function getReferralPdf() {
      const files = await Promise.all(referral.files.map(getBinaryFile))

      const blob = await pdf(
        <ReferralPdf
          referral={referral}
          sender={referral.sender.practice}
          recipient={referral.recipient.practice}
        />
      ).toBlob()

      const referralPdf = new File([blob], 'clinnectReferral.pdf', {
        type: 'application/pdf'
      })

      const attachments = [referralPdf, ...files]

      setActiveAttachmentIndex(0)
      setAttachments(attachments)
    }

    if (referral) getReferralPdf()
  }, [referral, createDownloadUrl, getAccount])

  useEffect(() => {
    if (attachments && activeAttachmentIndex !== null) {
      if (attachments[activeAttachmentIndex]) {
        const newObjectUrl = URL.createObjectURL(
          attachments[activeAttachmentIndex]
        )
        setObjectUrl(newObjectUrl)
      } else {
        setObjectUrl(null)
      }
    }
  }, [activeAttachmentIndex, attachments])

  useEffect(() => {
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl)
    }
  }, [objectUrl])

  const handleChange = event => setActiveAttachmentIndex(event.target.value)

  return (
    <ReferralDuplicateViewContainer>
      {activeAttachmentIndex !== null && attachments !== null ? (
        <>
          <DropdownContainer>
            <StyledSelect
              select
              value={activeAttachmentIndex}
              onChange={handleChange}
              aria-labelledby="referral-attachments-dropdown-label">
              {attachments?.map((attachment, i) => (
                <StyledMenuItem key={`referral-attachment-${i}`} value={i}>
                  <div>
                    {i === 0 && 'Referral'}
                    {i > 0 && `Attachment ${i}`}
                    <DocumentCountStyling>
                      {`Document ${i + 1} of ${attachments.length}`}
                    </DocumentCountStyling>
                  </div>
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </DropdownContainer>
          {referral.state === 'declined' && (
            <DeclinedContainer>
              <DeclinedReason referral={referral.id} />
            </DeclinedContainer>
          )}
          {objectUrl ? (
            <PdfContainer state={referral.state}>
              {attachments[activeAttachmentIndex].type ===
                'application/pdf' && (
                <Pdf
                  title="Referral preview"
                  src={objectUrl}
                  width="530"
                  height="730"
                  type="application/pdf"
                />
              )}
              {attachments[activeAttachmentIndex].type === 'image/png' && (
                <img
                  title="Referral attachment"
                  src={objectUrl}
                  width="510"
                  alt="Referral attachment"
                />
              )}
            </PdfContainer>
          ) : (
            <LoadingContainer>
              <LoadingError
                message={[
                  'Attachment could not be loaded',
                  'Please try refreshing the page.'
                ]}
              />
            </LoadingContainer>
          )}
        </>
      ) : (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}
    </ReferralDuplicateViewContainer>
  )
}

ReferralDuplicateView.propTypes = {
  referral: PropTypes.object.isRequired
}

export default ReferralDuplicateView
