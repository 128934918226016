import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { List, Paper } from '@material-ui/core'
import { useHistory, Link } from 'react-router-dom'

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import PrinterIcon from 'mdi-react/PrinterIcon'
import AccountArrowRightIcon from 'mdi-react/AccountArrowRightIcon'

import {
  InfoMessage,
  ErrorMessage,
  ProcessingMessage,
  SuccessMessage,
  CancelledMessage
} from '../common/UserAlert'
import { SecondaryButton } from '../common/Button'
import { PageTitle, SectionTitle } from '../common/Title'
import { FieldLabel, FieldValue } from '../common/StyledField'
import UrgencyIndicator from '../common/UrgencyIndicator'
import StatusIndicator from '../common/StatusIndicator'
import IconStatusIndicator from '../common/IconStatusIndicator'
import PhysicianFromToInfo from '../refer/PhysicianFromToInfo'
import ReferralPrint from '../print/ReferralPrint'
import usePrinter from '../../hooks/usePrinter'
import ReferralDataView from './ReferralDataView'
import FileButton from './FileButton'
import DownloadProgress from './DownloadProgress'
import AcceptReferralButton from './AcceptReferralButton'
import DeclineReferralButton from './DeclineReferralButton'
import ChangeUrgency from './ChangeUrgency'
import SingleFileUploader from './SingleFileUploader'
import ReferralHistory from './ReferralHistory'
import FaxFromReferralButton from '../faxing/FaxFromReferralButton'
import ResendFaxFromReferralButton from '../faxing/ResendFaxFromReferralButton'
import CancelFaxButton from '../faxing/CancelFaxButton'
import JourneyLog from '../journeyLog/JourneyLog'
import Roadmap from '../journeyLog/Roadmap'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

const StatusContainer = styled.div`
  margin: 0 0 30px 30px;
  display: flex;
  align-items: center;
  gap: 15px;
`

const ActionButtonsContainer = styled(FlexContainer)`
  padding-bottom: 30px;
  display: flex;
  gap: 15px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RoadmapContainer = styled.div`
  margin-bottom: 27px;
`

const ReferralContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 0 30px 100px;
`

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 15px;
  width: 40%;
  height: 100%;
`
const ReferralHistoryContainer = styled(Paper)`
  border-radius: 6px;
`

const JourneyLogContainer = styled(Paper)`
  border-radius: 6px;
  padding: 0 15px 30px;
  margin-bottom: 30px;
`

const ReferralInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const AllFields = styled.div`
  padding: 0 35px;
`

const StyledFieldLabel = styled(FieldLabel)`
  padding-top: 27px;
`

const BoxWrapper = styled.div`
  margin: 1em 0 0;
`

const UrgencyIndicatorWrapper = styled.div`
  margin-bottom: 9px;
`

const StyledList = styled(List)`
  background-color: #f9fbfe;
  border-radius: 3px;
  padding: 8px 15px;
  margin: 9px 0 0;
  min-height: 34px;
`

const StyledInfoMessage = styled(InfoMessage)`
  margin-top: 0px;
`

const FailedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div:first-child {
    margin: 0 0 15px 0;
  }
  div[role='alert'] {
    height: 100%;
  }
`

const FaxButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 8px;
  margin-left: 15px;

  div:first-child {
    margin: 0;
  }
  p[role='alert'] {
    padding-bottom: 5px;
  }
`

const BoldText = styled.span`
  font-weight: 600;
`

function ReferralView({
  referral,
  sender,
  recipient,
  isRecipient,
  isSender,
  events,
  journeyDates,
  refreshJourneyDates = () => {}
}) {
  const [downloads, setDownloads] = useState({})
  const print = usePrinter({ documentTitle: 'Clinnect Referral' })
  const history = useHistory()

  function onDownloadProgress({ key, name, progress, total }) {
    setDownloads(downloads => {
      const newDownloads = {
        ...downloads,
        [key]: { name, progress, total }
      }
      const isAllDone = Object.values(newDownloads).every(
        v => v.progress === v.total
      )
      if (isAllDone) setDownloads({})
      else setDownloads(newDownloads)
    })
  }

  function printReferral() {
    print(
      <ReferralPrint
        date={new Date()}
        referral={referral}
        sender={sender.practice}
        recipient={recipient.practice}
        isFree={false}
      />
    )
  }

  function resendButtons(type, state) {
    const fax = type === 'emr' ? referral.faxToEmr : referral.triageFax
    const text = type === 'emr' ? 'Referral to EMR' : 'Triage Response'

    let redirectedEvent = null
    if (type === 'triage' && referral.redirectedFrom) {
      const redirectedEvents = events.filter(
        e => e.type === 'referral_redirected'
      )

      if (redirectedEvents.length > 0) {
        if (referral.redirectedTo && redirectedEvents.length > 1)
          redirectedEvent = redirectedEvents[redirectedEvents.length - 2]
        if (!referral.redirectedTo)
          redirectedEvent = redirectedEvents[redirectedEvents.length - 1]
      }
    }

    const practices = []
    if (isSender) practices.push(sender.practice)
    if (isRecipient) practices.push(recipient.practice)

    return (
      <FailedContainer>
        {state === 'cancelled' && (
          <CancelledMessage>
            <BoldText>{text} Cancelled</BoldText>
          </CancelledMessage>
        )}
        {state === 'failed' && (
          <ErrorMessage>
            <BoldText>{text} Failed: </BoldText>
            {fax?.errorText || 'Provider unavailable'}
          </ErrorMessage>
        )}
        {state === 'processing' && (
          <ProcessingMessage>
            <BoldText>{text} Sending</BoldText>
          </ProcessingMessage>
        )}
        {state === 'success' && (
          <SuccessMessage iconType="circle">
            <BoldText>{text} Successfully Sent</BoldText>
          </SuccessMessage>
        )}
        {fax?.state !== 'processing' && (
          <FaxButtonContainer>
            {fax?.state === 'failed' ? (
              <>
                <CancelFaxButton
                  faxId={fax.id}
                  referralId={referral.id}
                  isSender={isSender}
                  isRecipient={isRecipient}
                />
                <ResendFaxFromReferralButton
                  type="icon"
                  faxId={fax.id}
                  isSender={isSender}
                  isRecipient={isRecipient}
                />
              </>
            ) : (
              <FaxFromReferralButton
                type="icon"
                referral={{ ...referral, sender, recipient }}
                redirectedEvent={redirectedEvent}
                destination={type}
                practices={practices}
              />
            )}
          </FaxButtonContainer>
        )}
      </FailedContainer>
    )
  }

  return (
    <div>
      <Header>
        <FlexContainer>
          <PageTitle>Referral Information</PageTitle>
          <StatusContainer>
            <StatusIndicator state={referral.state} />
            {(referral.faxToEmr?.state ||
              (recipient.practice.emrSyncActiveDate &&
                referral.createdAt > recipient.practice.emrSyncActiveDate &&
                !referral.faxToEmr)) && (
              <IconStatusIndicator
                state={referral.faxToEmr?.state || 'failed'}
                label="Referral to EMR"
                side="left"
              />
            )}
            {(referral.triageFax?.state ||
              (sender.practice.triageResponseActiveDate &&
                referral.createdAt > sender.practice.triageResponseActiveDate &&
                !referral.triageFax &&
                referral.initialFax)) && (
              <IconStatusIndicator
                state={referral.triageFax?.state || 'failed'}
                label="Triage Response"
                side="left"
              />
            )}
          </StatusContainer>
        </FlexContainer>
        <ActionButtonsContainer>
          {isSender && referral.state === 'declined' && (
            <StyledLink to={`/refer?redirectedFrom=${referral.id}`}>
              <SecondaryButton color="secondary">
                <AccountArrowRightIcon />
                Redirect Referral
              </SecondaryButton>
            </StyledLink>
          )}
          {isRecipient && referral.state === 'pending' && (
            <>
              <DeclineReferralButton
                referralId={referral.id}
                onSuccess={refreshJourneyDates}
              />
              <AcceptReferralButton referralId={referral.id} />
            </>
          )}
          {isRecipient && referral.state === 'accepted' && (
            <DeclineReferralButton
              referralId={referral.id}
              onSuccess={refreshJourneyDates}
            />
          )}
          {isRecipient && referral.state === 'declined' && (
            <AcceptReferralButton
              variant="secondary"
              referralId={referral.id}
            />
          )}
        </ActionButtonsContainer>
      </Header>
      <BlueBar>
        <SecondaryButton
          color="secondary"
          padding="medium"
          onClick={() =>
            history.length > 1
              ? history.goBack()
              : history.push('/notifications')
          }>
          <ChevronLeftIcon style={{ opacity: '0.6' }} /> Back
        </SecondaryButton>
        <PhysicianFromToInfo
          sender={sender.practice}
          recipient={recipient.practice}
        />
        <SecondaryButton color="secondary" onClick={printReferral}>
          <PrinterIcon />
          Print
        </SecondaryButton>
      </BlueBar>
      {isRecipient && journeyDates && (
        <RoadmapContainer>
          <Roadmap journeyDates={journeyDates} />
        </RoadmapContainer>
      )}
      <ContentContainer>
        <LeftColumn>
          {referral.type === 'free' && (
            <StyledInfoMessage>
              This referral was sent using Clinnect&#39;s Free Referral service.
              The referring provider will be notified when you accept or decline
              the referral, however no other updates will be sent.
            </StyledInfoMessage>
          )}
          <ReferralContainer elevation={3}>
            <ReferralDataView
              referral={referral}
              recipient={recipient.practice}
              isRecipient={isRecipient}
              page="referral"
            />
            <ReferralInformation>
              <SectionTitle>Referral Information</SectionTitle>
              <Divider />
              <AllFields>
                <StyledFieldLabel>
                  Chosen <strong>urgency</strong>
                </StyledFieldLabel>
                <BoxWrapper>
                  {isRecipient &&
                  !['declined', 'redirected'].includes(referral.state) ? (
                    <ChangeUrgency
                      referralId={referral.id}
                      initialUrgency={referral.urgency}
                    />
                  ) : (
                    <UrgencyIndicatorWrapper>
                      <UrgencyIndicator box={true} urgency={referral.urgency} />
                    </UrgencyIndicatorWrapper>
                  )}
                </BoxWrapper>
                <StyledFieldLabel>
                  Reason for Referral and/or Additional Comments
                </StyledFieldLabel>
                <FieldValue>{referral.message}</FieldValue>
                <StyledFieldLabel>Attached File(s)</StyledFieldLabel>
                <StyledList disablePadding>
                  {referral.files.map((file, i) => (
                    <FileButton
                      key={file.key}
                      file={i}
                      sender={sender}
                      referral={referral}
                      recipient={recipient}
                      onDownloadProgress={onDownloadProgress}
                    />
                  ))}
                </StyledList>
                {referral.state !== 'redirected' && (
                  <SingleFileUploader
                    referral={referral}
                    sender={sender}
                    recipient={recipient}
                  />
                )}
              </AllFields>
            </ReferralInformation>
            <DownloadProgress downloads={downloads} />
          </ReferralContainer>
        </LeftColumn>
        <RightColumn>
          {(referral.faxToEmr ||
            (recipient.practice.emrSyncActiveDate &&
              referral.createdAt > recipient.practice.emrSyncActiveDate &&
              !referral.faxToEmr)) &&
            resendButtons('emr', referral.faxToEmr?.state || 'failed')}
          {(referral.triageFax ||
            (sender.practice.triageResponseActiveDate &&
              referral.initialFax &&
              !referral.triageFax &&
              referral.createdAt > sender.practice.triageResponseActiveDate)) &&
            resendButtons('triage', referral.triageFax?.state || 'failed')}
          {isRecipient && journeyDates && (
            <JourneyLogContainer>
              <JourneyLog journeyDates={journeyDates} referral={referral.id} />
            </JourneyLogContainer>
          )}
          <ReferralHistoryContainer>
            <ReferralHistory
              referral={referral}
              events={events}
              sender={sender}
              recipient={recipient}
              isSender={isSender}
              isRecipient={isRecipient}
            />
          </ReferralHistoryContainer>
        </RightColumn>
      </ContentContainer>
    </div>
  )
}

ReferralView.propTypes = {
  referral: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date),
    urgency: PropTypes.string,
    files: PropTypes.array,
    message: PropTypes.string,
    fileNames: PropTypes.arrayOf(PropTypes.string),
    state: PropTypes.string,
    type: PropTypes.string,
    initialFax: PropTypes.object,
    faxToEmr: PropTypes.object,
    triageFax: PropTypes.object,
    referringProvider: PropTypes.object,
    redirectedFrom: PropTypes.string,
    redirectedTo: PropTypes.string
  }).isRequired,
  sender: PropTypes.object.isRequired,
  recipient: PropTypes.object.isRequired,
  isRecipient: PropTypes.bool.isRequired,
  isSender: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  journeyDates: PropTypes.array,
  refreshJourneyDates: PropTypes.func
}

export default ReferralView
