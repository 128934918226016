import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { format } from 'date-fns'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useMutation } from '@apollo/client'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import MapMarkerIcon from 'mdi-react/MapMarkerIcon'
import MapMarkerCheckIcon from 'mdi-react/MapMarkerCheckIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import CloseIcon from 'mdi-react/CloseIcon'

import { getJourneyDateDisplayName } from '../../util/strings'
import { IconButton } from '../common/Button'
import DeleteJourneyDateButton from './DeleteJourneyDateButton'
import Select from '../common/Select'

const typeColorMap = {
  referralDate: '#A5BE00',
  initialConsult: '#B4436C',
  decision: '#404E7C',
  procedure: '#33CCEB',
  followUpConsult: '#BBA0B2',
  completed: '#A5BE00'
}

const JourneyDateContainer = styled.form`
  display: flex;
  justify-content: space-between;
  padding: ${props => (props.editing === 'true' ? '15px' : '0 15px')};
  background-color: ${props =>
    props.editing === 'true' ? 'rgba(6, 153, 135, 0.05)' : 'transparent'};
`

const StartContainer = styled.div`
  display: flex;
  align-items: ${props => (props.editing === 'true' ? 'flex-start' : 'center')};
`

const DateContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`

const EditingContainer = styled(DateContainer)`
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0 0;
`

const DateType = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin: 0;
`

const DateText = styled(DateType)`
  font-weight: 400;
`

const StyledSelect = styled(Select)`
  min-width: 150px;

  .MuiInputBase-root {
    margin-top: 0;
    margin-right: 15px;

    & .MuiInputBase-input {
      padding: 8px 0 8px 15px;
    }
  }
  .MuiIconButton-root {
    padding: 5px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: ${props => (props.editing === 'true' ? 'flex-end' : 'center')};
  gap: 10px;
  padding: ${props => (props.editing === 'true' ? '0 0 4px' : '0')};
`

const ButtonErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`

const StyledMapMarker = styled(MapMarkerIcon)`
  color: ${props => (props.type ? typeColorMap[props.type] : '#3a3a3a')};
`

const StyledMapMarkerCheck = styled(MapMarkerCheckIcon)`
  color: ${props => (props.type ? typeColorMap[props.type] : '#3a3a3a')};
`

const ErrorText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  color: #e84c3d;
  margin: 0;
  padding-top: 5px;
`

const UPDATE_JOURNEY_DATE = gql`
  mutation updateJourneyDate(
    $journeyDate: ID!
    $date: String!
    $billingCode: String
  ) {
    updateJourneyDate(
      journeyDate: $journeyDate
      date: $date
      billingCode: $billingCode
    ) {
      id
      date
      billingCode
    }
  }
`

function JourneyLogDate({
  journeyDate,
  referralDate = null,
  completedDate = null,
  lastJourneyDate = null
}) {
  const journeyDateSchema = yup.object().shape({
    date: yup
      .date()
      .typeError('Date is required')
      .required('Date is required')
      .when([], dateSchema =>
        lastJourneyDate === null || journeyDate.type !== 'completed'
          ? referralDate === null
            ? dateSchema
            : dateSchema.min(
                new Date(referralDate.setHours(0, 0, 0, 0)),
                'Date must be after the referral date'
              )
          : dateSchema.min(
              new Date(lastJourneyDate.setHours(0, 0, 0, 0)),
              'Date must be after the other journey dates'
            )
      )
      .when([], dateSchema =>
        completedDate === null || journeyDate.type === 'completed'
          ? dateSchema
          : dateSchema.max(
              new Date(completedDate.setHours(23, 59, 59, 999)),
              'Date must be before the end of the patient journey'
            )
      )
  })

  const [editing, setEditing] = useState(false)
  const [updateJourneyDate, updateResult] = useMutation(UPDATE_JOURNEY_DATE)

  const { handleSubmit, control, errors, reset } = useForm({
    resolver: yupResolver(journeyDateSchema),
    criteriaMode: 'all',
    defaultValues: { date: journeyDate.date }
  })

  useEffect(() => {
    const resetValues =
      journeyDate.type === 'procedure'
        ? { date: journeyDate.date, billingCode: journeyDate.billingCode || '' }
        : { date: journeyDate.date }
    reset(resetValues)
  }, [reset, journeyDate])

  const handleEdit = () => setEditing(true)
  function handleCancel() {
    setEditing(false)

    const resetValues =
      journeyDate.type === 'procedure'
        ? { date: journeyDate.date, billingCode: journeyDate.billingCode || '' }
        : { date: journeyDate.date }
    reset(resetValues)
  }

  async function onSubmit(values) {
    try {
      const variables =
        journeyDate.type === 'procedure'
          ? { billingCode: values.billingCode }
          : {}

      await updateJourneyDate({
        variables: {
          ...variables,
          journeyDate: journeyDate.id,
          date: values.date.toISOString()
        }
      })
      setEditing(false)
    } catch (e) {
      console.error('Error editing journey date:', e.message)
    }
  }

  return (
    <JourneyDateContainer aria-label="Edit date" editing={editing.toString()}>
      <StartContainer editing={editing.toString()}>
        {journeyDate.type !== 'completed' ? (
          <StyledMapMarker type={journeyDate.type} />
        ) : (
          <StyledMapMarkerCheck type={journeyDate.type} />
        )}
        {!editing ? (
          <>
            <DateContainer>
              <DateType>
                {getJourneyDateDisplayName(journeyDate.type)}:
              </DateType>
              <DateText>{format(journeyDate.date, 'dd/MMM/yyyy')}</DateText>
              {journeyDate.type === 'procedure' && (
                <DateText>{journeyDate.billingCode}</DateText>
              )}
            </DateContainer>
          </>
        ) : (
          <>
            <EditingContainer>
              <DateType>
                {getJourneyDateDisplayName(journeyDate.type)}:
              </DateType>
              <Controller
                name="date"
                control={control}
                render={({ ref, ...props }) => (
                  <KeyboardDatePicker
                    {...props}
                    TextFieldComponent={StyledSelect}
                    id="date"
                    inputRef={ref}
                    InputLabelProps={{
                      shrink: true,
                      required: true
                    }}
                    label="Date"
                    mask="__/___/____"
                    refuse={/[^a-zA-Z0-9]+/gi}
                    format="dd/MMM/yyyy"
                    placeholder="DD/MMM/YYYY"
                    views={['year', 'month', 'date']}
                    openTo="year"
                    error={!!errors.date}
                    helperText={errors?.date?.message}
                    FormHelperTextProps={{ role: 'alert' }}
                  />
                )}
              />
              {journeyDate.type === 'procedure' && (
                <>
                  <DateType>Procedure Billing Code:</DateType>
                  <Controller
                    name="billingCode"
                    control={control}
                    defaultValue={journeyDate.billingCode || ''}
                    render={({ onChange, value, name }) => (
                      <StyledSelect
                        id="billingCode"
                        name={name}
                        label="Billing Code"
                        value={value}
                        onChange={onChange}
                        InputLabelProps={{
                          shrink: true
                        }}
                        error={!!errors.billingCode}
                        helperText={errors?.billingCode?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                      />
                    )}
                  />
                </>
              )}
            </EditingContainer>
          </>
        )}
      </StartContainer>
      {journeyDate.type !== 'referralDate' && (
        <>
          {!editing ? (
            <ButtonContainer>
              <IconButton
                color="secondary"
                padding="short"
                onClick={handleEdit}
                aria-label="Edit date">
                <PencilIcon />
              </IconButton>

              <DeleteJourneyDateButton journeyDate={journeyDate} />
            </ButtonContainer>
          ) : (
            <ButtonErrorContainer>
              <ButtonContainer editing={editing.toString()}>
                <IconButton
                  color="secondary"
                  padding="short"
                  onClick={handleCancel}
                  disabled={updateResult.loading}
                  aria-label="Cancel">
                  <CloseIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  padding="short"
                  onClick={handleSubmit(onSubmit)}
                  loading={updateResult.loading}
                  aria-label="Save">
                  <ContentSaveIcon />
                </IconButton>
              </ButtonContainer>
              {updateResult.error && (
                <ErrorText role="alert">
                  Hm, an error occurred. Try again.
                </ErrorText>
              )}
            </ButtonErrorContainer>
          )}
        </>
      )}
    </JourneyDateContainer>
  )
}

JourneyLogDate.propTypes = {
  journeyDate: PropTypes.shape({
    id: PropTypes.string.isRequired,
    referral: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    date: PropTypes.object.isRequired,
    billingCode: PropTypes.string
  }).isRequired,
  referralDate: PropTypes.object,
  completedDate: PropTypes.object,
  lastJourneyDate: PropTypes.object
}

export default JourneyLogDate
