import React, { useState, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  InputLabel,
  FormControl,
  ListItemText
} from '@material-ui/core'
import FilterIcon from 'mdi-react/FilterIcon'
import styled from 'styled-components'
import Button, { SecondaryButton } from '../common/Button'
import {
  SelectMultiple,
  SelectMenuItem,
  SelectCheckbox
} from '../common/SelectMultiple'
import { SectionTitle } from '../common/Title'
import {
  getFactorCategoryName,
  getAllLeafCategoryIds
} from '../../util/categories'

const StyledSectionTitle = styled(SectionTitle)`
  margin: 0;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(58, 58, 58, 0.1);
  margin-top: 30px;
`

const SelectButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button:last-child {
    margin-left: 15px;
  }
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 600px;
  }
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  :first-child {
    padding: 0 30px;
  }
`

const StyledInputLabel = styled(InputLabel)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #3a3a3a;
`

const StyledFormControl = styled(FormControl).attrs(props => ({
  variant: props.variant || 'outlined',
  margin: props.margin || 'dense'
}))`
  margin: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px 30px;
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`

const FilterByContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button:last-child {
    margin-left: 10px;
  }
  padding-bottom: 30px;
`

function FilterButton({ specialists, filters, applyFilters = () => {} }) {
  const [isVisible, setIsVisible] = useState(false)
  const [selectedSpecialists, setSelectedSpecialists] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState(null)

  const isMounted = useRef(true)

  const categories = useMemo(() => {
    const allCategories = getAllLeafCategoryIds(specialists[0].specialty).map(
      c => {
        return {
          category: getFactorCategoryName(c),
          categoryId: c
        }
      }
    )

    return [{ category: 'Overall', categoryId: null }, ...allCategories]
  }, [specialists])

  useEffect(() => {
    setSelectedSpecialists(specialists)
    setSelectedCategories(categories)
  }, [specialists, categories])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleOpen = () => setIsVisible(true)
  const handleClose = () => setIsVisible(false)

  function handleSelection(type) {
    setSelectedSpecialists(type === 'clear' ? [] : specialists)
    setSelectedCategories(type === 'clear' ? [] : categories)
  }

  function applyFiltersChange(event, type) {
    const { value } = event.target

    if (type === 'specialist') {
      setSelectedSpecialists(specialists.filter(s => value.includes(s.id)))
    } else if (type === 'category') {
      setSelectedCategories(
        categories.filter(c => value.includes(c.categoryId))
      )
    }
  }

  function handleCancel() {
    if (filters) {
      setSelectedSpecialists(
        specialists.filter(s =>
          filters.specialists.map(spc => spc.id).includes(s.id)
        )
      )
      setSelectedCategories(
        categories.filter(c =>
          filters.categories.map(ctg => ctg.categoryId).includes(c.categoryId)
        )
      )
    } else {
      setSelectedSpecialists(specialists)
      setSelectedCategories(categories)
    }
    handleClose()
  }

  function handleApplyFilters() {
    const filters = {
      specialists: selectedSpecialists,
      categories: selectedCategories
    }

    applyFilters(filters)
    handleClose()
  }

  return (
    <div>
      <SecondaryButton
        color="secondary"
        onClick={handleOpen}
        aria-label="Filter">
        <FilterIcon size={16} />
        Filter
      </SecondaryButton>
      <StyledDialog
        fullWidth
        open={isVisible}
        onClose={handleClose}
        keepMounted={false}>
        <StyledDialogContent>
          <Header>
            <StyledSectionTitle>Filters</StyledSectionTitle>
            <SelectButtonContainer>
              <SecondaryButton
                onClick={() => handleSelection('selectAll')}
                padding="small"
                color="secondary">
                Select All
              </SecondaryButton>
              <SecondaryButton
                onClick={() => handleSelection('clear')}
                padding="small"
                color="secondary">
                Clear All
              </SecondaryButton>
            </SelectButtonContainer>
          </Header>
          <Container>
            <FilterContainer>
              <FilterByContainer>
                <StyledInputLabel id="practice-selector-label">
                  Practices
                </StyledInputLabel>
              </FilterByContainer>
              <StyledFormControl>
                {selectedSpecialists && (
                  <SelectMultiple
                    labelId="practice-selector-label"
                    id="practice-selector"
                    multiple
                    value={selectedSpecialists.map(s => s.id)}
                    onChange={e => applyFiltersChange(e, 'specialist')}
                    renderValue={selected => {
                      const selectedSpecialistNames = selected.map(select => {
                        const selectedSpecialist = specialists.find(
                          s => s.id === select
                        )
                        return `${selectedSpecialist.firstName} ${selectedSpecialist.lastName}`
                      })
                      return selectedSpecialistNames.join(', ')
                    }}>
                    {specialists.map(s => (
                      <SelectMenuItem key={s.id} value={s.id}>
                        <SelectCheckbox
                          size="small"
                          checked={
                            selectedSpecialists.map(s => s.id).indexOf(s.id) >
                            -1
                          }
                        />
                        <ListItemText
                          primary={`${s.firstName} ${s.lastName}`}
                        />
                      </SelectMenuItem>
                    ))}
                  </SelectMultiple>
                )}
              </StyledFormControl>
            </FilterContainer>
            <FilterContainer>
              <FilterByContainer>
                <StyledInputLabel id="category-selector-label">
                  Categories
                </StyledInputLabel>
              </FilterByContainer>
              <StyledFormControl>
                {selectedCategories && (
                  <SelectMultiple
                    labelId="category-selector-label"
                    id="category-selector"
                    multiple
                    value={selectedCategories.map(c => c.categoryId)}
                    onChange={e => applyFiltersChange(e, 'category')}
                    renderValue={selected => {
                      const selectedCategoryNames = selected.map(select => {
                        const selectedCategory = categories.find(
                          c => c.categoryId === select
                        )
                        return selectedCategory.category
                      })
                      return selectedCategoryNames.join(', ')
                    }}>
                    {categories.map((c, i) => (
                      <SelectMenuItem key={i} value={c.categoryId}>
                        <SelectCheckbox
                          size="small"
                          checked={
                            selectedCategories
                              .map(c => c.categoryId)
                              .indexOf(c.categoryId) > -1
                          }
                        />
                        <ListItemText primary={c.category} />
                      </SelectMenuItem>
                    ))}
                  </SelectMultiple>
                )}
              </StyledFormControl>
            </FilterContainer>
          </Container>
          <ButtonContainer>
            <SecondaryButton
              onClick={handleCancel}
              padding="small"
              color="secondary">
              Cancel
            </SecondaryButton>
            <Button onClick={handleApplyFilters} padding="small">
              Apply Filters
            </Button>
          </ButtonContainer>
        </StyledDialogContent>
      </StyledDialog>
    </div>
  )
}

FilterButton.propTypes = {
  specialists: PropTypes.array.isRequired,
  filters: PropTypes.object,
  applyFilters: PropTypes.func
}

export default FilterButton
