import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import styled from 'styled-components'
import RefreshIcon from 'mdi-react/RefreshIcon'
import { SecondaryButton } from '../common/Button'

const ErrorText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  color: #e84c3d;
  margin: 0;
  padding-top: 5px;
`

function sortByDate(a, b) {
  if (a.createdAt < b.createdAt) return 1
  if (a.createdAt > b.createdAt) return -1
  return 0
}

const RESEND_FAX = gql`
  mutation resendFax($fax: ID!) {
    resendFax(fax: $fax) {
      id
      state
      faxId
      createdAt
    }
  }
`

const GET_FAXES = gql`
  query getFaxes($direction: String!, $archived: String!) {
    getFaxes(direction: $direction, archived: $archived) {
      id
      createdAt
    }
  }
`

const GET_ALERTS = gql`
  query getAlerts {
    getAlerts {
      sentReferrals
      receivedReferrals
      sentFaxes
    }
  }
`

function ResendFaxButton({ faxId }) {
  const [resendFax, { loading, error }] = useMutation(RESEND_FAX, {
    update(cache) {
      if (
        cache.data.data.ROOT_QUERY?.[
          `getFaxes({"archived":"notArchived","direction":"sent"})`
        ]
      ) {
        const { getFaxes } = cache.readQuery({
          query: GET_FAXES,
          variables: { direction: 'sent', archived: 'notArchived' }
        })
        cache.writeQuery({
          query: GET_FAXES,
          variables: { direction: 'sent', archived: 'notArchived' },
          data: {
            getFaxes: [...getFaxes].sort(sortByDate)
          }
        })
      }
      if (cache.data.data.ROOT_QUERY?.[`getAlerts`]) {
        const { getAlerts } = cache.readQuery({
          query: GET_ALERTS
        })

        cache.writeQuery({
          query: GET_ALERTS,
          data: {
            getAlerts: {
              ...getAlerts,
              sentFaxes: getAlerts.sentFaxes - 1
            }
          }
        })
      }
    }
  })

  async function onResend() {
    try {
      await resendFax({ variables: { fax: faxId } })
    } catch (e) {
      console.error('Error resending fax:', e.message)
    }
  }

  return (
    <>
      <SecondaryButton
        color="secondary"
        padding="small"
        onClick={onResend}
        loading={loading}>
        <RefreshIcon />
        Resend
      </SecondaryButton>
      {error && (
        <ErrorText role="alert">Hm, an error occurred. Try again.</ErrorText>
      )}
    </>
  )
}

ResendFaxButton.propTypes = {
  faxId: PropTypes.string.isRequired
}

export default ResendFaxButton
