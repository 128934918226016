import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import AddMembersForm from '../members/AddMembersForm'
import { useParams } from 'react-router-dom'

const GET_PRACTICES = gql`
  query getPractices {
    getPractices {
      id
      firstName
      lastName
      centralIntakeName
      type
      memberships {
        privateKey {
          encryptedData
          iv
        }
        publicKey
        user {
          id
        }
      }
    }
  }
`

const AddMembersPage = () => {
  const { loading, error, data } = useQuery(GET_PRACTICES)
  const { practiceId } = useParams()

  if (error) return <LoadingError />
  if (loading) return <Loading />
  return (
    <AddMembersForm
      practices={data.getPractices}
      initialPractice={practiceId}
    />
  )
}

export default AddMembersPage
