import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { Dialog, DialogContent, Tooltip } from '@material-ui/core'
import CloseIcon from 'mdi-react/CloseIcon'

import Button, { SecondaryButton, IconButton } from '../common/Button'
import { ErrorMessage } from '../common/UserAlert'

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 500px;
  }
`

const ErrorContainer = styled.div`
  margin: 5px 30px 0;
  div:first-child {
    margin-bottom: 8px;
  }
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
  :first-child {
    padding-top: 0px;
  }
`

const Message = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #393e46;
  padding: 30px 30px 0;
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 500px;
  button:last-child {
    margin-left: 15px;
  }
  padding: 30px;
`

const IconWrapper = styled.div`
  height: 34px;
`

const CANCEL_FAX = gql`
  mutation cancelFax($fax: ID!) {
    cancelFax(fax: $fax) {
      id
      state
      errorText
    }
  }
`

const GET_ALERTS = gql`
  query getAlerts {
    getAlerts {
      sentReferrals
      receivedReferrals
      sentFaxes
    }
  }
`

const GET_REFERRAL = gql`
  query getReferral {
    getReferral(id: $id) {
      id
      faxToEmr {
        id
        state
        errorText
      }
      triageFax {
        id
        state
        errorText
      }
    }
  }
`

function CancelFaxButton({
  faxId,
  referralId,
  isSender = false,
  isRecipient = false
}) {
  const [cancelFax, { loading, error }] = useMutation(CANCEL_FAX, {
    update(cache, { data: { cancelFax } }) {
      if (cache.data.data.ROOT_QUERY?.[`getAlerts`]) {
        const { getAlerts } = cache.readQuery({
          query: GET_ALERTS
        })

        const updatedData = {
          sentFaxes: getAlerts.sentFaxes,
          sentReferrals: isSender
            ? getAlerts.sentReferrals - 1
            : getAlerts.sentReferrals,
          receivedReferrals: isRecipient
            ? getAlerts.receivedReferrals - 1
            : getAlerts.receivedReferrals
        }

        cache.writeQuery({
          query: GET_ALERTS,
          data: {
            getAlerts: updatedData
          }
        })
      }
      if (cache.data.data.ROOT_QUERY?.[`getReferral({"id":"${referralId}"})`]) {
        const { getReferral } = cache.readQuery({
          query: GET_REFERRAL,
          variables: { id: referralId }
        })

        if (
          cancelFax.id === getReferral.triageFax?.id &&
          getReferral.triageFax?.state === 'failed' &&
          getReferral.triageFax?.errorText ===
            'Invalid recipient fax number - Please cancel the fax or reach out to support@clinnect.ca to update the fax number.'
        ) {
          const updatedData = { ...getReferral, triageFax: cancelFax }

          cache.writeQuery({
            query: GET_REFERRAL,
            variables: { id: referralId },
            data: {
              getReferral: updatedData
            }
          })
        }
      }
    }
  })

  const [isVisible, setIsVisible] = useState(false)
  const handleOpen = () => setIsVisible(true)
  const handleClose = () => setIsVisible(false)

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const onCancel = useCallback(async () => {
    setIsVisible(true)
    try {
      await cancelFax({ variables: { fax: faxId } })
      if (isMounted.current) handleClose()
    } catch (e) {
      console.error('Error cancelling fax:', e.message)
    }
  }, [faxId, cancelFax])

  return (
    <>
      <Tooltip title="Cancel fax" arrow>
        <IconWrapper>
          <IconButton
            color="secondary"
            onClick={handleOpen}
            aria-label="Cancel fax">
            <CloseIcon size={16} />
          </IconButton>
        </IconWrapper>
      </Tooltip>
      <StyledDialog
        fullWidth
        open={isVisible}
        onClose={handleClose}
        keepMounted={false}>
        <StyledDialogContent>
          <Message>Are you sure you want to cancel this fax?</Message>
          {error && (
            <ErrorContainer>
              <ErrorMessage>Hm, an error occurred. Try again.</ErrorMessage>
            </ErrorContainer>
          )}
          <ButtonContainer>
            <SecondaryButton
              onClick={handleClose}
              disabled={loading}
              padding="small"
              color="secondary">
              Cancel
            </SecondaryButton>
            <Button onClick={onCancel} loading={loading} padding="small">
              Confirm
            </Button>
          </ButtonContainer>
        </StyledDialogContent>
      </StyledDialog>
    </>
  )
}

CancelFaxButton.propTypes = {
  faxId: PropTypes.string.isRequired,
  referralId: PropTypes.string.isRequired,
  isSender: PropTypes.bool,
  isRecipient: PropTypes.bool
}

export default CancelFaxButton
