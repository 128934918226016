import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import AccountIcon from 'mdi-react/AccountIcon'
import CheckCircleIcon from 'mdi-react/CheckCircleIcon'
import CheckCircleOutlineIcon from 'mdi-react/CheckCircleOutlineIcon'
import StatusIndicator from '../common/StatusIndicator'
import { DateIndicator, TimeIndicator } from '../common/DateTimeIndicators'
import ArchiveFaxButton from './ArchiveFaxButton'
import UnarchiveFaxButton from './UnarchiveFaxButton'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import LoadingError from '../common/LoadingError'
import Loading from '../common/Loading'

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

const ArchiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const FaxInfo = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;
  min-width: 0;

  background: ${props =>
    props.isactive ? 'rgba(17, 147, 173, 0.05)' : 'white'};
  border-left: ${props =>
    props.isactive ? '3px solid #1193ad' : '3px solid transparent'};

  color: rgba(58, 58, 58, 0.7);

  :hover {
    background: rgba(17, 147, 173, 0.05);
    border-left: 3px solid #1193ad;
  }
`

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const BottomLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-top: 1px solid rgba(17, 147, 173, 0.05);
  padding-right: 30px;
`

const ReadContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  width: 155px;
  border-left: 1px solid rgba(17, 147, 173, 0.05);
  height: 45px;
  .mdi-icon {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }
`

const FromToContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 30px;
  font-size: 18px;
  .mdi-icon {
    margin-right: 10px;
    width: 22px;
    height: 22px;
    overflow: visible;
  }
  white-space: nowrap;
`

const BlueText = styled.p`
  color: rgba(17, 147, 173, 0.7);
`

const RecipientText = styled(BlueText)`
  text-overflow: ellipsis;
  overflow: hidden;
`

const Overflow = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`

const EmptyText = styled.p`
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
`

const EmptyDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const PagesContainer = styled.div`
  display: flex;
  padding-left: 30px;
  border-right: 1px solid rgba(17, 147, 173, 0.05);
`

const TotalPages = styled.p`
  color: rgba(58, 58, 58, 0.7);
  margin-left: 3px;
  padding-right: 30px;
  min-height: 17px;
  min-width: 105px;
`

const Arrow = styled(ArrowRightIcon)`
  width: 15px;
  height: 15px;
  margin: 0px 10px 0px 10px;
`

const SET_FAX_READ = gql`
  mutation setFaxRead($fax: ID!) {
    setFaxRead(fax: $fax) {
      id
      isRead
    }
  }
`

const faxStates = ['processing', 'success', 'failed']

function FaxList({
  faxes = [],
  onSelect = () => {},
  isReceived = false,
  activeFaxId = ''
}) {
  const [mutate, { loading, error }] = useMutation(SET_FAX_READ)

  useEffect(() => {
    async function setFaxRead() {
      try {
        await mutate({ variables: { fax: activeFaxId } })
      } catch (e) {
        console.error('Error reading fax:', e.message)
      }
    }

    const activeFax = faxes.find(fax => fax.id === activeFaxId)

    if (
      activeFax &&
      activeFaxId !== '' &&
      isReceived === true &&
      activeFax.isRead === false
    ) {
      setFaxRead()
    }
  }, [faxes, activeFaxId, isReceived, mutate])

  if (error) return <LoadingError />
  if (loading) return <Loading />

  return (
    <ListContainer>
      {faxes.length > 0 ? (
        faxes.map((fax, i, arr) => {
          const previous = arr[i - 1]
          return (
            <Row key={fax.id}>
              {i === 0 ||
              (previous &&
                previous.createdAt.getDate() !== fax.createdAt.getDate()) ? (
                <DateIndicator dateTime={fax.createdAt} />
              ) : null}
              <FlexContainer>
                <TimeContainer>
                  <TimeIndicator dateTime={fax.createdAt} />
                </TimeContainer>
                <FaxInfo
                  isactive={fax.id === activeFaxId}
                  key={fax.id}
                  onClick={() => onSelect(fax)}>
                  <TopLine>
                    <FromToContainer>
                      <AccountIcon size={22} />
                      {isReceived ? (
                        <>
                          {fax.senderFaxNumber}
                          <Arrow />
                          <RecipientText> {fax.recipientName} </RecipientText>
                        </>
                      ) : (
                        <>
                          <BlueText> {fax.senderName}</BlueText>
                          <Arrow />
                          <Overflow>{fax.recipientName}</Overflow>
                        </>
                      )}
                    </FromToContainer>
                  </TopLine>
                  <BottomLine>
                    <PagesContainer>
                      <TotalPages>
                        {fax.totalPages !== 'Processing' && (
                          <>Total Pages: {fax.totalPages}</>
                        )}
                      </TotalPages>
                    </PagesContainer>
                    {isReceived &&
                      (fax.isRead ? (
                        <ReadContainer>
                          <CheckCircleIcon />
                          Read
                        </ReadContainer>
                      ) : (
                        <ReadContainer>
                          <CheckCircleOutlineIcon />
                          Unread
                        </ReadContainer>
                      ))}
                    {!isReceived && (
                      <StatusIndicator
                        state={
                          faxStates.includes(fax.state)
                            ? fax.state
                            : 'processing'
                        }
                      />
                    )}
                  </BottomLine>
                </FaxInfo>
                <ArchiveContainer>
                  {fax.isArchived ? (
                    <UnarchiveFaxButton
                      faxId={fax.id}
                      isReceived={isReceived}
                    />
                  ) : (
                    <ArchiveFaxButton faxId={fax.id} isReceived={isReceived} />
                  )}
                </ArchiveContainer>
              </FlexContainer>
            </Row>
          )
        })
      ) : (
        <EmptyDiv>
          {isReceived ? (
            <EmptyText>
              There are currently no received faxes to show.
            </EmptyText>
          ) : (
            <EmptyText>There are no sent faxes to show.</EmptyText>
          )}
        </EmptyDiv>
      )}
    </ListContainer>
  )
}

FaxList.propTypes = {
  faxes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      recipientName: PropTypes.string.isRequired,
      senderName: PropTypes.string.isRequired,
      senderFaxNumber: PropTypes.string.isRequired,
      isRead: PropTypes.bool,
      state: PropTypes.string.isRequired,
      createdAt: PropTypes.instanceOf(Date).isRequired,
      totalPages: PropTypes.string.isRequired
    })
  ),
  activeFaxId: PropTypes.string,
  onSelect: PropTypes.func,
  isReceived: PropTypes.bool
}

export default FaxList
