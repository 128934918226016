import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Button from '../common/Button'

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ErrorText = styled.h3`
  color: #393e46;
  font-weight: 500;
  margin: 10px;
`

const LinkButton = styled(Link)`
  margin-top: 20px;
  text-decoration: none;
`

const LoadingError = ({ message, buttonText, linkTo }) => (
  <Container>
    {message ? (
      <>
        {message.map((sentence, i) => (
          <ErrorText key={`loading-error-message-${i}`}>{sentence}</ErrorText>
        ))}
      </>
    ) : (
      <>
        <ErrorText>We&#39;re sorry.</ErrorText>
        <ErrorText>The data you requested could not be loaded.</ErrorText>
      </>
    )}
    {buttonText && linkTo && (
      <LinkButton to={linkTo}>
        <Button padding="small">{buttonText}</Button>
      </LinkButton>
    )}
  </Container>
)

LoadingError.propTypes = {
  message: PropTypes.array,
  buttonText: PropTypes.string,
  linkTo: PropTypes.string
}

export default LoadingError
