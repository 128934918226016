import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Background = styled.div`
  background: #e84c3d;
  border-radius: 50%;
  display: inline-block;
`

const Text = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  color: #fff;
  margin: 0;
  padding: 5px;
`

const AlertBadge = ({ number }) => (
  <Background>
    <Text>! {number}</Text>
  </Background>
)

AlertBadge.propTypes = {
  number: PropTypes.number.isRequired
}

export default AlertBadge
