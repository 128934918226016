import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import CircularProgress from '@material-ui/core/CircularProgress'
import AccountOffIcon from 'mdi-react/AccountOffIcon'

const Content = styled.div`
  display: flex;
  font-size: 12px;
  color: #3a3a3a;
  line-height: 16px;

  & > svg {
    color: #f9fbfe;
    background: #e84c3d;
    border-radius: 50%;
    padding: 9px;
    min-width: 33px;
    height: 33px;
  }
`

const TextContainer = styled.div`
  padding-left: 15px;
  width: 100%;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Strong = styled.strong`
  font-weight: 600;
`

const DateText = styled.p`
  font-size: 10px;
  color: rgba(58, 58, 58, 0.7);
  padding: 8px 0 0;
  margin: 0;
`

const LoadingSpinner = styled(CircularProgress).attrs(props => ({
  size: 20,
  thickness: 3.5
}))`
  && {
    color: #1193ad;
    margin-bottom: 10px;
  }
`

const GET_REFERRAL_EVENTS = gql`
  query getReferralEvents($referral: ID!) {
    getReferralEvents(referral: $referral) {
      id
      type
      createdAt
      data {
        ... on ReferralDeclinedEvent {
          reason
          description
        }
      }
    }
  }
`

function DeclinedReason({ referral }) {
  const [declinedEvent, setDeclinedEvent] = useState()
  const { loading, error, data } = useQuery(GET_REFERRAL_EVENTS, {
    variables: { referral }
  })

  useEffect(() => {
    function getDeclinedEvent() {
      const declinedEvents = data.getReferralEvents.filter(
        f => f.type === 'referral_declined'
      )

      const lastEvent =
        declinedEvents.length > 0
          ? declinedEvents[declinedEvents.length - 1]
          : null

      setDeclinedEvent(lastEvent)
    }

    if (data?.getReferralEvents) getDeclinedEvent()
  }, [data, setDeclinedEvent])

  return (
    <Content>
      {error && (
        <ErrorContainer>
          We&#39;re sorry. The declined reason could not be loaded.
        </ErrorContainer>
      )}
      {!error && (loading || declinedEvent === undefined) && (
        <ErrorContainer>
          <LoadingSpinner />
          Loading...
        </ErrorContainer>
      )}
      {!error && !loading && declinedEvent && (
        <>
          <AccountOffIcon />
          <TextContainer>
            <Strong>Referral Declined</Strong>
            <div>
              <Strong>
                <em>Reason: </em>
              </Strong>
              {declinedEvent.data.reason}
            </div>
            {declinedEvent.data.description.length > 0 && (
              <div>
                <Strong>
                  <em>Description: </em>
                </Strong>
                {declinedEvent.data.description}
              </div>
            )}
            <DateText>
              {format(
                new Date(parseInt(declinedEvent.createdAt)),
                'MMMM d, yyyy @ hh:mm a'
              )}
            </DateText>
          </TextContainer>
        </>
      )}
    </Content>
  )
}

DeclinedReason.propTypes = {
  referral: PropTypes.string.isRequired
}

export default DeclinedReason
