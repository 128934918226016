function filterDataByDate(startDate, endDate, data, key) {
  const currStartDate = new Date(startDate).setHours(0, 0, 0, 0)
  const currEndDate = new Date(endDate).setHours(0, 0, 0, 0)

  const filtered = data.filter(d => {
    const currDate = new Date(parseInt(d[key])).setHours(0, 0, 0, 0)

    if (startDate && !endDate) return currDate >= currStartDate
    if (!startDate && endDate) return currDate <= currEndDate
    return currDate >= currStartDate && currDate <= currEndDate
  })

  return filtered
}

function filterDataBySpecialistCategory(data, type, filters) {
  const { specialists, categories } = filters
  const specialistIds = specialists.map(s => s.id)
  const categoryIds = categories.map(c => c.categoryId)
  if (type === 'referrals') {
    return data.filter(
      d =>
        specialistIds.includes(d.recipientPractice.id) &&
        categoryIds.includes(d.categoryId)
    )
  } else if (
    ['factors', 'procedures', 'waitlist', 'journeys', 'waitTimes'].includes(
      type
    )
  ) {
    return data.filter(
      d =>
        specialistIds.includes(d.practiceId) &&
        categoryIds.includes(d.categoryId)
    )
  } else if (type === 'triageEvents') {
    return data.filter(
      d => specialistIds.includes(d.practice) || d.type === 'Declined by Triage'
    )
  } else if (type === 'allowReferrals') {
    return data.filter(d => specialistIds.includes(d.practiceId))
  }
}

export { filterDataByDate, filterDataBySpecialistCategory }
