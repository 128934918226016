import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import AlarmIcon from 'mdi-react/AlarmIcon'
import AutorenewIcon from 'mdi-react/AutorenewIcon'
import AccountCheckIcon from 'mdi-react/AccountCheckIcon'
import AccountOffIcon from 'mdi-react/AccountOffIcon'
import MessageProcessingIcon from 'mdi-react/MessageProcessingIcon'
import AttachFileIcon from 'mdi-react/AttachFileIcon'
import AccountArrowRightIcon from 'mdi-react/AccountArrowRightIcon'

import { getCategoryName, getSubcategoryName } from '../../util/categories'
import { getPracticeType, getPracticeDisplayName } from '../../util/strings'
import FileButton from './FileButton'

function textColor({ urgency }) {
  if (urgency === 'Urgent') return '#611A15'
  if (urgency === 'Semi-urgent') return '#663C00'
  return '#60750d'
}

function stateColor({ state }) {
  if (state === 'Accepted') return '#2DCC70'
  return '#E84C3D'
}

const ReferralHistoryEntry = styled.div`
  display: flex;
  padding: 1px 15px 30px 0;
  & > svg {
    color: #f9fbfe;
    background: #1193ad;
    border-radius: 50%;
    padding: 9px;
    min-width: 33px;
    height: 33px;
  }
`

const TextContainer = styled.div`
  padding-left: 15px;
  width: 100%;
`

const MessageContainer = styled.div`
  padding: 15px;
  background: rgba(6, 153, 135, 0.05);
  flex: 1;
  border-radius: 6px;
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 11px solid rgba(6, 153, 135, 0.05);
  margin-left: 4px;
  margin-top: 14px;
`

const MainText = styled.h4`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #3a3a3a;
  padding: 0;
  margin: 0;
`

const InfoText = styled.div`
  display: flex;
  justify-content: space-between;
`

const DateText = styled.h5`
  font-size: 10px;
  font-weight: 400;
  color: rgba(58, 58, 58, 0.7);
  padding: 8px 0 0;
  margin: 0;
`

const Author = styled.h5`
  font-size: 10px;
  font-weight: 400;
  color: rgba(58, 58, 58, 0.7);
  padding: 8px 0 0;
  margin: 0;
`

const Strong = styled.strong`
  font-weight: 600;
`

const UrgencyText = styled.span`
  color: ${urgency => textColor(urgency)};
  font-weight: 600;
`

const StateText = styled.span`
  color: ${state => stateColor(state)};
  font-weight: 600;
`

const FileContainer = styled.div`
  padding: 9px 15px;
  background: rgba(6, 153, 135, 0.05);
  flex: 1;
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`

const RedirectedDrContainer = styled.div`
  background: rgba(6, 153, 135, 0.15);
  padding: 10px;
  margin-top: 10px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #3a3a3a;
`

const RedirectedInfo = styled.div`
  display: flex;
  gap: 10px;
`

const InfoContainer = styled.div`
  display: flex;
  gap: 3px;
`

const Separator = styled.span`
  font-weight: bold;
  padding: 0 5px;
`

const LinkContainer = styled.div`
  margin-top: 5px;
`

const StyledLink = styled(Link)`
  font-size: 12px;
  font-weight: 600;
  color: #3598db;
  text-decoration: none;
`

function ReferralHistoryEvent({
  event,
  sender,
  recipient,
  referral,
  isSender = false
}) {
  const newRecipient =
    event.type === 'referral_redirected'
      ? event.data.newReferral.recipient.practice
      : {}
  const originalReferral =
    event.type === 'referral_redirected' ? event.data.originalReferral : {}

  return (
    <div>
      {event.type === 'referral_sent' && (
        <ReferralHistoryEntry>
          <AccountPlusIcon />
          <TextContainer>
            <MainText>
              Referral sent from{' '}
              <Strong>
                {getPracticeDisplayName(
                  event.data.sender.practice,
                  'short',
                  'long'
                )}
              </Strong>{' '}
              ({getPracticeType(event.data.sender.practice.type)}) to{' '}
              <Strong>
                Dr. {event.data.recipient.practice.firstName}{' '}
                {event.data.recipient.practice.lastName}{' '}
              </Strong>
              (Specialist) by{' '}
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}
              </Strong>
            </MainText>
            <DateText>
              {format(
                new Date(parseInt(event.createdAt)),
                'MMMM d, yyyy @ hh:mm a'
              )}
            </DateText>
          </TextContainer>
        </ReferralHistoryEntry>
      )}
      {event.type === 'urgency_changed' && (
        <ReferralHistoryEntry>
          <AlarmIcon />
          <TextContainer>
            <MainText>
              Urgency changed from{' '}
              <UrgencyText urgency={event.data.initialUrgency}>
                {' '}
                {event.data.initialUrgency}{' '}
              </UrgencyText>
              to{' '}
              <UrgencyText urgency={event.data.urgency}>
                {event.data.urgency}
              </UrgencyText>{' '}
              by{' '}
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}
              </Strong>
            </MainText>
            <DateText>
              {format(
                new Date(parseInt(event.createdAt)),
                'MMMM d, yyyy @ hh:mm a'
              )}
            </DateText>
          </TextContainer>
        </ReferralHistoryEntry>
      )}
      {event.type === 'category_changed' && (
        <ReferralHistoryEntry>
          <AutorenewIcon transform="rotate(15)" />
          <TextContainer>
            <MainText>
              Category changed from
              <Strong> {getCategoryName(event.data.initialCategory)} </Strong> -
              <Strong>
                {' '}
                {getSubcategoryName(event.data.initialCategory)}{' '}
              </Strong>
              to
              <Strong> {getCategoryName(event.data.category)}</Strong> -
              <Strong> {getSubcategoryName(event.data.category)}</Strong> by{' '}
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}
              </Strong>
            </MainText>
            <DateText>
              {format(
                new Date(parseInt(event.createdAt)),
                'MMMM d, yyyy @ hh:mm a'
              )}
            </DateText>
          </TextContainer>
        </ReferralHistoryEntry>
      )}
      {event.type === 'referral_accepted' && (
        <ReferralHistoryEntry>
          <AccountCheckIcon />
          <TextContainer>
            <MainText>
              Referral has been
              <StateText state="Accepted"> Accepted </StateText>
              by{' '}
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}
              </Strong>
            </MainText>
            <DateText>
              {format(
                new Date(parseInt(event.createdAt)),
                'MMMM d, yyyy @ hh:mm a'
              )}
            </DateText>
          </TextContainer>
        </ReferralHistoryEntry>
      )}
      {event.type === 'referral_declined' && (
        <ReferralHistoryEntry>
          <AccountOffIcon />
          <TextContainer>
            <MainText>
              Referral has been
              <StateText state="Declined"> Declined </StateText> by{' '}
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}
              </Strong>
            </MainText>
            <MainText>
              <Strong>
                <em>Reason:</em>
              </Strong>{' '}
              {event.data.reason}
              {event.data.description.length > 0
                ? '. ' + event.data.description
                : ''}
            </MainText>
            <DateText>
              {format(
                new Date(parseInt(event.createdAt)),
                'MMMM d, yyyy @ hh:mm a'
              )}
            </DateText>
          </TextContainer>
        </ReferralHistoryEntry>
      )}
      {event.type === 'referral_redirected' && (
        <ReferralHistoryEntry>
          <AccountArrowRightIcon />
          <TextContainer>
            <MainText>
              Referral redirected by{' '}
              <Strong>
                {getPracticeDisplayName(sender.practice, 'short', 'long')}
              </Strong>{' '}
              ({getPracticeType(sender.practice.type)}) to{' '}
              <Strong>
                Dr. {newRecipient.firstName} {newRecipient.lastName}
              </Strong>{' '}
              (Specialist) by{' '}
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}
              </Strong>
            </MainText>
            {referral.id === originalReferral.id && (
              <RedirectedDrContainer>
                <Strong>
                  Dr. {newRecipient.firstName} {newRecipient.lastName}
                </Strong>
                <RedirectedInfo>
                  <InfoContainer>
                    <span>MSP </span>
                    <Strong>{newRecipient.msp}</Strong>
                    <Separator>|</Separator>
                    <span>Phone </span>
                    <Strong>
                      {newRecipient.phone.substring(
                        newRecipient.phone.length - 13
                      ) === ', Ext. ______'
                        ? newRecipient.phone.substr(0, 14)
                        : newRecipient.phone}
                    </Strong>
                  </InfoContainer>
                </RedirectedInfo>
              </RedirectedDrContainer>
            )}
            {isSender && (
              <LinkContainer>
                {referral.id === originalReferral.id && (
                  <StyledLink to={`/referrals/${event.data.newReferral.id}`}>
                    View new referral
                  </StyledLink>
                )}
                {(referral.id === event.data.newReferral.id ||
                  (referral.id !== event.data.newReferral.id &&
                    referral.id !== originalReferral.id)) && (
                  <StyledLink to={`/referrals/${originalReferral.id}`}>
                    View original referral
                  </StyledLink>
                )}
              </LinkContainer>
            )}

            <DateText>
              {format(
                new Date(parseInt(event.createdAt)),
                'MMMM d, yyyy @ hh:mm a'
              )}
            </DateText>
          </TextContainer>
        </ReferralHistoryEntry>
      )}
      {event.type === 'message_sent' && (
        <ReferralHistoryEntry>
          <MessageProcessingIcon />
          <Triangle />
          <MessageContainer>
            <MainText>{event.data.decryptedMessage}</MainText>
            <InfoText>
              <DateText>
                {format(
                  new Date(parseInt(event.createdAt)),
                  'MMMM d, yyyy @ hh:mm a'
                )}
              </DateText>
              <Author>
                Message by <Strong>{event.createdBy.firstName}</Strong>
              </Author>
            </InfoText>
          </MessageContainer>
        </ReferralHistoryEntry>
      )}
      {event.type === 'file_added' && (
        <ReferralHistoryEntry>
          <AttachFileIcon />
          <TextContainer>
            <MainText>
              <Strong>
                {event.createdBy.firstName} {event.createdBy.lastName}
              </Strong>{' '}
              attached a file from the{' '}
              {recipient.practice.memberships.find(
                m => m.user.id === event.createdBy.id
              ) !== undefined
                ? 'receiving '
                : 'referring '}
              practice
            </MainText>
            <FileContainer>
              <FileButton
                withIcons
                referral={referral}
                sender={sender}
                recipient={recipient}
                file={referral.files.findIndex(
                  f =>
                    f.iv === event.data.file.iv && f.key === event.data.file.key
                )}
              />
            </FileContainer>
            <DateText>
              {format(
                new Date(parseInt(event.createdAt)),
                'MMMM d, yyyy @ hh:mm a'
              )}
            </DateText>
          </TextContainer>
        </ReferralHistoryEntry>
      )}
    </div>
  )
}

ReferralHistoryEvent.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.object.isRequired
  }).isRequired,
  sender: PropTypes.object.isRequired,
  recipient: PropTypes.object.isRequired,
  referral: PropTypes.object.isRequired,
  isSender: PropTypes.bool
}

export default ReferralHistoryEvent
