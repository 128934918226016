import React from 'react'
import FilePdfBoxIcon from 'mdi-react/FilePdfBoxIcon'
import FileIcon from 'mdi-react/FileIcon'
import FileWordIcon from 'mdi-react/FileWordIcon'
import FolderZipIcon from 'mdi-react/FolderZipIcon'
import FileImageIcon from 'mdi-react/FileImageIcon'

import {
  deriveAesKey,
  unwrapEccKey,
  unwrapAesKey,
  importPublicKey,
  decryptFile
} from '../crypto'

export const getFileTypeIcon = fileName => {
  const ext = fileName.substr(fileName.lastIndexOf('.'))

  switch (ext.toLowerCase()) {
    case '.pdf':
      return <FilePdfBoxIcon />
    case '.doc':
    case '.docx':
      return <FileWordIcon />
    case '.zip':
    case '.gz':
    case '.7z':
      return <FolderZipIcon />
    case '.png':
    case '.jpg':
    case '.jpeg':
    case '.raw':
    case '.gif':
      return <FileImageIcon />
    default:
      return <FileIcon />
  }
}

async function getBinaryFile(referral, file, account, url) {
  try {
    const key = file.key
    const index = referral.files.findIndex(f => f.key === key)
    const name = referral.fileNames[index]

    const isSender = referral.sender.publicKey !== undefined

    const participant = isSender ? referral.sender : referral.recipient
    const membership = isSender
      ? referral.sender.practice.memberships.find(m => m.user.id === account.id)
      : referral.recipient.practice.memberships.find(
          m => m.user.id === account.id
        )

    const membershipAesKey = await deriveAesKey(
      account.privateKey,
      await importPublicKey(membership.publicKey)
    )

    const practicePrivateKey = await unwrapEccKey(
      membership.privateKey.encryptedData,
      membershipAesKey,
      membership.privateKey.iv,
      true
    )

    const referralAesKey = await deriveAesKey(
      practicePrivateKey,
      await importPublicKey(participant.publicKey)
    )

    const encryptionKey = await unwrapAesKey(
      participant.encryptionKey.encryptedData,
      referralAesKey,
      participant.encryptionKey.iv,
      'enc'
    )

    const response = await fetch(url)
    const reader = response.body.getReader()

    const chunks = []
    let isDone = false

    while (!isDone) {
      const response = await reader.read()

      if (response.value) {
        chunks.push(response.value)
      }

      isDone = response.done
    }

    const blob = new Blob(chunks)
    const encryptedFile = new File([blob], name)

    const iv = file.iv

    const rawFile = await decryptFile(encryptedFile, encryptionKey, iv)

    const extension = rawFile.name.split('.')

    const extensionMap = {
      pdf: 'application/pdf',
      png: 'image/png',
      jpg: 'image/png',
      jpeg: 'image/png'
    }

    const newFile = new File([rawFile], name, {
      type: extensionMap[extension[extension.length - 1]] ?? 'application/pdf'
    })

    return newFile
  } catch (e) {
    console.error('Request error occurred:', e.message)
  }
}

export { getBinaryFile }
