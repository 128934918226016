import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'

import Chart from './Chart'
import PivotTable from './PivotTable'
import ValueDisplay from './ValueDisplay'
import { filterDataBySpecialistCategory } from '../../util/filterData'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  padding-top: 30px;
`

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  height: 385px;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  width: 20%;
`

const MiddleWrapper = styled.div`
  display: flex;
  width: 40%;
`

const RightWrapper = styled.div`
  display: flex;
  width: 40%;
`

const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  height: 385px;
`

const BottomLeftWrapper = styled.div`
  display: flex;
  width: 44%;
`

const BottomRightWrapper = styled.div`
  display: flex;
  width: 56%;
`

const Wrapper = styled(Paper)`
  border-radius: 6px;
  padding: 0 22px 18px;
  width: 100%;
`

const ValueWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  padding: 16px 22px 0;
  height: 100%;
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

function GroupSummary({
  setReport,
  receivedReferrals,
  currentFactors,
  filters = null
}) {
  const filteredReferrals = filters
    ? filterDataBySpecialistCategory(receivedReferrals, 'referrals', filters)
    : receivedReferrals
  const filteredFactors = filters
    ? filterDataBySpecialistCategory(currentFactors, 'factors', filters)
    : currentFactors

  if (!filteredReferrals.length > 0) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  const declinedReferrals = filteredReferrals.filter(
    r => r.state === 'declined' || r.state === 'redirected'
  )
  const acceptedPendingReferrals = filteredReferrals.filter(
    r => r.state === 'accepted' || r.state === 'pending'
  )

  const practiceIds = [
    ...new Set(acceptedPendingReferrals.map(r => r.recipientPractice.id))
  ]

  const dataSourceSettingsDefault = {
    valueSortSettings: { headerDelimiter: '##' },
    emptyCellsTextContent: ['0'],
    showHeaderWhenEmpty: false,
    showGrandTotals: false,
    expandAll: true,
    columns: [{ name: 'isDirected' }],
    rows: [{ name: 'to' }, { name: 'category' }, { name: 'subcategory' }],
    values: [{ name: 'category' }],
    dataSource: acceptedPendingReferrals
  }

  const dataSourceSettingsCollapsed = {
    ...dataSourceSettingsDefault,
    expandAll: false
  }

  const dataSourceSettings =
    practiceIds.length > 3
      ? dataSourceSettingsCollapsed
      : dataSourceSettingsDefault

  return (
    <ReportVisualsContainer>
      <TopWrapper>
        <LeftWrapper>
          <ValueWrapper elevation={3}>
            <ValueDisplay
              title="Referrals Received"
              value={filteredReferrals.length.toLocaleString()}
            />
          </ValueWrapper>
          <ValueWrapper elevation={3}>
            <ValueDisplay
              title="Accepted Referrals"
              value={acceptedPendingReferrals.length.toLocaleString()}
            />
          </ValueWrapper>
          <ValueWrapper elevation={3}>
            <ValueDisplay
              title="Declined Referrals"
              value={declinedReferrals.length.toLocaleString()}
            />
          </ValueWrapper>
        </LeftWrapper>
        <MiddleWrapper>
          <Wrapper elevation={3}>
            <PivotTable
              title="Current Availability"
              handleClick={() => setReport('doctorAvail')}
              dataSourceSettings={{
                showGrandTotals: false,
                dataSource: filteredFactors,
                formatSettings: [{ name: 'factor', format: '### %' }],
                columns: [{ name: 'practice' }],
                rows: [{ name: 'category' }],
                values: [{ name: 'factor' }],
                enableSorting: false,
                valueAxis: 'row'
              }}
            />
          </Wrapper>
        </MiddleWrapper>
        <RightWrapper>
          <Wrapper elevation={3}>
            <Chart
              type="pie"
              handleClick={() => setReport('referralSummary')}
              legend={false}
              title="Total Received Referrals by Doctor"
              dataSourceSettings={{
                valueSortSettings: { headerDelimiter: '##' },
                dataSource: acceptedPendingReferrals,
                rows: [{ name: 'to' }],
                columns: [{}],
                values: [{ name: 'to' }]
              }}
              customSettings={{
                height: '250',
                width: '100%',
                padding: 0
              }}
            />
          </Wrapper>
        </RightWrapper>
      </TopWrapper>
      <BottomWrapper>
        <BottomLeftWrapper>
          <Wrapper elevation={3}>
            <PivotTable
              title="Received Referrals by Doctor and Category"
              handleClick={() => setReport('referralSummary')}
              dataSourceSettings={dataSourceSettings}
            />
          </Wrapper>
        </BottomLeftWrapper>
        <BottomRightWrapper>
          <Wrapper elevation={3}>
            <Chart
              type="line"
              handleClick={() => setReport('referralTrends')}
              legend={false}
              title="Received Referral Volume Over Time"
              dataSourceSettings={{
                valueSortSettings: { headerDelimiter: '##' },
                dataSource: acceptedPendingReferrals,
                rows: [{ name: 'year' }, { name: 'month' }],
                columns: [{ name: 'to' }],
                values: [{ name: 'to' }],
                expandAll: true
              }}
              customSettings={{ height: '220', width: '100%' }}
            />
          </Wrapper>
        </BottomRightWrapper>
      </BottomWrapper>
    </ReportVisualsContainer>
  )
}

GroupSummary.propTypes = {
  setReport: PropTypes.func.isRequired,
  receivedReferrals: PropTypes.array.isRequired,
  currentFactors: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default GroupSummary
