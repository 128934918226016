import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import Settings from '../settings/Settings'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import { PageTitle } from '../common/Title'

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

const GET_PRACTICES = gql`
  query getPractices {
    getPractices {
      id
      firstName
      lastName
      centralIntakeName
      type
      faxNumber
    }
  }
`

const SettingsPage = () => {
  const { loading, error, data } = useQuery(GET_PRACTICES)
  if (error) return <LoadingError />
  if (loading) return <Loading />
  return (
    <>
      <Header>
        <PageTitle>Settings</PageTitle>
      </Header>
      <Settings practices={data.getPractices} />
    </>
  )
}

export default SettingsPage
