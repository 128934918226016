import React from 'react'
import styled from 'styled-components'
import ForgottenPasswordForm from '../login/ForgottenPasswordForm'
import Hero from '../common/Hero'

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
`

const ForgottenPasswordPage = () => (
  <Container>
    <Hero />
    <ForgottenPasswordForm />
  </Container>
)

export default ForgottenPasswordPage
