import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import useAccount from '../../hooks/useAccount'
import useTokenizer from '../../hooks/useTokenizer'
import Referrals from '../common/Referrals'
import LoadingError from '../common/LoadingError'
import { PageTitle } from '../common/Title'
import Loading from '../common/Loading'
import Pagination from '../common/Pagination'
import { getDecryptedReferral } from '../../util/decryptReferrals'
import {
  deriveAesKey,
  unwrapHmacKey,
  unwrapEccKey,
  importPublicKey,
  deriveHmacToken
} from '../../crypto'
import { getPracticeDisplayName, capitalize } from '../../util/strings'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`

const PaginationContainer = styled.div`
  margin: 51px 0 30px;
`

const GET_SENT_PAGE_DATA = gql`
  query getSentPageData($filter: FilterInput, $before: String, $after: String) {
    getCurrentUser {
      id
    }
    getReferrals(type: sent, filter: $filter, before: $before, after: $after) {
      referrals {
        id
        region
        urgency
        gender
        category
        state
        referralDate
        createdAt
        encryptedData {
          iv
          encryptedData
        }
        sender {
          publicKey
          encryptionKey {
            iv
            encryptedData
          }
          practice {
            id
            firstName
            lastName
            msp
            centralIntakeName
            type
            phone
            faxNumber
            triageResponseActiveDate
            memberships {
              privateKey {
                encryptedData
                iv
              }
              publicKey
              user {
                id
              }
            }
          }
        }
        recipient {
          practice {
            id
            firstName
            lastName
            msp
            phone
            specialty
            emrSyncActiveDate
          }
        }
        files {
          iv
          key
        }
        referringProvider {
          id
          name
          faxNumber
        }
        initialFax {
          id
          faxContact {
            ... on FaxNumber {
              faxNumber
            }
          }
        }
        faxToEmr {
          id
          state
        }
        triageFax {
          id
          state
        }
        redirectedFrom
      }
      pageInfo {
        hasNextPage
        before
        after
      }
    }
    getPractices {
      id
      searchKey {
        hashKey {
          iv
          encryptedData
        }
        publicKey
      }
      memberships {
        privateKey {
          encryptedData
          iv
        }
        publicKey
        user {
          id
        }
      }
    }
  }
`

function SentPage() {
  const [page, setPage] = useState(1)
  const [filterInput, setFilterInput] = useState()
  const [filter, setFilter] = useState()
  const { loading, error, data, fetchMore } = useQuery(GET_SENT_PAGE_DATA, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  })
  const practices = data ? data.getPractices : null
  const [referrals, setReferrals] = useState(null)
  const [searchKeys, setSearchKeys] = useState(null)

  const { getAccount } = useAccount()
  const isTokenizing = useTokenizer()

  async function getFilterString(filterInput) {
    const searchTokens = []
    for (const searchKey of searchKeys) {
      searchTokens.push(
        await deriveHmacToken(searchKey, filterInput.value.toLowerCase())
      )
    }

    return {
      type: filterInput.type,
      value: searchTokens.join(':')
    }
  }

  async function onChangeFilter(filterInput) {
    setPage(1)
    setFilterInput(filterInput)
    const filter =
      filterInput.value !== '' &&
      (filterInput.type === 'phn' || filterInput.type === 'patient')
        ? await getFilterString(filterInput)
        : filterInput
    setFilter(filter)
    fetchMore({ variables: { before: null, after: null, filter } })
  }

  useEffect(() => {
    async function decryptSearchKeys(practices) {
      const account = await getAccount()

      const allSearchKeys = []
      for (const practice of practices) {
        const membership = practice.memberships.find(
          m => m.user.id === account.id
        )

        const membershipAesKey = await deriveAesKey(
          account.privateKey,
          await importPublicKey(membership.publicKey)
        )

        const practicePrivateKey = await unwrapEccKey(
          membership.privateKey.encryptedData,
          membershipAesKey,
          membership.privateKey.iv,
          true
        )

        const ephemeralPublicKey = await importPublicKey(
          practice.searchKey.publicKey
        )
        const wrappingKey = await deriveAesKey(
          practicePrivateKey,
          ephemeralPublicKey
        )
        const searchKey = await unwrapHmacKey(
          practice.searchKey.hashKey.encryptedData,
          wrappingKey,
          practice.searchKey.hashKey.iv
        )

        allSearchKeys.push(searchKey)
      }
      setSearchKeys(allSearchKeys)
    }

    if (practices) decryptSearchKeys(practices)
  }, [practices, getAccount])

  useEffect(() => {
    async function decryptReferrals() {
      const referrals = await Promise.all(
        data.getReferrals.referrals.map(decryptReferral)
      )
      setReferrals(referrals.filter(r => r !== null))
    }

    async function decryptReferral(referral) {
      const currentUser = data.getCurrentUser.id
      const account = await getAccount()

      const encryptedData = await getDecryptedReferral(
        referral,
        currentUser,
        account,
        true
      )

      if (!encryptedData) return null

      return {
        id: referral.id,
        state: referral.state,
        firstName: capitalize(encryptedData.firstName),
        lastName: capitalize(encryptedData.lastName),
        fileNames: encryptedData.fileNames,
        patient: capitalize(
          `${encryptedData.firstName} ${encryptedData.lastName}`
        ),
        phn: encryptedData.phn,
        phone: encryptedData.phone,
        address: encryptedData.address,
        city: encryptedData.city,
        province: encryptedData.province,
        postalCode: encryptedData.postalCode,
        email: encryptedData.email,
        region: referral.region,
        urgency: referral.urgency,
        gender: referral.gender,
        category: referral.category,
        message: encryptedData.message,
        birthDate: new Date(encryptedData.birthDate),
        referralDate: new Date(parseInt(referral.referralDate)),
        createdAt: new Date(parseInt(referral.createdAt)),
        files: referral.files,
        fromPractice: {
          id: referral.sender.practice.id,
          name: getPracticeDisplayName(referral.sender.practice, 'med', 'long'),
          triageResponseActiveDate: new Date(
            parseInt(referral.sender.practice.triageResponseActiveDate)
          )
        },
        toPractice: {
          id: referral.recipient.practice.id,
          name: `Dr. ${referral.recipient.practice.firstName} ${referral.recipient.practice.lastName}`,
          emrSyncActiveDate: new Date(
            parseInt(referral.recipient.practice.emrSyncActiveDate)
          )
        },
        referringProvider: referral.referringProvider,
        initialFax: referral.initialFax,
        faxToEmr: referral.faxToEmr,
        triageFax: referral.triageFax,
        sender: referral.sender,
        recipient: referral.recipient,
        redirectedFrom: referral.redirectedFrom
      }
    }

    if (data) decryptReferrals()
  }, [data, getAccount])

  function handleBack() {
    setPage(page - 1)
    fetchMore({ variables: { before: referrals[0].id, after: null, filter } })
  }

  function handleNext() {
    setPage(page + 1)
    fetchMore({
      variables: {
        before: null,
        after: referrals[referrals.length - 1].id,
        filter
      }
    })
  }

  function handleUpdateReferralFax() {
    if (
      filter &&
      ['triageFaxStatus', 'emrFaxStatus'].includes(filter.type) &&
      filter.value === 'failed'
    ) {
      if (referrals.length === 1 && page > 1) {
        handleBack()
      } else if (page === 1 && !hasNextPage) {
        fetchMore({
          variables: {
            before: null,
            after: null,
            filter
          }
        })
      } else {
        fetchMore({
          variables: {
            before: null,
            after: data.getReferrals.pageInfo.after,
            filter
          }
        })
      }
    } else {
      fetchMore({
        variables: {
          before: data.getReferrals.pageInfo.before,
          after: data.getReferrals.pageInfo.after,
          filter
        }
      })
    }
  }

  if (error) return <LoadingError />
  if (!referrals || !searchKeys) return <Loading />

  const hasNextPage = loading ? false : data.getReferrals.pageInfo.hasNextPage

  return (
    <Container>
      <Header>
        <PageTitle>Sent Referrals</PageTitle>
        <PaginationContainer>
          <Pagination
            page={page}
            loading={loading}
            hasNextPage={hasNextPage}
            onBack={handleBack}
            onNext={handleNext}
          />
        </PaginationContainer>
      </Header>
      <Referrals
        referrals={referrals}
        practices={practices}
        isTokenizing={isTokenizing}
        filter={filterInput}
        onChangeFilter={onChangeFilter}
        onUpdateReferralFax={handleUpdateReferralFax}
        loading={loading}
      />
    </Container>
  )
}

export default SentPage
