import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SendCircleIcon from 'mdi-react/SendCircleIcon'
import CloseCircleIcon from 'mdi-react/CloseCircleIcon'
import CheckboxIcon from 'mdi-react/CheckboxMarkedCircleIcon'
import AlertCircleIcon from 'mdi-react/AlertCircleIcon'

const color = {
  processing: '#f1c40f',
  success: '#2dcc70',
  failed: '#e84c3d',
  cancelled: '#3a3a3a'
}

const Text = styled.span`
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: ${props => (props.side === 'left' ? 'right' : 'left')};
`

const Container = styled.div`
  display: flex;
  justify-content: ${props =>
    props.side === 'left' ? 'flex-start' : 'flex-end'};

  svg {
    color: ${props => color[props.state]};
    margin: ${props => (props.side === 'left' ? '0 5px 0 0' : '0 0 0 10px')};
  }
`

const IconStatusIndicator = ({ state, label = '', side = 'right' }) => (
  <Container side={side} state={state}>
    {side === 'right' && <Text>{label}</Text>}
    {state === 'processing' && <SendCircleIcon size={15} />}
    {state === 'success' && <CheckboxIcon size={15} />}
    {state === 'failed' && <AlertCircleIcon size={15} />}
    {state === 'cancelled' && <CloseCircleIcon size={15} />}
    {side === 'left' && <Text>{label}</Text>}
  </Container>
)

IconStatusIndicator.propTypes = {
  state: PropTypes.oneOf(['processing', 'failed', 'success', 'cancelled'])
    .isRequired,
  label: PropTypes.string,
  side: PropTypes.oneOf(['left', 'right'])
}

export default IconStatusIndicator
