import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import InformationIcon from 'mdi-react/InformationIcon'
import AlertCircleIcon from 'mdi-react/AlertCircleIcon'
import CheckboxIcon from 'mdi-react/CheckboxMultipleMarkedCircleOutlineIcon'
import CheckboxCircleIcon from 'mdi-react/CheckboxMarkedCircleIcon'
import CloseCircleIcon from 'mdi-react/CloseCircleIcon'
import SendCircleIcon from 'mdi-react/SendCircleIcon'

const Container = styled.div`
  display: flex;
  padding: 16px 15px;
  margin: 15px 0;
  border-radius: 3px;
  width: 100%;
`

const InfoContainer = styled(Container)`
  background: rgba(53, 152, 219, 0.15);
  svg {
    color: #4ca1d8;
    margin-right: 15px;
  }
`

const WarningContainer = styled(Container)`
  background: rgba(241, 196, 15, 0.15);
  margin-bottom: 0;
  svg {
    color: #f1c40f;
    margin-right: 15px;
  }
`

const ErrorContainer = styled(Container)`
  background: rgba(232, 76, 61, 0.15);
  svg {
    color: #e84c3d;
    margin-right: 15px;
  }
`

const SuccessContainer = styled(Container)`
  background: rgba(45, 204, 112, 0.15);
  margin-bottom: 0;
  svg {
    color: #2dcc70;
    margin-right: 15px;
  }
`

const CancelledContainer = styled(Container)`
  background: rgba(58, 58, 58, 0.15);
  svg {
    color: #3a3a3a;
    margin-right: 15px;
  }
`

const Message = styled.label`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  color: #3a3a3a;
  flex: 1;
`

const InfoMessage = ({ children, className }) => (
  <InfoContainer className={className}>
    <InformationIcon size={18} />
    <Message>{children}</Message>
  </InfoContainer>
)

const WarningMessage = ({ children }) => (
  <WarningContainer>
    <InformationIcon size={18} />
    <Message>{children}</Message>
  </WarningContainer>
)

const ProcessingMessage = ({ children }) => (
  <WarningContainer>
    <SendCircleIcon size={18} />
    <Message>{children}</Message>
  </WarningContainer>
)

const ErrorMessage = ({ children }) => (
  <ErrorContainer role="alert">
    <AlertCircleIcon size={18} />
    <Message>{children ?? 'Hm, an error occurred. Try again.'}</Message>
  </ErrorContainer>
)

const SuccessMessage = ({ children, iconType = '' }) => (
  <SuccessContainer>
    {iconType === 'circle' ? (
      <CheckboxCircleIcon size={18} />
    ) : (
      <CheckboxIcon size={18} />
    )}
    <Message>{children}</Message>
  </SuccessContainer>
)

const CancelledMessage = ({ children }) => (
  <CancelledContainer>
    <CloseCircleIcon size={18} />
    <Message>{children}</Message>
  </CancelledContainer>
)

InfoMessage.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

WarningMessage.propTypes = {
  children: PropTypes.node.isRequired
}

ProcessingMessage.propTypes = {
  children: PropTypes.node.isRequired
}

ErrorMessage.propTypes = {
  children: PropTypes.node
}

SuccessMessage.propTypes = {
  children: PropTypes.node.isRequired,
  iconType: PropTypes.string
}

CancelledMessage.propTypes = {
  children: PropTypes.node.isRequired
}

export {
  InfoMessage,
  WarningMessage,
  ProcessingMessage,
  ErrorMessage,
  SuccessMessage,
  CancelledMessage
}
