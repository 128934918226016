import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import MenuItem from '@material-ui/core/MenuItem'
import { Paper } from '@material-ui/core'
import CheckIcon from 'mdi-react/CheckIcon'
import styled from 'styled-components'

import { debounce } from '../../util/debounce'
import {
  getGender,
  getGenderDescription,
  getPracticeDisplayName
} from '../../util/strings'

import { PageTitle, SectionTitle } from '../common/Title'
import { InfoMessage } from '../common/UserAlert'
import { FieldLabel, FieldValue } from '../common/StyledField'
import LoadingError from '../common/LoadingError'
import Button from '../common/Button'
import Select from '../common/Select'
import PdfPreview from '../faxing/PdfPreview'
import ReferralFields from '../forms/ReferralFields'
import FaxContactsDropdown from '../faxing/FaxContactsDropdown'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const StyledFieldValue = styled(FieldValue)`
  height: 36px;
`

const ReferrerInfo = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: 20px;
`

const ReferrerField = styled.div`
  padding: 27px 5px 0 25px;
  flex-basis: 50%;
  overflow: hidden;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 51px 0 30px;
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: baseline;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 30px);
`

const Pdf = styled.iframe`
  border: none;
`

const ErrorContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StickyContainer = styled.div`
  display: flex;
  position: sticky;
  top: 15px;
  height: 100%;
  width: 50%;
`

const ReferralInfoWrapper = styled(Paper)`
  width: 100%;
  border-radius: 6px;
  padding: 0px 30px 100px;
`

const SelectorLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  color: #fff;
  padding-right: 18px;
  margin: 0;
  white-space: nowrap;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    width: 250px;
  }
`

const StyledInfoMessage = styled(InfoMessage)`
  margin: 15px 0 -5px 0;
`

const ProviderSelect = styled(StyledSelect)`
  .MuiInputBase-root {
    &.Mui-disabled {
      color: #e2e2e2;
      opacity: 1;
    }
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root.Mui-selected,
  &.MuiListItem-root.Mui-selected:hover {
    background-color: rgba(6, 153, 135, 0.15);
  }

  &.MuiListItem-root:hover {
    background-color: rgba(6, 153, 135, 0.05);
  }

  div {
    flex-direction: column;
  }
`

const ClinicNameStyling = styled.span`
  padding-left: 5px;
  color: rgba(58, 58, 58, 0.7);
  font-size: 14px;
  font-style: italic;

  &::before {
    content: '(';
  }

  &::after {
    content: ')';
  }
`

function ReferralForm({
  providers = [],
  referral = {
    provider: `${providers[0] ? providers[0].id : ''}`,
    firstName: '',
    lastName: '',
    phn: '',
    birthDate: null,
    referralDate: new Date(),
    gender: 'Unknown',
    phone: '',
    address: '',
    city: '',
    province: '',
    postalCode: '',
    email: '',
    region: '',
    specialist: '',
    nextSpecialist: false,
    specificSpecialist: false,
    specialty: '',
    category: '',
    subcategory: '',
    urgency: '',
    files: [],
    message: '',
    referringProvider: '',
    redirectedFrom: undefined
  },
  specialties = [],
  specialists = [],
  contacts = [],
  onSave = () => {},
  setReset = () => {},
  fax,
  setPhn = () => {},
  hasPossibleDuplicates = null,
  handleOpenDuplicates = () => {},
  populatedPatientInfo = null
}) {
  const phoneRegex = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})/
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const postalCodeRegex = /^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]/
  /*
   * TODO: We should move this out of the component, once we find a way to
   * pass `specialties` to the schema
   */
  const referralSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    gender: yup.string().oneOf(['Unknown', 'Female', 'Male', 'Other']),
    genderDescription: yup.string(),
    phn: yup
      .number()
      .typeError('PHN must be a valid PHN')
      .required('PHN must be a valid PHN')
      .positive('PHN must be a valid PHN')
      .integer('PHN must be a valid PHN')
      .moreThan(1000, 'PHN must be a valid PHN'),
    birthDate: yup
      .date()
      .typeError('Date of Birth is required')
      .required('Date of Birth is required'),
    phone: yup
      .string()
      .matches(phoneRegex, 'Phone Number must be valid')
      .required('Phone Number is required'),
    email: yup.string().matches(emailRegex, {
      message: 'Email must be valid',
      excludeEmptyString: true
    }),
    postalCode: yup.string().matches(postalCodeRegex, {
      message: 'Postal Code must be valid',
      excludeEmptyString: true
    }),
    region: yup.string().required('Region is required'),
    specialty: yup.string().required('Specialty is required'),
    category: yup.string().required('Category is required'),
    subcategory: yup.string().when(['specialty', 'category'], {
      is: (specialty, category) =>
        specialties
          .find(s => s.id === specialty)
          ?.categories?.find(c => c.id === category)?.subcategories.length !==
        0,
      then: yup.string().required('Subcategory is required')
    }),
    nextSpecialist: yup.boolean(),
    specificSpecialist: yup.boolean(),
    specialist: yup.string().when('nextSpecialist', {
      is: nextSpecialist => !nextSpecialist,
      then: yup
        .string()
        .required(
          'Required: Select a specific specialist or check choose for me'
        )
    }),
    urgency: yup.string().required('Urgency is required')
  })

  const emptyContact = {
    id: '',
    practice: '',
    name: '',
    location: '',
    faxNumber: ''
  }

  const matchingContacts = contacts.filter(
    c => c.faxNumber === fax.faxContact.faxNumber
  )
  const oneMatch = matchingContacts.length === 1

  const [isSelected, setIsSelected] = useState(oneMatch)
  const [defaultContact, setDefaultContact] = useState(
    matchingContacts.length === 1 ? matchingContacts[0] : null
  )

  const [activeContact, setActiveContact] = useState(
    oneMatch ? matchingContacts[0] : emptyContact
  )
  const [faxDocumentUrl, setFaxDocumentUrl] = useState(null)

  const methods = useForm({
    resolver: yupResolver(referralSchema),
    criteriaMode: 'all',
    defaultValues: {
      ...referral,
      gender: getGender(referral.gender),
      genderDescription: getGenderDescription(referral.gender),
      referringProvider: oneMatch ? matchingContacts[0].id : ''
    }
  })

  const { handleSubmit, control, register, reset, watch, setValue } = methods

  const provider = watch('provider')
  const region = watch('region')
  const specialty = watch('specialty')
  const referringProvider = watch('referringProvider')
  const phn = watch('phn')

  useEffect(() => {
    if (referral && referral.redirectedFrom && referral.files.length > 0) {
      const newObjectUrl = URL.createObjectURL(referral.files[0])
      setFaxDocumentUrl(newObjectUrl)
    }
  }, [referral])

  useEffect(() => {
    if (populatedPatientInfo) {
      setValue('birthDate', populatedPatientInfo.birthDate)
      setValue('firstName', populatedPatientInfo.firstName)
      setValue('lastName', populatedPatientInfo.lastName)
      setValue('phone', populatedPatientInfo.phone)
      setValue('address', populatedPatientInfo.address)
      setValue('city', populatedPatientInfo.city)
      setValue('province', populatedPatientInfo.province)
      setValue('postalCode', populatedPatientInfo.postalCode)
      setValue('email', populatedPatientInfo.email)
      setValue('gender', getGender(populatedPatientInfo.gender))
      setValue(
        'genderDescription',
        getGenderDescription(populatedPatientInfo.gender)
      )
    }
  }, [setValue, populatedPatientInfo])

  useEffect(() => {
    if (referral.referringProvider !== '') {
      setValue('referringProvider', referral.referringProvider)
      setActiveContact(contacts.find(c => c.id === referral.referringProvider))
      setIsSelected(true)
      setDefaultContact(contacts.find(c => c.id === referral.referringProvider))
    }
  }, [referral.referringProvider, contacts, setValue])

  const debounceSetPhn = useMemo(
    () =>
      debounce(phn => {
        setPhn(phn)
      }, 1000),
    [setPhn]
  )

  useEffect(() => {
    debounceSetPhn(phn)
  }, [phn, debounceSetPhn])

  useEffect(() => {
    function setRegionSpecialty() {
      const selectedProvider = providers.find(p => p.id === provider)

      if (selectedProvider.region && selectedProvider.specialty) {
        setValue('region', selectedProvider.region)
        setValue('specialty', selectedProvider.specialty)
      } else {
        setValue('region', '')
        setValue('specialty', '')
      }
    }

    if (!region && !specialty && provider) {
      setRegionSpecialty()
    }
  }, [region, specialty, provider, providers, specialists, setValue])

  // ReferralPage needs to be able to reset the form itself
  useEffect(() => {
    setReset(() => reset)
  }, [reset, setReset])

  useEffect(() => {
    register({ name: 'urgency' })
    register({ name: 'files' })
    register({ name: 'referringProvider' })
  }, [register])

  useEffect(() => {
    return () => {
      if (faxDocumentUrl) URL.revokeObjectURL(faxDocumentUrl)
    }
  }, [faxDocumentUrl])

  const onSubmit = values => {
    onSave(values)
  }

  function onSelect(faxContact) {
    setValue('referringProvider', faxContact.id)
    setActiveContact(faxContact)
    setIsSelected(true)
    setDefaultContact(faxContact)
  }

  function onClear() {
    setActiveContact(emptyContact)
    setValue('referringProvider', '')
    setDefaultContact(null)
    setIsSelected(false)
  }

  const returnToNames = matchingContacts.map(f => f.name)

  return (
    <Content>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} aria-label="Referral form">
          <Header>
            <PageTitle>New Referral</PageTitle>
            <ButtonContainer>
              <Button type="submit">
                <CheckIcon /> Finish & Review
              </Button>
            </ButtonContainer>
          </Header>
          <BlueBar>
            <SelectorLabel id="selector-label">
              {fax ? 'Triaging as' : 'Referring as'}
            </SelectorLabel>
            <Controller
              name="provider"
              control={control}
              render={({ onChange, value, name }) => (
                <ProviderSelect
                  select
                  id="provider"
                  disabled={fax || referral.redirectedFrom ? true : false}
                  aria-labelledby="selector-label"
                  name={name}
                  onChange={e => {
                    setValue('region', '')
                    setValue('specialty', '')
                    onChange(e.target.value)
                  }}
                  value={value}>
                  {providers.map(p => (
                    <StyledMenuItem key={p.id} value={p.id}>
                      <div>
                        <span>
                          {getPracticeDisplayName(p, 'long', 'short')}
                          {p.clinicName && (
                            <ClinicNameStyling>
                              {p.clinicName}
                            </ClinicNameStyling>
                          )}
                        </span>
                      </div>
                    </StyledMenuItem>
                  ))}
                </ProviderSelect>
              )}
            />
          </BlueBar>
          <Container>
            {fax ? (
              <>
                <FieldsContainer>
                  <ReferralInfoWrapper elevation={3}>
                    <>
                      <SectionTitle>Referring Provider</SectionTitle>
                      <Divider />
                      {fax.faxContact.faxNumber &&
                        matchingContacts.length > 1 && (
                          <StyledInfoMessage>
                            There were {matchingContacts.length} contacts found
                            with the fax number {fax.faxContact.faxNumber}:{' '}
                            {returnToNames.join(', ')}
                          </StyledInfoMessage>
                        )}
                      {fax.faxContact.faxNumber &&
                        matchingContacts.length === 0 && (
                          <StyledInfoMessage>
                            There were no contacts found with the fax number{' '}
                            {fax.faxContact.faxNumber}. Please create a contact
                            now.
                          </StyledInfoMessage>
                        )}
                      <ReferrerInfo>
                        <ReferrerField>
                          <FaxContactsDropdown
                            id="referringProvider"
                            name="referringProvider"
                            value={referringProvider}
                            contacts={contacts}
                            practice={provider}
                            defaultContact={defaultContact}
                            onSelect={onSelect}
                            onClear={onClear}
                            isRequired={false}
                          />
                        </ReferrerField>
                        {isSelected ? (
                          <>
                            <ReferrerField>
                              <FieldLabel>Fax no.</FieldLabel>
                              <StyledFieldValue>
                                {activeContact.faxNumber}
                              </StyledFieldValue>
                            </ReferrerField>
                            <ReferrerField>
                              <FieldLabel>Location</FieldLabel>
                              <StyledFieldValue>
                                {activeContact.location}
                              </StyledFieldValue>
                            </ReferrerField>
                            <ReferrerField />
                          </>
                        ) : (
                          <>
                            <ReferrerField />
                          </>
                        )}
                      </ReferrerInfo>
                    </>
                    <ReferralFields
                      specialists={specialists}
                      specialties={specialties}
                      faxId={!referral.redirectedFrom ? fax.id : ''}
                      padding=""
                      column="2"
                      hasPossibleDuplicates={hasPossibleDuplicates}
                      handleOpenDuplicates={handleOpenDuplicates}
                    />
                  </ReferralInfoWrapper>
                </FieldsContainer>
                <StickyContainer>
                  <PdfContainer>
                    {referral.redirectedFrom && faxDocumentUrl && (
                      <Pdf
                        title="Referral preview"
                        src={`${faxDocumentUrl}#view=fitH`}
                        width="100%"
                        height="100%"
                        type="application/pdf"
                      />
                    )}
                    {referral.redirectedFrom && !faxDocumentUrl && (
                      <ErrorContainer>
                        <LoadingError
                          message={[
                            'Referral fax PDF could not be loaded',
                            referral.files.length === 0
                              ? 'This referral has no files to display'
                              : 'Please try refreshing the page.'
                          ]}
                        />
                      </ErrorContainer>
                    )}
                    {!referral.redirectedFrom && <PdfPreview fax={fax.id} />}
                  </PdfContainer>
                </StickyContainer>
              </>
            ) : (
              <ReferralInfoWrapper elevation={3}>
                <ReferralFields
                  specialists={specialists}
                  specialties={specialties}
                  hasPossibleDuplicates={hasPossibleDuplicates}
                  handleOpenDuplicates={handleOpenDuplicates}
                />
              </ReferralInfoWrapper>
            )}
          </Container>
        </form>
      </FormProvider>
    </Content>
  )
}

ReferralForm.propTypes = {
  referral: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phn: PropTypes.number,
    phone: PropTypes.string,
    gender: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
    email: PropTypes.string,
    region: PropTypes.string,
    specialty: PropTypes.string,
    category: PropTypes.string,
    subcategory: PropTypes.string,
    specialist: PropTypes.string,
    nextSpecialist: PropTypes.bool,
    specificSpecialist: PropTypes.bool,
    urgency: PropTypes.string,
    message: PropTypes.string,
    referringProvider: PropTypes.string,
    redirectedFrom: PropTypes.string
  }),
  specialties: PropTypes.array, // TODO: add shape here
  specialists: PropTypes.array, // TODO: add shape here
  providers: PropTypes.array, // TODO: add shape here
  contacts: PropTypes.array,
  onSave: PropTypes.func,
  setReset: PropTypes.func,
  fax: PropTypes.object,
  setPhn: PropTypes.func,
  hasPossibleDuplicates: PropTypes.bool,
  handleOpenDuplicates: PropTypes.func,
  populatedPatientInfo: PropTypes.object
}

export default ReferralForm
