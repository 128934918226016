import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const colors = {
  grey: '#c4c4c4',
  transparentGrey: 'rgba(58, 58, 58, 0.5)'
}

const filledColors = {
  blue: '#1193ad',
  white: '#F9FBFE'
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
`

const Dot = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  margin: 0px 3px;

  background-color: ${props => colors[props.color]};
`

const FilledDot = styled(Dot)`
  background-color: ${props => filledColors[props.filledColor]};
`

function ProgressDots({
  currentStep = 1,
  steps = 2,
  size = 5,
  filledColor = 'blue',
  color = 'grey'
}) {
  const dots = [...Array(steps).keys()]

  return (
    <Row>
      {dots.map(n => {
        if (n === currentStep - 1) {
          return (
            <FilledDot
              key={n}
              aria-label={`on step ${currentStep} of ${steps}`}
              role="presentation"
              size={size}
              filledColor={filledColor}
            />
          )
        }
        return <Dot key={n} role="presentation" size={size} color={color} />
      })}
    </Row>
  )
}

ProgressDots.propTypes = {
  currentStep: (props, propName) => {
    if (props.currentStep == null)
      return new Error('The prop `currentStep` is required in `ProgressDots`.')
    if (typeof props.currentStep !== 'number')
      return new Error(
        'The prop `currentStep` in `ProgressDots` must have type `number`.'
      )
    if (
      props.currentStep <= 0 ||
      props.steps <= 0 ||
      props.currentStep > props.steps
    )
      return new Error('ProgressDots is not properly configured')
  },
  steps: PropTypes.number.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  filledColor: PropTypes.string
}

export default ProgressDots
