const chartStyling = {
  text: {
    fontFamily: 'Source Sans Pro',
    size: '14px',
    color: '#3A3A3A'
  },
  label: {
    fontFamily: 'Source Sans Pro',
    size: '14px'
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontWeight: '700',
    size: '15px',
    color: 'rgba(58,58,58,0.7)'
  }
}

export { chartStyling }
