import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  GridComponent,
  Inject,
  Group,
  Sort,
  Resize,
  Grid,
  ExcelExport
} from '@syncfusion/ej2-react-grids'
import { Tooltip as TableTooltip } from '@syncfusion/ej2-popups'
import Tooltip from '@material-ui/core/Tooltip'

import FileExcelIcon from 'mdi-react/FileExcelIcon'

import { SectionTitle } from '../common/Title'
import { IconButton } from '../common/Button'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h1:only-child {
    margin-left: auto;
    margin-right: auto;
  }
`

const TableTitle = styled(SectionTitle)`
  display: flex;
  justify-content: center;
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
`

const ButtonContainer = styled.div`
  margin: 25px 0 15px;
`

const Placeholder = styled.div`
  width: 35px;
`

const styling = `
  .e-grid {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .e-grid .e-headercelldiv {
    font-weight: 600;
    font-size: 13px;
  }

  .e-grid .e-headercell {
      background-color: rgba(17,147,173,0.2) !important;
  }

  .e-grid .e-group-intent {
    width: 15px !important;
  }
`

function Table({ title, options, handleClick, downloadable = false }) {
  let grid = Grid | null

  let columnTooltip

  function beforeRender(args) {
    if (args.target.ariaColIndex !== '1') {
      args.cancel = true
    }
    this.content = args.target.innerText
  }

  function dataBoundFunc() {
    if (grid) grid.autoFitColumns()
    if (!columnTooltip) {
      const columnTooltip = new TableTooltip({
        target: 'td.e-rowcell',
        beforeRender
      })
      columnTooltip.appendTo(this.element)
    }
  }

  const settings = {
    resizeSettings: {
      mode: options.allowGrouping ? 'Normal' : 'Auto'
    },
    gridLines: 'Both',
    rowHeight: 30,
    dataBound: dataBoundFunc,
    ref: g => (grid = g),
    allowExcelExport: true,
    ...options
  }

  const clickable = handleClick ? true : false

  const handleExportToExcel = () => grid.excelExport()

  return (
    <div>
      <style>{styling}</style>
      <Header>
        {downloadable && <Placeholder />}
        <TableTitle
          onClick={handleClick ? handleClick : () => {}}
          clickable={clickable}>
          {title}
        </TableTitle>
        {downloadable && (
          <ButtonContainer>
            <Tooltip title="Export to Excel" arrow>
              <div>
                <IconButton
                  color="secondary"
                  onClick={handleExportToExcel}
                  aria-label="Export to Excel">
                  <FileExcelIcon />
                </IconButton>
              </div>
            </Tooltip>
          </ButtonContainer>
        )}
      </Header>
      <GridComponent {...settings}>
        <Inject services={[Group, Sort, Resize, ExcelExport]} />
      </GridComponent>
    </div>
  )
}

Table.propTypes = {
  title: PropTypes.string,
  options: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  downloadable: PropTypes.bool
}

export default Table
