import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import CheckIcon from 'mdi-react/CheckIcon'

import { LandingPageTitle } from '../common/Title'
import Button from '../common/Button'
import LandingContainer from './LandingContainer'

const TextContainer = styled.div`
  max-width: 400px;
`

const Content = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: #3a3a3a;
  margin: 0 0 20px;
`

const StyledLink = styled.a`
  color: #3598db;
  font-weight: 700;
`

const StyledTitle = styled(LandingPageTitle)`
  color: #3a3a3a;
`

const ErrorMessage = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  color: #e84c3d;
  margin: 0;
  padding-top: 5px;
`

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      acceptTerms
    }
  }
`

const ACCEPT_TERMS = gql`
  mutation acceptTerms {
    acceptTerms {
      id
      acceptTerms
    }
  }
`

function UpdatedTermsLanding() {
  const [accept, { loading, error }] = useMutation(ACCEPT_TERMS, {
    update(cache) {
      if (cache.data.data.ROOT_QUERY?.[`getCurrentUser`]) {
        const { getCurrentUser } = cache.readQuery({
          query: GET_CURRENT_USER
        })
        cache.writeQuery({
          query: GET_CURRENT_USER,
          data: { getCurrentUser: { ...getCurrentUser, acceptTerms: true } }
        })
      }
    }
  })

  async function handleAccept() {
    try {
      await accept()
    } catch (e) {
      console.error('Error accepting terms:', e)
    }
  }

  return (
    <LandingContainer type="secondary">
      <StyledTitle>Updated Terms</StyledTitle>
      <TextContainer>
        <Content>
          We have updated our{' '}
          <StyledLink href="https://clinnect.ca/terms-of-use/">
            Terms of Use
          </StyledLink>
          . Please take a few minutes to read and understand them.
        </Content>
        <Content>By accepting, you agree to our updated terms.</Content>
      </TextContainer>
      <div>
        <Button padding="small" loading={loading} onClick={handleAccept}>
          <CheckIcon />
          Accept
        </Button>
        {error && (
          <ErrorMessage role="alert">
            Hm, an error occurred. Try again.
          </ErrorMessage>
        )}
      </div>
    </LandingContainer>
  )
}

export default UpdatedTermsLanding
