import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import AccountSettings from '../settings/AccountSettings'
import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import { PageTitle } from '../common/Title'

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      email
      firstName
      lastName
      salt
      privateKey {
        encryptedData
        iv
      }
      recoveryKey {
        encryptedData
        iv
      }
      id
      emailPreferences {
        declinedReferrals
        acceptedReferrals
        newReferrals
        newMessages
        newFiles
        promotions
      }
    }
  }
`

const AccountSettingsPage = () => {
  const { loading, error, data } = useQuery(GET_CURRENT_USER)
  if (error) return <LoadingError />
  if (loading) return <Loading />

  return (
    <>
      <Header>
        <PageTitle>Account Information</PageTitle>
      </Header>
      <AccountSettings user={data.getCurrentUser} />
    </>
  )
}

export default AccountSettingsPage
