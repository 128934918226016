import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import ChevronDownIcon from 'mdi-react/ChevronDownIcon'

import { SectionTitle } from '../common/Title'
import JourneyLogDate from './JourneyLogDate'
import NewJourneyDateButton from './NewJourneyDateButton'

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 15px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

const CollapseIcon = styled(ChevronDownIcon)`
  margin: 0 10px 13px 0;
  color: rgba(58, 58, 58, 0.7);
  transform: ${props => (props.rotate === 'true' ? 'rotate(-90deg)' : '0')};
  :hover {
    cursor: pointer;
  }
`

const NewButtonContainer = styled.div`
  margin-bottom: 15px;
`

const DatesContainer = styled.div`
  max-height: calc(50vh - 100px);

  /* Flex starts the scroll bar at the bottom of the
   * referral history log, displaying the most recent events */
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;

  /* note: ::-webkit-scroll is non-standard */
  scrollbar-width: thin;
  scrollbar-color: rgba(58, 58, 58, 0.15) #fff;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(58, 58, 58, 0.15);
    border-radius: 6px;
  }
`

const Dates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  :first-child {
    margin-top: 27px;
  }
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
  margin: 0 15px;
`

function JourneyLog({ journeyDates, referral }) {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggleCollapse = () => setIsCollapsed(!isCollapsed)

  const referralDate = journeyDates.length > 0 ? journeyDates[0].date : null
  const completedDate =
    journeyDates.length > 0 &&
    journeyDates[journeyDates.length - 1].type === 'completed'
      ? journeyDates[journeyDates.length - 1].date
      : null

  const lastJourneyDate =
    journeyDates.length > 0
      ? journeyDates.reduce(
          (acc, curr) => (curr.type !== 'completed' ? curr : acc),
          null
        ).date
      : null

  return (
    <>
      <Header>
        <TitleContainer>
          <CollapseIcon
            aria-label={
              isCollapsed ? 'Expand Journey Log' : 'Collapse Journey Log'
            }
            rotate={isCollapsed.toString()}
            onClick={() => toggleCollapse()}
          />
          <SectionTitle variant="section">Journey Log</SectionTitle>
        </TitleContainer>
        <NewButtonContainer>
          <NewJourneyDateButton
            referral={referral}
            referralDate={referralDate}
            completedDate={completedDate}
            lastJourneyDate={lastJourneyDate}
          />
        </NewButtonContainer>
      </Header>
      <Divider />
      {!isCollapsed && (
        <DatesContainer>
          <Dates>
            {journeyDates.map((date, i) => (
              <JourneyLogDate
                key={i}
                journeyDate={date}
                referralDate={referralDate}
                completedDate={completedDate}
                lastJourneyDate={lastJourneyDate}
              />
            ))}
          </Dates>
        </DatesContainer>
      )}
    </>
  )
}

JourneyLog.propTypes = {
  journeyDates: PropTypes.array.isRequired,
  referral: PropTypes.string.isRequired
}

export default JourneyLog
