import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import PivotTable from './PivotTable'
import { Paper } from '@material-ui/core'
import { SectionTitle } from '../common/Title'
import { filterDataBySpecialistCategory } from '../../util/filterData'
import ValueDisplay from './ValueDisplay'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Bar = styled.div`
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
`

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
`

const Wrapper = styled(Paper)`
  border-radius: 6px;
  padding: 0 22px 18px;
  width: 100%;
`

const ValueWrapper = styled(Wrapper)`
  display: flex;
  padding: 16px 22px 0;
  margin-top: 30px;
  height: 164px;
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

function PatientTriageReport({ events, referrals, filters = null }) {
  const filteredReferrals = filters
    ? filterDataBySpecialistCategory(referrals, 'referrals', filters)
    : referrals
  const filteredEvents = filters
    ? filterDataBySpecialistCategory(events, 'triageEvents', filters)
    : events

  if (filteredEvents.length === 0) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  const declinedAtTriage = filteredEvents.filter(
    e => e.type === 'Declined by Triage'
  ).length
  const total = declinedAtTriage + filteredReferrals.length
  const accepted = filteredReferrals.filter(
    r => r.state === 'accepted' || r.state === 'pending'
  ).length
  const declinedBySpecialist = filteredEvents.filter(
    r => r.type === 'Declined by Specialist'
  ).length

  return (
    <ReportVisualsContainer>
      <SectionTitle>Patient Triage Report</SectionTitle>
      <Bar />
      <TopWrapper>
        <ValueWrapper elevation={3}>
          <ValueDisplay
            title={
              <>
                Received <br />
                <br />
              </>
            }
            value={total.toLocaleString()}
          />
        </ValueWrapper>
        <ValueWrapper elevation={3}>
          <ValueDisplay
            title={
              <>
                Declined <br /> by Triage
              </>
            }
            value={declinedAtTriage.toLocaleString()}
            subValue={'(' + ((declinedAtTriage / total) * 100).toFixed() + '%)'}
          />
        </ValueWrapper>
        <ValueWrapper elevation={3}>
          <ValueDisplay
            title={
              <>
                Declined <br /> by Specialist
              </>
            }
            value={declinedBySpecialist.toLocaleString()}
            subValue={
              '(' + ((declinedBySpecialist / total) * 100).toFixed() + '%)'
            }
          />
        </ValueWrapper>
        <ValueWrapper elevation={3}>
          <ValueDisplay
            title={
              <>
                Pending/Accepted <br /> by Specialist
              </>
            }
            value={accepted.toLocaleString()}
            subValue={'(' + ((accepted / total) * 100).toFixed() + '%)'}
          />
        </ValueWrapper>
      </TopWrapper>
      <PivotTable
        title="Returned Reasons"
        dataSourceSettings={{
          expandAll: false,
          columns: [{ name: 'type' }],
          rows: [{ name: 'reason' }, { name: 'practiceName' }],
          values: [{ name: 'reason' }],
          dataSource: filteredEvents,
          allowSorting: true
        }}
        downloadable={true}
        expandable={true}
      />
    </ReportVisualsContainer>
  )
}

PatientTriageReport.propTypes = {
  events: PropTypes.array.isRequired,
  referrals: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default PatientTriageReport
