import React, { useState } from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'

import Loading from '../common/Loading'
import LoadingError from '../common/LoadingError'
import Mnemonic from '../recovery/Mnemonic'
import ViewMnemonicDialog from '../recovery/ViewMnemonicDialog'
import NewMnemonicDialog from '../recovery/NewMnemonicDialog'
import EmergencyAccessKitPrintView from '../print/EmergencyAccessKitPrintView'
import usePrinter from '../../hooks/usePrinter'
import { PageTitle, SectionTitle } from '../common/Title'
import PrimaryButton, { SecondaryButton } from '../common/Button'
import { InfoMessage } from '../common/UserAlert'

import EyeIcon from 'mdi-react/EyeIcon'
import EyeOffIcon from 'mdi-react/EyeOffIcon'
import KeyIcon from 'mdi-react/KeyIcon'
import ShieldKeyIcon from 'mdi-react/ShieldKeyIcon'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  padding: 0px 30px;
`

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`

const Description = styled.p`
  margin: 30px;
  font-size: 14px;
  line-height: 20px;
`

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  flex: 1;

  button {
    margin-left: 20px;
  }
`

const Line = styled.div`
  border-top: 1px solid rgba(58, 58, 58, 0.05);
  width: 100%;
`

const CenterMnemonic = styled.div`
  width: 437px;
  margin: 0px auto;
`

const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      email
      firstName
      lastName
      salt
      privateKey {
        encryptedData
        iv
      }
      recoveryKey {
        encryptedData
        iv
      }
    }
  }
`

function EmergencyAccessKitPage() {
  const print = usePrinter({ documentTitle: 'Clinnect Recovery Words' })
  const { loading, error, data } = useQuery(GET_CURRENT_USER)
  const [mnemonic, setMnemonic] = useState(null)
  const [state, setState] = useState('VIEW')

  function toggleWords() {
    if (!mnemonic) setState('UNLOCKING')
    else setMnemonic(null)
  }

  function handleMnemonic(mnemonic) {
    if (state === 'PRINTING') {
      print(
        <EmergencyAccessKitPrintView
          mnemonic={mnemonic}
          email={user.email}
          date={new Date()}
        />
      )
    } else {
      setMnemonic(mnemonic)
    }
    setState(state === 'GENERATING' ? 'NEW' : 'VIEW')
  }

  function printEmergencyAccessKit() {
    if (mnemonic) {
      print(
        <EmergencyAccessKitPrintView
          mnemonic={mnemonic}
          email={user.email}
          date={new Date()}
        />
      )
    } else {
      setState('PRINTING')
    }
  }

  if (error) return <LoadingError />
  if (loading) return <Loading />

  const user = data.getCurrentUser

  // User just generated a new key and we don't need to show as many UI elements
  if (state === 'NEW') {
    return (
      <>
        <TitleBar>
          <PageTitle>Emergency Access Kit</PageTitle>
          <PrimaryButton onClick={printEmergencyAccessKit}>
            Print Emergency Access Kit
          </PrimaryButton>
        </TitleBar>
        <Content>
          <SectionTitle>New Emergency Access Kit</SectionTitle>
          <Line />
          <CenterMnemonic>
            <InfoMessage>
              You must <strong>print your Emergency Access Kit</strong> now
            </InfoMessage>
            <Mnemonic mnemonic={mnemonic} />
          </CenterMnemonic>
        </Content>
      </>
    )
  }

  // User has no recovery key
  if (user.recoveryKey === null) {
    return (
      <>
        <TitleBar>
          <PageTitle>Emergency Access Kit0</PageTitle>
          <PrimaryButton onClick={() => setState('GENERATING')}>
            <ShieldKeyIcon />
            Create Emergency Access Kit
          </PrimaryButton>
        </TitleBar>
        <Content>
          <SectionTitle>Create An Emergency Access Kit</SectionTitle>
          <Line />
          <Description>
            Your data is securely encrypted using your password. If you forget
            your password, you will be required to input all of the words in
            your Emergency Access Kit to set a new password. If you lose your
            password and Emergency Acccess Kit, you will not be able to regain
            access to your account. Please create your kit before continuing.
          </Description>
        </Content>
        <NewMnemonicDialog
          isOpen={state === 'GENERATING'}
          onClose={() => setState('VIEW')}
          onMnemonic={handleMnemonic}
          user={user}
        />
      </>
    )
  }

  // User has a current recovery key
  return (
    <>
      <TitleBar>
        <PageTitle>Emergency Access Kit</PageTitle>
        <PrimaryButton onClick={printEmergencyAccessKit}>
          Print Emergency Access Kit
        </PrimaryButton>
      </TitleBar>
      <Content>
        <TitleBar>
          <SectionTitle>Current Emergency Access Kit</SectionTitle>
          <ButtonsRow>
            <SecondaryButton color="secondary" onClick={toggleWords}>
              {mnemonic ? <EyeOffIcon /> : <EyeIcon />}
              {mnemonic ? 'Hide' : 'Reveal'} Words
            </SecondaryButton>
            <SecondaryButton
              color="secondary"
              onClick={() => setState('GENERATING')}>
              <KeyIcon />
              Generate new kit
            </SecondaryButton>
          </ButtonsRow>
        </TitleBar>

        <Line />
        <Description>
          Your data is securely encrypted using your password. If you forget
          your password, you will be required to input all of the words in your
          Emergency Access Kit to set a new password. If you lose your password
          and Emergency Acccess Kit, you will not be able to regain access to
          your account. Please keep a printed copy of your kit.
        </Description>
        <CenterMnemonic>
          <Mnemonic mnemonic={mnemonic} />
        </CenterMnemonic>
        <ButtonsRow></ButtonsRow>
      </Content>
      <ViewMnemonicDialog
        isOpen={state === 'PRINTING' || state === 'UNLOCKING'}
        onClose={() => setState('VIEW')}
        onMnemonic={handleMnemonic}
        user={user}
      />
      <NewMnemonicDialog
        isOpen={state === 'GENERATING'}
        onClose={() => setState('VIEW')}
        onMnemonic={handleMnemonic}
        user={user}
      />
    </>
  )
}

export default EmergencyAccessKitPage
