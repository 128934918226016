import gql from 'graphql-tag'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import cacheOpts from './cacheOpts'
import { isTokenExpiringSoon, isTokenValid } from '../util/tokens'

const authLink = setContext(attachHeader)

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`
})

const client = new ApolloClient({
  cache: new InMemoryCache(cacheOpts),
  link: authLink.concat(httpLink)
})

const REFRESH = gql`
  mutation refresh {
    refresh {
      authToken
    }
  }
`

let refreshing = false

async function updateToken() {
  const response = await client.mutate({
    mutation: REFRESH
  })

  const newToken = response.data.refresh.authToken
  window.localStorage.authToken = newToken

  refreshing = false

  return newToken
}

function attachHeader(request, context) {
  const token = window.localStorage.authToken

  if (isTokenValid()) {
    if (isTokenExpiringSoon() && !refreshing) {
      refreshing = true
      updateToken()
    }

    return {
      ...context,
      headers: {
        ...context.headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  }

  return context
}

export default client
