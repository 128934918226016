import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'

import useAccount from '../../hooks/useAccount'
import Sidebar from './Sidebar'
import PageContent from './PageContent'
import SentPage from '../pages/SentPage'
import LoginPage from '../pages/LoginPage'
import LogoutPage from '../pages/LogoutPage'
import ReferralPage from '../pages/ReferralPage'
import ReceivedPage from '../pages/ReceivedPage'
import SettingsPage from '../pages/SettingsPage'
import RegistrationPage from '../pages/RegistrationPage'
import ReferralViewPage from '../pages/ReferralViewPage'
import PracticeCreationPage from '../pages/PracticeCreationPage'
import PracticeManagementPage from '../pages/PracticeManagementPage'
import AddMembersPage from '../pages/AddMembersPage'
import NotFoundPage from '../pages/NotFoundPage'
import CrashPage from '../pages/CrashPage'
import AccountRecoveryPage from '../pages/AccountRecoveryPage'
import ForgottenPasswordPage from '../pages/ForgottenPasswordPage'
import EmergencyAccessKitPage from '../pages/EmergencyAccessKitPage'
import AccountSettingsPage from '../pages/AccountSettingsPage'
import FeedbackButton from '../common/FeedbackButton'
import NotificationsPage from '../pages/NotificationsPage'
import FaxContactsPage from '../pages/FaxContactsPage'
import FaxPage from '../pages/FaxPage'
import SentFaxesPage from '../pages/SentFaxesPage'
import ReceivedFaxesPage from '../pages/ReceivedFaxesPage'
import MetaData from './MetaData'
import ReportsPage from '../pages/ReportsPage'
import UpdatedTermsLanding from '../landing/UpdatedTermsLanding'

const SidebarContainer = styled.div`
  display: flex;
`

const AppContainer = styled.div`
  min-width: 1440px;
`

const LandingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9fbfe;
`

const GET_APP_DATA = gql`
  query getAppData {
    getCurrentUser {
      id
      acceptTerms
    }
    getPractices {
      id
      faxNumber
      group
      isAdmin
    }
  }
`

const App = () => {
  const { isLoggedIn } = useAccount()
  const [isFaxUser, setFaxUser] = useState()
  const [isReportsUser, setIsReportsUser] = useState()
  const [acceptTerms, setAcceptTerms] = useState()
  const location = useLocation()

  const [getAppData, getAppDataResults] = useLazyQuery(GET_APP_DATA, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (isLoggedIn) {
      getAppData()
    } else {
      setFaxUser()
      setIsReportsUser()
      setAcceptTerms()
    }
  }, [isLoggedIn, getAppData])

  useEffect(() => {
    async function setPermissions() {
      const practices = getAppDataResults.data.getPractices

      const faxPractices = practices.filter(r => r.faxNumber !== '')
      faxPractices.length === 0 ? setFaxUser(false) : setFaxUser(true)

      const reportPractices = practices.filter(p => p.group || p.isAdmin)
      reportPractices.length === 0
        ? setIsReportsUser(false)
        : setIsReportsUser(true)

      const terms = getAppDataResults.data.getCurrentUser.acceptTerms
      setAcceptTerms(terms)
    }

    if (getAppDataResults?.data) setPermissions()
  }, [getAppDataResults])

  return (
    <>
      <MetaData
        title="Clinnect Central Intake & Triage - Electronic Patient Referrals"
        description="Increase the quality of your referrals, improve job satisfaction, and eliminate errors by using an electronic patients referral system. Starts at $49 a month."
      />
      <AppContainer>
        <Switch>
          <Route exact path="/error">
            <CrashPage />
          </Route>

          <Route exact path="/">
            <Redirect from="/" to="/login" exact />
          </Route>

          <Route path={['/register/:email', '/register']}>
            <RegistrationPage />
          </Route>

          <Route path="/login">
            <LoginPage />
          </Route>

          <Route path="/forgot">
            <ForgottenPasswordPage />
          </Route>

          <Route path="/recover/:resetToken">
            <AccountRecoveryPage />
          </Route>

          <Route
            path={`/(notifications|refer|referrals|sent|received|settings${
              isFaxUser || isFaxUser === undefined ? '|fax' : ''
            }${
              isReportsUser || isReportsUser === undefined ? '|reports' : ''
            })`}>
            {isLoggedIn ? (
              <SidebarContainer>
                <Sidebar
                  showFaxing={isFaxUser === undefined ? false : isFaxUser}
                  showReports={
                    isReportsUser === undefined ? false : isReportsUser
                  }
                />
                <PageContent>
                  {acceptTerms !== undefined && acceptTerms && (
                    <Switch>
                      <Route path="/notifications">
                        <NotificationsPage />
                      </Route>

                      <Route path="/refer">
                        <ReferralPage />
                      </Route>

                      <Route path="/sent">
                        <SentPage />
                      </Route>

                      <Route path="/received">
                        <ReceivedPage />
                      </Route>

                      <Route path="/referrals/:id">
                        <ReferralViewPage />
                      </Route>

                      <Route path="/settings/practices/:practiceId/members">
                        <AddMembersPage />
                      </Route>

                      <Route path="/settings/practices/new">
                        <PracticeCreationPage />
                      </Route>

                      <Route path="/settings/practices/:practiceId/:invitationId?">
                        <PracticeManagementPage />
                      </Route>

                      <Route path="/settings/contacts/:practiceId/">
                        <FaxContactsPage />
                      </Route>

                      <Route path="/settings/account-information">
                        <AccountSettingsPage />
                      </Route>

                      <Route path="/settings/emergency-access-kit">
                        <EmergencyAccessKitPage />
                      </Route>

                      <Route path="/settings">
                        <SettingsPage />
                      </Route>

                      {isReportsUser && (
                        <Route path="/reports">
                          <ReportsPage />
                        </Route>
                      )}

                      {isFaxUser && (
                        <>
                          <Route path="/fax/create">
                            <FaxPage />
                          </Route>

                          <Route exact path="/fax/sent">
                            <Redirect
                              from="/fax/sent"
                              to="/fax/sent/active"
                              exact
                            />
                          </Route>

                          <Route path="/fax/sent/active">
                            <SentFaxesPage />
                          </Route>

                          <Route path="/fax/sent/archived">
                            <SentFaxesPage status={'onlyArchived'} />
                          </Route>

                          <Route exact path="/fax/received">
                            <Redirect
                              from="/fax/received"
                              to="/fax/received/active"
                              exact
                            />
                          </Route>

                          <Route path="/fax/received/active">
                            <ReceivedFaxesPage />
                          </Route>

                          <Route path="/fax/received/archived">
                            <ReceivedFaxesPage status={'onlyArchived'} />
                          </Route>
                        </>
                      )}
                    </Switch>
                  )}
                  {acceptTerms !== undefined && !acceptTerms && (
                    <LandingContainer>
                      <UpdatedTermsLanding />
                    </LandingContainer>
                  )}

                  <FeedbackButton />
                </PageContent>
              </SidebarContainer>
            ) : (
              <Redirect
                to={'/logout?dest=' + location.pathname + location.search}
              />
            )}
          </Route>

          <Route path="/logout">
            <LogoutPage />
          </Route>

          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </AppContainer>
    </>
  )
}

export default App
