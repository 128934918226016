import {
  decryptObject,
  deriveAesKey,
  unwrapEccKey,
  unwrapAesKey,
  importPublicKey
} from '../crypto'

async function getDecryptedReferral(referral, currentUser, account, isSender) {
  const participant = isSender ? referral.sender : referral.recipient
  const practice = participant.practice
  const membership = practice.memberships.find(m => m.user.id === currentUser)

  // Ignore ones we can't decrypt
  if (!membership) return null

  const membershipAesKey = await deriveAesKey(
    account.privateKey,
    await importPublicKey(membership.publicKey)
  )

  const practicePrivateKey = await unwrapEccKey(
    membership.privateKey.encryptedData,
    membershipAesKey,
    membership.privateKey.iv,
    true
  )

  const referralAesKey = await deriveAesKey(
    practicePrivateKey,
    await importPublicKey(participant.publicKey)
  )

  const encryptionKey = await unwrapAesKey(
    participant.encryptionKey.encryptedData,
    referralAesKey,
    participant.encryptionKey.iv,
    'enc'
  )

  const encryptedData = await decryptObject(
    referral.encryptedData.encryptedData,
    encryptionKey,
    referral.encryptedData.iv
  )

  return { ...encryptedData }
}

export { getDecryptedReferral }
