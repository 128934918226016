import { Select, Checkbox, MenuItem } from '@material-ui/core'
import styled from 'styled-components'

const SelectMultiple = styled(Select)`
  &.MuiInputBase-root {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3a3a3a;
  }

  & .MuiSelect-outlined {
    padding-right: 35px;
  }

  & .MuiSelect-iconOutlined {
    right: 10px;
  }

  &.MuiOutlinedInput-root {
    border-radius: 3px;
    border: 1px solid rgba(58, 58, 58, 0.35);
    background-color: #f9fbfe;

    &.Mui-focused {
      border-color: #1193ad;
    }
    &:hover:not(.Mui-disabled) {
      border-color: #1193ad;
    }

    & fieldset {
      border: none;
    }
  }
`

const SelectMenuItem = styled(MenuItem)`
  &.MuiListItem-root {
    &:hover {
      background-color: rgba(6, 153, 135, 0.05);
    }
    &.Mui-selected {
      background-color: rgba(6, 153, 135, 0.15);
    }
  }

  .MuiTypography-body1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3a3a3a;
  }
`

const SelectCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: #1193ad;
  }
`

export { SelectMultiple, SelectMenuItem, SelectCheckbox }
