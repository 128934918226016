import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Chart from './Chart'
import PivotTable from './PivotTable'
import { SectionTitle } from '../common/Title'
import { filterDataBySpecialistCategory } from '../../util/filterData'

const ReportVisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Bar = styled.div`
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
`

const EmptyText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgba(58, 58, 58, 0.7);
  padding-top: 30px;
`

function ReferralSummary({ receivedReferrals, filters = null }) {
  const filteredReferrals = filters
    ? filterDataBySpecialistCategory(receivedReferrals, 'referrals', filters)
    : receivedReferrals

  if (filteredReferrals.length === 0) {
    return (
      <EmptyText>There is no data to show in the selected date range</EmptyText>
    )
  }

  const categoriesLength = [...new Set(filteredReferrals.map(r => r.category))]
    .length

  let height = 625

  if (categoriesLength < 6) {
    height = 450
  } else if (categoriesLength > 10) {
    height = 800
  }

  return (
    <ReportVisualsContainer>
      <SectionTitle>Referral Summary</SectionTitle>
      <Bar />
      <Chart
        type="pie"
        title="Total Referrals by Doctor"
        dataSourceSettings={{
          dataSource: filteredReferrals,
          rows: [{ name: 'to' }],
          columns: [{}],
          values: [{ name: 'to' }]
        }}
        downloadable={true}
      />
      <PivotTable
        title="Referral Counts by Doctor and Category"
        dataSourceSettings={{
          dataSource: filteredReferrals,
          columns: [{ name: 'to' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          values: [{ name: 'to' }]
        }}
        visibleRows={15}
        downloadable={true}
        expandable={true}
      />
      <Chart
        type="stackingBar100"
        title="Distribution of Referrals by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredReferrals,
          sortSettings: [{ name: 'category', order: 'Descending' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }],
          columns: [{ name: 'to' }],
          values: [{ name: 'category' }]
        }}
        customSettings={{ height }}
        expandable={true}
        downloadable={true}
      />
      <PivotTable
        title="Referrals by Doctor and Category"
        dataSourceSettings={{
          dataSource: filteredReferrals,
          columns: [{ name: 'isDirected' }],
          rows: [{ name: 'to' }, { name: 'category' }, { name: 'subcategory' }],
          values: [{ name: 'category' }]
        }}
        visibleRows={15}
        downloadable={true}
        expandable={true}
      />
      <PivotTable
        title="Referrals by Category and Doctor"
        dataSourceSettings={{
          dataSource: filteredReferrals,
          columns: [{ name: 'isDirected' }],
          rows: [{ name: 'category' }, { name: 'subcategory' }, { name: 'to' }],
          values: [{ name: 'to' }]
        }}
        visibleRows={15}
        downloadable={true}
        expandable={true}
      />
    </ReportVisualsContainer>
  )
}

ReferralSummary.propTypes = {
  receivedReferrals: PropTypes.array.isRequired,
  filters: PropTypes.object
}

export default ReferralSummary
