import wordlist from './wordlist'

/*
 * Mnemonic <-> Key functions originally from
 * https://github.com/bitcoinjs/bip39/blob/master/src/index.js, with some
 * modifications for the browser and our specific use-case
 */

function convertBinaryToNumber(bin) {
  // Parses a binary-formatted string into a number
  return parseInt(bin, 2)
}

function convertNumbersToBinary(numbers) {
  // Converts an array of numbers into a binary-formatted string
  return numbers.map(x => x.toString(2).padStart(8, '0')).join('')
}

async function deriveChecksumBinary(buffer) {
  const checksumLength = (buffer.length * 8) / 32
  const hash = await window.crypto.subtle.digest('SHA-256', buffer)

  return convertNumbersToBinary(Array.from(new Uint8Array(hash))).slice(
    0,
    checksumLength
  )
}

export async function convertBufferToMnemonic(buffer) {
  // Encodes a 256-bit buffer into a 24 word phrase
  if (buffer.length * 8 !== 256) {
    throw new Error('Expected a 256-bit buffer')
  }

  const value = convertNumbersToBinary(Array.from(buffer))
  const checksum = await deriveChecksumBinary(buffer)
  const binary = value + checksum
  const binaryChunks = binary.match(/(.{1,11})/g)
  const words = binaryChunks.map(binary => {
    const index = convertBinaryToNumber(binary)
    return wordlist[index]
  })

  return words
}

export async function convertMnemonicToBuffer(words) {
  // Tries to decode a list of 24 words into a 256-bit buffer
  if (words.length !== 24) {
    throw new Error('Expected 24 words')
  }

  words = words.map(w => w.toLowerCase())

  // convert word indices to 11 bit binary strings
  const binary = words
    .map(word => {
      const index = wordlist.indexOf(word)
      if (index === -1) {
        throw new Error('Phrase contains invalid word')
      }
      return index.toString(2).padStart(11, '0')
    })
    .join('')
  // split the binary string into key/checksum
  const dividerIndex = Math.floor(binary.length / 33) * 32
  const value = binary.slice(0, dividerIndex)
  const checksum = binary.slice(dividerIndex)
  // calculate the checksum and compare
  const numbers = value.match(/(.{1,8})/g).map(convertBinaryToNumber)
  const buffer = new Uint8Array(numbers)
  const newChecksum = await deriveChecksumBinary(buffer)
  if (newChecksum !== checksum) {
    throw new Error('Invalid checksum')
  }

  return buffer
}
