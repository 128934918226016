import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  Paper,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Typography
} from '@material-ui/core'
import styled from 'styled-components'
import prettyBytes from 'pretty-bytes'
import DeleteIcon from 'mdi-react/DeleteIcon'

import { getFileTypeIcon } from '../../util/files'

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    margin: 10px 0 0;
  }
`

const useStyles = makeStyles(theme => ({
  filename: {
    color: '#3a3a3a'
  },
  totalSize: {
    marginLeft: theme.spacing() * 2,
    color: 'rgba(58, 58, 58, 0.7)'
  }
}))

const FileList = ({ files = [], onDelete }) => {
  const classes = useStyles()

  const totalSize = files
    .map(file => file.size || 0)
    .reduce((sum, size) => sum + size, 0)

  if (files.length === 0) {
    return null
  }

  return (
    <StyledPaper>
      <List dense>
        {files.map((file, i) => {
          return (
            <ListItem key={`${file.name}_${i}`} aria-label="uploaded files">
              <ListItemIcon>{getFileTypeIcon(file.name)}</ListItemIcon>
              <ListItemText
                className={classes.filename}
                primary={file.name}
                secondary={prettyBytes(file.size)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDelete(i, file)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
      <Divider />
      <Typography variant="subtitle2" className={classes.totalSize}>
        Total size: {prettyBytes(totalSize)}
      </Typography>
    </StyledPaper>
  )
}

FileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.instanceOf(File).isRequired),
  onDelete: PropTypes.func.isRequired
}

export default FileList
