import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Hero from '../common/Hero'
import { useParams, NavLink } from 'react-router-dom'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import Loading from '../common/Loading.js'
import LoadingError from '../common/LoadingError'
import MnemonicForm from '../recovery/MnemonicForm'
import PasswordResetForm from '../recovery/PasswordResetForm'

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
`

const GET_BACKUP_KEY = gql`
  query getBackupKey($resetToken: String!) {
    getBackupKey(resetToken: $resetToken) {
      iv
      encryptedData
    }
  }
`

const ErrorText = styled.h3`
  color: #393e46;
  font-weight: 500;
  margin: 10px;
  display: flex;
  align-items: center;
  width: 700px;
  padding-right: 200px;

  a {
    color: #3598db;
    text-decoration: none;
  }
`

function AccountRecoveryPage() {
  const { resetToken } = useParams()
  const { loading, error, data } = useQuery(GET_BACKUP_KEY, {
    variables: { resetToken }
  })
  const [step, setStep] = useState(0)
  const [keys, setKeys] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  function handleBack() {
    setStep(1)
    setKeys({})
  }

  useEffect(() => {
    if (step === 0 && !!data) {
      setStep(1)
    }

    if (step === 1 && keys.privateKey && keys.recoveryKey) {
      setStep(2)
    }
  }, [data, keys, step])

  useEffect(() => {
    setErrorMessage('')
    if (error) {
      if (error.message.includes('Cannot find a user with this reset token')) {
        setErrorMessage('Hm, we cannot find your account.')
      } else if (error.message.includes('This reset token has expired')) {
        setErrorMessage('This recovery key has expired.')
      }
    }
  }, [error])

  return (
    <Container>
      <Hero />
      {loading && <Loading />}
      {errorMessage && (
        <ErrorText>
          <p>
            {errorMessage}
            <br />
            <br />
            Please <NavLink to="/forgot">request a new recovery email.</NavLink>
            <br />
            <br />
            Remember your password? <NavLink to="/login">Login.</NavLink>
          </p>
        </ErrorText>
      )}
      {error && !errorMessage && <LoadingError />}
      {step === 1 && (
        <MnemonicForm
          onSuccess={({ privateKey, recoveryKey }) => {
            setKeys({ privateKey, recoveryKey })
          }}
          backupKey={data.getBackupKey}
        />
      )}
      {step === 2 && (
        <PasswordResetForm
          privateKey={keys.privateKey}
          recoveryKey={keys.recoveryKey}
          resetToken={resetToken}
          handleBack={handleBack}
        />
      )}
    </Container>
  )
}

export default AccountRecoveryPage
