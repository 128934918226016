import specialties from '../specialties.json'
import regions from '../regions.json'

const categoriesMap = {}
const specialtyNamesMap = {}

for (const specialty of specialties) {
  specialtyNamesMap[specialty.id] = specialty.name
  for (const category of specialty.categories) {
    for (const subcategory of category.subcategories) {
      const mapValue = {
        subcategoryName: subcategory.name,
        categoryName: category.name,
        subcategoryId: subcategory.id,
        categoryId: category.id,
        subcategoryMessage: subcategory.message,
        subcategoryReferralType: subcategory.referralType,
        categoryMessage: category.message,
        categoryReferralType: category.referralType,
        specialtyName: specialty.name,
        specialtyId: specialty.id,
        region: specialty.region
      }

      categoriesMap[subcategory.id] = mapValue
    }

    categoriesMap[category.id] = {
      categoryName: category.name,
      categoryId: category.id,
      subcategories: category.subcategories,
      specialtyName: specialty.name,
      specialtyId: specialty.id,
      categoryMessage: category.message,
      categoryReferralType: category.referralType,
      region: specialty.region
    }
  }
}

const getMap = id => categoriesMap[id] ?? {}
const getSpecialtyId = id => getMap(id).specialtyId
const getCategoryId = id => getMap(id).categoryId
const getSubcategoryId = id => getMap(id).subcategoryId
const getCategoryName = id => getMap(id).categoryName ?? 'N/A'
const getCategoryMessage = id => getMap(id).categoryMessage ?? ''
const getCategoryReferralType = id => getMap(id).categoryReferralType ?? 'N/A'
const getSubcategoryName = id => getMap(id).subcategoryName ?? 'N/A'
const getSubcategoryMessage = id => getMap(id).subcategoryMessage ?? ''
const getSubcategoryReferralType = id =>
  getMap(id).subcategoryReferralType ?? 'N/A'
const getSpecialtyName = id => specialtyNamesMap[id]
const getSpecialtyByCategoryId = id => getMap(id).specialtyName ?? 'N/A'
const getRegionNameByCategoryId = id =>
  regions.find(r => r.id === getMap(id).region).name ?? 'N/A'

const getAllSpecialtyIds = () =>
  Object.entries(specialtyNamesMap).map(s => s[0])
const getAllCategories = () =>
  Object.entries(categoriesMap)
    .filter(c => !c[1].subcategoryName)
    .map(c => {
      return { value: c[0], label: c[1].categoryName }
    })
const getAllSubcategories = () =>
  Object.entries(categoriesMap)
    .filter(c => c[1].subcategoryName)
    .map(c => {
      return { value: c[0], label: c[1].subcategoryName }
    })

function getAllLeafCategoryIds(id) {
  const categories = Object.entries(categoriesMap)
    .filter(
      c =>
        !c[1].subcategoryName &&
        c[1].specialtyId === id &&
        c[1].subcategories.length === 0
    )
    .map(c => c[1].categoryId)

  const subcategories = Object.entries(categoriesMap)
    .filter(c => c[1].subcategoryName && c[1].specialtyId === id)
    .map(c => c[1].subcategoryId)

  const allCategories = [...categories, ...subcategories]

  return allCategories
}

function getSubcategoriesString(categoryId) {
  const subcategories = getMap(categoryId).subcategories.map(c => c.id)
  return subcategories.join(':')
}

function doesCategoryHaveSubcategories(categoryId) {
  return getMap(categoryId).subcategories?.length > 0
}

function getFactorCategoryName(category) {
  if (category === null) {
    return 'Overall'
  } else if (getSubcategoryName(category) === 'N/A') {
    return getCategoryName(category)
  } else {
    return getCategoryName(category) + ' - ' + getSubcategoryName(category)
  }
}

function getCategoriesFromSpecialty(specialty) {
  if (!Array.isArray(specialty?.categories)) return []

  const determineCategories = (subcategories, category) => {
    const theseCategories = subcategories.map(subcategory => ({
      id: subcategory.id,
      name: `${category.name} - ${subcategory.name}`,
      category
    }))

    return theseCategories
  }

  return specialty.categories.reduce(
    // Flatten list so subcategories appears as `category name - subcategory name`
    (categories, { subcategories, ...category }) => {
      const theseCategories = determineCategories(subcategories, category)
      return theseCategories.length === 0
        ? [...categories, category]
        : [...categories, ...theseCategories]
    },
    []
  )
}

function getLeafCategoriesForSpecialties(specialties) {
  const leafCategories = []

  for (const specialty of specialties) {
    const currCategories = getAllLeafCategoryIds(specialty).map(c => {
      return {
        category: getFactorCategoryName(c),
        categoryId: c
      }
    })

    leafCategories.push(...currCategories)
  }

  return leafCategories
}

export {
  getSpecialtyId,
  getCategoryId,
  getSubcategoryId,
  getCategoryName,
  getSubcategoryName,
  getSpecialtyName,
  doesCategoryHaveSubcategories,
  getAllSpecialtyIds,
  getAllCategories,
  getAllSubcategories,
  getSubcategoriesString,
  getSpecialtyByCategoryId,
  getRegionNameByCategoryId,
  getFactorCategoryName,
  getCategoriesFromSpecialty,
  getSubcategoryMessage,
  getSubcategoryReferralType,
  getCategoryMessage,
  getCategoryReferralType,
  getAllLeafCategoryIds,
  getLeafCategoriesForSpecialties
}
