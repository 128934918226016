import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Tooltip } from '@material-ui/core'
import gql from 'graphql-tag'

import RefreshIcon from 'mdi-react/RefreshIcon'

import { SecondaryButton, IconButton } from '../common/Button'
import styled from 'styled-components'

const IconButtonContainer = styled.div`
  max-width: 35px;
`

const IconWrapper = styled.div`
  height: 34px;
`

const ErrorText = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  color: #e84c3d;
  margin: 0;
  padding-top: 5px;
`

const RESEND_FAX = gql`
  mutation resendFax($fax: ID!) {
    resendFax(fax: $fax) {
      id
      state
      faxId
      createdAt
    }
  }
`

const GET_ALERTS = gql`
  query getAlerts {
    getAlerts {
      sentReferrals
      receivedReferrals
      sentFaxes
    }
  }
`

function ResendFaxFromReferralButton({
  faxId,
  type = 'full',
  isSender = false,
  isRecipient = false,
  onResendFax = () => {}
}) {
  const [resendFax, { loading, error }] = useMutation(RESEND_FAX, {
    update(cache) {
      if (cache.data.data.ROOT_QUERY?.[`getAlerts`]) {
        const { getAlerts } = cache.readQuery({
          query: GET_ALERTS
        })

        const updatedData = {
          sentFaxes: getAlerts.sentFaxes,
          sentReferrals: isSender
            ? getAlerts.sentReferrals - 1
            : getAlerts.sentReferrals,
          receivedReferrals: isRecipient
            ? getAlerts.receivedReferrals - 1
            : getAlerts.receivedReferrals
        }

        cache.writeQuery({
          query: GET_ALERTS,
          data: {
            getAlerts: updatedData
          }
        })
      }
    }
  })

  async function onResend() {
    try {
      await resendFax({ variables: { fax: faxId } })
      onResendFax()
    } catch (e) {
      console.error('Error resending fax:', e.message)
    }
  }

  return (
    <>
      {type === 'full' && (
        <>
          <SecondaryButton
            color="secondary"
            padding="small"
            onClick={onResend}
            loading={loading}>
            <RefreshIcon />
            Resend
          </SecondaryButton>
          {error && (
            <ErrorText role="alert">
              Hm, an error occurred. Try again.
            </ErrorText>
          )}
        </>
      )}
      {type === 'icon' && (
        <IconButtonContainer>
          <Tooltip title="Resend fax" arrow>
            <IconWrapper>
              <IconButton
                color="secondary"
                onClick={onResend}
                loading={loading}
                aria-label="Resend fax">
                <RefreshIcon />
              </IconButton>
            </IconWrapper>
          </Tooltip>
          {error && <ErrorText role="alert">Error, try again.</ErrorText>}
        </IconButtonContainer>
      )}
    </>
  )
}

ResendFaxFromReferralButton.propTypes = {
  faxId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['icon', 'full']),
  isSender: PropTypes.bool,
  isRecipient: PropTypes.bool,
  onResendFax: PropTypes.func
}

export default ResendFaxFromReferralButton
