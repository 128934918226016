import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { MenuItem } from '@material-ui/core'

import { chartStyling } from '../common/ChartStyling'
import { SectionTitle } from '../common/Title'
import Select from '../common/Select'
import Chart from './Chart'
import PivotTable from './PivotTable'

const ChartSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ChartContainer = styled.div`
  display: flex;
  gap: 15px;
`

const Field = styled.div`
  padding-top: 15px;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    width: 200px;
  }
`

const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

function sortCategories(a, b) {
  if (a === 'All') return -1
  if (b === 'All') return 1
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function ProcedureBillingCodeCharts({ procedures }) {
  const [category, setCategory] = useState('All')
  const [categories, setCategories] = useState(['All'])
  const [filteredProcedures, setFilteredProcedures] = useState(procedures)

  useEffect(() => {
    if (procedures) {
      setCategory('All')

      const updatedCategories = [
        'All',
        ...new Set(
          procedures.map(j =>
            j.subcategory ? j.category + ' - ' + j.subcategory : j.category
          )
        )
      ].sort(sortCategories)
      setCategories(updatedCategories)

      setFilteredProcedures(procedures)
    }
  }, [procedures])

  const billingCodeLength = [
    ...new Set(filteredProcedures.map(j => j.billingCode))
  ].length
  const height =
    billingCodeLength < 6 ? 450 : billingCodeLength > 10 ? 800 : 625

  function onSelectCategory(event) {
    setCategory(event.target.value)

    const filtered =
      event.target.value === 'All'
        ? procedures
        : procedures.filter(
            j =>
              (j.subcategory
                ? j.category + ' - ' + j.subcategory
                : j.category) === event.target.value
          )

    setFilteredProcedures(filtered)
  }

  return (
    <ChartContainer>
      <ChartSettingsContainer>
        <SectionTitle>Chart Settings</SectionTitle>
        <Field>
          <StyledSelect
            select
            value={category}
            onChange={onSelectCategory}
            label="Category"
            aria-label="Category"
            InputLabelProps={{ shrink: true }}>
            {categories.map((value, i) => (
              <MenuItem key={i} value={value}>
                {value}
              </MenuItem>
            ))}
          </StyledSelect>
        </Field>
      </ChartSettingsContainer>
      <ChartWrapper>
        <Chart
          type="stackingBar"
          title={`Procedures by Billing Code: ${category}`}
          dataSourceSettings={{
            dataSource: filteredProcedures,
            sortSettings: [{ name: 'billingCode', order: 'Ascending' }],
            rows: [{ name: 'billingCode' }],
            columns: [{ name: 'to' }],
            values: [{ name: 'to', type: 'Count' }]
          }}
          customSettings={{ height }}
          customChartSettings={{
            primaryYAxis: {
              title: 'Number of Procedures',
              labelStyle: chartStyling.label,
              titleStyle: chartStyling.title
            }
          }}
          expandable={true}
          downloadable={true}
        />
        <PivotTable
          title={`Procedure Counts by Billing Code and Doctor: ${category}`}
          dataSourceSettings={{
            dataSource: filteredProcedures,
            columns: [{ name: 'to' }],
            rows: [{ name: 'billingCode' }],
            values: [{ name: 'to' }]
          }}
          visibleRows={15}
          downloadable={true}
          expandable={true}
        />
      </ChartWrapper>
    </ChartContainer>
  )
}

ProcedureBillingCodeCharts.propTypes = {
  procedures: PropTypes.array.isRequired
}

export default ProcedureBillingCodeCharts
