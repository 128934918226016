import React, { useState } from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import EmailPlusIcon from 'mdi-react/EmailPlusIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import MenuItem from '@material-ui/core/MenuItem'
import { useApolloClient, useMutation } from '@apollo/client'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useHistory, Link, useLocation, Redirect } from 'react-router-dom'

import { ErrorMessage } from '../common/UserAlert'
import Button, { SecondaryButton, IconButton } from '../common/Button'
import { PageTitle, SectionTitle } from '../common/Title'
import Select from '../common/Select'
import useAccount from '../../hooks/useAccount'
import { FieldLabel } from '../common/StyledField'
import {
  deriveAesKey,
  generateEccKeys,
  wrapSecretKey,
  unwrapEccKey,
  exportPublicKey,
  importPublicKey
} from '../../crypto'
import { getPracticeDisplayName } from '../../util/strings'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const ButtonContainer = styled.div`
  display: flex;
  button:last-child {
    margin-left: 15px;
  }
`

const ButtonErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 51px 0 30px;
`

const BlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: baseline;
`

const SelectorLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  color: #fff;
  padding-right: 18px;
  margin: 0;
  white-space: nowrap;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    margin-top: 0;
    width: 250px;
  }
`

const FormContainer = styled.div`
  background: #ffffff;
  border: 1px solid rgba(17, 147, 173, 0.05);
  box-shadow: 0px 5px 15px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  padding: 0 30px 30px;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const ErrorContainer = styled.div`
  margin-bottom: -15px;
`

const Emails = styled.div`
  padding: 27px 35px 0;
`

const EmailContainer = styled.div`
  display: flex;
  margin-top: 10px;
`

const EmailInput = styled(Select)`
  width: 250px;
  .MuiInputBase-root {
    margin: 0 15px 0 0;
    padding: 1px 0;
  }
`

const DeleteButton = styled(IconButton)`
  max-height: 38px;
  margin-right: 15px;
`

const InviteOthersButton = styled(SecondaryButton)`
  max-height: 38px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      id
      publicKey
    }
  }
`

const INVITE = gql`
  mutation invite($invitation: InvitationInput!) {
    invite(invitation: $invitation) {
      id
    }
  }
`

const ADD_TO_PRACTICE = gql`
  mutation addToPractice($practice: ID!, $membership: MembershipInput!) {
    addToPractice(practice: $practice, membership: $membership) {
      id
    }
  }
`

function AddMembersForm({ practices, initialPractice }) {
  const location = useLocation()
  const [addToPractice] = useMutation(ADD_TO_PRACTICE)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [invite] = useMutation(INVITE)
  const { getAccount } = useAccount()
  const client = useApolloClient()

  const firstPractice = practices[0]?.id
  const defaultPractice = initialPractice ?? firstPractice ?? ''

  const { register, control, watch, errors, handleSubmit } = useForm({
    defaultValues: {
      practice: defaultPractice,
      emails: [{ email: '' }]
    }
  })

  const { fields, append, remove } = useFieldArray({ control, name: 'emails' })
  const history = useHistory()

  const practice = watch('practice', defaultPractice)

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const required = 'This field is required'

  async function onSubmit({ emails }) {
    setLoading(true)
    setError(false)

    try {
      const account = await getAccount()
      for (const { email } of emails) {
        const result = await client.query({
          query: GET_USER_BY_EMAIL,
          variables: { email }
        })
        if (result.data.getUserByEmail === null) {
          const invitation = { email, practice }
          await invite({ variables: { invitation } })
        } else {
          // decrypt the practice private key
          const sourceMembership = practices
            .find(p => p.id === practice)
            .memberships.find(m => m.user.id === account.id)

          const sourceAesKey = await deriveAesKey(
            account.privateKey,
            await importPublicKey(sourceMembership.publicKey)
          )

          const privateKey = await unwrapEccKey(
            sourceMembership.privateKey.encryptedData,
            sourceAesKey,
            sourceMembership.privateKey.iv,
            true
          )

          // encrypt the practice private key with the user's public key
          const ephemeralKeys = await generateEccKeys()

          const { publicKey } = result.data.getUserByEmail

          const targetAesKey = await deriveAesKey(
            ephemeralKeys.privateKey,
            await importPublicKey(publicKey)
          )

          const { wrappedKey, iv } = await wrapSecretKey(
            privateKey,
            targetAesKey
          )

          const exportedEphemeralKey = await exportPublicKey(
            ephemeralKeys.publicKey
          )

          // post the new private key for that user
          const targetMembership = {
            user: result.data.getUserByEmail.id,
            privateKey: { encryptedData: wrappedKey, iv },
            publicKey: exportedEphemeralKey
          }

          await addToPractice({
            variables: { practice, membership: targetMembership }
          })
        }
        history.push(`/settings/practices/${practice}`, {
          unverifiedPractice: location.state?.unverifiedPractice
        })
      }
    } catch (e) {
      console.error('Request error occurred:', e)
      if (e.message.includes('Member already exists')) {
        setError('This user is already a part of your practice.')
      } else {
        setError('Hm, an error occurred. Try again.')
      }
    }
    setLoading(false)
  }

  if (practices.length === 0) return <Redirect to={'/settings'} />

  return (
    <Content>
      <form
        aria-labelledby="form-label"
        onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <Header>
          <PageTitle id="form-label">Add Members</PageTitle>
          <ButtonErrorContainer>
            <ButtonContainer>
              {location.search === '?offerSkip=true' ? (
                <StyledLink
                  to={{
                    pathname: '/refer',
                    state: {
                      unverifiedPractice: location.state?.unverifiedPractice
                    }
                  }}>
                  <SecondaryButton color="secondary" padding="small">
                    Skip
                  </SecondaryButton>
                </StyledLink>
              ) : (
                <SecondaryButton
                  color="secondary"
                  padding="small"
                  onClick={() =>
                    history.push(`/settings/practices/${practice}`)
                  }>
                  Cancel
                </SecondaryButton>
              )}
              <Button type="submit" loading={loading} padding="small">
                <AccountPlusIcon />
                Invite members
              </Button>
            </ButtonContainer>
            {error && (
              <ErrorContainer>
                <ErrorMessage>{error}</ErrorMessage>
              </ErrorContainer>
            )}
          </ButtonErrorContainer>
        </Header>
        <BlueBar>
          <SelectorLabel id="practice-label">Choose a practice</SelectorLabel>
          <Controller
            name="practice"
            control={control}
            render={({ onChange, value, name }) => (
              <StyledSelect
                select
                id="practice"
                aria-labelledby="practice-label"
                name={name}
                onChange={onChange}
                value={value}
                defaultValue={practices[0].id}>
                {practices.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {getPracticeDisplayName(p, 'long', 'long')}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
          />
        </BlueBar>
        <FormContainer>
          <SectionTitle>Add new members to practice</SectionTitle>
          <Divider />
          <Emails>
            <FieldLabel id="email-label">
              Who would you like to invite to{' '}
              {getPracticeDisplayName(
                practices.find(p => p.id === practice),
                'long',
                'long'
              )}
              &apos;s practice?
            </FieldLabel>
            {fields.map((field, index) => (
              <EmailContainer key={field.id}>
                <EmailInput
                  id={`emails.${index}.email`}
                  name={`emails.${index}.email`}
                  type="email"
                  error={!!errors.emails?.[index]?.email}
                  helperText={errors?.emails?.[index]?.email?.message}
                  placeholder="anna.smith@example.com"
                  inputProps={{
                    'aria-labelledby': 'email-label'
                  }}
                  inputRef={register({
                    pattern: { value: emailRegex, message: 'Invalid email' },
                    required
                  })}
                />
                {fields.length > 1 && (
                  <DeleteButton
                    color="secondary"
                    onClick={() => remove(index)}
                    aria-label="delete">
                    <CloseIcon />
                  </DeleteButton>
                )}
                {index === fields.length - 1 && (
                  <InviteOthersButton
                    color="secondary"
                    padding="small"
                    onClick={append}>
                    <EmailPlusIcon />
                    Invite others
                  </InviteOthersButton>
                )}
              </EmailContainer>
            ))}
          </Emails>
        </FormContainer>
      </form>
    </Content>
  )
}

AddMembersForm.propTypes = {
  practices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    })
  ).isRequired,
  initialPractice: PropTypes.string
}

export default AddMembersForm
