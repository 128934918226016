import React, { useState } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'

import ArrowRightThickIcon from 'mdi-react/ArrowRightThickIcon'
import InformationIcon from 'mdi-react/InformationIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'

import EmailIcon from 'mdi-react/EmailIcon'

import { SectionTitle } from '../common/Title'
import { ErrorMessage } from '../common/UserAlert'
import Button, { IconButton } from '../common/Button'
import ProgressDots from './ProgressDots'
import Select from '../common/Select'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 100px 30px 0;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
`

const BackButton = styled(IconButton)`
  padding: 4px;
  .mdi-icon {
    height: 25px;
    width: 25px;
  }
`

const Title = styled(SectionTitle)`
  color: #3a3a3a;
  margin: 0;
`

const Form = styled.form`
  width: 400px;
`

const StyledSelect = styled(Select)`
  .MuiInputBase-root .MuiInputBase-input {
    padding: 13px 15px 11px;
  }
`

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const InputIcon = styled(InputAdornment)`
  min-height: 15px;
  margin: 13px 0 12px 15px;
  .mdi-icon {
    color: #3a3a3a;
    max-width: 20px;
  }
`

const InfoAlert = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: #3a3a3a;
  margin: 5px 4px;
`

const ButtonWrapper = styled.div`
  padding-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`

const Placeholder = styled.div`
  width: 35px;
`

const SuccessMessage = styled.p`
  text-align: center;
`

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`

function ForgottenPasswordForm() {
  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET
  )
  const [error, setError] = useState('')
  const [complete, setComplete] = useState(false)
  const { register, errors, handleSubmit } = useForm()

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const required = 'This field is required'

  async function onSubmit({ email }) {
    setError(false)
    try {
      await requestPasswordReset({
        variables: { email }
      })
      setComplete(true)
    } catch (e) {
      console.error('Request error occurred:', e)
      if (e.message.includes('no user associated with this email.')) {
        setError(`There is no account associated with ${email}.`)
      } else {
        setError('Hm, an error occurred. Try again.')
      }
    }
  }

  if (complete) {
    return (
      <Container>
        <TitleContainer>
          <BackButton color="secondary" onClick={() => setComplete(false)}>
            <ChevronLeftIcon />
          </BackButton>
          <Title id="form-title">Forgotten Password</Title>
          <Placeholder />
        </TitleContainer>
        <SuccessMessage>
          Please check your email for a link to recover your password.
        </SuccessMessage>
        <ProgressDots currentStep={2} steps={4} />
      </Container>
    )
  }

  return (
    <Container>
      <TitleContainer>
        <Link to="/login">
          <BackButton color="secondary">
            <ChevronLeftIcon />
          </BackButton>
        </Link>
        <Title id="form-title">Forgotten Password</Title>
        <Placeholder />
      </TitleContainer>
      <Form
        aria-labelledby="form-title"
        onSubmit={handleSubmit(onSubmit)}
        noValidate>
        {error && <ErrorMessage severity="error">{error}</ErrorMessage>}
        <StyledSelect
          id="email"
          type="email"
          name="email"
          label="Email"
          error={!!errors.email}
          helperText={errors?.email?.message}
          inputRef={register({
            pattern: { value: emailRegex, message: 'Invalid email' },
            required
          })}
          InputProps={{
            startAdornment: (
              <InputIcon position="start">
                <EmailIcon size={22} />
              </InputIcon>
            )
          }}
          InputLabelProps={{ required: true }}
          placeholder="Enter email"
          fullWidth
        />
        <AlertContainer>
          <InformationIcon size={13} style={{ color: '#4ca1d8' }} />
          <InfoAlert>
            Please enter the email associated with your account
          </InfoAlert>
        </AlertContainer>
        <ButtonWrapper>
          <Button type="submit" padding="small" loading={loading}>
            <ArrowRightThickIcon />
            Continue
          </Button>
        </ButtonWrapper>
      </Form>
      <ProgressDots currentStep={1} steps={4}></ProgressDots>
    </Container>
  )
}

export default ForgottenPasswordForm
