import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import { getPracticeType, getPracticeDisplayName } from '../../util/strings'

import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import AccountEditIcon from 'mdi-react/AccountEditIcon'
import AccountCogIcon from 'mdi-react/AccountCogIcon'
import AccountIcon from 'mdi-react/AccountIcon'
import ShieldKeyIcon from 'mdi-react/ShieldKeyIcon'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'

import { SectionTitle } from '../common/Title'
import { SecondaryButton } from '../common/Button'

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: rgba(58, 58, 58, 0.7);
  background: #ffffff;
  border: 1px solid rgba(17, 147, 173, 0.05);
  border-radius: 6px;
  box-shadow: 0px 5px 15px rgba(58, 58, 58, 0.15);
`

const PracticesContainer = styled(Container)`
  width: 70%;
  min-height: 430px;
  padding: 18px 30px 30px;
`

const LinkCards = styled(Container)`
  min-height: 200px;
  padding: 14px 30px 30px;
  :hover {
    cursor: pointer;
  }
`

const LinkCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 30px;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const Title = styled(SectionTitle)`
  margin: 0;
`

const DetailsText = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0;
`

const LinkButton = styled(Link)`
  text-decoration: none;
  margin-left: 30px;
`

const StyledAccountCogIcon = styled(AccountCogIcon)`
  height: 40px;
  width: 40px;
  color: #f2641a;
  margin: 19px 0 17px;
`

const StyledShieldKeyIcon = styled(ShieldKeyIcon)`
  height: 33px;
  width: 33px;
  color: #f2641a;
  margin: 25px 0 19px;
`

const ArrowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PracticeList = styled.div`
  margin-top: 16px;
`

const PracticeType = styled.p`
  font-style: italic;
  font-size: 12px;
  line-height: 20px;
  margin: 0;
`

const PlaceHolder = styled.div`
  min-width: 154px;
`

const DoctorName = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #1193ad;
  margin: 0;
`

const PracticeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(58, 58, 58, 0.7);
  background: rgba(6, 153, 135, 0.03);
  padding: 15px 20px 15px 30px;
  width: 100%;

  border-top: 1px solid rgba(58, 58, 58, 0.05);

  display: flex;
  justify-content: space-between;
  align-items: center;

  :last-of-type {
    border-bottom: 1px solid rgba(58, 58, 58, 0.05);
  }

  :hover {
    cursor: pointer;
  }
`

function Settings({ practices }) {
  const history = useHistory()

  return (
    <Content>
      <PracticesContainer>
        <FlexContainer>
          <Title>Current Practices</Title>
          <div>
            <LinkButton to="/settings/practices/new">
              <SecondaryButton color="secondary" padding="small">
                <AccountEditIcon />
                Create new practice
              </SecondaryButton>
            </LinkButton>
          </div>
        </FlexContainer>
        <DetailsText>
          Create Practices, Update Practice Information, Add/Remove Members
        </DetailsText>
        <PracticeList>
          {practices.map(p => (
            <PracticeContainer key={p.id}>
              <StyledLink to={'/settings/practices/' + p.id}>
                <div>
                  <PracticeType>{getPracticeType(p.type)}</PracticeType>
                  <DoctorName>
                    {getPracticeDisplayName(p, 'short', 'med')}
                  </DoctorName>
                </div>
                <ChevronRightIcon size={30} />
              </StyledLink>
              {p.faxNumber ? (
                <LinkButton to={'/settings/contacts/' + p.id}>
                  <SecondaryButton color="secondary" padding="small">
                    <AccountIcon size={22} />
                    Contacts
                  </SecondaryButton>
                </LinkButton>
              ) : (
                <PlaceHolder />
              )}
            </PracticeContainer>
          ))}
        </PracticeList>
      </PracticesContainer>
      <LinkCardsContainer>
        <LinkCards
          onClick={() => history.push(`/settings/account-information`)}>
          <StyledAccountCogIcon />
          <Title>Account Information</Title>
          <DetailsText>Manage account information</DetailsText>
          <ArrowContainer>
            <ArrowRightIcon />
          </ArrowContainer>
        </LinkCards>
        <LinkCards
          onClick={() => history.push(`/settings/emergency-access-kit`)}>
          <StyledShieldKeyIcon />
          <Title>Emergency Access Kit</Title>
          <DetailsText>Create or update Emergency Access Kit</DetailsText>
          <ArrowContainer>
            <ArrowRightIcon />
          </ArrowContainer>
        </LinkCards>
      </LinkCardsContainer>
    </Content>
  )
}

Settings.propTypes = {
  practices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      faxNumber: PropTypes.string
    }).isRequired
  )
}

export default Settings
