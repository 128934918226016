import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Button, Menu, darken } from '@material-ui/core'
import { SecondaryButton } from '../common/Button'
import CaretDownIcon from 'mdi-react/CaretDownIcon'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { ErrorMessage, SuccessMessage } from '../common/UserAlert'

const MenuButton = styled(Button)`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-transform: capitalize;
  margin-right: 8px;
  padding: 9px 6px 9px 14px;
  border-radius: 3px;
  border: 1px solid rgba(58, 58, 58, 0.7);
  color: ${props => props.fg};
  background-color: ${props => props.bg};
  width: 250px;

  justify-content: space-between;

  &:hover {
    color: ${props => darken(props.fg, 0.1)};
    background-color: ${props => darken(props.bg, 0.1)};
  }
  svg {
    height: 18px;
  }
`

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 3px;
    padding: 0;
    width: 250px;
  }

  & .MuiList-root {
    padding: 0;
  }
`

const StyledMenuItem = styled(MenuItem)`
  color: ${props => props.fg};
  background-color: ${props => props.bg};

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  padding-left: 14px;
  padding-right: 14px;

  height: 36px;

  &:hover {
    color: ${props => darken(props.fg, 0.1)};
    background-color: ${props => darken(props.bg, 0.1)};
  }
`

const StyledArrowIcon = styled(CaretDownIcon)`
  margin: 0;
`

const CancelButton = styled(SecondaryButton)`
  background-color: #f9fbfe;
  margin: 0 15px 0 40px;
`

const urgencies = [
  {
    value: 'Urgent',
    label: 'Urgent',
    fg: '#611A15',
    bg: '#FDECEA'
  },
  {
    value: 'Semi-urgent',
    label: 'Semi-urgent',
    fg: '#663C00',
    bg: '#FFF3E0'
  },
  {
    value: 'Not Urgent',
    label: 'Not Urgent',
    fg: '#1E4620',
    bg: '#EDF7ED'
  }
]

const CHANGE_URGENCY = gql`
  mutation updateUrgency($urgency: String!, $referral: ID!) {
    updateUrgency(urgency: $urgency, referral: $referral) {
      id
      urgency
    }
  }
`

function ChangeUrgency({ initialUrgency, referralId }) {
  const [updateUrgency, { loading, error }] = useMutation(CHANGE_URGENCY)
  const [selectedUrgencyValue, setSelectedUrgency] = useState(initialUrgency)
  const [success, setSuccess] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setSelectedUrgency(initialUrgency)
  }, [initialUrgency])

  const closeMenu = () => setAnchorEl(null)
  const openMenu = e => setAnchorEl(e.currentTarget)

  async function handleSave() {
    try {
      await updateUrgency({
        variables: { referral: referralId, urgency: selectedUrgencyValue }
      })
      setSuccess(true)
    } catch (e) {
      console.error('Error changing urgency:', e)
    }
  }

  const isDirty = selectedUrgencyValue !== initialUrgency

  const selectedUrgency = urgencies.find(u => u.value === selectedUrgencyValue)

  return (
    <>
      <MenuButton
        disabled={loading}
        aria-controls="change-urgency"
        aria-haspopup="true"
        bg={selectedUrgency.bg}
        fg={selectedUrgency.fg}
        onClick={openMenu}>
        {selectedUrgency.label}
        <StyledArrowIcon />
      </MenuButton>
      <StyledMenu
        id="change-urgency"
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={closeMenu}
        open={Boolean(anchorEl)}>
        {urgencies
          .filter(u => u.value !== selectedUrgencyValue)
          .map(urgency => (
            <StyledMenuItem
              key={urgency.value}
              value={urgency.value}
              bg={urgency.bg}
              fg={urgency.fg}
              onClick={() => {
                setSuccess(false)
                setSelectedUrgency(urgency.value)
                closeMenu()
              }}>
              {urgency.label}
            </StyledMenuItem>
          ))}
      </StyledMenu>
      {isDirty && (
        <>
          <CancelButton
            color="secondary"
            padding="small"
            onClick={() => setSelectedUrgency(initialUrgency)}
            disabled={loading}>
            <CloseIcon />
            Cancel
          </CancelButton>
          <SecondaryButton
            color="secondary"
            padding="medium"
            loading={loading}
            onClick={handleSave}
            disabled={loading}>
            <ContentSaveIcon />
            Save
          </SecondaryButton>
        </>
      )}
      {success && <SuccessMessage>Urgency has been changed</SuccessMessage>}
      {error && <ErrorMessage>Hm, an error occurred. Try again.</ErrorMessage>}
    </>
  )
}

ChangeUrgency.propTypes = {
  initialUrgency: PropTypes.oneOf(urgencies.map(u => u.label)).isRequired,
  referralId: PropTypes.string.isRequired
}

export default ChangeUrgency
