import React, { useState } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import useAccount from '../../hooks/useAccount'
import {
  deriveAccountKeys,
  generateEccKeys,
  wrapSecretKey,
  exportPublicKey,
  generateAesKey,
  exportAesKey,
  convertBase64ToBuffer
} from '../../crypto'
import PrinterIcon from 'mdi-react/PrinterIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import TermsOfUse from '../registration/TermsOfUse'
import Button, { LinkButton, IconButton } from '../common/Button'
import UserDataForm from './UserDataForm'
import Mnemonic from '../recovery/Mnemonic'
import EmergencyAccessKitPrintView from '../print/EmergencyAccessKitPrintView'
import Loading from '../common/Loading'
import { convertBufferToMnemonic } from '../../mnemonic'
import ProgressDots from '../login/ProgressDots'
import usePrinter from '../../hooks/usePrinter'
import { SectionTitle } from '../common/Title'
import { ErrorMessage } from '../common/UserAlert'

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 100px 30px 0;
  min-height: 720px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
  width: 100%;
`

const BackButton = styled(IconButton)`
  padding: 4px;
  .mdi-icon {
    height: 25px;
    width: 25px;
  }
`

const Placeholder = styled.div`
  width: 35px;
`

const Title = styled(SectionTitle)`
  color: #3a3a3a;
  margin: 0;
`

const DescriptionText = styled.p`
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
  margin: 0;
`

const LinkContainer = styled(DescriptionText)`
  margin-bottom: 30px;
`

const StyledLink = styled.a`
  font-weight: 600;
  text-decoration: none;
  color: #3598db;
  :hover {
    background: #fff;
    opacity: 0.6;
    text-decoration: underline solid #3598db;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  button:last-child {
    margin-left: 30px;
  }
`

const Bold = styled.span`
  font-weight: 600;
`

const REGISTER = gql`
  mutation register($user: UserInput!) {
    register(user: $user) {
      authToken
    }
  }
`

function RegistrationForm() {
  const [userData, setUserData] = useState()
  const [terms, setTerms] = useState()
  const [recoveryKey, setRecoveryKey] = useState()
  const [mnemonic, setMnemonic] = useState()
  const print = usePrinter({ documentTitle: 'Clinnect Recovery Words' })

  const [error, setError] = useState()

  const [currentStep, setCurrentStep] = useState(1)
  const setNextStep = () => setCurrentStep(step => step + 1)
  const setBackStep = () => setCurrentStep(step => step - 1)

  const [mutate, { loading }] = useMutation(REGISTER)
  const { setAccount } = useAccount()
  const history = useHistory()

  function printEmergencyAccessKit() {
    print(
      <EmergencyAccessKitPrintView
        mnemonic={mnemonic}
        email={userData.email}
        date={new Date()}
      />
    )
  }

  function handleUserDataSubmit(userData) {
    setUserData(userData)

    setNextStep()
  }

  async function handleTermsSubmit(terms) {
    setTerms(terms)

    const recoveryKey = await generateAesKey('wrap')
    const exportedKey = await exportAesKey(recoveryKey)
    const keyBuffer = convertBase64ToBuffer(exportedKey)

    setRecoveryKey(recoveryKey)
    setMnemonic(await convertBufferToMnemonic(keyBuffer))

    setNextStep()
  }

  async function handleEmergencyAccessKitSubmit() {
    const { firstName, lastName, email, password } = userData

    const { encryptionKey, accountToken, salt } = await deriveAccountKeys(
      password
    )

    const { publicKey, privateKey } = await generateEccKeys()
    const { wrappedKey, iv } = await wrapSecretKey(privateKey, encryptionKey)
    const exportedPublicKey = await exportPublicKey(publicKey)

    const { wrappedKey: backupKey, iv: backupIv } = await wrapSecretKey(
      privateKey,
      recoveryKey
    )

    const { wrappedKey: wrappedRecoveryKey, iv: recoveryIv } =
      await wrapSecretKey(recoveryKey, encryptionKey)

    const user = {
      publicKey: exportedPublicKey,
      privateKey: { encryptedData: wrappedKey, iv },
      firstName,
      lastName,
      accountToken,
      email,
      salt,
      isSubscriber: terms.isSubscriber,
      backupKey: {
        encryptedData: backupKey,
        iv: backupIv
      },
      recoveryKey: {
        encryptedData: wrappedRecoveryKey,
        iv: recoveryIv
      }
    }

    try {
      const result = await mutate({ variables: { user } })
      const { authToken } = result.data.register
      setAccount(authToken, publicKey, privateKey)
      history.push('/refer')
    } catch (e) {
      if (e.message.includes('There is already a user with this email')) {
        setError('That email is already in use.')
      } else {
        setError('Hm, an error occurred. Try again.')
      }
    }
  }

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }

  return (
    <Container>
      {currentStep === 1 && (
        <UserDataForm userData={userData} onSubmit={handleUserDataSubmit} />
      )}
      {currentStep === 2 && (
        <Content>
          <TermsOfUse
            terms={terms}
            onBack={() => setBackStep()}
            onSubmit={handleTermsSubmit}
          />
        </Content>
      )}
      {currentStep === 3 && (
        <Content>
          <TitleContainer>
            <BackButton color="secondary" onClick={() => setBackStep()}>
              <ChevronLeftIcon />
            </BackButton>
            <Title>Emergency Access Kit</Title>
            <Placeholder />
          </TitleContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <DescriptionText>
            Your data is securely encrypted using your password. If you forget
            your password, you will be required to input all of the words in
            your Emergency Access Kit to set a new password. If you lose your
            password and Emergency Acccess Kit, you will not be able to regain
            access to your account.{' '}
            <Bold>
              Remember to keep a printed copy of your Kit in a secure location.
            </Bold>
          </DescriptionText>
          <LinkContainer>
            <StyledLink
              href="https://clinnect.ca/emergency-access-kit-about/"
              target="_blank"
              rel="noopener noreferrer">
              Interested in how the Emergency Access Kit works?
            </StyledLink>
          </LinkContainer>
          <Mnemonic mnemonic={mnemonic} />
          <ButtonContainer>
            <Button onClick={printEmergencyAccessKit} padding="small">
              <PrinterIcon />
              Print Emergency Access Kit
            </Button>
            <LinkButton
              type="button"
              onClick={handleEmergencyAccessKitSubmit}
              color="blue">
              Create Account
            </LinkButton>
          </ButtonContainer>
        </Content>
      )}
      <ProgressDots steps={3} currentStep={currentStep} />
    </Container>
  )
}

export default RegistrationForm
