import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import styled from 'styled-components'
import * as yup from 'yup'
import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { FormControl } from '@material-ui/core'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'

import { sortFaxContactsByName } from '../../util/sort'
import { capitalize } from '../../util/strings'
import { ErrorMessage } from '../common/UserAlert'
import { FieldLabel } from '../common/StyledField'
import { SectionTitle } from '../common/Title'
import Button, { SecondaryButton } from '../common/Button'
import Select from '../common/Select'
import FaxContact from '../forms/FaxContact'

const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
  }
`

const RequiredFieldLabel = styled(FieldLabel)`
  :after {
    content: ' *';
    color: red;
  }
`

const SectionLabel = styled(SectionTitle)`
  margin-top: 18px;
`

const StyledDialogContent = styled(DialogContent)`
  padding: 12px 30px 30px;
`

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    padding-left: 15px;
    margin-top: 9px;
    height: 36px;
  }
  .MuiAutocomplete-endAdornment {
    right: 10px;
  }
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const LocationStyling = styled.p`
  color: rgba(58, 58, 58, 0.7);
  font-size: 14px;
  margin: 0px;
  font-style: italic;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button:last-child {
    margin-left: 10px;
  }
`

const faxRegex = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})/

const contactSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  faxNumber: yup
    .string()
    .matches(faxRegex, 'Fax Number must be valid')
    .required('Fax Number is required'),
  location: yup.string()
})

const CREATE_FAX_CONTACT = gql`
  mutation createFaxContact($faxContact: FaxContactInput!) {
    createFaxContact(faxContact: $faxContact) {
      id
      practice
      name
      location
      faxNumber
    }
  }
`

const GET_FAX_CONTACTS = gql`
  query getFaxContacts {
    getFaxContacts {
      id
      practice
      name
      location
      faxNumber
    }
  }
`

function FaxContactsDropdown({
  defaultContact = null,
  contacts,
  practice,
  onSelect,
  onClear,
  isRequired = true
}) {
  const [value, setValue] = useState(defaultContact)
  const [isVisible, setIsVisible] = useState(false)
  const isMounted = useRef(true)

  const filter = createFilterOptions()

  const methods = useForm({
    resolver: yupResolver(contactSchema),
    criteriaMode: 'all',
    defaultValues: { id: '', faxNumber: '', location: '' }
  })

  const { handleSubmit, reset } = methods

  const [createFaxContact, faxContactResult] = useMutation(CREATE_FAX_CONTACT, {
    update(cache, { data: { createFaxContact } }) {
      if (cache.data.data.ROOT_QUERY?.[`getFaxContacts`]) {
        const { getFaxContacts } = cache.readQuery({
          query: GET_FAX_CONTACTS
        })
        const data = [...getFaxContacts, createFaxContact].sort(
          sortFaxContactsByName
        )
        cache.writeQuery({
          query: GET_FAX_CONTACTS,
          data: { getFaxContacts: data }
        })
      }
    }
  })

  function handleClose() {
    if (!faxContactResult.loading) {
      setIsVisible(false)
      reset()
    }
  }

  async function onSubmit(values) {
    setIsVisible(true)
    try {
      const faxContact = {
        ...values,
        name: capitalize(values.name),
        practice
      }
      const result = await createFaxContact({ variables: { faxContact } })
      setValue(result.data.createFaxContact)

      if (isMounted.current) handleClose()
      onSelect(result.data.createFaxContact)
    } catch (e) {
      console.error('Error creating fax contact:', e)
    }
  }

  useEffect(() => {
    setValue(defaultContact)
  }, [defaultContact])

  return (
    <StyledFormControl>
      {isRequired ? (
        <RequiredFieldLabel id="contact-dropdown">Contact</RequiredFieldLabel>
      ) : (
        <FieldLabel id="contact-dropdown">Contact</FieldLabel>
      )}
      <StyledAutocomplete
        label="Contact"
        value={value}
        aria-labelledby="contact-dropdown"
        onChange={(event, newValue) => {
          if (newValue && newValue.inputValue) {
            setIsVisible(true)
            reset({ name: newValue.inputValue, faxNumber: '', location: '' })
          } else {
            setValue(newValue)
            if (!newValue) {
              onClear()
            } else {
              onSelect(newValue)
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Create Contact "${params.inputValue}"`
            })
          }
          return filtered
        }}
        id="contacts-list"
        options={contacts}
        noOptionsText="No Contacts"
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.name
        }}
        autoHighlight
        selectOnFocus
        clearOnBlur
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={(option, { selected }) => (
          <div key={option.id}>
            {option.name}
            <LocationStyling>{option.location}</LocationStyling>
          </div>
        )}
        renderInput={params => <Select {...params} />}
      />

      <Dialog fullWidth open={isVisible} onClose={handleClose}>
        <StyledDialogContent>
          <FormProvider {...methods}>
            <form aria-label="Contact form" onSubmit={handleSubmit(onSubmit)}>
              <SectionLabel>Create New Contact</SectionLabel>
              <Divider />
              {faxContactResult.error && (
                <ErrorMessage>Hm, an error occurred. Try again.</ErrorMessage>
              )}
              <FaxContact isEditing={true} newContact={true} />
              <ButtonContainer>
                <SecondaryButton
                  color="secondary"
                  padding="small"
                  onClick={handleClose}
                  disabled={faxContactResult.loading}>
                  Cancel
                </SecondaryButton>
                <Button
                  padding="small"
                  onClick={handleSubmit(onSubmit)}
                  loading={faxContactResult.loading}>
                  Create
                </Button>
              </ButtonContainer>
            </form>
          </FormProvider>
        </StyledDialogContent>
      </Dialog>
    </StyledFormControl>
  )
}

FaxContactsDropdown.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      practice: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      faxNumber: PropTypes.string.isRequired,
      location: PropTypes.string
    })
  ).isRequired,
  defaultContact: PropTypes.object,
  practice: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  isRequired: PropTypes.bool
}

export default FaxContactsDropdown
