import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
  MenuItem,
  List,
  ListItem,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core'
import { useMutation } from '@apollo/client'
import NumberFormat from 'react-number-format'

import CheckDecagramIcon from 'mdi-react/CheckDecagramIcon'
import AlertDecagramIcon from 'mdi-react/AlertDecagramIcon'
import AccountPlusIcon from 'mdi-react/AccountPlusIcon'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import EditIcon from 'mdi-react/PencilIcon'
import PhoneIcon from 'mdi-react/TelephoneIcon'
import EmailIcon from 'mdi-react/EmailIcon'

import {
  getPracticeType,
  getPracticeDisplayName,
  matchPostalCode
} from '../../util/strings'
import { FieldLabel, FieldValue } from '../common/StyledField'
import { PageTitle, SectionTitle } from '../common/Title'
import { ErrorMessage, InfoMessage, WarningMessage } from '../common/UserAlert'
import Button, { SecondaryButton, IconButton } from '../common/Button'
import Select from '../common/Select'
import CategoryAvailability from '../forms/CategoryAvailability'
import DeleteMembershipButton from './DeleteMembershipButton'
import provinces from '../../provinces.json'
import regions from '../../regions.json'
import specialties from '../../specialties.json'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const PracticeBlueBar = styled.div`
  background: #1193ad;
  box-shadow: 0px 5px 10px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: baseline;
  justify-content: center;
`

const PractictionerName = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;
`

const PracticeType = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #ffffff;
  margin: 0;
  padding-left: 4px;
  white-space: nowrap;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(17, 147, 173, 0.05);
  box-shadow: 0px 5px 15px rgba(58, 58, 58, 0.15);
  border-radius: 6px;
  color: rgba(58, 58, 58, 0.7);
`

const PracticeManagementContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`

const DetailsContainer = styled(Container)`
  width: 70%;
  padding: 0 30px 30px;
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 30px;
`

const Card = styled(Container)`
  padding: 0 30px 30px;
`

const DeleteInfoContainer = styled(Container)`
  padding: 30px 30px 15px;
`

const DeleteInfoText = styled.span`
  display: flex;
  font-weight: 400;
  color: rgba(58, 58, 58, 1);
  margin-bottom: 15px;
`
const EmailLink = styled.a`
  color: #3598db;
`

const StyledEmailIcon = styled(EmailIcon)`
  margin-right: 15px;
`

const StyledPhoneIcon = styled(PhoneIcon)`
  margin-right: 15px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: 35px;
`

const CategorySectionContent = styled(SectionContent)`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
`

const CategorySelectorContainer = styled.div`
  margin-top: ${props => (props.setmargin ? '15px' : '0px')};
`

const FormItem = styled.div`
  flex-basis: 50%;
  padding: 27px 30px 0 0;
`

const StyledSelect = styled(Select)`
  & .Mui-disabled {
    border: none;
  }
  .MuiInputBase-root.Mui-disabled {
    opacity: 1;
  }
`

const Bold = styled.span`
  font-weight: 700;
  margin: 0;
`

const TestingPracticeText = styled.div`
  color: #800;
  padding: 30px 30px 0 0;
`

const VerifiedText = styled.span`
  background: #2dcc70;
  height: 20px;
  width: 210px;
  margin-left: -8px;
  padding-left: 20px;
`

const NotVerifiedText = styled.span`
  background: #777777;
  height: 20px;
  width: 160px;
  margin-left: -8px;
  padding-left: 15px;
`

const VerificationWrapper = styled.div`
  padding-top: 30px;
`

const VerifiedIconTextContainer = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
`

const MembersTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 51px 0 30px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button:last-child {
    margin-left: 15px;
  }
`

const ErrorContainer = styled.div`
  margin-bottom: -15px;
`

const StyledList = styled(List)`
  margin-top: 30px;
  border-top: 1px solid rgba(58, 58, 58, 0.15);
`

const StyledListItem = styled(ListItem)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #3a3a3a;
  background-color: rgba(6, 153, 135, 0.03);
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  svg {
    color: #3a3a3a;
  }
`

const StyledInvitationListItem = styled(ListItem)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #3a3a3a;
  background-color: rgba(241, 196, 15, 0.15);
  border-bottom: 1px solid rgba(58, 58, 58, 0.15);
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const InviteText = styled.h3`
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  line-height: 15px;
  color: rgba(58, 58, 58, 0.7);
  margin: 0;
`

const StyledCheckDecagramIcon = styled(CheckDecagramIcon)`
  color: #2dcc70;
`

const StyledAlertDecagramIcon = styled(AlertDecagramIcon)`
  color: #777777;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(58, 58, 58, 0.15);
`

const StyledFieldValue = styled(FieldValue)`
  ${props =>
    props.disabled &&
    `
    color: rgba(0, 0, 0, 0.38);
    border: 1px solid rgba(58, 58, 58, 0.15);
    `}
`

const StyledRadioGroup = styled(RadioGroup)`
  margin: -10px 0 -6px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  margin-top: 4px;

  .MuiTypography-body1 {
    font-family: Source Sans Pro, sans-serif;
    color: #3a3a3a;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    white-space: nowrap;
    letter-spacing: 0;
  }
  .Mui-checked {
    color: rgba(17, 147, 173, 1);
  }
  span {
    display: flex;
    align-items: center;
  }
  .MuiTextField-root {
    min-width: 250px;
  }
  .MuiButtonBase-root.Mui-disabled {
    color: rgba(58, 58, 58, 0.3);
  }
`

const UPDATE_PRACTICE = gql`
  mutation updatePractice($practiceUpdate: PracticeUpdateInput!) {
    updatePractice(practiceUpdate: $practiceUpdate) {
      id
      firstName
      lastName
      msp
      centralIntakeName
      clinicName
      phone
      address
      city
      type
      province
      postalCode
      region
      specialty
      allowReferrals
      faxNumber
      emrNumber
      memberships {
        id
        user {
          id
          email
        }
      }
      invitations {
        id
        email
      }
      categoryFactors {
        category
        factor
      }
    }
  }
`

const faxRegex = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})/
const phoneRegex = /\(\d{3}\)-\d{3}-\d{4},\x20Ext\.\x20[_\d]{6}/
const postalCodeRegex = /^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]/

function getDefaultValues(practice) {
  if (practice.type === 'specialist') {
    const categoryFactors = practice.categoryFactors.reduce((acc, curr) => {
      const currCat = curr.category !== null ? curr.category : 'overall'
      acc[currCat] = String(curr.factor * 100)
      return acc
    }, {})

    return { ...practice, ...categoryFactors }
  }
  return { ...practice }
}

function PracticeManagement({ practice, currentUser }) {
  const [mutate, { loading, error, data }] = useMutation(UPDATE_PRACTICE)
  const [isEditing, setIsEditing] = useState(false)

  const practiceSchema = yup.object().shape({
    dummy: yup.string(),
    firstName: yup.string().when('dummy', {
      is: () => practice.type === 'specialist' || practice.type === 'referrer',
      then: yup.string().required('First Name is required')
    }),
    lastName: yup.string().when('dummy', {
      is: () => practice.type === 'specialist' || practice.type === 'referrer',
      then: yup.string().required('Last Name is required')
    }),
    clinicName: yup.string(),
    phone: yup
      .string()
      .required('Phone Number is required')
      .matches(phoneRegex, {
        message: 'Phone Number must be valid'
      }),
    address: yup.string().required('Address is required'),
    city: yup.string().required('City is required'),
    province: yup.string().required('Province is required'),
    postalCode: yup
      .string()
      .required('Postal Code is required')
      .matches(postalCodeRegex, 'Postal Code must be valid'),
    emrNumber: yup.string().matches(faxRegex, {
      message: 'Fax Number must be valid',
      excludeEmptyString: true
    })
  })

  const methods = useForm({
    resolver: yupResolver(practiceSchema),
    criteriaMode: 'all',
    defaultValues: { ...getDefaultValues(practice) }
  })

  const { handleSubmit, control, errors, register, reset, watch } = methods

  const allowReferrals = watch('allowReferrals')

  async function onSubmit(values) {
    const msp = values.msp
    delete values.msp

    const practiceUpdate = {
      id: practice.id,
      firstName: values.firstName || '',
      lastName: values.lastName || '',
      clinicName: values.clinicName || '',
      phone: values.phone,
      address: values.address,
      city: values.city,
      province: values.province,
      postalCode: values.postalCode,
      emrNumber: values.emrNumber || '',
      allowReferrals: values.allowReferrals || ''
    }

    function factorValue(attribute) {
      if (values[attribute] === '') {
        values[attribute] = 0
      }
      return Number(values[attribute]) / 100
    }

    if (practice.type === 'specialist') {
      practiceUpdate.categoryFactors = []

      for (const attribute of Object.keys(values)) {
        if (attribute === 'overall') {
          practiceUpdate.categoryFactors.push({
            category: null,
            factor: factorValue(attribute)
          })
        } else if (practiceUpdate[attribute] === undefined) {
          practiceUpdate.categoryFactors.push({
            category: attribute,
            factor: factorValue(attribute)
          })
        }
      }
    }

    try {
      await mutate({
        variables: { practiceUpdate }
      })

      reset({ ...values, msp })
      setIsEditing(false)
    } catch (e) {
      console.error('Error updating practice details:', e.message)
    }
  }

  function handleCancel() {
    const currentPracticeValues = data?.updatePractice
      ? getDefaultValues(data.updatePractice)
      : getDefaultValues(practice)

    reset({ ...currentPracticeValues })
    setIsEditing(false)
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        aria-label="Practice Management Form">
        <Header>
          <PageTitle id="form-label">Practice</PageTitle>
          <HeaderRightContainer>
            {isEditing ? (
              <>
                <ButtonContainer>
                  <SecondaryButton
                    color="secondary"
                    padding="medium"
                    onClick={handleCancel}>
                    Cancel
                  </SecondaryButton>
                  <Button type="submit" loading={loading} padding="small">
                    <ContentSaveIcon />
                    Save Changes
                  </Button>
                </ButtonContainer>
                {error && (
                  <ErrorContainer>
                    <ErrorMessage />
                  </ErrorContainer>
                )}
              </>
            ) : (
              <SecondaryButton
                color="secondary"
                onClick={() => {
                  setIsEditing(true)
                }}>
                <EditIcon />
                Edit
              </SecondaryButton>
            )}
          </HeaderRightContainer>
        </Header>
        <PracticeBlueBar>
          <PractictionerName>
            {getPracticeDisplayName(practice, 'short', 'long')}
          </PractictionerName>
          <PracticeType>- {getPracticeType(practice.type)}</PracticeType>
        </PracticeBlueBar>
        <PracticeManagementContainer>
          <DetailsContainer>
            {practice.isTest && (
              <TestingPracticeText>
                This is a test practice.
              </TestingPracticeText>
            )}

            <Section>
              <SectionTitle>
                {`${getPracticeType(practice.type)} Information`}
              </SectionTitle>
              <Divider />
              {practice.type === 'centralIntake' && (
                <SectionContent>
                  <FormItem>
                    <FieldLabel>Region</FieldLabel>
                    <StyledFieldValue disabled={isEditing}>
                      {regions.find(r => r.id === practice.region).name}
                    </StyledFieldValue>
                  </FormItem>
                  <FormItem>
                    <FieldLabel>Specialty</FieldLabel>
                    <StyledFieldValue disabled={isEditing}>
                      {specialties.find(r => r.id === practice.specialty).name}
                    </StyledFieldValue>
                  </FormItem>
                  <FormItem>
                    <FieldLabel>Central Intake Name</FieldLabel>
                    <StyledFieldValue disabled={isEditing}>
                      {practice.centralIntakeName}
                    </StyledFieldValue>
                  </FormItem>
                </SectionContent>
              )}
              {(practice.type === 'specialist' ||
                practice.type === 'referrer') && (
                <SectionContent>
                  <FormItem>
                    <StyledSelect
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      inputRef={register}
                      disabled={!isEditing}
                      error={!!errors.firstName}
                      helperText={errors?.firstName?.message}
                      FormHelperTextProps={{ role: 'alert' }}
                      InputLabelProps={{ required: isEditing, shrink: true }}
                    />
                  </FormItem>
                  <FormItem>
                    <StyledSelect
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      inputRef={register}
                      disabled={!isEditing}
                      error={!!errors.lastName}
                      helperText={errors?.lastName?.message}
                      FormHelperTextProps={{ role: 'alert' }}
                      InputLabelProps={{ required: isEditing, shrink: true }}
                    />
                  </FormItem>
                  <FormItem>
                    <FieldLabel>MSP</FieldLabel>
                    <StyledFieldValue disabled={isEditing}>
                      {practice.msp}
                    </StyledFieldValue>
                  </FormItem>
                </SectionContent>
              )}
            </Section>
            <Section>
              <SectionTitle>Clinic Information</SectionTitle>
              <Divider />
              <SectionContent>
                <FormItem>
                  <StyledSelect
                    id="clinicName"
                    name="clinicName"
                    label="Clinic Name"
                    inputRef={register}
                    disabled={!isEditing}
                    error={!!errors.clinicName}
                    helperText={errors?.clinicName?.message}
                    FormHelperTextProps={{ role: 'alert' }}
                    InputLabelProps={{ required: false, shrink: true }}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <NumberFormat
                        id="phone"
                        name={name}
                        label="Phone No."
                        value={value}
                        onChange={onChange}
                        disabled={!isEditing}
                        mask="_"
                        format="(###)-###-####, Ext. ######"
                        placeholder="(000)-000-0000, Ext. 123"
                        customInput={StyledSelect}
                        error={!!errors.phone}
                        helperText={errors?.phone?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: isEditing, shrink: true }}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <StyledSelect
                    id="address"
                    name="address"
                    label="Address"
                    inputRef={register}
                    disabled={!isEditing}
                    error={!!errors.address}
                    helperText={errors?.address?.message}
                    FormHelperTextProps={{ role: 'alert' }}
                    InputLabelProps={{ required: isEditing, shrink: true }}
                  />
                </FormItem>
                <FormItem>
                  <StyledSelect
                    id="city"
                    name="city"
                    label="City"
                    inputRef={register}
                    disabled={!isEditing}
                    error={!!errors.city}
                    helperText={errors?.city?.message}
                    FormHelperTextProps={{ role: 'alert' }}
                    InputLabelProps={{ required: isEditing, shrink: true }}
                  />
                </FormItem>
                <FormItem>
                  <Controller
                    as={StyledSelect}
                    select
                    id="province"
                    name="province"
                    label="Province"
                    placeholder="Province"
                    control={control}
                    disabled={!isEditing}
                    error={!!errors.province}
                    helperText={errors?.province?.message}
                    FormHelperTextProps={{ role: 'alert' }}
                    InputLabelProps={{ required: isEditing, shrink: true }}>
                    {Object.keys(provinces).map(p => (
                      <MenuItem key={p} value={p}>
                        {provinces[p]}
                      </MenuItem>
                    ))}
                  </Controller>
                </FormItem>
                <FormItem>
                  <Controller
                    name="postalCode"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <StyledSelect
                        id="postalCode"
                        name="postalCode"
                        label="Postal Code"
                        value={value}
                        onChange={e => {
                          const postalCode = matchPostalCode(e.target.value)
                          onChange(postalCode)
                        }}
                        disabled={!isEditing}
                        placeholder="X1X 1X1"
                        error={!!errors.postalCode}
                        helperText={errors?.postalCode?.message}
                        FormHelperTextProps={{ role: 'alert' }}
                        InputLabelProps={{ required: isEditing, shrink: true }}
                      />
                    )}
                  />
                </FormItem>
                {practice.type === 'centralIntake' && (
                  <FormItem>
                    <FieldLabel>Fax No.</FieldLabel>
                    <StyledFieldValue disabled={isEditing}>
                      {practice.faxNumber}
                    </StyledFieldValue>
                  </FormItem>
                )}
                {practice.type === 'specialist' && (
                  <FormItem>
                    <Controller
                      name="emrNumber"
                      control={control}
                      render={({ onChange, value, name }) => (
                        <NumberFormat
                          id="emrNumber"
                          name={name}
                          label="EMR Fax No."
                          value={value}
                          onChange={onChange}
                          mask="_"
                          format="(###)-###-####"
                          placeholder="(000)-000-0000"
                          customInput={StyledSelect}
                          disabled={!isEditing}
                          style={{ width: '100%' }}
                          error={!!errors.emrNumber}
                          helperText={errors?.emrNumber?.message}
                          FormHelperTextProps={{ role: 'alert' }}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </FormItem>
                )}
              </SectionContent>
            </Section>
            {practice.type === 'specialist' && (
              <>
                <Section>
                  <SectionTitle>Specialty</SectionTitle>
                  <Divider />
                  <SectionContent>
                    <FormItem>
                      <FieldLabel>Region</FieldLabel>
                      <StyledFieldValue disabled={isEditing}>
                        {regions.find(r => r.id === practice.region).name}
                      </StyledFieldValue>
                    </FormItem>
                    <FormItem>
                      <FieldLabel>Specialty</FieldLabel>
                      <StyledFieldValue disabled={isEditing}>
                        {
                          specialties.find(r => r.id === practice.specialty)
                            .name
                        }
                      </StyledFieldValue>
                    </FormItem>
                  </SectionContent>
                </Section>

                <Section>
                  <SectionTitle>Referral Categories</SectionTitle>
                  <Divider />
                  <CategorySectionContent>
                    {isEditing && !practice.isVerified && (
                      <WarningMessage>
                        <Bold>
                          Your referral category settings will be editable once
                          your practice is verified.
                        </Bold>
                      </WarningMessage>
                    )}
                    <InfoMessage>
                      When this is set to “Directed only” you will only be sent
                      directed referrals.
                    </InfoMessage>
                    <Controller
                      name="allowReferrals"
                      control={control}
                      render={({ onChange, value, name }) => (
                        <StyledRadioGroup name={name} onChange={onChange} row>
                          <StyledFormControlLabel
                            value="all"
                            control={
                              <Radio
                                size="small"
                                checked={value === 'all'}
                                disabled={!isEditing || !practice.isVerified}
                              />
                            }
                            label="Directed and Next Available"
                          />
                          <StyledFormControlLabel
                            value="directedOnly"
                            control={
                              <Radio
                                size="small"
                                checked={value === 'directedOnly'}
                                disabled={!isEditing || !practice.isVerified}
                              />
                            }
                            label="Directed only"
                          />
                        </StyledRadioGroup>
                      )}
                    />
                    {allowReferrals === 'all' ? (
                      <InfoMessage>
                        Your overall volume is applied on top of your category
                        volume for each category. For example, an overall volume
                        of 50% and a category volume of 50% results in a
                        combined volume of 25%. Setting your overall value to 0
                        means you will receive no referrals.
                      </InfoMessage>
                    ) : (
                      <InfoMessage>
                        To receive referrals your overall volume and any
                        categories you would like to receive referrals in must
                        be set to on.
                      </InfoMessage>
                    )}
                    <CategoryAvailability
                      categoryFactors={practice.categoryFactors.filter(
                        c => c.category === null
                      )}
                      isEditing={isEditing && practice.isVerified}
                      showPercents={allowReferrals === 'all'}
                    />
                    {allowReferrals === 'all' && (
                      <InfoMessage>
                        For each category, enter a percentage between 0 and 100.
                        If a value is 0, you will receive no referrals in that
                        category.
                      </InfoMessage>
                    )}
                    <CategorySelectorContainer
                      setmargin={allowReferrals === 'directedOnly'}>
                      <CategoryAvailability
                        categoryFactors={practice.categoryFactors.filter(
                          c => c.category !== null
                        )}
                        isEditing={isEditing && practice.isVerified}
                        showPercents={allowReferrals === 'all'}
                      />
                    </CategorySelectorContainer>
                  </CategorySectionContent>
                </Section>
              </>
            )}
          </DetailsContainer>
          <RightColumn>
            <Card>
              <Section>
                <MembersTitleWrapper>
                  <SectionTitle>Members</SectionTitle>
                  <Link to={`/settings/practices/${practice.id}/members`}>
                    <IconButton aria-label="invite members" color="secondary">
                      <AccountPlusIcon />
                    </IconButton>
                  </Link>
                </MembersTitleWrapper>
                <Divider />
                <StyledList disablePadding>
                  {practice.memberships.map(membership => {
                    return (
                      <StyledListItem
                        key={membership.user.id}
                        disableGutters
                        dense>
                        {membership.user.email}
                        {membership.user.id !== currentUser.id && (
                          <DeleteMembershipButton membership={membership} />
                        )}
                      </StyledListItem>
                    )
                  })}
                  {practice.invitations
                    .filter(i => {
                      return (
                        practice.memberships.filter(
                          m => m.user.id === i.user?.id
                        ).length === 0
                      )
                    })
                    .map(invitation => {
                      return (
                        <StyledInvitationListItem
                          key={invitation.id}
                          disableGutters
                          dense>
                          {invitation.email}
                          <InviteText>Invitation pending</InviteText>
                        </StyledInvitationListItem>
                      )
                    })}
                </StyledList>
              </Section>
            </Card>
            {practice.type === 'specialist' && (
              <Card>
                <SectionTitle>Verification</SectionTitle>
                <Divider />
                <VerificationWrapper>
                  {practice.isVerified && (
                    <VerifiedIconTextContainer>
                      <StyledCheckDecagramIcon size={45} />
                      <VerifiedText>
                        This practice has been verified
                      </VerifiedText>
                    </VerifiedIconTextContainer>
                  )}
                  {!practice.isVerified && (
                    <>
                      <VerifiedIconTextContainer>
                        <StyledAlertDecagramIcon size={45} />
                        <NotVerifiedText>
                          Verification Pending...
                        </NotVerifiedText>
                      </VerifiedIconTextContainer>
                      <InfoMessage>
                        <Bold>
                          You can send referrals and use other functions of the
                          app but cannot receive referrals until you are
                          verified.{' '}
                        </Bold>
                        Please reach out to{' '}
                        <EmailLink
                          href="mailto:support@clinnect.ca?subject=Clinnect Support"
                          target="__blank">
                          support@clinnect.ca
                        </EmailLink>{' '}
                        if you have any questions.
                      </InfoMessage>
                    </>
                  )}
                </VerificationWrapper>
              </Card>
            )}
            <DeleteInfoContainer>
              <DeleteInfoText>
                If you want to&nbsp;<Bold>delete a practice</Bold>
              </DeleteInfoText>
              <DeleteInfoText>
                <StyledPhoneIcon size={18} />
                Call us at 1-250-319-8039
              </DeleteInfoText>
              <DeleteInfoText>
                <StyledEmailIcon size={18} />
                Email us at&nbsp;{' '}
                <EmailLink
                  href="mailto:support@clinnect.ca?subject=Clinnect Delete Practice"
                  target="__blank">
                  support@clinnect.ca
                </EmailLink>
              </DeleteInfoText>
            </DeleteInfoContainer>
          </RightColumn>
        </PracticeManagementContainer>
      </form>
    </FormProvider>
  )
}

PracticeManagement.propTypes = {
  practice: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    centralIntakeName: PropTypes.string,
    clinicName: PropTypes.string,
    msp: PropTypes.string,
    phone: PropTypes.string,
    region: PropTypes.string,
    specialty: PropTypes.string,
    allowReferrals: PropTypes.oneOf(['all', 'directedOnly', '']),
    address: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postalCode: PropTypes.string,
    type: PropTypes.string,
    memberships: PropTypes.array,
    invitations: PropTypes.array,
    isTest: PropTypes.bool,
    isVerified: PropTypes.bool,
    faxNumber: PropTypes.string,
    emrNumber: PropTypes.string,
    categoryFactors: PropTypes.array
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.string
  })
}

export default PracticeManagement
