import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'

const StyledTextField = styled(TextField)`
  width: 100%;

  .MuiSelect-icon {
    top: calc(50% - 8px);
    margin-right: 10px;
  }
  .MuiInput-underline {
    :before,
    :after,
    :hover:before {
      border-bottom: 0;
    }
  }
  .MuiFormLabel-root {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #393e46;
    transform: translate(0) scale(1);
  }
  .MuiInputBase-input {
    font-weight: 400;
    color: #393e46;
    padding: 10px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    margin-top: 8px;
    :hover,
    :focus {
      border: 1px solid #385170;
      border-radius: 4px;
    }
  }
`

export default StyledTextField
