import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f9fbfe;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 30px 100px;
  width: 100%;
  max-width: 1920px;
  min-height: 100vh;
  height: 100%;
`

function PageContent({ children }) {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  )
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageContent
