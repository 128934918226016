import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const color = {
  grey: '#3a3a3a',
  white: '#fff',
  yellow: '#f1c40f',
  green: '#2dcc70',
  red: '#e84c3d',
  purple: '#9A59B5',
  lightYellow: '#f1c40f10',
  lightGreen: '#2dcc7010',
  lightRed: '#e84c3d10',
  lightPurple: '#9A59B510'
}

const Indicator = styled.span`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;

  text-transform: uppercase;
`

const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${props => color[props.text]};
  background-color: ${props => color[props.bg]};
  border: ${props => `1px solid ${color[props.border]}`};

  width: 80px;
  height: 20px;
  border-radius: 6px;
`

function getBg(state) {
  if ('success' === state) return 'green'
  if ('failed' === state) return 'red'
  if ('processing' === state) return 'yellow'
  if ('accepted' === state) return 'lightGreen'
  if ('redirected' === state) return 'lightPurple'
  if ('declined' === state) return 'lightRed'
  if ('pending' === state) return 'lightYellow'
  return 'white'
}

function getText(state) {
  if (['success', 'failed'].includes(state)) return 'white'
  return 'grey'
}

function getBorder(state) {
  if ('accepted' === state) return 'green'
  if ('declined' === state) return 'red'
  if ('pending' === state) return 'yellow'
  if ('redirected' === state) return 'purple'
  return 'white'
}

const StatusIndicator = ({ state }) => (
  <Background text={getText(state)} bg={getBg(state)} border={getBorder(state)}>
    <Indicator>{state}</Indicator>
  </Background>
)

StatusIndicator.propTypes = {
  state: PropTypes.oneOf([
    'processing',
    'pending',
    'declined',
    'failed',
    'success',
    'accepted',
    'redirected'
  ]).isRequired
}

export default StatusIndicator
