import styled from 'styled-components'

const FieldLabel = styled.h3`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: rgba(58, 58, 58, 0.7);
  line-height: 18px;
  margin: 0 9px 0 0;
`

const FieldValue = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #3a3a3a;
  line-height: 18px;
  background-color: #f9fbfe;
  border-radius: 3px;
  padding: 8px 15px;
  margin: 9px 0 0;
  min-height: 34px;
  min-width: 250px;
  white-space: pre-wrap;
`

export { FieldLabel, FieldValue }
