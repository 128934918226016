import React, { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import useAccount from '../../hooks/useAccount'

const LogoutPage = () => {
  const { setAccount } = useAccount()
  const client = useApolloClient()
  const location = useLocation()

  useEffect(() => {
    client.clearStore()
    setAccount(null)
  }, [client, setAccount])

  return <Redirect to={'/login' + location.search} />
}

export default LogoutPage
