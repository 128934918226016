import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { ListItem } from '@material-ui/core'
import { IconButton } from '../common/Button'
import FileDownloadIcon from 'mdi-react/FileDownloadIcon'
import DataUsageIcon from 'mdi-react/DataUsageIcon'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import useAccount from '../../hooks/useAccount'
import { getFileTypeIcon } from '../../util/files'
import {
  deriveAesKey,
  unwrapEccKey,
  unwrapAesKey,
  importPublicKey,
  decryptFile
} from '../../crypto'

const decryptionFudge = 1.05

const StyledListItem = styled(ListItem)`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: #f9fbfe;
  border-radius: 3px;
  min-height: 20px;
  color: ${({ textcolor }) => textcolor};
  text-decoration: underline;
  :hover {
    cursor: pointer;
    color: black;
  }
`

const WithDownloadButton = styled(StyledListItem)`
  display: flex;
  justify-content: space-between;
  background: transparent;
  text-decoration: none;
  color: #4ca1d8;
  font-size: 12px;

  :hover {
    text-decoration: underline;
    color: #4ca1d8;
  }

  & > span {
    flex: 1;
    padding-right: 4px;
  }
`

const DownloadIcon = styled(IconButton)`
  .mdi-icon {
    ${props =>
      props.downloadstate === 'downloading'
        ? 'animation: spin 2s linear infinite;'
        : ''}
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const FileIcon = styled.div`
  display: flex;
  align-content: center;

  .mdi-icon {
    color: #e84c3d;
    padding: 9px 9px 9px 0px;
    min-width: 24px;
    height: 33px;
  }
`

const CREATE_DOWNLOAD_URL = gql`
  mutation createDownloadUrl($referral: ID!, $file: Int!) {
    createDownloadUrl(referral: $referral, file: $file)
  }
`

const FileButton = ({
  file,
  referral,
  sender,
  recipient,
  onDownloadProgress = () => {},
  withIcons = false,
  withFileIcon = false
}) => {
  const [createDownloadUrl] = useMutation(CREATE_DOWNLOAD_URL)
  const [downloadState, setDownloadState] = useState('new')
  const [objectUrl, setObjectUrl] = useState()
  const { getAccount } = useAccount()

  async function downloadFile() {
    const key = referral.files[file].key
    const name = referral.fileNames[file]
    const account = await getAccount()

    if (downloadState === 'downloading') return
    setDownloadState('downloading')

    const recipientMembership = recipient.practice.memberships.find(
      m => m.user.id === account.id
    )

    const senderMembership = sender.practice.memberships.find(
      m => m.user.id === account.id
    )

    const participant = recipientMembership ? recipient : sender
    const membership = recipientMembership || senderMembership

    const membershipAesKey = await deriveAesKey(
      account.privateKey,
      await importPublicKey(membership.publicKey)
    )

    const practicePrivateKey = await unwrapEccKey(
      membership.privateKey.encryptedData,
      membershipAesKey,
      membership.privateKey.iv,
      true
    )

    const referralAesKey = await deriveAesKey(
      practicePrivateKey,
      await importPublicKey(participant.publicKey)
    )

    const encryptionKey = await unwrapAesKey(
      participant.encryptionKey.encryptedData,
      referralAesKey,
      participant.encryptionKey.iv,
      'enc'
    )

    const {
      data: { createDownloadUrl: url }
    } = await createDownloadUrl({
      variables: { referral: referral.id, file }
    })

    const response = await fetch(url)
    const reader = response.body.getReader()
    const total = +response.headers.get('Content-Length') * decryptionFudge

    const chunks = []
    let isDone = false
    let progress = 0

    while (!isDone) {
      onDownloadProgress({ key, name, progress, total })

      const response = await reader.read()

      if (response.value) {
        chunks.push(response.value)
        progress += response.value.length
      }

      isDone = response.done
    }

    const blob = new Blob(chunks)
    const encryptedFile = new File([blob], name)

    const iv = referral.files[file].iv

    const rawFile = await decryptFile(encryptedFile, encryptionKey, iv)

    const a = document.createElement('a')
    a.download = rawFile.name

    const newObjectUrl = URL.createObjectURL(rawFile)
    a.href = newObjectUrl

    a.click()

    progress = total
    onDownloadProgress({ key, name, progress, total })
    setDownloadState('downloaded')

    setObjectUrl(newObjectUrl)
  }

  useEffect(() => {
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl)
    }
  }, [objectUrl])

  if (withIcons || withFileIcon) {
    return (
      <WithDownloadButton
        disableGutters
        dense
        role="button"
        onClick={downloadFile}
        disabled={downloadState === 'downloading'}>
        <FileIcon>{getFileTypeIcon(referral.fileNames[file])}</FileIcon>
        <span>{referral.fileNames[file]}</span>
        {withIcons && (
          <DownloadIcon downloadstate={downloadState} color="secondary">
            {downloadState === 'downloading' ? (
              <DataUsageIcon />
            ) : (
              <FileDownloadIcon />
            )}
          </DownloadIcon>
        )}
      </WithDownloadButton>
    )
  }

  return (
    <StyledListItem
      disableGutters
      dense
      role="button"
      onClick={downloadFile}
      disabled={downloadState === 'downloading'}
      textcolor={downloadState !== 'new' ? 'rgba(58,58,58,0.7)' : '#4CA1D8'}>
      {referral.fileNames[file]}
    </StyledListItem>
  )
}

FileButton.propTypes = {
  file: PropTypes.number,
  referral: PropTypes.object,
  sender: PropTypes.object.isRequired,
  recipient: PropTypes.object.isRequired,
  onDownloadProgress: PropTypes.func,
  withIcons: PropTypes.bool,
  withFileIcon: PropTypes.bool
}

export default FileButton
