import React from 'react'
import PropTypes from 'prop-types'
import { Button as MuiButton, CircularProgress, Fade } from '@material-ui/core'
import styled from 'styled-components'

const colors = {
  primary: '#F2641A',
  secondary: '#069987',
  white: '#fff',
  blue: '#3598DB',
  grey: 'rgba(58, 58, 58, 0.7)'
}

const hoverColors = {
  primary: 'rgba(242, 100, 26, 0.6)',
  secondary: 'rgba(6, 153, 135, 0.6)',
  white: 'rgba(0, 0, 0, 0.4)',
  blue: 'rgba(53, 152, 219, 0.6)',
  grey: 'rgba(58, 58, 58, 0.5)'
}

const padding = {
  default: '9px 32px',
  large: '9px 60px',
  medium: '9px 24px',
  small: '9px 15px',
  tiny: '9px 0',
  short: '5px'
}

const ButtonSpinner = styled(CircularProgress)`
  position: absolute;
  margin-left: -12px;
  margin-top: -12px;
  left: 50%;
  top: 50%;
  color: #1193ad;
`

const DefaultButton = styled(({ color, ...props }) => <MuiButton {...props} />)`
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  background-color: ${props => colors[props.color]};
  opacity: ${props => (props.disabled ? '0.5 ' : '1')};
  color: #fff;
  border: 1px solid ${props => colors[props.color]};
  border-radius: 4px;
  padding: ${props => padding[props.padding]};

  :hover {
    background-color: ${props => hoverColors[props.color]};
  }
  & ${ButtonSpinner} {
    color: #fff;
  }
  .mdi-icon {
    height: 15px;
    width: 15px;
    margin-right: 9px;
  }
`

const Button = ({
  children,
  loading,
  color = 'primary',
  padding = 'default',
  ...props
}) => (
  <DefaultButton disabled={loading} {...props} color={color} padding={padding}>
    {loading && (
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms'
        }}
        unmountOnExit>
        <ButtonSpinner size={24} />
      </Fade>
    )}
    {children}
  </DefaultButton>
)

const SecondaryButton = styled(Button)`
  color: ${props => (props.color ? colors[props.color] : [colors['primary']])};
  background-color: #fff;
  border: 1px solid rgba(58, 58, 58, 0.15);
  :hover {
    background-color: #ebedf0;
  }

  & ${ButtonSpinner} {
    color: ${props => colors[props.color]};
  }
`

const IconButton = styled(SecondaryButton)`
  padding: ${props => (props.padding ? padding[props.padding] : '9px')};
  min-width: 15px;
  .mdi-icon {
    margin: 0;
  }
`

const LinkButton = styled(Button)`
  color: ${props => colors[props.color]};
  padding: 9px 0px;
  border: none;
  background: transparent;
  :hover {
    background: #fff;
    opacity: 0.6;
    text-decoration: underline solid ${props => colors[props.color]};
  }
  a {
    color: ${props => colors[props.color]};
    text-decoration: none;
    :hover {
      background: #fff;
      opacity: 0.6;
      text-decoration: underline solid ${props => colors[props.color]};
    }
  }
`

const TextButton = styled(Button)`
  color: ${props => colors[props.color]};
  border: none;
  background: transparent;
  padding: 9px;
  :hover {
    background: rgba(0, 0, 0, 0.04);
  }
`

Button.propTypes = {
  ...MuiButton.propTypes,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'blue', 'grey']),
  padding: PropTypes.oneOf([
    'default',
    'large',
    'medium',
    'small',
    'tiny',
    'short'
  ])
}

export default Button
export { SecondaryButton, IconButton, LinkButton, TextButton }
