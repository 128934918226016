import React from 'react'
import styled from 'styled-components'
import LogoImage from '../../images/logo.svg'
import Illustration from '../../images/signIn.svg'
import LegalLinks from '../common/LegalLinks'

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
`

const LogoContainer = styled.div`
  position: absolute;
  left: 28%;
  top: 60px;
  z-index: -1;
`

const IllustrationContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -5px;
  z-index: -2;
`

const StyledImg = styled.img`
  width: 100vw;
  min-width: 1440px;
`

const BottomContainer = styled.div`
  position: absolute;
  width: 100vw;
  left: 0;
  bottom: 0;
  z-index: 0;
  padding: 30px 120px;
`

const Hero = () => (
  <Container>
    <LogoContainer>
      <img
        src={LogoImage}
        width="272"
        alt="Clinnect: Simple, central, secure"
      />
    </LogoContainer>
    <IllustrationContainer>
      <StyledImg src={Illustration} width="1440" alt="" />
    </IllustrationContainer>
    <BottomContainer>
      <LegalLinks />
    </BottomContainer>
  </Container>
)

export default Hero
